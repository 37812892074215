import { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { services } from '@tw/types/module/services';
import { Skeleton, Text, Icon, IconName, ActionIcon, Menu, Loader } from '@tw/ui-components';
import { WorkflowWithRun } from 'components/Willy/types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $shopUsers } from '$stores/$users';
import { chatActions } from 'utils/dataLayer/constants';
import { Role } from '$stores/willy/$globalDashboardRoles';
import { genericEventLogger } from 'utils/dataLayer';
import { analyticsEvents } from 'utils/dataLayer/constants';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom';
import { formatSequenceItemDate, simpleEditSequence } from './utils/sequences';
import { UserAndDate } from './UserAndDate';
import _db, { FieldValue } from 'utils/DB';

type SequenceListCardProps = {
  item: WorkflowWithRun;
  index: number;
  onItemClick: (item: any) => void;
  workflowCategories?: Role[];
  fetchWorkflows: () => Promise<void>;
  fromDashboard?: boolean;
  todaysDesign?: boolean;
  read: boolean;
};

export const SequenceListCard: React.FC<SequenceListCardProps> = ({
  item,
  index,
  onItemClick,
  workflowCategories,
  fetchWorkflows,
  fromDashboard,
  todaysDesign,
  read,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const isSmall = useIsSmall();
  const showSmallVersion = isSmall || fromDashboard;

  const providers = useMemo(() => {
    const icons = item?.providers
      ?.map((provider) => {
        const service = services[provider];
        if (!service || !service?.smallIcon) return null;
        return { ...service, id: provider };
      })
      .filter(Boolean);

    return showSmallVersion ? icons?.slice(0, 4) : icons;
  }, [item?.providers, showSmallVersion]);

  const menuOptions = useMemo(() => {
    const actionSection = {
      items: [
        {
          content: 'View Details',
          onAction: async () => {
            const params = new URLSearchParams(search);
            params.set('from', 'feed');
            navigate({
              pathname: `/workflows/${item.id}`,
              search: params.toString(),
            });
            setOpened(false);
          },
        },
        {
          content: 'Edit Agent',
          onAction: async () => {
            const params = new URLSearchParams(search);
            params.set('from', 'feed');
            navigate({
              pathname: `/workflows/create/${item.id}`,
              search: params.toString(),
            });
            setOpened(false);
          },
        },
        {
          content: 'Run Agent',
          onAction: async () => {
            const params = new URLSearchParams(search);
            params.set('from', 'feed');
            params.set('run', 'true');
            navigate({
              pathname: `/workflows/create/${item.id}`,
              search: params.toString(),
            });

            setOpened(false);

            genericEventLogger(analyticsEvents.CHAT, {
              action: chatActions.RUN_SEQUENCE,
              id: item.id,
              text: item.name || '',
              conversationId: item.id,
            });
          },
        },
        {
          content: 'Dismiss from feed',
          onAction: async () => {
            await simpleEditSequence({
              ...item,
              skipSaveRunDate: true,
              lastRunAt: firebase.firestore.FieldValue.delete() as any,
            });
            await fetchWorkflows();
            setOpened(false);
          },
        },
        {
          content: 'Mark as read',
          onAction: async () => {
            await simpleEditSequence({
              ...item,
              skipSaveRunDate: true,
              lastRunAt: FieldValue.delete() as any,
            });
            await fetchWorkflows();
            setOpened(false);
          },
        },
      ],
    };

    return [actionSection];
  }, [item, navigate, search, fetchWorkflows]);

  return (
    <SequenceListCardWrapper
      fromDashboard={fromDashboard}
      item={item}
      onItemClick={onItemClick}
      key={item.id + index}
      index={index}
    >
      <div
        className={`flex justify-between items-start gap-4 w-full border-b relative no-underline group bg-white sm:hover:bg-gray-100 ${!read ? 'border-l-4 border-solid border-l-blue-500 border-r-0 border-t-0 border-b-0' : ''}`}
      >
        {item.running && (
          <div className="w-full absolute top-0 left-0">
            <Loader size="xs" />
          </div>
        )}
        <div className={`flex justify-between w-full ${todaysDesign ? 'items-center' : ''}`}>
          <div
            className={`${showSmallVersion ? 'p-4' : 'p-6.5'} flex flex-col gap-3 flex-shrink-0 basis-9/12`}
          >
            <div className="gap-2">
              <div className="flex justify-between gap-2">
                <div>
                  <p
                    className={`${showSmallVersion ? 'text-xl' : 'text-[20px]'} ${todaysDesign ? 'line-clamp-2' : ''} font-bold text-black`}
                  >
                    {item?.run?.outputTitle || item.name}
                  </p>
                </div>
                <div className={`${showSmallVersion ? 'hidden' : 'block'}`}>
                  <Menu opened={opened} onClose={() => setOpened(false)} withinPortal={true}>
                    <Menu.Target>
                      <div
                        className={`${opened ? 'visible' : 'sm:invisible sm:group-hover:visible'}`}
                      >
                        <ActionIcon
                          icon="three-dots"
                          iconSize={18}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpened(!opened);
                          }}
                        />
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {menuOptions.map((section, i) => (
                        <div key={i}>
                          {section.items.map((item, j) => {
                            const { content, onAction, ...rest } = item;

                            return (
                              <Menu.Item
                                key={j}
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  await onAction();
                                }}
                                {...rest}
                              >
                                <Text size="sm" weight={500}>
                                  {content}
                                </Text>
                              </Menu.Item>
                            );
                          })}
                        </div>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                </div>
              </div>
            </div>
            {/* <div className={`${showSmallVersion ? 'hidden' : 'block'}`}>
              <Text size="md">
                <span className="line-clamp-2 text-[13px]">
                  {item?.run?.outputDescription ?? item.description}
                </span>
              </Text>
            </div> */}
            <div
              className={`flex justify-between w-full ${showSmallVersion ? '' : 'items-center'} gap-2 mt-auto`}
            >
              <div className="flex items-end gap-4 overflow-hidden">
                <div className="pr-4 border-solid border-0 border-r border-gray-400">
                  <UserAndDate
                    forceSmallVersion={showSmallVersion}
                    user={item.user}
                    date={
                      todaysDesign
                        ? null
                        : formatSequenceItemDate(item?.lastRunAt || null, showSmallVersion)
                    }
                  />
                </div>

                <div className="flex flex-col">
                  <Text size="xs" fw="600">
                    <span
                      className={`${showSmallVersion ? 'line-clamp-1' : 'line-clamp-2'} overflow-hidden text-ellipsis`}
                    >
                      {item.name}
                    </span>
                  </Text>
                </div>
              </div>
            </div>
          </div>
          {!!item.run.insightsCoverImage && (
            <div
              className={`flex min-h-full aspect-square overflow-hidden ${showSmallVersion ? '' : 'flex-shrink-0'} basis-3/12 ${todaysDesign ? 'rounded-md w-[70px] h-[70px]' : ''}`}
            >
              <img
                src={item.run.insightsCoverImage}
                alt={item.name}
                width="auto"
                height="100px"
                className="w-full h-full object-cover"
              />
            </div>
          )}
        </div>
      </div>
    </SequenceListCardWrapper>
  );
};

const SequenceListCardWrapper = ({ children, fromDashboard, item, onItemClick, index }) => {
  if (!fromDashboard) {
    return (
      <Link
        className="no-underline group rounded-lg overflow-hidden"
        to={{
          pathname: `/workflows/view/${item.id}/${item.run?.id}`,
          search: '?from=feed',
        }}
        onClick={() => onItemClick(item)}
        key={item.id + index}
      >
        {children}
      </Link>
    );
  }
  return (
    <div
      className="no-underline group rounded-lg overflow-hidden cursor-pointer"
      onClick={() => onItemClick(item)}
    >
      {children}
    </div>
  );
};
