import { useEffect } from 'react';
import { checkServicesConnection, initShop, updateShop } from '../../ducks/actions';
import { useAppDispatch } from '../../index';
import _db from '../../utils/DB';
import { useAppSelector } from '../../reducers/RootType';
import moment from '@tw/moment-cached/module/timezone';
import { $store } from '@tw/snipestate';

const $isInit = $store(false);

export const useStoreInit = () => {
  const dispatch = useAppDispatch();
  const currentShopId = useAppSelector((state) => state.currentShopId);
  useEffect(() => {
    if (currentShopId) {
      return _db().onSnapshot((shopDataSnapshot) => {
        const data = shopDataSnapshot.data() || {};
        moment.tz.setDefault(data.timezone);
        dispatch(checkServicesConnection(data));
        dispatch(updateShop(data));
        if (!$isInit.get()) {
          dispatch(initShop({ ...data, shopId: currentShopId, id: currentShopId }));
          $isInit.set(true);
        }
      });
    }
  }, [dispatch, currentShopId]);
};
