import { Checkbox } from '@shopify/polaris';
import {
  ActionIcon,
  Button,
  Icon,
  Modal,
  Select,
  Text,
  Textarea,
  TextInput,
} from '@tw/ui-components';
import {
  Column,
  ColumnName,
  PossibleValue,
  RetentionPeriod,
  retentionPeriodItems,
  SequenceProgressEvent,
} from './types/willyTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import {
  $defaultAiColumns,
  $mergedAiColumns,
  $shopAiColumns,
  $shopSequences,
  updateShopColumn,
} from '$stores/willy/$sequences';
import { toast } from 'react-toastify';
import { Allotment } from 'allotment';
import { WorkflowBuilderWrapper } from './sequenceBuilder/WorkflowBuilderWrapper';
import { noop } from 'lodash';
import { $socket } from '$stores/$socket';
import { Link, useSearchParams } from 'react-router-dom';
import { ColorPickerPopover } from './ColorPicker';
import { copyGlobalColumnToShop } from './utils/willyUtils';

type WorkflowAiColumnsProps = {
  allowTest: boolean;
  inModal?: boolean;
  specificColumn?: ColumnName;
};

export const WorkflowAiColumns: React.FC<WorkflowAiColumnsProps> = ({
  allowTest,
  specificColumn,
  inModal,
}) => {
  const [searchParams, setSearchParams] = useSearchParams(
    specificColumn ? { column: specificColumn } : undefined,
  );

  const currentShopId = useStoreValue($currentShopId);
  const shopSequences = useStoreValue($shopSequences);
  const socket = useStoreValue($socket);
  const defaultAiColumns = useStoreValue($defaultAiColumns);
  const mergedColumns = useStoreValue($mergedAiColumns);
  const [_, setShopColumns] = useWritableStore($shopAiColumns);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [runningSequenceId, setRunningSequenceId] = useState<string | null>(null);
  const [seeAllColumns, setSeeAllColumns] = useState(false);

  const columnToEdit = specificColumn ?? (searchParams.get('column') as ColumnName | null);

  const column = useMemo(() => {
    return mergedColumns.columns.find((c) => c.key === columnToEdit);
  }, [mergedColumns.columns, columnToEdit]);

  const runningSequence = useMemo(() => {
    return shopSequences.find((seq) => seq.id === runningSequenceId);
  }, [runningSequenceId, shopSequences]);

  const saveColumn = useCallback(
    async (props: Partial<Column<ColumnName>>) => {
      if (!column || !currentShopId) {
        return;
      }

      try {
        setLoading(true);
        setError(null);
        await updateShopColumn(currentShopId, column.key, {
          ...props,
        });
        toast.success('Saved', { autoClose: 1000 });
      } catch (error) {
        setError(error.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    },
    [column, currentShopId],
  );

  useEffect(() => {
    const func = (msg) => {
      if (msg.type !== 'workflow-progress' || msg.account !== currentShopId) {
        return;
      }

      const { sequenceId: seqId, type } = msg.data as SequenceProgressEvent;

      if (seqId !== runningSequenceId) {
        return;
      }

      if (type === 'sequence-done') {
        setRunningSequenceId(null);
      }
    };

    socket.on('message', func);

    return () => {
      socket.off('message', func);
    };
  }, [socket, runningSequenceId, currentShopId]);

  return (
    <>
      <Allotment className="h-full">
        <Allotment.Pane className="flex-1 flex flex-col gap-2 p-4 pb-0 !overflow-auto">
          <div className="flex flex-row items-center justify-between">
            {!inModal && <Text as="h1">AI Columns Configuration</Text>}
            {!specificColumn && (
              <Button
                variant="secondary"
                onClick={() => {
                  setSeeAllColumns(true);
                }}
              >
                All Columns Configuration
              </Button>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <Select
              disabled={!!specificColumn}
              searchable
              my="sm"
              label="Select A Column"
              data={[
                {
                  label: 'None',
                  value: '',
                },
              ].concat(
                mergedColumns.columns.map((column) => ({
                  label: column.label,
                  value: column.key,
                })),
              )}
              onChange={(id) => {
                if (id === null) {
                  return;
                }

                setSearchParams((old) => {
                  return {
                    ...old,
                    column: id,
                  };
                });
              }}
              value={columnToEdit || ''}
            />

            {!!columnToEdit && (
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                  <Textarea
                    label="Description"
                    value={column?.description}
                    onChange={(e) => {
                      setShopColumns((prev) => {
                        if (!column) {
                          return prev;
                        }

                        return {
                          ...prev,
                          data: prev.data?.map((c) => {
                            return { ...c, description: e.target.value };
                          }),
                        };
                      });
                    }}
                  />
                </div>
                <Select
                  searchable
                  my="sm"
                  label="Select Agent"
                  data={[
                    {
                      label: 'None',
                      value: '',
                    },
                  ].concat(
                    shopSequences.map((seq) => ({
                      label: seq.name,
                      value: seq.id,
                    })),
                  )}
                  onChange={(id) => {
                    setShopColumns((prev) => {
                      if (!column) {
                        return prev;
                      }

                      return {
                        ...prev,
                        data: prev.data?.map((c) => {
                          if (c.key === column.key) {
                            return { ...c, sequenceId: id };
                          }
                          return c;
                        }),
                      };
                    });
                  }}
                  value={column?.sequenceId || ''}
                />
                <Select
                  my="sm"
                  label="Retention Period"
                  data={Object.values(retentionPeriodItems)}
                  onChange={(id) => {
                    setShopColumns((prev) => {
                      if (!column) {
                        return prev;
                      }

                      return {
                        ...prev,
                        data: prev.data?.map((c) => {
                          if (c.key === column.key) {
                            return { ...c, retentionPeriod: id as RetentionPeriod };
                          }
                          return c;
                        }),
                      };
                    });
                  }}
                  value={column?.retentionPeriod || 'forever'}
                />
                <Select
                  my="sm"
                  label="Possible Values"
                  data={[
                    {
                      label: 'Any Value',
                      value: 'any',
                    },
                    {
                      label: 'Number',
                      value: 'number',
                    },
                    {
                      label: 'Predefined Values',
                      value: 'enum',
                    },
                  ]}
                  onChange={(id) => {
                    if (!id) {
                      return;
                    }

                    setShopColumns((prev) => {
                      if (!column) {
                        return prev;
                      }

                      return {
                        ...prev,
                        data: prev.data?.map((c) => {
                          if (c.key === column.key) {
                            return { ...c, valueType: id as 'number' | 'enum' | 'any' };
                          }
                          return c;
                        }),
                      };
                    });
                  }}
                  value={column?.valueType || 'number'}
                />

                {column?.valueType === 'enum' && (
                  <EnumValues
                    values={column?.possibleValues || []}
                    disabled={false}
                    onChange={(values) => {
                      setShopColumns((prev) => {
                        if (!column) {
                          return prev;
                        }

                        return {
                          ...prev,
                          data: prev.data?.map((c) => {
                            if (c.key === column.key) {
                              return { ...c, possibleValues: values };
                            }
                            return c;
                          }),
                        };
                      });
                    }}
                  />
                )}

                <div>
                  <Textarea
                    label="Prompt"
                    value={column?.defaultPrompt}
                    onChange={(e) =>
                      setShopColumns((prev) => {
                        if (!column) {
                          return prev;
                        }

                        return {
                          ...prev,
                          data: prev.data?.map((c) => {
                            if (c.key === column.key) {
                              return { ...c, defaultPrompt: e.target.value };
                            }
                            return c;
                          }),
                        };
                      })
                    }
                    autosize
                  />
                </div>
                {!!error && <Text color="red.4">{error}</Text>}
                <div
                  className={`flex flex-row gap-2 items-center border-t border-solid border-t-gray-200 pt-4 pb-4 border-b-0 border-l-0 border-r-0 ${
                    inModal ? 'sticky bottom-0 bg-white z-10' : ''
                  }`}
                >
                  <Button
                    loading={loading}
                    disabled={
                      column?.sequenceId === null &&
                      JSON.stringify(column) ===
                        JSON.stringify(defaultAiColumns.data?.find((c) => c.key === column.key))
                    }
                    onClick={async () => {
                      if (!column) {
                        return;
                      }

                      await saveColumn(column);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    loading={loading}
                    variant="secondary"
                    onClick={async () => {
                      if (!currentShopId || !column) {
                        return;
                      }

                      const defaultColumn = defaultAiColumns.data?.find(
                        (c) => c.key === column.key,
                      );

                      if (!defaultColumn) {
                        return;
                      }

                      setShopColumns((prev) => {
                        if (!column) {
                          return prev;
                        }

                        return {
                          ...prev,
                          data: prev.data?.map((c) => {
                            if (c.key === column.key) {
                              return defaultColumn;
                            }
                            return c;
                          }),
                        };
                      });

                      await copyGlobalColumnToShop(column);
                    }}
                  >
                    Reset to default
                  </Button>
                  {!!columnToEdit && !!column?.sequenceId && allowTest && (
                    <div className="flex ml-auto">
                      <Button
                        disabled={
                          !mergedColumns.columns.some((c) => c.sequenceId === column?.sequenceId)
                        }
                        onClick={async () => {
                          if (!column?.sequenceId) {
                            return;
                          }

                          if (!!runningSequenceId) {
                            setRunningSequenceId(null);
                          } else {
                            setRunningSequenceId(column.sequenceId);
                          }
                        }}
                      >
                        {!!runningSequenceId ? 'Stop Agent' : 'Test Agent'}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Allotment.Pane>

        {!!runningSequenceId && !!runningSequence && (
          <Allotment.Pane className="h-full">
            <WorkflowBuilderWrapper
              sequence={runningSequence!}
              setSequence={noop}
              canAddNewStep={false}
              runOnInit
              readOnly
            />
          </Allotment.Pane>
        )}
      </Allotment>
      <Modal opened={seeAllColumns} onClose={() => setSeeAllColumns(false)} title="All Columns">
        <div className="flex flex-col gap-8">
          {mergedColumns.columns.map((column) => {
            if (!column) {
              return null;
            }

            const agent = shopSequences.find((seq) => seq.id === column.sequenceId);

            if (!agent) {
              return null;
            }

            return (
              <div
                key={column.key}
                className="flex flex-row gap-4 items-center justify-between pb-4 mb-4 border-b border-solid border-t-0 border-l-0 border-r-0 border-gray-200 last:border-b-0 last:mb-0 last:pb-0 last:border-none"
              >
                <div
                  onClick={() => setSearchParams((old) => ({ ...old, column: column.key }))}
                  className="flex-1 underline cursor-pointer text-blue-700"
                >
                  {column.label}
                </div>
                <div className="flex items-center m-auto">→</div>
                <Link
                  target="_blank"
                  to={`/workflows/create/${agent?.id}`}
                  className="flex-1 text-right underline cursor-pointer text-blue-700"
                >
                  <div className="flex flex-row gap-2 items-center">
                    <div className="flex-shrink-0">{agent?.name}</div>
                    <div className="flex-shrink-0">
                      <Icon name="external-minor" size={10} />
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

type EnumValuesProps = {
  values: PossibleValue[];
  disabled: boolean;
  onChange: (values: PossibleValue[]) => void;
};

export const EnumValues: React.FC<EnumValuesProps> = ({ values, onChange, disabled }) => {
  return (
    <div className="flex flex-col gap-2">
      {values.map((value, index) => (
        <div key={index} className="flex flex-row gap-2 items-center">
          <ColorPickerPopover
            color={value.color}
            onChange={(color) => {
              const newValues = [...values];
              newValues[index] = { ...value, color };
              onChange(newValues);
            }}
            saveOnChange={false}
            onReset={() => {
              const newValues = [...values];
              newValues[index] = { ...value, color: '' };
              onChange(newValues);
            }}
          />
          <TextInput
            value={value.value}
            onChange={(e) => {
              const newValues = [...values];
              newValues[index] = { ...value, value: e };
              onChange(newValues);
            }}
            disabled={disabled}
          />
          <ActionIcon
            icon="delete"
            onClick={() => {
              const newValues = [...values];
              newValues.splice(index, 1);
              onChange(newValues);
            }}
            disabled={disabled}
          />
        </div>
      ))}
      <div>
        <Button
          onClick={() => {
            onChange([...values, { value: '', color: '' }]);
          }}
          disabled={disabled}
        >
          Add Value
        </Button>
      </div>
    </div>
  );
};

type AiColumnModalProps = {
  column: ColumnName;
  opened: boolean;
  onClose: () => void;
  allowTest: boolean;
  onlyOneColumn?: boolean;
};

export const AiColumnModal: React.FC<AiColumnModalProps> = ({
  column,
  opened,
  onClose,
  allowTest,
  onlyOneColumn,
}) => {
  return (
    <Modal.Root opened={opened} onClose={onClose} size="xl" centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Edit Column</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body h="500px" p={0}>
          <WorkflowAiColumns specificColumn={column} allowTest={allowTest} inModal />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
