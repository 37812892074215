import moment from '@tw/moment-cached';
import {
  CDPActionFilter,
  CDPSegmentFilterType,
  CDPSegmentQuery,
  CDPUserPropertyFilter,
  Segment,
  SegmentMetric,
  SegmentSync,
  SegmentType,
  UN_SUPPORTED_ACTIONS,
  UN_SUPPORTED_PROPERTIES,
} from '@tw/types/module/services/cdp';

export type SegmentDataView = {
  id: string;
  name: string;
  description?: string;
  query?: CDPSegmentQuery;
  customersCount?: number;
  updatedAt?: moment.Moment;
  ordersCount?: number;
  revenue?: number;
  historicalLTV30Days?: number;
  historicalLTV60Days?: number;
  historicalLTV90Days?: number;
  historicalLTV180Days?: number;
  historicalLTV365Days?: number;
  isCurentlyBuilding: boolean;
  type: SegmentType;
  integrationsSyncDetails: SegmentSync[];
  hasActiveSyncs: boolean;
  label?: string;
  hasUnSupportedProperty?: boolean;
};

export function getSegmentDataView(segment: Segment): SegmentDataView {
  return {
    id: segment.id,
    name: segment.name,
    description: segment.description,
    query: segment.type === SegmentType.LOOKALIKE ? undefined : segment.query,
    isCurentlyBuilding: !segment.lastInstance,
    integrationsSyncDetails: segment.integrationsSyncDetails || [],
    hasActiveSyncs: segment.integrationsSyncDetails?.some((i) => i.isSynced) || false,
    type: segment.type,
    label: segment.type === SegmentType.RFM ? 'RFM' : undefined,
    ...(segment.lastInstance && {
      [SegmentMetric.CUSTOMERS_COUNT]: segment.lastInstance.customersCount,
      updatedAt: moment(segment.lastInstance.createdAt),
      [SegmentMetric.ORDERS_COUNT]: segment.lastInstance.ordersCount || 0,
      [SegmentMetric.REVENUE]: segment.lastInstance.revenue || 0,
      [SegmentMetric.HISTORICAL_LTV_30_DAYS]: segment.lastInstance.historicalLTV30Days || 0,
      [SegmentMetric.HISTORICAL_LTV_60_DAYS]: segment.lastInstance.historicalLTV60Days || 0,
      [SegmentMetric.HISTORICAL_LTV_90_DAYS]: segment.lastInstance.historicalLTV90Days || 0,
      [SegmentMetric.HISTORICAL_LTV_180_DAYS]: segment.lastInstance.historicalLTV180Days || 0,
      [SegmentMetric.HISTORICAL_LTV_365_DAYS]: segment.lastInstance.historicalLTV365Days || 0,
      hasUnSupportedProperty:
        UN_SUPPORTED_PROPERTIES.some((property) => {
          return segment.query?.filters.some((f) => {
            return f.filters.some((filter) => {
              return (
                (filter as CDPActionFilter | CDPUserPropertyFilter)?.definition?.property
                  ?.property === property
              );
            });
          });
        }) ||
        UN_SUPPORTED_ACTIONS.some((action) => {
          return segment.query?.filters.some((f) => {
            return f.filters.some((filter) => {
              if (filter?.type == CDPSegmentFilterType.ACTION) {
                return (filter as CDPActionFilter)?.definition?.action?.type === action;
              }
            });
          });
        }),
    }),
  };
}
