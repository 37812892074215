import { Button, SegmentedControl, Switch } from '@tw/ui-components';
import { WorkflowStepBase, WorkflowStepTool, WorkflowStepToolPreload } from '../types/willyTypes';
import { stepActionType } from '../types/willyTypes';
import { FlowStepWithText } from './FlowStepWithText';
import { useState, useMemo, useEffect } from 'react';
import { Editor } from '@monaco-editor/react';
import { formatSqlSafely } from '../utils/willyUtils';

type FlowToolStepProps = {
  step: WorkflowStepTool & WorkflowStepBase;
  stepChange: (newStep: WorkflowStepTool & WorkflowStepBase) => void;
  setIsPristine: (isPristine: boolean) => void;
  readOnly: boolean;
  depth: number;
  toolConfig?: stepActionType;
};

export const FlowToolStep: React.FC<FlowToolStepProps> = ({
  step,
  stepChange,
  setIsPristine,
  toolConfig,
  readOnly,
  depth,
}) => {
  const [mode, setMode] = useState<'text' | 'code'>('text');
  const language = useMemo(() => {
    if (step.toolToUse === 'TextToPython') {
      return 'python';
    } else if (step.toolToUse === 'TextToSQL') {
      return 'sql';
    }
    return 'plaintext';
  }, [step.toolToUse]);

  const defaultCode = useMemo(() => {
    if (step.toolToUse === 'TextToPython' && step.toolPreload?.name === 'TextToPython') {
      return step.toolPreload?.pythonCode || '';
    } else if (step.toolToUse === 'TextToSQL' && step.toolPreload?.name === 'TextToSQL') {
      return formatSqlSafely(step.toolPreload?.generatedQuery || '', 'clickhouse');
    }
    return null;
  }, [step.toolToUse, step.toolPreload]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex">
        <SegmentedControl
          data={[
            { label: 'Text', value: 'text' },
            { label: step.toolToUse === 'TextToSQL' ? 'SQL' : 'Python', value: 'code' },
          ]}
          value={mode}
          onChange={(value) => setMode(value as 'text' | 'code')}
        />
      </div>
      {mode === 'text' && (
        <FlowStepWithText
          readOnly={readOnly}
          text={step.text || ''}
          textChange={(text) => stepChange({ ...step, text })}
          toolConfig={toolConfig}
          setIsPristine={setIsPristine}
          variables={step.variables || []}
          depth={depth}
          allowComments
          variableChange={(variable) => {
            const variables = step.variables || [];
            const variableIndex = variables.findIndex((v) => v.key === variable.key);
            if (variableIndex !== -1) {
              variables[variableIndex] = variable;
            } else {
              variables.push(variable);
            }
            stepChange({
              ...step,
              variables,
            });
          }}
        />
      )}
      {mode === 'code' && (
        <Editor
          language={language}
          height="400px"
          className="rounded overflow-hidden"
          defaultLanguage={language}
          defaultValue={defaultCode || ''}
          theme="light"
          value={defaultCode || ''}
          options={{
            readOnly: readOnly,
            minimap: {
              enabled: false,
            },
          }}
          onChange={(value) => {
            if (step.toolToUse === 'TextToPython') {
              let toolPreload: WorkflowStepToolPreload | null = null;
              if (value !== '') {
                toolPreload = {
                  ...step.toolPreload,
                  name: 'TextToPython',
                  pythonCode: value,
                } as WorkflowStepToolPreload;
              }

              stepChange({
                ...step,
                toolPreload,
              });
            } else if (step.toolToUse === 'TextToSQL') {
              let toolPreload: WorkflowStepToolPreload | null = null;
              if (value !== '') {
                toolPreload = {
                  ...step.toolPreload,
                  name: 'TextToSQL',
                  generatedQuery: value,
                } as WorkflowStepToolPreload;
              }
              stepChange({
                ...step,
                toolPreload: {
                  ...step.toolPreload,
                  name: 'TextToSQL',
                  generatedQuery: value,
                } as WorkflowStepToolPreload,
              });
            }
          }}
          onMount={(editor, monaco) => {}}
        />
      )}
    </div>
  );
};
