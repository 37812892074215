import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Loader, Button, Text, ActionIcon } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $userId } from '$stores/$user';
import { useFilteredItems } from './hooks/useFilteredItems';
import { WillySearchInput } from './WillySearchInput';
import { WillySelect } from './dashboardManagment/WillySelect';
import { $shopUsers } from '$stores/$users';
import { $globalDashboardRoles } from '$stores/willy/$globalDashboardRoles';
import { MENU_SIZE } from './constants';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { RunDoc } from './types/willyTypes';
import { WorkflowWithRun } from './types/willyTypes';
import { $currentShopId } from '$stores/$shop';
import _db, { toArray } from 'utils/DB';
import InfiniteScroll from 'react-infinite-scroll-component';
import firebase from 'firebase/compat/app';
import { analyticsEvents, genericEventLogger, sequencesActions } from 'utils/dataLayer';
import useDebounce from 'utils/useDebounce';
import { SequenceFeedList } from './SequenceFeedList';
import { computeFeatureFlags } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { UpgradePageFallBack } from 'feature-flag-system/components';

const filterOptions = [
  { value: 'all', label: 'All Agents' },
  { value: 'user', label: 'My Agents' },
];

export const SequencesAllList: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const userId = useStoreValue($userId);
  const { data: shopUsers } = useStoreValue($shopUsers);
  const shopUsersMap = useMemo(() => {
    return shopUsers?.reduce(
      (acc, user) => {
        acc[user.id] = user.name;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [shopUsers]);

  const isSmall = useIsSmall();
  const defaultFilter = filterOptions[0].value;

  const currentShopId = useStoreValue($currentShopId);
  const [workflows, setWorkflows] = useState<WorkflowWithRun[]>([]);
  const [loading, setLoading] = useState(false);
  const [lastWorkflow, setLastWorkflow] = useState<firebase.firestore.Timestamp | null>(null);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [filter, setFilter] = useState(defaultFilter);
  const [freeSearch, setFreeSearch] = useState('');

  const debouncedFreeSearch = useDebounce(freeSearch, 500);

  const fetchWorkflows = useCallback(
    async (startAfter?: firebase.firestore.Timestamp | null) => {
      if (!currentShopId) {
        return;
      }

      setLoading(true);

      let query = _db(currentShopId)
        .collection('data_sequences')
        .where('lastRunAt', '!=', null)
        .orderBy('lastRunAt', 'desc')
        .limit(20);

      if (!!debouncedFreeSearch.trim()) {
        query = query
          .where('name', '>=', debouncedFreeSearch.trim())
          .where('name', '<', debouncedFreeSearch.trim() + '\uf8ff');
      }
      if (filter === 'user') {
        query = query.where('user', '==', userId);
      }

      if (startAfter) {
        query = query.startAfter(startAfter);
      }

      const workflows = await query.get();
      setHasMoreItems(!workflows.empty && workflows.size === 20);
      const asArray = toArray(workflows) as WorkflowWithRun[];
      const runsPromises = asArray.map(async (workflow) => {
        const runs = await _db(currentShopId)
          .collection('data_sequences')
          .doc(workflow.id)
          .collection('runs')
          .orderBy('finishedAt', 'desc')
          .limit(1)
          .get();

        const runsArray = toArray(runs) as RunDoc[];
        const lastRun: RunDoc = {
          ...runsArray?.[0],
          userId: shopUsersMap?.[workflow.user] || '',
        };
        workflow.run = lastRun;
        return workflow;
      });
      const runs: WorkflowWithRun[] = await Promise.all(runsPromises);
      setWorkflows((prev) => {
        if (startAfter) {
          return [...prev, ...runs];
        }
        return runs;
      });
      setLastWorkflow(runs[runs.length - 1]?.lastRunAt || null);
      setLoading(false);
    },
    [currentShopId, shopUsersMap, filter, userId, debouncedFreeSearch],
  );

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={scrollContainerRef}
      className="flex flex-col h-full w-full no-scrollbar overflow-y-scroll"
    >
      {/* {!isSmall && (
        <div style={{ height: MENU_SIZE }}>
          <div className="willy-dash-header bg-white flex flex-row items-center lg:w-full h-full grow overflow-hidden overflow-x-auto border-b border-gray-200 border-solid border-t-0 border-l-0 border-r-0 px-6 py-4 lg:px-8">
            <div className="flex items-center justify-between flex-grow">
              <Text size="lg" weight={500}>
                Feed
              </Text>
            </div>
            <Button
              onClick={() => {
                navigate({ pathname: '/workflows/create', search });
              }}
              leftSection="plus-1"
              iconSize={20}
            >
              New Agent
            </Button>
          </div>
        </div>
      )} */}
      <div className="hidden sm:block p-4 sm:py-12 w-full max-w-[800px] m-auto">
        <div className="w-full flex gap-6.5">
          <div className="w-full flex items-center gap-4">
            <WillySearchInput
              value={freeSearch}
              onChange={setFreeSearch}
              placeholder="Search All Agents (case sensitive)"
              className="!p-0 overflow-visible w-full"
              clearable
            />
          </div>
          <div className="flex items-center lg:justify-between flex-shrink-0 gap-4">
            {!loading && <ActionIcon icon="refresh" onClick={() => fetchWorkflows()} />}
            {loading && <Loader size="sm" />}
            <WillySelect data={filterOptions} value={filter} onChange={setFilter} />
          </div>
        </div>
      </div>
      <SequenceFeedList
        workflows={workflows}
        loading={loading}
        fetchWorkflows={fetchWorkflows}
        lastWorkflow={lastWorkflow}
        hasMoreItems={hasMoreItems}
        freeSearch={freeSearch}
      />
    </div>
  );
};

export const SequenceFeed = computeFeatureFlags(
  [FeatureFlag.AGENT_SUPPORT_FF],
  SequencesAllList,
  () => (
    <UpgradePageFallBack
      InAppContextBannerEnabled={false}
      title="Moby Agents"
      description="Supercharge your workflow and get back precious time to focus on what matters most with Moby Agents"
      featureFlag={FeatureFlag.AGENT_SUPPORT_FF}
    />
  ),
);
