import { Button, Icon, IconName, Text, Tooltip } from '@tw/ui-components';
import { services, ServicesIds } from '@tw/types/module/services';
import { useStoreValue } from '@tw/snipestate';
import { $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import { useNavigate } from 'react-router-dom';
import { $isAgentSupported } from '$stores/willy/$sequences';
import { openUpgradeAgentsPopup } from '../$upgradeAgentsModal';

export const TemplateLibraryCard: React.FC<{
  exists: boolean;
  themeColor: 'blue' | 'green' | 'turquoise';
  iconSrc?: string;
  onCopy: () => void;
  key: string;
  isLocked: boolean;
  onPreview: () => void;
  name: string;
  description: string;
  providers: ServicesIds[];
  needsUpdate: boolean;
  copyLoading: boolean;
  onUpdate: () => void;
  onAddToCart?: () => void;
  itemInCart?: boolean;
}> = ({
  exists,
  themeColor,
  iconSrc,
  onCopy,
  key,
  isLocked,
  onPreview,
  name,
  description,
  providers,
  needsUpdate,
  copyLoading,
  onUpdate,
  onAddToCart,
  itemInCart,
}) => {
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const headerBg = `https://storage.googleapis.com/public-assets-shofifi/agents-library/${themeColor}_theme_top.png`;
  const iconBg = `https://storage.googleapis.com/public-assets-shofifi/agents-library/${themeColor}_icon_bg.png`;
  const isAgentSupported = useStoreValue($isAgentSupported);

  const onAddAgent = () => {
    if (!isAgentSupported) {
      openUpgradeAgentsPopup(`You're one step away from adding ${name} agent!`, 'add_agent');
    } else {
      onCopy();
    }
  };

  return (
    <div
      className={`relative cursor-pointer w-full h-full flex flex-col gap-2 border border-solid border-gray-200 rounded-lg shadow-sm bg-white hover:shadow-md transition duration-300 ease-in-out overflow-hidden group`}
      key={key}
    >
      {!!onAddToCart && !!itemInCart && (
        <div
          className="absolute top-3 right-3 z-[200] cursor-pointer"
          onClick={() => onAddToCart()}
        >
          <Tooltip label="Remove from cart" lightTooltip={true}>
            <Icon name="green-check" size={24} />
          </Tooltip>
        </div>
      )}
      <div className="absolute hidden group-hover:flex left-1/2 transform -translate-x-1/2  z-[12] w-full h-full gap-6.5 items-center justify-center flex-col ">
        <div className="relative w-full h-full ">
          <div className="absolute top-0 left-0 bg-gray-800 w-full h-full opacity-50" />
          {!!onAddToCart && !itemInCart && !exists && (
            <div className="absolute top-3 right-3  cursor-pointer" onClick={() => onAddToCart()}>
              <Tooltip label="Add to cart" lightTooltip={true}>
                <Icon name="plus-circle" color="white" size={24} />
              </Tooltip>
            </div>
          )}
          <div className="absolute left-1/2 transform -translate-x-1/2 h-full flex flex-col justify-center items-center gap-4 z-[100]">
            {(isTwGlobalDashboardCreatorClaim || !isLocked) && (
              <>
                {!exists && (
                  <Button size="md" onClick={() => onAddAgent()} loading={copyLoading}>
                    Add Agent
                  </Button>
                )}
                {needsUpdate && (
                  <Button size="md" onClick={onUpdate} loading={copyLoading}>
                    Update Agent
                  </Button>
                )}
                {(isTwGlobalDashboardCreatorClaim || exists) && (
                  <Button size="md" variant="activator" onClick={onPreview} fullWidth>
                    Preview
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="h-[80px] relative">
        <div className="h-[80%]" style={{ backgroundImage: `url(${headerBg})` }} />
        <div
          className="w-[68px] h-[68px] bg-contain absolute rounded-full bottom-[-10px] left-1/2 transform -translate-x-1/2 flex items-center justify-center"
          style={{ backgroundImage: `url(${iconBg})` }}
        >
          {!!iconSrc ? (
            <img src={iconSrc} height="34" />
          ) : (
            <Icon name="rocket" color="white" size={34} />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-7 p-[20px]">
        <Text fz={18} fw={500} lineClamp={1}>
          {name}
        </Text>
        <Text fw={400} fz={14} lineClamp={3}>
          {description}
        </Text>
        <div className="flex gap-[15px]">
          {providers?.map((provider) => {
            const service = services[provider];
            return (
              <div
                className="relative w-[24px] h-[24px] bg-white flex items-center justify-center "
                key={provider}
              >
                <Icon key={provider} name={service?.smallIcon as IconName} size={16} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
