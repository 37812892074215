import { useCallback, useState } from 'react';
import {
  DialectWithBoth,
  stepActionType,
  WorkflowStepBase,
  WorkflowStepLoop,
} from '../types/willyTypes';
import { Button, Text, TextInput } from '@tw/ui-components';
import { FlowStepWithFullEditor } from './FlowStepWithFullEditor';
import { convertDataToJson, executeCustomQuery } from '../utils/willyUtils';
import { useStoreValue } from '@tw/snipestate';
import { $activeAccounts, $currency, $currentShopId, $timezone } from '$stores/$shop';
import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

type FlowLoopStepProps = {
  step: WorkflowStepLoop & WorkflowStepBase;
  onSave: () => void;
  readOnly: boolean;
  toolConfig?: stepActionType;
  depth: number;
  dialect: DialectWithBoth;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowLoopStep: React.FC<FlowLoopStepProps> = ({
  step,
  onSave,
  readOnly,
  toolConfig,
  depth,
  dialect,
  setIsPristine,
}) => {
  const shopId = useStoreValue($currentShopId);
  const activeAccounts = useStoreValue($activeAccounts);
  const currency = useStoreValue($currency);
  const timezone = useStoreValue($timezone);

  const [showModal, setShowModal] = useState(false);
  const [testing, setTesting] = useState(false);
  const [result, setResult] = useState<Record<string, string | number | null>[] | null>(null);

  return (
    <div className="flex flex-col gap-2">
      <Text>
        This step takes the results from the previous step and executes the remaining agent steps in
        a loop. The number of iterations is determined by the number of rows from the previous step.
        In each iteration, one row is used as input for executing the remaining agent steps.
        <br />
        <br />
        Steps inside the loop won't be include data from the steps before the loop, only the row of
        the iteration itself.
      </Text>
    </div>
  );
};
