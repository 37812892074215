import { Checkbox, OptionList, Popover } from '@shopify/polaris';
import { providerDomainEnum } from '@tw/types/module/sensory';
import { Button, Icon, Text, TextInput, Tooltip } from '@tw/ui-components';
import { providers } from 'ducks/shopIntegrations';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FlowStepWithText } from './FlowStepWithText';
import { stepActionType, WorkflowStepBase, WorkflowStepSendToWarehouse } from '../types/willyTypes';
import { FlowStepWithFullEditor } from './FlowStepWithFullEditor';

type FlowSendToWarehouseProps = {
  step: WorkflowStepSendToWarehouse & WorkflowStepBase;
  providerId: string;
  integrationId: string;
  tableId: string;
  readOnly: boolean;
  query: string;
  useDedicatedQuery: boolean;
  toolConfig?: stepActionType;
  depth: number;
  setIsPristine: (isPristine: boolean) => void;
  onSave: (
    query: string,
    params: Record<string, string>,
    useDedicatedQuery: boolean,
    providerId: string,
    integrationId: string,
    tableId: string,
  ) => void;
  variableChange: (variable: { key: string; value: string }) => void;
};

export const FlowSendToWarehouse: React.FC<FlowSendToWarehouseProps> = ({
  step,
  providerId,
  integrationId,
  tableId,
  readOnly,
  useDedicatedQuery,
  toolConfig,
  depth,
  setIsPristine,
  onSave,
  variableChange,
}) => {
  const providerList = useSelector(providers);

  const [opened, setOpened] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [query, setQuery] = useState(step.query);
  const [params, setParams] = useState(step.queryParams || {});

  const warehouseProviders = useMemo(() => {
    const list = Object.entries(providerList)
      .filter(([id, p]) => p.isConnected && p.domain === providerDomainEnum.dataWarehouse)
      .map(([id, p]) => ({
        label: id,
        value: id,
        options: p.integrations.map((integration) => ({
          label: integration.name || integration.id,
          value: integration.id,
        })),
      }));
    return list;
  }, [providerList]);

  const activeProvider = warehouseProviders.find((provider) => provider.value === providerId);

  const activator = (
    <div>
      <Button
        variant="white"
        iconSize={20}
        leftSection="db"
        onClick={() => setOpened((x) => !x)}
        disabled={readOnly || !warehouseProviders.length}
      >
        {activeProvider?.label || 'Select Provider'}
      </Button>
    </div>
  );

  return (
    <div className="flex flex-col gap-4">
      {warehouseProviders.length === 0 && (
        <Text size="sm" color="gray.4">
          <span>No warehouse providers found</span>{' '}
          <Link
            to={{
              pathname: '/integrations',
              search: window.location.search,
              hash: window.location.hash,
            }}
            target="_blank"
          >
            Connect a warehouse provider
          </Link>
        </Text>
      )}
      <Popover
        active={opened}
        onClose={() => setOpened(false)}
        preventCloseOnChildOverlayClick
        preferredAlignment="left"
        activator={
          <div className="w-full">
            <Tooltip label="Warehouse Providers">{activator}</Tooltip>
          </div>
        }
      >
        <OptionList
          options={[]}
          sections={warehouseProviders.map((provider) => ({
            title: provider.label,
            options: provider.options,
          }))}
          onChange={([integrationId]) => {
            setIsPristine(false);
            const provider = warehouseProviders.find((p) =>
              p.options.some((o) => o.value === integrationId),
            );
            if (!provider) {
              return;
            }
            onSave(query, params, useDedicatedQuery, provider.value, integrationId, tableId);
            setOpened(false);
          }}
          selected={[integrationId]}
        />
      </Popover>

      <TextInput
        label="Table id"
        description="Only alphanumeric characters and underscores are allowed"
        disabled={readOnly || !warehouseProviders.length}
        value={tableId}
        onChange={(value) => {
          setIsPristine(false);
          const withoutSpaces = value.replace(/\s/g, '_');
          const sanitized = withoutSpaces.replace(/[^a-zA-Z0-9_]/g, '');
          onSave(query, params, useDedicatedQuery, providerId, integrationId, sanitized);
        }}
      />
      <div className="flex items-center gap-2">
        <Checkbox
          label="Use dedicated query"
          checked={useDedicatedQuery}
          onChange={(value) => {
            setIsPristine(false);
            onSave(query, params, value, providerId, integrationId, tableId);
          }}
        />
        <Tooltip label="If you check this, you will need to provide a query to sync to the warehouse. Leave this unchecked if you want to use the latest query from this step.">
          <Icon name="info" />
        </Tooltip>
      </div>
      {useDedicatedQuery && (
        <div className="flex flex-col gap-4 relative">
          <FlowStepWithText
            text={query}
            readOnly={readOnly}
            textChange={(text) => {
              setIsPristine(false);
              setQuery(text);
              onSave(text, params, useDedicatedQuery, providerId, integrationId, tableId);
            }}
            variableChange={(variable) => {
              variableChange(variable);
            }}
            toolConfig={toolConfig}
            setIsPristine={setIsPristine}
            variables={step.variables || []}
            depth={depth}
          />
          <div className="flex justify-end gap-2 items-center">
            <Button variant="white" onClick={() => setShowModal(true)} disabled={readOnly}>
              Edit in full editor
            </Button>
          </div>
        </div>
      )}

      <FlowStepWithFullEditor
        opened={showModal}
        onClose={() => setShowModal(false)}
        query={query}
        setQuery={setQuery}
        params={params}
        setParams={setParams}
        onSave={(query, params) => {
          setIsPristine(false);
          setQuery(query);
          onSave(query, params, useDedicatedQuery, providerId, integrationId, tableId);
        }}
      />
    </div>
  );
};
