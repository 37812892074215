import { Timestamp } from '../../../utils/DB';
import { SalesPlatform, ServicesIds } from '@tw/types/module/services';
import { FormattedColor, IconName, colors } from '@tw/ui-components';
import { BaseColumn } from 'components/library/TWTable/types';
import { editor } from 'monaco-editor';
import { Socket } from 'socket.io-client';
import { BqTable } from '../../../pages/FreeQuery/dataStuff/tables';
import { FilterBuilderType } from '../../../pages/FreeQuery/SqlFilterBuilder';
import { WillyEmoji } from './emojiTypes';
import { AxisDomainItem } from 'recharts/types/util/types';
import { BqColumn } from 'pages/FreeQuery/dataStuff/columns/types';
import { FeatureFlag, FeatureFlagConfigKey } from '@tw/feature-flag-system/module/types';
import firebase from 'firebase/compat/app';
import { CurrentDateRange, PrevDateRange } from '../../../$stores/willy/$dateRange';
import {
  FilterRow,
  BuilderTable,
  FilterOperator,
  WillyOperatorItem,
  ScheduleRulesRow,
} from '@tw/willy-data-dictionary/module/columns/types';
import { type valueFormats, type Granularity, AnalyticsObjectType } from '@tw/types';
import { BaseSummaryMetric, SummaryMetricIdsTypes } from '@tw/types/module/SummaryMetrics';
import { type RootState } from 'reducers/RootType';
import { AttributionData, AttributionStatsRequest, PixelColumnsKeys } from 'types/attribution';
import { IndustryTypes } from '@tw/types/module/types/IndustryType';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';
import { ForecastModel, Interval } from '../constants';
import { CodeAction } from '../WillySimpleText';
import { PreviousPeriodIds } from '@tw/types/module/datePicker/datePicker';
import { LabelPosition } from 'recharts/types/component/Label';

export type SuggestionModes = 'title' | 'question';
export type ChatSources =
  | 'chat'
  | 'dashboard'
  | 'editor'
  | 'summary'
  | 'sequence'
  | 'pixel'
  | 'sequenceRun';

export type SearchSource = 'knowledgeBase' | 'blog' | 'readme' | 'webSearch' | 'webSiteTW';

export type RelatedSchema = {
  table: string;
  columns: string[];
};

export type UniformChatResponse = {
  type: 'text' | 'error';
  text: string;
  question: string;
  messageId: string;
  conversationId?: string;
  shopId: string;
  userId: string;
  data: NlqResponse[];
  messages: HistoryItem[];
  latencies?: {
    [key: string]: number;
  };
};

export const Dialects = ['bigquery', 'clickhouse'] as const;
export type Dialect = (typeof Dialects)[number];
export type DialectWithBoth = Dialect | 'both';

export type AnswerNlqQuestionParams = {
  shopId: string;
  additionalShopIds: string[];
  conversationId: string;
  messageId: string;
  question: string;
  dialect: Dialect;
  generateInsights?: boolean;
  query?: string;
  metrics?: string[];
  widgetId?: string;
  dashboardId?: string;
  conversationLink?: string;
  mentionedUsers?: string[];
  source: ChatSources;
  returnQueryOnly?: boolean;
  relatedSchema?: RelatedSchema[];
  currency: string;
  timezone: string;
  industry: IndustryTypes;
  stream?: boolean;
  dashboardData?: any;
  modelToolsName?: string;
  sqlGeneratingModel?: string;
  forecastModel?: ForecastModel;
  preGeneratedQuery?: NlqResponse[];
  runWorkflowIfPossible?: boolean;
  sequenceName?: string;
  sequenceRunData?: any;
  uploadedFiles?: MobyUploadedFile[];
};

export type RunSequenceRequest = AnswerNlqQuestionParams & {
  sequenceId: string;
  upToStepId?: string;
  variables?: Record<string, string>;
  asDraft?: boolean;
  rephraseStepsBasedOnConversation?: string;
  onlyStepId?: string;
  startDate?: string;
  endDate?: string;
  prevStartDate?: string;
  prevEndDate?: string;
};

export type WillyEmitEvents = {
  'answer-nlq-question': (msg: AnswerNlqQuestionParams) => void;
  'run-sequence': (msg: RunSequenceRequest) => void;
  'stop-nlq-question': (msg: BaseEventPayload) => void;
  'suggest-title': (msg: {
    shopId: string;
    queryId: string;
    mode: SuggestionModes;
    generatedQuery: string;
    question?: string;
  }) => void;
  'insight-sql': (msg: {
    shopId: string;
    query: string;
    id: string;
    conversationId: string;
  }) => void;
  'build-sql': (msg: {
    shopId: string;
    existingQuery: string;
    table: BqTable;
    filterBuilder?: FilterBuilderType;
    isPivot?: boolean;
    id: string;
  }) => void;
  'stop-answer-nlq-question': (msg: BaseEventPayload) => void;
  'stop-sequence': (msg: { sequenceId: string } & BaseEventPayload) => void;
  'get-metrics-data': (msg: {
    shopId: string;
    messageId: string;
    start: string;
    end: string;
    granularity: Granularity;
    previous: boolean;
    adTableFilters: FilterRow[][];
    dialect: Dialect;
  }) => void;
  'explain-widget': (msg: {
    dashboard: WillyDashboardElement;
    dateRange: string;
    widgetTitle: string;
    widgetQuestion: string;
    sqlQuery: string;
    parsedData: any;
    totalRows: number;
    messageId: string;
  }) => void;
  'explain-general': (msg: {
    title: string;
    data: any;
    messageId: string;
    dateRange: string;
  }) => void;
};

export type BaseEventPayload = {
  shopId: string;
  messageId: string;
  question?: string;
  originalQuestion?: string;
  conversationId?: string;
};

export type HelpCenterLink = {
  title: string;
  link: string;
};

export type WillyListenEvents = {
  error: (error: string, messageId?: string) => void;
  progress: (
    progress: {
      message: string;
      progress: number;
    } & BaseEventPayload,
  ) => void;
  data: (
    data: {
      message: string;
      queryId: string;
      progress: number;
    } & BaseEventPayload,
  ) => void;
  insight: (insight: { text: string } & BaseEventPayload) => void;
  conversation: (
    conversation: {
      data: any;
      error: string;
      question: string;
    } & BaseEventPayload,
  ) => void;
  help: (help: { text: string; links: HelpCenterLink[] } & BaseEventPayload) => void;
  title: (title: { title: string } & BaseEventPayload) => void;
  done: (
    data: {
      times: Record<any, any>;
    } & BaseEventPayload,
  ) => void;
  'metrics-data': (data: MetricsDataEvent) => void;
  'sequence-progress': (data: SequenceProgressEvent) => void;
  'chat-response': ChatResponse;
  'widget-insight': (data: {
    type: 'chunk' | 'done' | 'error';
    messageId: string;
    text?: string;
  }) => void;
  'general-insight': (data: {
    type: 'chunk' | 'done' | 'error';
    messageId: string;
    text?: string;
  }) => void;
};

export type ChatResponseStarted = {
  type: 'started';
} & BaseEventPayload;

export type ChatResponseDone = {
  type: 'done';
} & BaseEventPayload;

export type ChatResponseError = {
  type: 'error';
  error: string;
} & BaseEventPayload;

export type ChatResponseInsight = {
  type: 'insight';
  text: string;
} & BaseEventPayload;

export type ChatResponseWorkflowStarted = {
  type: 'workflow_started';
  workflowId: string;
  text: string;
} & BaseEventPayload;

export type ChatResponseWorkflowEdit = {
  type: 'workflow_edit';
  workflowId: string;
  text: string;
} & BaseEventPayload;

export type ChatResponseWorkflow = {
  type: 'workflow_run';
  workflowId: string;
  text: string;
} & BaseEventPayload;

export type Step = 'start' | 'write' | 'execute' | 'error' | 'done';

export type SupportedLanguages = 'python' | 'sql' | 'text';

export type ChatResponseToolBuild = {
  type: 'tool-build';
  tool: WillyToolName;
  progress: number;
} & BaseEventPayload;

export type ChatResponseToolStep = {
  type: 'tool-step';
  tool: WillyToolName;
  delta: {
    progress: number;
    reason: string;
    content?: string;
    contentLanguage?: SupportedLanguages;
  };
} & BaseEventPayload;

export type ChatResponseToolStarted = {
  type: 'tool-started';
  tool: WillyToolName;
} & BaseEventPayload;

export type ChatResponseToolDone = {
  type: 'tool-done';
  toolResults: ToolResult;
} & BaseEventPayload;

export type ChatResponses =
  | ChatResponseStarted
  | ChatResponseDone
  | ChatResponseError
  | ChatResponseInsight
  | ChatResponseWorkflowStarted
  | ChatResponseWorkflowEdit
  | ChatResponseWorkflow
  | ChatResponseToolBuild
  | ChatResponseToolStep
  | ChatResponseToolStarted
  | ChatResponseToolDone;

export type DataFromWarehouseResponse = {
  nlqResponse?: NlqResponse;
  errorMessages?: string[];
};

export type ToolFunctionsDictionary = {
  TextToSQL: DataFromWarehouseResponse;
  GenerateInsights: { text: string; nlqResponse?: NlqResponse; error?: string | null };
  Searching: SearchingResponse;
  TextToPython: CodeInterpreterResponse;
  Forecasting: LongForecastingViaNLQResponse;
  MarketingMixModel: BudgetReallocationViaNLQResponse; //BudgetReallocationToolResultType;
};

export type WebSources = {
  sources: { header: string; url: string; content: string; similarity: number }[];
  imgs: string[];
  videos: string[];
};

export type SearchingResponse = WebSources & {
  internalApiError?: string;
};

export type ToolResult =
  | ({
      name: 'TextToSQL';
    } & ToolFunctionsDictionary['TextToSQL'])
  | ({
      name: 'Searching';
    } & ToolFunctionsDictionary['Searching'])
  | ({
      name: 'TextToPython';
    } & ToolFunctionsDictionary['TextToPython'])
  | ({
      name: 'Forecasting';
    } & ToolFunctionsDictionary['Forecasting'])
  | ({
      name: 'MarketingMixModel';
    } & ToolFunctionsDictionary['MarketingMixModel'])
  | ({
      name: 'GenerateInsights';
    } & ToolFunctionsDictionary['GenerateInsights']);

export type ToolProgress = {
  name: WillyToolName;
  progress: number;
  step: string;
  error?: boolean;
  body?: string;
  language?: SupportedLanguages;
};

export type WillySocketResponsePayload = (
  | NlqErrorResponse
  | NlqDatabaseResponse
  | NlqDoneResponse
  | NlqInsightResponse
  | NlqProgressResponse
  | NlqRunCodeResponse
) &
  BaseEventPayload;

export type ChatResponse = (res: ChatResponses) => void;
export type WillySocketResponse = (res: WillySocketResponsePayload) => void;

export type SequenceProgressBaseEvent = {
  messageId: string;
  sequenceId: string;
  runId: string;
  workflowId: string;
  text: string;
};

export type SequenceProgressStartedEvent = {
  type: 'sequence-started';
};

export type SequenceProgressDoneEvent = {
  type: 'sequence-done';
  outputTitle?: string;
  outputDescription?: string;
};

export type SequenceProgressErrorEvent = {
  type: 'sequence-error';
  error: string;
  shouldRetry?: boolean;
};

export type SequenceProgressProgressEvent = {
  type: 'progress';
};

export type SequenceProgressStepStartedEvent = {
  type: 'step-started';
  stepId: string;
};

export type SequenceProgressStepDoneEvent = {
  type: 'step-done';
  stepId: string;
  result: WorkflowResponse | null;
  comments?: string[];
};

export type SequenceProgressStepErrorEvent = {
  type: 'step-error';
  stepId: string;
  error: string;
};

export type SequenceProgressStepSkippedEvent = {
  type: 'step-skipped';
  stepId: string;
  text: string;
};

export type SequenceProgressStepProgressEvent = {
  type: 'step-progress';
  stepId: string;
};

export type SequenceProgressStepRephrasedEvent = {
  type: 'step-rephrased';
  stepId: string;
  newText: string;
  language: 'sql' | 'python' | 'plain';
};

export type SequenceProgressSendToDatabaseEvent = {
  type: 'sendToDatabase';
  data: StructuredColumns;
};

export type SequenceProgressEvent = (
  | SequenceProgressStartedEvent
  | SequenceProgressDoneEvent
  | SequenceProgressErrorEvent
  | SequenceProgressProgressEvent
  | SequenceProgressStepStartedEvent
  | SequenceProgressStepDoneEvent
  | SequenceProgressStepErrorEvent
  | SequenceProgressStepProgressEvent
  | SequenceProgressStepRephrasedEvent
  | SequenceProgressStepSkippedEvent
  | SequenceProgressSendToDatabaseEvent
) &
  SequenceProgressBaseEvent;

export type InsightsSteamEvent = {
  delta: string;
  messageId: string;
  shopId: string;
  userId: string;
};

export type ColumnSpendRecommendationPayload = {
  metric: ColumnName;
  entity: string;
  id: string;
  provider_id: string;
  value: string;
  reason: string;
  created_at: string;
};

export type ColumnPayload = ColumnSpendRecommendationPayload;

export type StructuredColumns = {
  columns: ColumnPayload[];
};

export type WorkflowWsStatus = {
  workflowId: string;
  status: 'running' | 'done' | 'error';
  error?: string;
  progress?: string;
  outputTitle?: string;
  outputDescription?: string;
};

export type StepStatus = 'running' | 'done' | 'error' | 'skipped';

export type StepWsStatus = {
  response: WorkflowResponse | null;
  error?: string;
  status: StepStatus;
  stepId: string;
  progress?: string;
  rephrasedText?: string;
  comments?: string[];
};

export type MetricsDataEvent = {
  messageId: string;
  metricId: string;
  data: NlqResponse;
  error?: string;
  previous?: boolean;
  shopId: string;
};

export type NlqErrorResponse = {
  error: string;
  type: 'error';
};

export type NlqDatabaseResponse = {
  message?: string;
  data?: string;
  step: Step;
  queryId: string;
  type: 'data';
};

export type NlqInsightResponse = {
  text: string;
  type: 'insight';
};

export type NlqDoneResponse = {
  type: 'done';
  data: {
    toolsNames: WillyToolName[];
    toolArgs: string[];
  };
  followupQuestions: string[];
};

export type NlqProgressResponse = {
  type: 'progress';
  progress?: number;
  progressMessage?: string;
};

export type NlqFileCodeResponse = {
  name: string;
  type: string;
  url: string;
  size: number;
};

export type FileWithData = {
  id: string;
  query: string;
  file_name: string;
  data: NlqCodeData[];
};

export type NlqCodeData = Record<string, any>;

export type NlqRunCodeResponse = {
  text: string;
  type: 'code';
  step: Step;
  trial?: number;
  files?: NlqFileCodeResponse[];
  data?: NlqResponse;
  executionId?: string;
  queries?: FileWithData[];
};

export type NlqForecastResponse = {};

export class WillySocket extends Socket<WillyListenEvents, WillyEmitEvents> {}

export type WillySort = 'asc' | 'desc' | null;

export type WillyBaseMetric = {
  key: string;
  isDimension?: boolean;
  pinned?: 'left' | 'right' | boolean | null;
  name: string;
  description: string;
  format: valueFormats;
  dateFormat?: string;
  isDate?: boolean;
  toFixed: number;
  valueIsNegative?: boolean;
  color: string;
  opacity?: number | null;
  icon: WillyEmoji;
  columnWidth?: number;
  mobileColumnWidth?: number;
  minimumFractionDigits?: number;
  colorName?: string;
  active?: boolean;
  hidden?: boolean;
  hiddenBecauseHasNoData?: boolean;
  chartType?: ChartType;
  lineStyle?: 'solid' | 'dashed' | 'dotted';
  yAxisId?: 'left' | 'right';
  showMean?: boolean;
  showMeanLabel?: boolean;
  showMode?: boolean;
  showModeLabel?: boolean;
  showMedian?: boolean;
  showMedianLabel?: boolean;
  sort?: WillySort;
  groupByColumn?: boolean;
  applyOnGlobalConditionalFormatting?: boolean;
  conditionalStyleType?: 'singleValue' | 'scale' | 'none';
  conditionalStyleValue?: string;
  conditionalStyleColor?: string;
  isBlocked?: boolean;
  v2metricId?: string;
  readmeUrl?: string | null;
};

export type LoadDataProps = {
  abortSignal: AbortSignal;
  forceReload?: boolean;
  parameters?: WillyParameter[];
  pageToFetch?: number;
};

export type LoadPreviousPeriodDataProps = {
  qId?: string;
  shouldReturn?: boolean;
} & LoadDataProps;

export type WillyMetric = {
  onClickAction?: 'query' | 'url' | 'none' | 'function' | 'externalUrl';
  onClickActionUrls?: { value: string; url: string; openInNewTab?: boolean }[];
  functionName?: string;
  popupWidget?: WillyWidgetElement | null;
  queryVars?: string[];
} & WillyBaseMetric;

export enum ElementTypes {
  OPERATOR = 'operator',
  INTEGER = 'integer',
  METRIC = 'metric',
  PARENTHESES = 'parentheses',
}

export type ExpressionElement = {
  id: string;
  title: string;
  value?: any;
  isGlobal?: boolean; // relevant only for metrics
  isCustomMetric?: boolean; // relevant only for metrics
  type: ElementTypes;
  isSelected?: boolean;
};

export type ExpressionElementsMap = { [key: number]: ExpressionElement[] };
// to here

export type WillyDataColumn = {
  x: string[];
  y: string[];
};

export type WillyParameter = {
  column: string;
  operator?: FilterOperator;
  value: string | string[];
  options?: string[];
  visible?: boolean;
  disabled?: boolean;
  visibleInDashboard?: boolean;
  isQueryParameter?: boolean;
  query?: string;
  sourceType?: 'sql' | 'forecast';
};

export type RCERequest = {
  pyCode: string;
  inputs?: {
    data: any;
    name: string; // ./input/name.json
    type: 'json' | 'csv' | 'url';
  }[];
};

export type CodeExecutionResponse = {
  executionId: string;
  code: string;
  dataFiles?: NlqFileCodeResponse[];
  filesOutput?: NlqResponse;
  output?: string;
  executionError?: string;
};

export type CodeInterpreterResponse = {
  processId?: string;
  pythonCode?: string;
  executionId?: string;
  error?: string;
  codeResults?: string;
  files?: NlqFileCodeResponse[];
  queries?: FileWithData[];
  data?: NlqResponse;
  step?: string;
  progress?: number;
};

export type BaseHistoryItem = {
  messageId: string;
  createdAt: string;
  text: string;
  originalQuestion: string;
  title?: string;
  error?: string;
};

export type UserHistoryItem = {
  role: 'user';
  userId: string;
  mentionedUsers?: string[];
  uploadedFiles?: MobyUploadedFile[];
};

export type AssistantHistoryItem = {
  role: 'assistant';
  tool_calls?: { function: any; id: string; type: 'function' }[];
  fromWorkflowId?: string;
};

export type ToolHistoryItem = {
  role: 'tool';
  toolResults: ToolResult;
  tool_call_id: string;
  toolsNames?: WillyToolName[];
};

export type HistoryItem = (UserHistoryItem | AssistantHistoryItem | ToolHistoryItem) &
  BaseHistoryItem;

export type NlqResponseDataType = 'nlq' | 'forecast' | 'mmm';

export const visualizationTypes = [
  'Bar',
  'StackedBar',
  'Table',
  'Pie',
  'Line',
  'Area',
  'Tile',
  'Scatter',
  'Map',
  'Auto',
] as const;

export type VisualizationTypes = (typeof visualizationTypes)[number];

export type NlqResponse = {
  bq?: number;
  CDPSegmentId?: string;
  conversationId?: string;
  data: RawNlqData;
  dataColumns: WillyDataColumn;
  dataType?: NlqResponseDataType;
  error?: string;
  errorForInterface?: string;
  warning?: string;
  fromNlq?: boolean;
  generatedQuery?: string;
  datesRange?: string;
  id?: string;
  messageId?: string;
  originalQuestion?: string;
  parameters?: WillyParameter[];
  queryId?: string;
  question?: string;
  returnQueryOnly?: boolean;
  serviceIds?: ServicesIds[];
  shopOwner?: string;
  userOwner?: string;
  validSql?: boolean;
  verified?: boolean;
  dialect?: Dialect;
  tables?: string[] | string;
  visualizationType?: VisualizationTypes;
  // not from the response, enriched by the frontend
  queries?: WidgetQuery[];
  twTotalCount?: number;
};

export type PaginationType = 'server' | 'client';

export type LongForecastingResponse = {
  message: string;
  data_for_forecast: string;
  forecast_nlq_format: RawNlqData[];
  forecast: string;
  algorithm: string;
  mape_1la_val?: number | null | undefined;
  mape_1la_test?: number | null | undefined;
  mape_1la_val_sample_len?: number | null | undefined;
  mape_1la_test_sample_len?: number | null | undefined;
  forecast_1la_nlq_format?: RawNlqData[] | null | undefined;
};

export type LongForecastingViaNLQResponse = {
  forecastingResponse?: LongForecastingResponse;
  message?: NlqResponse;
  error?: string;
  mape_1la_val?: number | undefined;
  mape_1la_test?: number | undefined;
  mape_1la_val_sample_len?: number | undefined;
  mape_1la_test_sample_len?: number | undefined;
};

export type TrainingConfigurationPayload = {
  startTrainingDate: string;
  endTrainingDate: string;
  endTestDate: string;
  shopId: string;
  trainingCadence?: string;
};

interface ReallocationCollection {
  [id: string]: mmmReAllocationDoc; // Keyed by document ID
}

export type mmmTrainingDoc = {
  createdAt: firebase.firestore.Timestamp;
  status: string;
  mmm_model_path?: string;
  trainingStartedAt?: firebase.firestore.Timestamp;
  trainingComppletedAt?: firebase.firestore.Timestamp;
  training_result?: {
    mape_train?: number;
    mape_test?: number;
  };
  mmm_reallocations?: ReallocationCollection;
} & TrainingConfigurationPayload;

export type MmmReallocationDataResponse = {
  // [metric: string]: {
  //   value: number;
  //   percentage: number;
  // };
  metric: string;
  metric_change: {
    channel: string;
    post_opt_value: number;
    post_percentage: number;
    pre_opt_value: number;
    pre_percentage: number;
  }[];
};

export type MmmReallocationResponse = {
  data: MmmReallocationDataResponse[];
};

export type mmmManualReAllocationDoc = {
  createdAt: firebase.firestore.Timestamp;
  reallocation_status: string;
  reallocation_results?: MmmReallocationResponse;
  conversationId?: string;
  messageId?: string;
} & Partial<MmmManualReAllocationImpactConfigurationPayload> &
  Partial<MmmManualReAllocationImpactConfigurationPayload>;

export type MmmManualReAllocationImpactConfigurationPayload = {
  shopId: string;
  trainingId: string;
  reallocationId?: string; // get a referall to an original reallocation
  channelBudgetConfigurations: MmmManualChannelReallocationConfiguration;
  startReallocDate?: string;
  endReallocDate?: string;
  conversationId?: string;
  messageId?: string;
};

export type MmmManualChannelReallocationConfiguration = {
  [channel: string]: {
    budget?: number;
    budget_change_absolute?: number;
    budget_change_percentage?: number;
    budget_final?: number;
  };
};

export type MmmReAllocationConfigurationPayload = {
  shopId: string;
  trainingId: string;
  // analysisLevel?: analysisLevelType;
  // channel?: channelType;
  // campaign_type?: string;
  // campaign?: string;
  // ad_set?: string;
  budgetScale?: number;
  iterations?: number;
  upperBound?: number;
  lowerBound?: number;
  startReallocDate?: string;
  endReallocDate?: string;
};

export type mmmReAllocationDoc = {
  createdAt: firebase.firestore.Timestamp;
  reallocation_status: string;
  reallocation_results?: MmmReallocationResponse;
} & MmmReAllocationConfigurationPayload;

export type BudgetReallocationViaNLQResponse = {
  message: NlqResponse;
  manualReallocationDoc: mmmManualReAllocationDoc;
};

export type MessageTypes =
  | 'text'
  | 'tile'
  | 'card'
  | 'table'
  | 'chart'
  | 'pie'
  | 'funnel'
  | 'map'
  | 'json'
  | 'summaryBox'
  | 'sankey';

export type MessageTypeElement<T> = {
  id: T;
  title: string;
  icon: IconName;
  widgetMinWidth: number;
  widgetMinHeight: number;
  widgetDefaultHeight: number;
  disabled?: boolean;
  tooltip?: string;
  decorativeIcon?: JSX.Element;
};

export type MessageTypesDictionary = {
  [key in MessageTypes]: MessageTypeElement<key>;
};

export type ChartDataItem = Record<
  string,
  {
    metric: string;
    value: number | null;
  }
>;

export interface ChartXAxisItem {
  x: string | number;
  sortingKey?: number;
}

export type ChartData = (ChartDataItem | ChartXAxisItem)[];

export type ChartInfo = {
  data: ChartData;
  stackedData?: ChartData;
};

export type TableData = { columns: BaseColumn<any, any>[]; data: any };

export type RawNlqData = {
  name: string;
  value: (number | string | null)[];
}[];

export type MessageData = {
  processId?: string;
  data?: RawNlqData;
  dataColumns?: WillyDataColumn;
  metrics?: WillyMetric[];
  parameters?: WillyParameter[];
  queryId?: string;
  question?: string;
  serviceIds?: ServicesIds[];
  query?: string;
  queries?: WidgetQuery[] | FileWithData[];
  dataType?: NlqResponseDataType;
  visualizationType?: VisualizationTypes;
  dialect?: Dialect;
  twTotalCount?: number;
  progressInfo?: {
    progress: number;
    text: string;
  };
};

export type Message = {
  id: string;
  role: 'user' | 'assistant' | 'tool';
  text?: string;
  richText?: React.ReactNode;
  question?: string;
  originalQuestion?: string;
  loading?: boolean;
  building?: boolean;
  verified?: boolean;
  conversationId?: string;
  error?: string;
  title?: string;
  notes?: string[];
  CDPSegmentId?: string;
  toolsNames?: WillyToolName[];
  userId?: string;
  codeInterpreterResponse?: CodeInterpreterResponse[];
  toolResults?: ToolResult;
  toolProgress?: ToolProgress;
  dialect?: Dialect;
  outputTitle?: string;
  outputDescription?: string;
  fromWorkflowId?: string;
  createdAt?: string;
  uploadedFiles?: MobyUploadedFile[];
};

export type ConversationUiOptions = {
  actionsMenuOpen?: boolean;
  renameMode?: boolean;
};

export type Conversation = {
  id: string;
  history: HistoryItem[];
  title?: string;
  user: string;
  v: number;
  createdAt: InstanceType<typeof Timestamp>;
  model: 'nlq';
  source: ChatSources;
  users?: string[];
} & ConversationUiOptions;

export type MentionedUser = {
  id: string;
  display: string;
};

export type MetricDict = {
  '#': string;
  Name: string;
  Abbreviation: string;
  About: string;
  Formula: string;
  Category: string;
  'Learn More': string;
};

export type WillyMainChatProps = {
  source: ChatSources;
  showLogo?: boolean;
  messagesWrapperClassName?: string;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  conversationId: string;
  initialQuery?: string;
  isReadyForChat?: boolean;
  returnQueryOnly?: boolean;
  codeActions?: CodeAction[];
  withoutInputBackground?: boolean;
  withoutInputShadow?: boolean;
  hideSuggestions?: boolean;
  setConversationId: React.Dispatch<React.SetStateAction<string>>;
  chatSourceIds?: { dashboardId: string; widgetId: string };
  activeVersion?: number;
  hidePills?: boolean;
  isDashboardPage?: boolean;
  dashboardId?: string;
  dashboardName?: string;
  dashboardData?: FormattedDashboardData;
  summaryData?: FormattedSummaryData;
  pixelData?: FormattedPixelData;
  buildMode?: boolean;
  setBuildMode?: React.Dispatch<React.SetStateAction<boolean>>;
  asPage?: boolean;
  workflowPanelOpen?: boolean;
  setWorkflowPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  workflowIdToRun?: string;
  setWorkflowIdToRun?: React.Dispatch<React.SetStateAction<string | undefined>>;
  sequenceName?: string;
  sequenceRunData?: PreloadSequenceRunData;
  promptsToUse?: WillyPrompt[];
  chatWithSequenceId?: string;
  loadingGeneratedPrompts?: boolean;
  sequenceLastRunAt?: firebase.firestore.Timestamp;
  lastInsightStep?: (WorkflowInsightsResponse & WorkflowStepBaseResponse) | null;
  rerunSequnece?: (sequenceId: string) => Promise<void>;
  workflowRunning?: boolean;
  loadingInsightsStep?: boolean;
};

export type WillyMainChatRef = {
  clearConversation: () => void;
  handleSubmit: (text: string, skipUserMessage?: boolean, userMessage?: string) => void;
  setLastMessageId: React.Dispatch<React.SetStateAction<string>>;
  lastMessageId: string;
  focusTextInput: () => void;
  setLoadingConversation: React.Dispatch<React.SetStateAction<boolean>>;
  shouldSetConversationRef: React.MutableRefObject<boolean>;
};

export type RunDoc = {
  finishedAt?: firebase.firestore.Timestamp;
  id: string;
  isGlobal: boolean;
  outputTitle?: string;
  outputDescription?: string;
  insightsCoverImage?: string | null;
  runAt: firebase.firestore.Timestamp;
  createdAt: firebase.firestore.Timestamp;
  sequenceId: string;
  success?: boolean;
  trigger: 'schedule' | 'manual' | 'unknown';
  userId: string;
  error?: string | null;
  stepsStatus?: Record<string, StepStatus>;
  read?: boolean;
};

export type QueryAsBlock = {
  id: string;
  question: string;
  dashboardId: string | null;
  isNew?: boolean;
};

export type RichTextImageBlock = {
  type: 'image';
  url: string;
};

export type RichTextHeadingBlock = {
  type: 'heading';
  text: string;
  level: 'h1' | 'h2' | 'h3';
};

export type RichTextQueryBlock = {
  type: 'query';
  query: QueryAsBlock;
};

export type RichTextBlock = RichTextImageBlock | RichTextHeadingBlock | RichTextQueryBlock;

export type WorkflowStepToolPreload =
  | ({
      name: 'TextToSQL';
    } & NlqResponse)
  | ({
      name: 'TextToPython';
    } & CodeInterpreterResponse);

export type WorkflowStepVariable = { key: string; value: string };

export type WorkflowStepBase = {
  id: string;
  title?: string;
  createdAt?: string;
  parentRuleId?: string;
  variables?: WorkflowStepVariable[];
  runInParallel?: boolean;
};

export const WorkflowStepTypesArr = [
  'tool',
  'subSequence',
  'preloadData',
  'insights',
  'runQuery',
  'rule',
  'condition',
  'sendToDashboard',
  'sendToEmail',
  'sendToSlack',
  'sendToGoogleSheet',
  'sendToWarehouse',
  'sendToWebhook',
  'preloadRuns',
  'preloadCSV',
  'preloadGoogleSheet',
  'loop',
  'vision',
] as const;

export type WorkflowStepTypesDict = {
  [key in WorkflowStepTypes]: {
    stepType: key;
  };
};

export const WorkflowStepTypesDict: WorkflowStepTypesDict = {
  tool: {
    stepType: 'tool',
  },
  subSequence: {
    stepType: 'subSequence',
  },
  preloadData: {
    stepType: 'preloadData',
  },
  preloadRuns: {
    stepType: 'preloadRuns',
  },
  insights: {
    stepType: 'insights',
  },
  runQuery: {
    stepType: 'runQuery',
  },
  rule: {
    stepType: 'rule',
  },
  condition: {
    stepType: 'condition',
  },
  sendToDashboard: {
    stepType: 'sendToDashboard',
  },
  sendToEmail: {
    stepType: 'sendToEmail',
  },
  sendToSlack: {
    stepType: 'sendToSlack',
  },
  sendToGoogleSheet: {
    stepType: 'sendToGoogleSheet',
  },
  sendToWarehouse: {
    stepType: 'sendToWarehouse',
  },
  sendToWebhook: {
    stepType: 'sendToWebhook',
  },
  preloadCSV: {
    stepType: 'preloadCSV',
  },
  preloadGoogleSheet: {
    stepType: 'preloadGoogleSheet',
  },
  loop: {
    stepType: 'loop',
  },
  vision: {
    stepType: 'vision',
  },
};

export type WorkflowStepTypes = (typeof WorkflowStepTypesArr)[number];

export type WorkflowStepTool = {
  stepType: WorkflowStepTypesDict['tool']['stepType'];
  toolToUse?: WillyToolName;
  text: string;
  mentionedUsers?: string[];
  toolPreload?: WorkflowStepToolPreload | null;
  preserveHistory?: boolean;
  searchSource?: SearchSource | null;
};

export type WorkflowStepSubSequence = {
  stepType: WorkflowStepTypesDict['subSequence']['stepType'];
  sequenceIds?: string[];
};

export type WorkflowStepPreloadData = {
  stepType: WorkflowStepTypesDict['preloadData']['stepType'];
  dashboardId: string;
  isStandardDashboard?: boolean;
  widgetId?: string;
  date: DatePickerTimePeriods | null;
  previousDate: PreviousPeriodIds | null;
  filters: Record<string, WillyParameter[]> | null;
  attributionOptions: {
    params: Partial<AttributionStatsRequest>;
    columns: PixelColumnsKeys[];
  } | null;
};

export type WorkflowStepPreloadRuns = {
  stepType: WorkflowStepTypesDict['preloadRuns']['stepType'];
  runIds: string[];
};

export type WorkflowStepPreloadCSV = {
  stepType: WorkflowStepTypesDict['preloadCSV']['stepType'];
  url: string;
  fileName: string;
  destFileName: string;
};

export type WorkflowStepPreloadGoogleSheet = {
  stepType: WorkflowStepTypesDict['preloadGoogleSheet']['stepType'];
  sheetsAccount: string;
  spreadsheetId: string;
  spreadsheetName: string;
  worksheetId: string;
  worksheetName: string;
};

export type WorkflowStepInsights = {
  stepType: WorkflowStepTypesDict['insights']['stepType'];
  text: string;
  model: string | null;
  outputType: 'text' | 'rich_text' | null;
  addExtraData: boolean;
};

export type WorkflowSendToDestinationStep =
  | WorkflowStepSendToDashboard
  | WorkflowStepSendToEmail
  | WorkflowStepSendToGoogleSheet
  | WorkflowStepSendToWarehouse
  | WorkflowStepSendToWebhook
  | WorkflowStepSendToSlack;

export type WorkflowStepCondition = {
  stepType: WorkflowStepTypesDict['condition']['stepType'];
  rules: ScheduleRulesRow[];
};

export type WorkflowStepRule = {
  stepType: WorkflowStepTypesDict['rule']['stepType'];
  text: string;
};

export type WorkflowStepSendToDashboard = {
  stepType: WorkflowStepTypesDict['sendToDashboard']['stepType'];
  dashboardId: string;
};

export type WorkflowStepSendToEmail = {
  stepType: WorkflowStepTypesDict['sendToEmail']['stepType'];
  email: string;
  formats: string[];
};

export type WorkflowStepSendToSlack = {
  stepType: WorkflowStepTypesDict['sendToSlack']['stepType'];
  formats: string[];
};

export type WorkflowStepSendToGoogleSheet = {
  stepType: WorkflowStepTypesDict['sendToGoogleSheet']['stepType'];
  sheetsAccount: string;
  spreadsheetId: string;
  spreadsheetName: string;
  ssNameError?: boolean;
  worksheetId: string;
  worksheetName: string;
  wsNameError?: boolean;
};

export type WorkflowStepSendToWebhook = {
  stepType: WorkflowStepTypesDict['sendToWebhook']['stepType'];
  url: string;
  headers: { id: string; key: string; value: string }[];
};

export type WorkflowStepSendToWarehouse = {
  stepType: WorkflowStepTypesDict['sendToWarehouse']['stepType'];
  providerId: string;
  integrationId: string;
  tableId: string;
  useDedicatedQuery: boolean;
  query: string;
  queryParams?: Record<string, string>;
};

export type WorkflowStepRunQuery = {
  stepType: WorkflowStepTypesDict['runQuery']['stepType'];
  query: string;
  queryParams?: Record<string, string>;
};

export type WorkflowStepLoop = {
  stepType: WorkflowStepTypesDict['loop']['stepType'];
};

export type WorkflowStepVision = {
  stepType: WorkflowStepTypesDict['vision']['stepType'];
  text: string;
  uploadedFiles: MobyUploadedFile[];
};

export type WorkflowStep = (
  | WorkflowStepTool
  | WorkflowStepSubSequence
  | WorkflowStepPreloadData
  | WorkflowStepPreloadRuns
  | WorkflowStepInsights
  | WorkflowStepRule
  | WorkflowStepCondition
  | WorkflowStepSendToDashboard
  | WorkflowStepSendToEmail
  | WorkflowStepSendToSlack
  | WorkflowStepSendToGoogleSheet
  | WorkflowStepSendToWarehouse
  | WorkflowStepRunQuery
  | WorkflowStepSendToWebhook
  | WorkflowStepPreloadCSV
  | WorkflowStepPreloadGoogleSheet
  | WorkflowStepLoop
  | WorkflowStepVision
) &
  WorkflowStepBase;

export type WorkflowStepBaseResponse = {
  stepId: string;
  status: 'ok' | 'error';
  error: string | null;
  text?: string;
  timestamp?: number;
  user?: string;
};

export type WorkflowToolResponse = {
  stepType: WorkflowStepTypesDict['tool']['stepType'];
  res: UniformChatResponse | null;
};

export type WorkflowSubSequenceResponse = {
  stepType: WorkflowStepTypesDict['subSequence']['stepType'];
};

export type WorkflowPreloadDataResponse = {
  stepType: WorkflowStepTypesDict['preloadData']['stepType'];
  data: NlqResponse[] | null;
  res: string | null;
};

export type WorkflowPreloadRunsResponse = {
  stepType: WorkflowStepTypesDict['preloadRuns']['stepType'];
};

export type WorkflowInsightsResponse = {
  stepType: WorkflowStepTypesDict['insights']['stepType'];
  text: string | null;
  error: string | null;
  richText?: string | null;
  blocks: RichTextBlock[] | null;
  modelUsed?: string;
  modelRequested?: string;
};

export type WorkflowRuleResponse = {
  stepType: WorkflowStepTypesDict['rule']['stepType'];
};

export type WorkflowConditionResponse = {
  stepType: WorkflowStepTypesDict['condition']['stepType'];
};

export type WorkflowSendToDashboardResponse = {
  stepType: WorkflowStepTypesDict['sendToDashboard']['stepType'];
};

export type WorkflowSendToEmailResponse = {
  stepType: WorkflowStepTypesDict['sendToEmail']['stepType'];
  attachments?: { path: string; format: string }[];
};

export type WorkflowSendToGoogleSheetResponse = {
  stepType: WorkflowStepTypesDict['sendToGoogleSheet']['stepType'];
};

export type WorkflowSendToWarehouseResponse = {
  stepType: WorkflowStepTypesDict['sendToWarehouse']['stepType'];
};

export type WorkflowSendToWebhookResponse = {
  stepType: WorkflowStepTypesDict['sendToWebhook']['stepType'];
};

export type WorkflowRunQueryResponse = {
  stepType: WorkflowStepTypesDict['runQuery']['stepType'];
  res: UniformChatResponse | null;
};

export type WorkflowLoopResponse = {
  stepType: WorkflowStepTypesDict['loop']['stepType'];
  loopCount: number;
  reachToEnd: boolean;
};

export type WorkflowResponse = (
  | WorkflowToolResponse
  | WorkflowSubSequenceResponse
  | WorkflowLoopResponse
  | WorkflowPreloadDataResponse
  | WorkflowPreloadRunsResponse
  | WorkflowInsightsResponse
  | WorkflowRuleResponse
  | WorkflowConditionResponse
  | WorkflowSendToDashboardResponse
  | WorkflowSendToEmailResponse
  | WorkflowSendToGoogleSheetResponse
  | WorkflowSendToWarehouseResponse
  | WorkflowSendToWebhookResponse
  | WorkflowRunQueryResponse
) &
  WorkflowStepBaseResponse;

export type RunSequenceResponse = {
  id: string;
  runId: string;
  status: 'ok' | 'error';
  message?: string;
  allSteps?: WorkflowResponse[];
};

export type WillyDataSequenceTaggedMetric = {
  metric: string;
  useDatePickers: boolean;
};

export type PixelSettingProviderId =
  | ServicesIds[]
  | 'Direct'
  | 'Non-attributed'
  | 'organic_and_social';

export type WillyDataSequencePixelEntitySettings = {
  use: boolean;
  providers?: PixelSettingProviderId;
  useDatePickers?: boolean;
};

export type WillyDataSequencePixelSettings = {
  [key in Exclude<AnalyticsObjectType, 'adAccount'>]: WillyDataSequencePixelEntitySettings;
};

type WillyGlobalDataSequenceColorTheme = 'green' | 'blue' | 'turquoise';

export type WillyDataSequence = {
  id: string;
  deleted?: boolean;
  steps: WorkflowStep[];
  additionalShopIds?: string[];
  name: string;
  user: string;
  createdAt: firebase.firestore.Timestamp;
  isGlobal?: boolean;
  schedule: ScheduleItem | null;
  v: number;
  taggedMetrics?: WillyDataSequenceTaggedMetric[];
  pixelSettings?: WillyDataSequencePixelSettings;
  lastRunAt?: firebase.firestore.Timestamp | null;
  running?: boolean;
  skipSaveRunDate?: boolean;
  consecutiveFailures?: number;
  themeColor?: WillyGlobalDataSequenceColorTheme;
  iconSrc?: string;
} & WillyGlobalWorfklowColumnsAdminFields &
  ConversationUiOptions &
  WillyBaseMainElement;

export type WillyDataSequenceWithRuns = WillyDataSequence & {
  runs: RunDoc[];
};
export type WorkflowWithRun = {
  run: RunDoc;
} & WillyDataSequence;

export type WillyDataSchedule = {
  scheduleId: string;
  spec: any;
  action: any;
  policies: {
    overlap: any;
    catchupWindow: number;
    pauseOnFailure: boolean;
  };
  memo?: Record<string, unknown>;
  searchAttributes: any;
  state: {
    paused: boolean;
    note?: string;
    remainingActions?: number;
  };
  info: {
    recentActions: any[];
    nextActionTimes: Date[];
    numActionsTaken: number;
    numActionsMissedCatchupWindow: number;
    numActionsSkippedOverlap: number;
    createdAt: Date;
    lastUpdatedAt?: Date;
    runningActions: any[];
  };
  raw: any;
};

export type WillySequenceInDashboard = {
  sequenceId: string;
  read: boolean;
  lastRunAt: firebase.firestore.Timestamp | null;
  shopId: string;
};

export type WillySequenceInDashboardWithSequenceAndRun = {};

export type WillySequenceInDashboardWithRun = WillySequenceInDashboard & {
  run: RunDoc;
};

export type DashboardDataWithSequenceHistory = WillyDashboardElement & {
  history: WillyDataSequence[];
};

export type SequencesReportsListProps = {
  dashboardId?: string;
  isAll?: boolean;
  onSelectedSequence: (sequence: WillySequenceInDashboard) => void;
};

export type SequencesReportsListModalProps = {
  open: boolean;
  onClose: () => void;
  dashboardId?: string | undefined;
  selectedSequence: WillySequenceInDashboard | null;
  setSelectedSequence: React.Dispatch<React.SetStateAction<WillySequenceInDashboard | null>>;
};

export type WidgetQuery = {
  id: string;
  question: string;
  query: string;
  explanation?: string;
  fromMetricBuilder?: boolean;
};

export type SqlSnippet = {
  id: string;
  snippet: string;
  name: string;
  isGlobal?: boolean;
  optionsOpen?: boolean;
  dialect: Dialect;
};

export type TileModes = 'tile' | 'table';

export type GridOptions = 'flex' | 'grid';

export type GridColumnOptions = 2 | 3 | 4;

export type WidgetChartLabel = {
  position: LabelPosition | null;
  color?: string;
  valueType?: 'value' | 'percent';
};

export type WillyChartLayout = 'vertical' | 'horizontal';

export type WillyWidgetElement = {
  title: string;
  queryId: string;
  withoutMainQuery?: boolean;
  type: MessageTypes;
  stacked: boolean;
  incrementedStacked: boolean;
  metrics: WillyMetric[];
  dataMetricIds?: string[];
  wrapText?: boolean;
  queries?: WidgetQuery[];
  parameters?: WillyParameter[];
  verified?: boolean;
  grid?: GridOptions;
  gridColumns?: GridColumnOptions;
  twoColumnMobile?: boolean;
  tileMode?: TileModes;
  permission?: WidgetPermissions;
  skinny?: boolean;
  yAxisDomain?: AxisDomain;
  allowDataOverflow?: boolean;
  /**
   * Dimension for the chart x-axis if not provided, the first dimension will be used.
   */
  dimension?: string;
  /**
   * Custom views dashboards can have one pinned section.
   */
  pinnedSection?: boolean;
  hidden?: boolean;
  isProviderNotConnected?: boolean; // calculated should not store in DB
  isProviderLocked?: boolean; // calculated should not store in DB
  hasGlobalConditionalFormatting?: boolean;
  globalConditionalFormattingColor?: string;
  breakdownMode?: boolean;
  codeResult?: CodeInterpreterResponse;
  dataType?: NlqResponseDataType;
  historicalQueryIds?: string[];
  dialect: Dialect;
  updatedAt?: firebase.firestore.Timestamp;
  mode?: 'sql' | 'builder';
  builderSetup?: BuilderTable;
  filtersOpen?: boolean;
  chartLabel?: WidgetChartLabel;
  rightYAxisLabel?: string;
  leftYAxisLabel?: string;
  xAxisLabel?: string;
  chartLayout?: WillyChartLayout;
  showQuickEditing?: boolean;
};

export type WillyFieldTypes = 'text' | 'image' | 'video';

export type WillySection = {
  id: string;
  hidden?: boolean;
  dialect?: Dialect;
  isProviderNotConnected?: boolean;
  isProviderLocked?: boolean;
  title: string;
  sectionType: 'widget' | 'field';
  type: MessageTypes | WillyFieldTypes;
};

export type WillyFieldElement = {
  id: string;
  title: string;
  type: WillyFieldTypes;
  content: string;
  author: string;
  createdAt: string;
  updatedAt: string;
  hidden?: boolean;
};

export type WillyDashboardVersionElement = {
  version: string;
  whatNew: string[];
  createdAt: string;
};

export type TemplateReview = {
  stars: number;
  review: string;
  userId: string;
  createdAt: Date;
};

export type WillyElementType = 'dashboard' | 'sequence';

// TODO: Ask about "admin" permission
export type WillyDashboardPermission = 'owner' | 'editor' | 'viewer' | 'none';

export type WillyDashboardAccessType = 'owner' | 'shop' | 'invite';

export type ProvidersBlockingCombination = 'AND' | 'OR' | 'NONE';

export type WillyBaseMainElement = {
  /** calculate should not store in DB */
  type: WillyElementType;
  name: string;
  id: string;
  description: string;
  reportPrompt?: string;
  /** calculate should not store in DB */
  canView?: boolean;
  /** calculate should not store in DB */
  canEdit: boolean;
  /** calculate should not store in DB */
  canDelete?: boolean;
  /** calculate should not store in DB */
  canEditPerms?: boolean;
  users?: string[];
  admins?: string[];
  /** relevant only to global */
  isBeta?: boolean;
  /** relevant only to global */
  isFavoriteByDefault?: boolean;
  /** relevant only to global */
  order?: boolean;
  /** calculated should not store in DB */
  isGlobal: boolean;
  /** relevant only to global */
  image?: string;
  emoji?: WillyEmoji;
  dialect?: DialectWithBoth;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  /** calculated should not store in DB - relevant only to global */
  installCount?: number;
  /** relevant only to global */
  category?: string;
  /** relevant only to global */
  roles?: string[];
  /** relevant only to global */
  isHide?: boolean;
  /** calculated should not store in DB - relevant only to global */
  packages?: FeatureFlagConfigKey[];
  /** calculated should not store in DB - relevant only to global */
  defaultPackages?: FeatureFlagConfigKey[];
  /** relevant only to global */
  providers?: ServicesIds[];
  /** relevant only to global */
  providersBlockingCombination?: ProvidersBlockingCombination;
  /** relevant only to shop, calculated should not store in DB */
  isFavorite?: boolean;
  version?: string;
  /** relevant only to shop //TODO need to rename it to something more global (not correct for sequence) */
  globalDashboardId?: string;
  /** The user doesn't want to be asked for further notification to update to this specific version of the dashboard. */
  noNotificationVersion?: string | null;
  templateTwEmailsPermission?: string[]; // relevant only to global
  information?: string;
  /** calculated should not store in DB */
  isLocked?: boolean;
  /** calculated should not store in DB */
  isProviderLocked?: boolean;
  versions?: WillyDashboardVersionElement[];
  msps?: SalesPlatform[];

  dependsOnFFSystem?: FeatureFlag;

  /**
   * Determines the base access necessary for this dashboard.
   * - `shop` means all users of the shop get base `permission`.
   * - `invite` means only users that were invited get access.
   */
  generalAccess?: Partial<{
    type: WillyDashboardAccessType;
    permission: WillyDashboardPermission;
  }>;

  /**
   * Record of user ids of users that have some sort of access to this
   * dashboard and the permissions each user has.
   */
  userPermissions?: Record<string, WillyDashboardPermission>;
  existsInStore?: boolean; // relevant for templates
  credits?: number;

  /** relevant only to global */
  isHighlight?: boolean;
  reviews?: TemplateReview[];
};

export interface FormattedDashboardElement {
  id: string;
  name: string;
}

export interface FormattedDashboardData extends FormattedDashboardElement {
  data: Record<string, any>;
}

export interface FormattedSummaryData extends FormattedDashboardElement {
  data: Array<{
    id: string;
    sqlConfig: any;
    title: string;
    value: string;
    previousPeriodValue: string;
  }>;
}

export interface FormattedPixelData extends FormattedDashboardElement {
  data: Array<Omit<AttributionData, 'metricsBreakdown'>>;
}

export type FormattedDashboardDataElement =
  | FormattedDashboardData
  | FormattedSummaryData
  | FormattedPixelData;

export type DashboardCurrentDateRange = {
  id: DatePickerTimePeriods;
} & CurrentDateRange;

export type DashboardPrevDateRange = {
  id: PreviousPeriodIds;
} & PrevDateRange;

export type WillyDashboardElement = {
  widgetIds: string[];
  isUseDashboardDefaultDate?: boolean;
  currentDateRange?: DashboardCurrentDateRange;
  prevDateRange?: DashboardPrevDateRange;
  isDnd?: boolean; //RGL vs Dnd-Kits
  isFullWidth?: boolean;
  layout?: string; // of type ReactGridLayout.Layout[];
  editing?: boolean;
  editor?: string;
  isDynamic?: boolean;
  isSyncCharts?: boolean;
  showWorkflowsInDashboard?: boolean;
  widgets?: WillyWidgetElement[];
  fields?: WillyFieldElement[];
  history: any[];

  isCustomView?: boolean; // calculated should not store in DB - only to custom view
  isCustomViewOfDashboardId?: string; // relevant only to custom view
  isCustomViewOfShopId?: string; // relevant only to custom view
  pinnedMetricKeys?: string[];

  // relevant only to "standard dashboards"
  isStandardDashboard?: boolean;
  integration?: string;
  link?: string;
  isSqlTeamReport?: boolean;
} & WillyBaseMainElement;

export const ChartTypeArr = [
  'bar',
  'stacked-bar',
  'horizontal-bar',
  'line',
  'area',
  'scatter',
] as const;

export type ChartType = (typeof ChartTypeArr)[number];

export type ChartWithComboType = ChartType | 'combo';

export type ChartTypeItem<K> = {
  title: string;
  id: K;
  icon: IconName;
  decorativeIcon?: JSX.Element;
};

export type ChartTypeDict = {
  [key in ChartType]: ChartTypeItem<key>;
};

export type sqlBuilderAi = {
  messageId?: string;
  loading?: boolean;
  streaming?: boolean;
  error?: string;
  existingQuery?: string;
  text?: string;
};

export type LogItem = {
  log: string;
  time: Date;
  type: 'success' | 'error' | 'info';
};

export type SavedQueryType = 'query' | 'snippet' | 'customMetric' | 'rule';

export type QueryValueRecord = Record<string, { value: string; options: string[]; query: string }>;

export type EditorInstance = {
  model: editor.ITextModel;
  state: editor.ICodeEditorViewState | null;
  query: string;
  tabName: string;
  language: 'sql' | 'python';
  jsonData?: string;
  nameChanged: boolean;
  schema: BqTable[];
  filterBuilder: FilterBuilderType;
  sqlBuilderAI?: sqlBuilderAi;
  nameEditable?: boolean;
  queryId?: string;
  savedQueryType?: SavedQueryType;
  active?: boolean;
  touched?: boolean;
  loading?: boolean;
  loadingSave?: boolean;
  error?: string;
  logs?: LogItem[];
  queryParametersWithValues?: QueryValueRecord;
};

export type TableTab = {
  id: 'campaigns' | 'ad_sets' | 'ads';
  label: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
};

export type SavedQuery = {
  id: string;
  name: string;
  query: string;
  language: 'sql' | 'python';
  jsonData?: string;
  updatedAt: Date;
  columns?: BqColumn[];
};

export type BreakdownValue = {
  metric: string;
  value: string | number | null;
  date?: string;
  prevDate?: string;
}[];

export type GptMessage = {
  role: 'user' | 'assistant';
  content: string;
  type?: 'error' | 'data' | 'loading';
  id?: string;
};

export function typeIsChartType(t: string): t is ChartType {
  return ChartTypeArr.includes(t as ChartType);
}

export type Category = {
  id: string;
  title: string;
};

export type MainQueryChangedParams = {
  newQuery: NlqResponse;
  oldQueryId: string;
  dashboardId: string;
  isGlobalDashboard?: boolean;
  isCustomViewDashboard?: boolean;
  queryParameters?: WillyParameter[];
};

export type EditMetricModalPayload = (params: {
  open: boolean;
  queryId?: string;
  metricId?: string;
}) => void;

export type AxisDomain = { min: AxisDomainItem; max: AxisDomainItem };

export type WidgetPermissions = {
  providers: Array<ServicesIds>;
};

export type WillyCustomTable = {
  name: string;
  description: string;
  table: BqTable['name'];
  metrics: WillyMetric[];
};

export const updatableFieldsArray = [
  'campaign_daily_budget',
  'campaign_lifetime_budget',
  'adset_daily_budget',
  'adset_lifetime_budget',
] as const;

export type UpdatableField = (typeof updatableFieldsArray)[number];

type ScheduleStatus = 'running' | 'paused' | 'error' | 'scheduled' | 'skipped' | 'suspended';

export type ScheduleItem = {
  id: string;
  sequenceId: string;
  days: DayOfWeek[];
  hours: number[];
  interval: Interval | null; // Duration of "ms" (https://typescript.temporal.io/api/interfaces/client.IntervalSpec)
  createdAt: Date;
  updatedAt: Date;
  status?: ScheduleStatus;
  error?: string;
};

export const DayOfWeekArr = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
] as const;

export type DayOfWeek = (typeof DayOfWeekArr)[number];

export type ScheduleSequenceParams = {
  shopId: string;
  userId: string;
  scheduleId: string;
  sequenceId: string;
  schedule: {
    dayOfWeek: DayOfWeek[];
    hour: number[];
  }[];
  interval: string | null;
  currency: string;
  timezone: string;
  additionalShopIds: string[];
  dialect: DialectWithBoth;
  industry: IndustryTypes;
};

export type TemporalScheduleInfo = {
  info: {
    nextActionTimes: string[];
    recentActions: {
      takenAt: string;
    }[];
  };
};

export const willyToolNamesArr = [
  'TextToSQL',
  'Searching',
  'TextToPython',
  'Forecasting',
  'MarketingMixModel',
  'GenerateInsights',
] as const;

export type WillyToolName = (typeof willyToolNamesArr)[number];

export type WillyTool<T extends WillyToolName> = {
  toolName: T;
  title: string;
  color?: FormattedColor;
  icon?: IconName;
};

export type WillyToolMap = {
  [K in WillyToolName]: WillyTool<K>;
};

export type ExecuteCustomQueryParams = {
  query: string;
  shopId: string;
  activeAccounts: string[];
  currency: string;
  timezone: string;
  queryParams?: Record<string, string>;
  usePreAgg?: boolean;
  dialect: Dialect;
  granularity?: Granularity;
  page?: number;
  pageSize?: number;
  applyGlobalFilters?: boolean;
  orderBy?: string;
  orderDirection?: 'asc' | 'desc';
  startDate?: string;
  endDate?: string;
  abortSignal?: AbortSignal;
};

export const ForecastAlgorithms = ['linear', 'seasonal', 'auto', 'prophet'] as const;

export type ForecastAlgorithms = (typeof ForecastAlgorithms)[number];

export type WillyPrompt = {
  category: string;
  subCategory: string;
  title: string;
  prompt: string;
  isPopular?: boolean;
  isFeatured?: boolean;
  isNew?: boolean;
  topic?: string;
  query?: string;
  /**
   * The prompt came from DB
   */
  id?: string;
  /**
   * The prompt came from DB
   */
  userId?: string;
};

export type WillyOperatorDict = {
  [key in FilterOperator]: WillyOperatorItem<key>;
};

export type PreloadDashboardData = {
  generatedQuery: string;
  question: string;
  data: any[];
};

export type SummaryData = {
  calculatedStats?: RootState['calculatedStats'];
  previousPeriodCalculatedStats?: RootState['previousPeriodCalculatedStats'];
  activeMetrics?: BaseSummaryMetric<SummaryMetricIdsTypes>[];
};

export type PixelData = AttributionData[];

export type PreloadSequenceRunData = PreloadSequenceMessageData[];

export type PreloadSequenceMessageData = {
  id: string;
  question: string;
  data: string[];
};

export type MobyUploadedFile = {
  name: string;
  type: string;
  content?: string;
  path?: string;
};

export type SimilarResourceDocument = {
  header: string;
  mediaUrls: string[];
  url: string;
};

export type SimilarTemplateDocument = {
  id: string;
  globalDashboardId: string;
  name: string;
  description: string;
  image: string;
};

export type RelatedTemplateResponse = {
  error: string | null;
  prepared_text: string;
  similar_documents: SimilarTemplateDocument[];
};

export type RelatedResourceResponse = {
  error: string | null;
  prepared_text: string;
  similar_documents: SimilarResourceDocument[];
};

export const deprecatedToolNames = [
  'tryToGetDataFromBigQuery',
  'helpCenter',
  'webSearch',
  'codeInterpreter',
  'forecaster',
  'ChatTool_MMM_ToolPrompt',
] as const;

export type DeprecatedToolNames = (typeof deprecatedToolNames)[number];

//sequence builder
export type AnswersObject = {
  [key: string]: UniformChatResponse;
};
export type UserPromptsObject = {
  [key: string]: HistoryItem;
};

export type builderShelf = 'trigger' | 'step' | 'report' | 'subsequence';

export type shelfActionType = {
  name: string;
  icon: IconName;
  color: keyof typeof colors;
  category: shelfCategories;
  tooltip?: string;
  ff?: FeatureFlag;
  hasSettings?: boolean;
  searchSource?: SearchSource;
};

export type shelfCategories =
  | 'tool'
  | 'destination'
  | 'rule'
  | 'subsequences'
  | 'preload'
  | 'condition';

export type shelfCategoryType = {
  name: string;
  icon: IconName;
  color: keyof typeof colors;
  id: shelfCategories;
  tooltip?: string;
};

export type stepActionType = (
  | {
      type: Exclude<WorkflowStepTypes, 'tool'>;
      examplePrompts?: string[];
    }
  | {
      type: Extract<WorkflowStepTypes, 'tool'>;
      toolName: WillyToolName | 'auto';
      examplePrompts?: string[];
    }
) &
  shelfActionType;

export type shelfType = {
  id: builderShelf;
  name: string;
  description: string;
  actions: shelfActionType[];
};

export type WillySankeyNode = {
  name: string;
  key: string;
  color?: string;
};

export type WillySankeyLink = {
  source: number;
  target: number;
  value: number;
  color?: string;
};

export type WillySankeyData = {
  nodes: WillySankeyNode[];
  links: WillySankeyLink[];
};

// temp till we have a shared types package
export const AI_COLUMN_NAMES = [
  'ai_target_spend',
  'ai_conversion_pacing',
  'ai_roas_pacing',
  'ai_nc_roas_pacing',
  'ai_campaign_recommendation',
  'ai_adset_recommendation',
  'ai_ad_recommendation',
  'ai_benchmark_analysis',
  'ai_optimal_budget_mta_mmm',
  'ai_optimal_budget_mta',
  'ai_roas_forecast',
  'ai_creative_fatigue',
  'ai_model_comparison',
  'ai_campaign_rule_recommendation',
  'ai_adset_rule_recommendation',
  'ai_ad_rule_recommendation',
] as const;
export type ColumnName = (typeof AI_COLUMN_NAMES)[number];

export type PossibleValue = {
  value: string;
  color: string;
};

export const RetentionPeriods = ['1d', '7d', '14d', '30d', 'forever'] as const;
export type RetentionPeriod = (typeof RetentionPeriods)[number];

export type retentionPeriodItem = {
  label: string;
  value: RetentionPeriod;
  asMs: number;
};

export type RetentionPeriodItems = {
  [K in RetentionPeriod]: retentionPeriodItem;
};

export type Column<T extends ColumnName> = {
  key: T;
  label: string;
  description: string;
  defaultPrompt: string;
  valueType: 'number' | 'enum' | 'any';
  possibleValues: PossibleValue[];
  sequenceId: string | null;
  error: string | null;
  defaultSchedule?: {
    days: DayOfWeek[];
    hours: number[];
    interval: Interval | null;
  };
  entity: 'campaign' | 'adset' | 'ad';
  version: number;
  themeColor?: WillyGlobalDataSequenceColorTheme;
  iconSrc?: string;
  retentionPeriod: RetentionPeriod;
} & WillyGlobalWorfklowColumnsAdminFields;

export type ColumnDictionary = {
  [K in ColumnName]: Column<K>;
};

export type WillyGlobalWorfklowColumnsAdminFields = {
  icon?: string;
  tagline?: string;
  aboutPoints?: string[];
  whyInfo?: { title: string; description: string }[];
  howInfo?: { title: string; description: string }[];
};

export type AgentCollection = {
  id: string;
  name: string;
  description: string;
  tagline: string;
  subTagline: string;
  color: 'green' | 'light_blue' | 'turquoise' | 'dark_blue';
  comingSoon?: boolean;
  templateIds: string[];
  columnIds: string[];
  order: number;
};

export const retentionPeriodItems: RetentionPeriodItems = {
  '1d': { label: '1 Day', value: '1d', asMs: 1000 * 60 * 60 * 24 },
  '7d': { label: '7 Days', value: '7d', asMs: 1000 * 60 * 60 * 24 * 7 },
  '14d': { label: '14 Days', value: '14d', asMs: 1000 * 60 * 60 * 24 * 14 },
  '30d': { label: '30 Days', value: '30d', asMs: 1000 * 60 * 60 * 24 * 30 },
  forever: { label: 'Forever', value: 'forever', asMs: Infinity },
};
