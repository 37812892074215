import { $shopAiColumns, $shopSequences } from '$stores/willy/$sequences';
import { useStoreValue } from '@tw/snipestate';
import { Button, Modal, Text } from '@tw/ui-components';
import { useSequenceMessages } from 'components/Willy/sequenceBuilder/hooks/useSequenceMessages';
import { WillyMessageTemplate } from 'components/Willy/WillyMessageTemplate';
import { WillySimpleText } from 'components/Willy/WillySimpleText';
import { capitalize } from 'lodash';
import { useMemo, useState, useRef, useEffect } from 'react';

type AiCellModalProps = {
  opened: boolean;
  onClose: () => void;
  cell: {
    id: string;
    entity: string;
    column: string;
    value: {
      data: string;
      reason: string;
      createdAt: string;
    };
  };
};

export const AiCellModal: React.FC<AiCellModalProps> = ({ opened, onClose, cell }) => {
  const { data, reason, createdAt } = cell.value;
  const shopColumns = useStoreValue($shopAiColumns);
  const shopSequences = useStoreValue($shopSequences);

  const [showMessages, setShowMessages] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [currentHeight, setCurrentHeight] = useState('0px');

  const column = useMemo(() => {
    return shopColumns.data?.find((x) => x.key === cell.column);
  }, [shopColumns.data, cell.column]);

  const sequence = useMemo(() => {
    return shopSequences.find((x) => x.id === column?.sequenceId);
  }, [shopSequences, column]);

  const { messages, loadingRunMessages, lastRun } = useSequenceMessages({
    activeWorkflowId: sequence?.id,
    activeWorkflowRunId: undefined,
    isSequenceViewer: false,
  });

  const messagesToShow = useMemo(() => {
    const assistantMessage = messages?.find((x) => x.role === 'assistant');
    if (!assistantMessage) {
      return messages;
    }

    const rest = messages.filter((x) => x.role !== 'assistant');

    return [assistantMessage, ...rest];
  }, [messages]);

  useEffect(() => {
    if (contentRef.current) {
      setCurrentHeight(showMessages ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [showMessages, messagesToShow]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <div className="flex items-center gap-2">
          {!!cell.entity && (
            <Text>
              {capitalize(cell.entity)} ID {cell.id}
            </Text>
          )}
        </div>
      }
      size="lg"
    >
      <div>
        <Text>
          The value for{' '}
          <Text as="span" fw={700}>
            {column?.label || cell.column}
          </Text>{' '}
          is{' '}
          <Text as="span" fw={700}>
            {data}
          </Text>
          {!!createdAt && (
            <Text size="xs" fs="italic" color="gray.4">
              Updated at: {createdAt} (UTC)
            </Text>
          )}
        </Text>
        <div className="mt-8 flex flex-col gap-4">
          <Text>Here are the insights leading to this value:</Text>
          <WillySimpleText text={reason} />
        </div>

        <div
          className={`gap-4 flex justify-center sticky top-20 bg-white z-10 ${showMessages ? 'py-8' : 'pt-8'}`}
        >
          <Button onClick={() => setShowMessages((x) => !x)} loading={loadingRunMessages}>
            {`${showMessages ? 'Hide' : 'Show'} Agent Output`}
          </Button>
        </div>

        {showMessages && (
          <div
            ref={contentRef}
            style={{
              maxHeight: currentHeight,
              overflow: 'hidden',
              transition: 'max-height 300ms ease-in-out 100ms',
            }}
          >
            {!loadingRunMessages && (
              <div className="flex flex-col gap-4">
                {messagesToShow.map((msg) => (
                  <WillyMessageTemplate
                    key={msg.id}
                    message={msg}
                    canEdit={false}
                    userName={lastRun?.userId || ''}
                    conversationUser={lastRun?.userId || ''}
                    isSequenceMode
                    conversationMessages={messages}
                    hideDetails
                    showToolResults
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
