import { useParams } from 'react-router-dom';
import { AgentLibraryCollection } from './AgentsLibraryCollection';
import { AgentsLibrary } from './AgentsLibrary';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AgentCollection } from '../types/willyTypes';
import { firestoreRef } from 'utils/DB';

export const AgentsLibraryWrapper = () => {
  const { collectionId } = useParams();
  const [collection, setCollection] = useState<AgentCollection | null>(null);
  const getCollection = useCallback(async () => {
    const collectionRef = await firestoreRef()
      .collection('agent_collections')
      .doc(collectionId)
      .get();
    const collectionData = collectionRef.data();
    setCollection(collectionData as AgentCollection);
  }, [collectionId]);

  useEffect(() => {
    if (!!collectionId) {
      getCollection();
    } else {
      setCollection(null);
    }
  }, [collectionId, getCollection]);
  return (
    <div className=" overflow-auto h-full" id="scroll-container">
      <div className=" w-full bg-white z-10 no-scrollbar">
        {collection ? <AgentLibraryCollection collection={collection} /> : <AgentsLibrary />}
      </div>
    </div>
  );
};
