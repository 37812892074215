import { SegmentType } from 'components/CreateEditSegmentationModal';
import firebase from 'firebase/compat/app';
import { sortBy } from 'lodash';
import moment from '@tw/moment-cached';
import { combineReducers, Reducer } from 'redux';
import axiosInstance from 'utils/axiosInstance';

import {
  CreativeFilterExpression,
  CreativeFilterOperands,
  CreativeFilterOperator,
  CreativeHighlightsRequest,
  CreativeHighlightsResponse,
  CreativeMetricFilterExpression,
  CreativeMetricFilterOperands,
  CreativeRequest,
  CreativeResponse,
  CreativeSegmentsResponse,
  CreativeTypes,
  SubscriptionFeature,
  FilterExpressions,
} from '@tw/types';
import { ServicesIds } from '@tw/types/module/services';

import _db from '../../utils/DB';
import { fillInBlanks, fillInBlanksOptions } from 'utils/fill-in-blanks';
import { INIT_SHOP, SEGMENT_INIT } from '../constants';
import { GET_SHOP_SUBSCRIPTION } from 'ducks/shop';
import { reverseAttributionNewGeneration } from 'ducks/attribution/reducers';

export function creatives() {}
const firestore = firebase.firestore;

const defaultExpressionSettings: CreativeFilterExpression = {
  operand: 'campaign_name',
  operator: 'contains',
  value: '',
};

export const defaultExpressionMetricSettings: CreativeMetricFilterExpression = {
  operand: 'roas',
  operator: 'equals',
  value: '',
};

export async function getHighlights(
  params: CreativeHighlightsRequest,
): Promise<CreativeHighlightsResponse> {
  const url = '/v2/metrics-table/get-creative-highlights';

  try {
    const oldAttributionModel = reverseAttributionNewGeneration(params.pixel_attribution_model);
    const { data } = await axiosInstance.post<CreativeHighlightsResponse>(url, {
      ...params,
      pixel_attribution_model: oldAttributionModel,
    });
    const { creatives } = data;
    return {
      creatives,
    };
  } catch (e) {
    console.error(e);
    return {
      creatives: [],
    };
  }
}

export async function getCreativeSegments(
  params: CreativeRequest,
): Promise<CreativeSegmentsResponse> {
  const url = '/v2/attribution/get-creative-segments';
  try {
    const oldAttributionModel = reverseAttributionNewGeneration(params.pixel_attribution_model);
    const { data } = await axiosInstance.post<CreativeSegmentsResponse>(url, {
      ...params,
      pixel_attribution_model: oldAttributionModel,
    });
    const segments = data.segments.map((x) => ({
      ...x,
      metricsBreakdown: sortBy(x.metricsBreakdown, 'date'),
    }));
    const withBlanks = fillInBlanks(segments, params as fillInBlanksOptions);
    return {
      segments: withBlanks,
    };
  } catch (e) {
    console.error(e);
    return {
      segments: [],
    };
  }
}

export async function getCreatives(params: CreativeRequest): Promise<CreativeResponse> {
  const url = `/v2/attribution/get-creatives`;
  const oldAttributionModel = reverseAttributionNewGeneration(params.pixel_attribution_model);
  try {
    const { data } = await axiosInstance.post(url, {
      ...params,
      pixel_attribution_model: oldAttributionModel,
    });
    let creatives = data.creatives
      .filter((creative) => creative?.id)
      .map((x) => ({
        ...x,
        metricsBreakdown: sortBy(x.metricsBreakdown, 'date'),
      }));
    if (params.creative_type === 'ad' || params.creative_type === 'adName') {
      creatives = creatives.filter((creative) => creative?.name);
    }
    const withBlanks = fillInBlanks<any>(creatives, params as fillInBlanksOptions);
    return {
      creatives: withBlanks,
      averages: data.averages,
      maximums: data.maximums,
    };
  } catch (e) {
    console.error(e);
    return {
      error: true,
      creatives: [],
      averages: {},
      maximums: {},
    };
  }
}

export async function createShareDoc(data: any, currentShopId: string): Promise<any> {
  console.log('createShareLink: start');

  const shareDoc = await firestore()
    .collection('shops')
    .doc(currentShopId)
    .collection('cc-share-data')
    .add({ data });

  return { shareDoc: shareDoc.id };
}

export const CK_GET_DATA_FROM_FIREBASE = 'CK_GET_DATA_FROM_FIREBASE';

export function getDataFromFireBase(currentShopId: string, docId: string) {
  return async (dispatch) => {
    try {
      let creativesData = await firestore()
        .collection('shops')
        .doc(currentShopId)
        .collection('cc-share-data')
        .doc(docId)
        .get();
      dispatch({
        type: CK_GET_DATA_FROM_FIREBASE,
        creativesData: creativesData.data(),
      });
      dispatch({
        type: GET_SHOP_SUBSCRIPTION,
        payload: {
          features: creativesData.data()?.data.shareHasPixelInstalled
            ? [SubscriptionFeature.PIXEL]
            : [],
        },
      });
    } catch (e) {
      console.log('Error to get the data for shared mode: ', e);
    }
  };
}

export const createFilter = (filter: SegmentType, service: ServicesIds) => {
  return async (dispatch, getState) => {
    const { filters } = getState().creativeCockpit;
    const { id: filterId }: any = await _db()
      .collection('creative_cockpit')
      .doc(service)
      .collection('filters')
      .add(filter);
    filters[service] = { ...filters[service], [filterId]: filter };

    dispatch({
      type: CK_FILTER_UPDATED,
      creative_filters: filters,
    });
  };
};

export const editFilter = (filter: SegmentType, service: ServicesIds) => {
  return async (dispatch, getState) => {
    const { filters } = getState().creativeCockpit;
    filters[service] = { ...filters[service], [filter.id!]: filter };
    dispatch({
      type: CK_FILTER_UPDATING,
    });
    const { id, ...rest } = filter;
    await _db().collection('creative_cockpit').doc(service).collection('filters').doc(id).set(rest);
    dispatch({
      type: CK_FILTER_UPDATED,
      creative_filters: filters,
    });
  };
};

export const deleteFilter = (filterId: string, service: ServicesIds) => {
  return async (dispatch, getState) => {
    const { filters } = getState().creativeCockpit;
    delete filters[service][filterId];
    dispatch({
      type: CK_FILTER_UPDATING,
    });
    await _db()
      .collection('creative_cockpit')
      .doc(service)
      .collection('filters')
      .doc(filterId)
      .delete();
    dispatch({
      type: CK_FILTER_UPDATED,
      creative_filters: filters,
    });
  };
};

export const createSegment = (segment: SegmentType, service: ServicesIds) => {
  return async (dispatch, getState) => {
    const { segments } = getState().creativeCockpit;
    const { id: segmentId }: any = await _db()
      .collection('creative_cockpit')
      .doc(service)
      .collection('segments')
      .add(segment);
    segments[service] = { ...segments[service], [segmentId]: segment };

    dispatch({
      type: CK_SEGMENT_UPDATED,
      creative_segments: segments,
    });
  };
};

export const editSegment = (segment: SegmentType, service: ServicesIds) => {
  return async (dispatch, getState) => {
    const { segments } = getState().creativeCockpit;
    segments[service] = { ...segments[service], [segment.id!]: segment };
    dispatch({
      type: CK_SEGMENT_UPDATING,
    });
    const { id, ...rest } = segment;
    await _db()
      .collection('creative_cockpit')
      .doc(service)
      .collection('segments')
      .doc(id)
      .set(rest);
    dispatch({
      type: CK_SEGMENT_UPDATED,
      creative_segments: segments,
    });
  };
};

export const deleteSegment = (segmentId: string, service: ServicesIds) => {
  return async (dispatch, getState) => {
    const { segments } = getState().creativeCockpit;
    delete segments[service][segmentId];
    dispatch({
      type: CK_SEGMENT_UPDATING,
    });
    await _db()
      .collection('creative_cockpit')
      .doc(service)
      .collection('segments')
      .doc(segmentId)
      .delete();
    dispatch({
      type: CK_SEGMENT_UPDATED,
      creative_segments: segments,
    });
  };
};

const shareData = (state = {}, action) => {
  switch (action.type) {
    case CK_GET_DATA_FROM_FIREBASE:
      return action.creativesData;
    default:
      return state;
  }
};

const currentSegment = (state = initialValue, action) => {
  switch (action.type) {
    case CK_MODAL_DATA:
      return state;
    default:
      return state;
  }
};

const currentFilter = (state = initialFilterValue, action) => {
  switch (action.type) {
    case CK_FILTER_MODAL_DATA:
      return state;
    default:
      return state;
  }
};

const segments: (
  state,
  action,
) => Partial<
  Record<
    ServicesIds,
    Record<string, FilterExpressions<CreativeFilterOperands, CreativeFilterOperator>>
  >
> = (state = {}, action) => {
  switch (action.type) {
    case SEGMENT_INIT:
    case CK_SEGMENT_UPDATED:
      return action.creative_segments || state;
    default:
      return state;
  }
};

const filters: (
  state,
  action,
) => Partial<
  Record<
    ServicesIds,
    Record<string, FilterExpressions<CreativeMetricFilterOperands, CreativeFilterOperator>>
  >
> = (state = {}, action) => {
  switch (action.type) {
    case SEGMENT_INIT:
    case CK_FILTER_UPDATED:
      return action.creative_filters || state;
    default:
      return state;
  }
};

const now = moment();

const lastUpdate = (state = now, action) => {
  switch (action.type) {
    case INIT_SHOP:
    case CK_SEGMENT_UPDATED:
      return moment();
    default:
      return state;
  }
};

const CK_SEGMENT_UPDATING = 'creative-k-segment-updating';
const CK_SEGMENT_UPDATED = 'creative-k-segment-updated';
const CK_MODAL_DATA = 'creative-k-modal-data';

const CK_FILTER_UPDATING = 'creative-k-filter-updating';
const CK_FILTER_UPDATED = 'creative-k-filter-updated';
const CK_FILTER_MODAL_DATA = 'creative-k-filter-modal-data';

const CK_INIT_HIGHLIGHTS_CREATIVES = 'creative-k-init-highlight';
const CK_ACTIVE_SERVICE_ID = 'CK_ACTIVE_SERVICE_ID';
const CK_CHART_OPEN_CHANGED = 'CK_CHART_OPEN_CHANGED';
const CK_ACTIVE_TYPE = 'CK_ACTIVE_TYPE';
const CK_SHOULD_SHOW_SEGMENT_INDICATOR = 'CK_SHOULD_SHOW_SEGMENT_INDICATOR';
const CK_SHARE_POPUP_OPEN = 'CK_SHARE_POPUP_OPEN';

export const USE_CC_NEXUS = 'USE_CC_NEXUS';

export const changeActiveCreativeType = (activeType: CreativeTypes) => {
  return {
    type: CK_ACTIVE_TYPE,
    activeType,
  };
};

export const toggleSharePopup = (isOpen: boolean) => {
  return {
    type: CK_SHARE_POPUP_OPEN,
    isOpen,
  };
};

export const shouldShowSegmentIndicator = (shouldShow: boolean) => {
  return {
    type: CK_SHOULD_SHOW_SEGMENT_INDICATOR,
    shouldShow,
  };
};

export const changeActiveServiceId = (serviceId: ServicesIds) => {
  return {
    type: CK_ACTIVE_SERVICE_ID,
    serviceId,
  };
};

export const chartOpenChanged = (opened: boolean) => ({
  type: CK_CHART_OPEN_CHANGED,
  opened,
});

const activeType: Reducer<CreativeTypes> = (state = 'ad', action) => {
  switch (action.type) {
    case CK_ACTIVE_TYPE:
      return action.activeType || state;
    default:
      return state;
  }
};

const sharePopupOpen: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case CK_SHARE_POPUP_OPEN:
      return action.isOpen;
    default:
      return state;
  }
};

const showSegmentIndicator = (state = false, action) => {
  switch (action.type) {
    case CK_SHOULD_SHOW_SEGMENT_INDICATOR:
      return action.shouldShow;
    default:
      return state;
  }
};

const activeServiceId: Reducer<ServicesIds> = (state = 'facebook-ads', action) => {
  switch (action.type) {
    case CK_ACTIVE_SERVICE_ID:
      return action.serviceId;
    default:
      return state;
  }
};

const chartOpen: Reducer<boolean> = (state = true, action) => {
  switch (action.type) {
    case CK_CHART_OPEN_CHANGED:
      return action.opened;
    default:
      return state;
  }
};

const segmentsUpdating = (state = false, action) => {
  switch (action.type) {
    case CK_SEGMENT_UPDATING:
      return true;
    case CK_SEGMENT_UPDATED:
      return false;
    default:
      return state;
  }
};

const filtersUpdating = (state = false, action) => {
  switch (action.type) {
    case CK_FILTER_UPDATING:
      return true;
    case CK_FILTER_UPDATED:
      return false;
    default:
      return state;
  }
};

const modalData = (state = {}, action) => {
  switch (action.type) {
    case CK_MODAL_DATA:
      return action.payload;
    default:
      return state;
  }
};

const modalFilterData = (state = {}, action) => {
  switch (action.type) {
    case CK_FILTER_MODAL_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const openModalSegmentation = (serviceId: ServicesIds | '', segmentId?: string) => {
  return {
    type: CK_MODAL_DATA,
    payload: {
      serviceId,
      segmentId,
      isOpen: serviceId.length > 0,
      isEdit: !!segmentId,
    },
  };
};

export const openModalMetricFilter = (serviceId: ServicesIds | '', filterId?: string) => {
  return {
    type: CK_FILTER_MODAL_DATA,
    payload: {
      serviceId,
      filterId,
      isOpen: serviceId.length > 0,
      isEdit: !!filterId,
    },
  };
};

const initialValue = {
  segmentTitle: '',
  segmentDescription: '',
  expressionList: [defaultExpressionSettings],
  enabled: true,
};

export const saveHighlightsCreatives = (highlightsCreatives) => ({
  type: CK_INIT_HIGHLIGHTS_CREATIVES,
  payload: highlightsCreatives,
});

export const highlightsCreatives = (state = {}, action) => {
  switch (action.type) {
    case CK_INIT_HIGHLIGHTS_CREATIVES:
      return action.payload;
    default:
      return state;
  }
};
const useCCNexus: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return false;
    case USE_CC_NEXUS:
      return !!action.useCCNexus;
    default:
      return state;
  }
};
export const changeUseCCNexus = (useCCNexus: boolean) => ({
  type: USE_CC_NEXUS,
  useCCNexus,
});

const initialFilterValue = {
  segmentTitle: '',
  segmentDescription: '',
  expressionList: [defaultExpressionMetricSettings],
  enabled: true,
};

export const reducers = combineReducers({
  modalData,
  modalFilterData,
  currentSegment,
  currentFilter,
  segments,
  filters,
  lastUpdate,
  segmentsUpdating,
  highlightsCreatives,
  filtersUpdating,
  activeServiceId,
  chartOpen,
  showSegmentIndicator,
  sharePopupOpen,
  activeType,
  shareData,
  useCCNexus,
});
