import EnquirelabsIconBase64 from 'components/EnquirelabsIconBase64';
import KnoLogoSVG from 'components/Icons/KnoLogoSVG';
import PinterestIconBase64 from 'components/PinterestIconBase64';
import PixelIconSVG from 'components/PixelIconSVG';
import PixelSummaryFilterBar from 'components/PixelSummaryFilterBar';
import { SummaryPixelWidget } from 'components/SummaryWidgets/summary.pixel.widget';
import { ReactComponent as AffluencerIcon } from 'icons/affluencer-hub.svg';
import { SummarySection as SummarySectionType, type SummarySection } from 'types/SummarySection';
import { Text } from '@shopify/polaris';
import FacebookIconBase64 from '../components/FacebookIconBase64';
import GoogleAdsIconBase64 from '../components/GoogleAdsIconBase64';
import GorgiasIconBase64 from '../components/GorgiasIconBase64';
import RechargeBase64 from '../components/RechargeBase64';
import MountainIconBase64 from 'components/Mountain/MountainIconBase64';
import CriteoIconBase64 from 'components/Criteo/CriteoIconBase64';
import SmsbumpYotpoEmailSMSIconBase64 from 'components/SmsbumpYotpoEmailSMS/SmsbumpYotpoEmailSMSIconBase64';
import SmsbumpYotpoReviewsIconBase64 from 'components/SmsbumpYotpoReviews/SmsbumpYotpoReviewsIconBase64';
import OkendoIconBase64 from 'components/Okendo/OkendoBase64';
import KlaviyoIconBase64 from '../components/KlaviyoIconBase64';
import PinIconBase64 from '../components/PinIconBase64';
import MicrosoftIcon from 'components/Icons/MicrosoftSvg';
import SnapchatIconBase64 from '../components/SnapchatIconBase64';
import TiktokIconBase64 from '../components/TiktokIconBase64';
import TwitterIconBase64 from '../components/TwitterIconBase64';
import TripleWhaleIconBase64 from '../components/TripleWhaleIconBase64';
import AmazonIconBase64 from 'components/AmazonBase64';
import AmazonNotice from 'components/AmazonNotice';
import { SalesPlatformName } from 'components/SalesPlatform/SalesPlatformName';
import SalesPlatformIcon from 'components/SalesPlatform/SalesPlatformIcon';
import { SummaryMetricIdsTypes } from '@tw/types/module/SummaryMetrics';
// import { PacingWidget } from 'pages/Forecasting/pacing/PacingWidget';
// import { ForwardView } from 'pages/Forecasting/forwardView/ForwardViewWidget';
// import PacingWidgetFilterBar from 'pages/Forecasting/pacing/PacingWidgetFilterBar';
import ShipstationIconBase64 from 'components/Shipstation/ShipstationIconBase64';
import { FormattedColor, Icon } from '@tw/ui-components';
import { useDarkMode } from 'dark-mode-control';
import ShipbobIconBase64 from 'components/Shipbob/ShipbobIconBase64';
import PostscriptIconBase64 from 'components/PostscriptBase64';
import { CreateCustomMetricModalToggle } from 'components/SummaryWidgets/CreateCustomMetricModalToggle';
import { useState } from 'react';
import { BenchmarksFilterBar } from '../components/BenchmarksFilterBar';
import { TaboolaIconBase64 } from '../components/onboarding/icons/integ/TaboolaBase64';
import StackAdaptIconBase64 from 'components/StackAdapt/StackAdapt';
import { OutbrainIconBase64 } from '../components/onboarding/icons/integ/OutbrainBase64';

import SkioIconBase64 from 'components/Skio/SkioIconBase64';
import ApplovinIconBase64 from 'components/Applovin/ApplovinIconBase64';
import RedditIconBase64 from 'components/Reddit/RedditIconBase64';
import SendlaneIconBase64 from 'components/Sendlane/SendlaneIconBase64';
import LinkedinIconBase64 from 'components/LinkedIn/LinkedinIconBase64';
import AdrollIconBase64 from '../components/Adroll/AdrollIconBase64';
import { ReactComponent as Attribution } from 'icons/attribution2.svg';
import wooCommerceIcon from '../icons/wooCommerce.svg';
import WoocommerceIconBase64 from 'components/Woocommerce/WoocommerceIconBase64';
import RakutenIconBase64 from 'components/Rakuten/RakutenIconBase64';
import ImpactIconBase64 from 'components/Impact/ImpactIconBase64';
import ShareasaleIconBase64 from 'components/Shareasale/ShareasaleIconBase64';

export const statsTiles: SummaryMetricIdsTypes[] = [
  'totalNetProfit',
  'roas',
  'mer',
  'totalNetMargin',
  'blendedAds',
  'newCustomersCpa',
  'newCustomersRoas',
  'blendedSales',
];
export const statsHiddenTiles: SummaryMetricIdsTypes[] = [
  'googleRps',
  'totalReturns',
  'shopifyCpa',
  'poas',
  'totalCustomAdSpends',
  'cashTurnover',
  'blendedAttributedRoas',
];
const SECTIONS: SummarySection[] = [
  // {
  //   id: 'forwardView',
  //   types: ['summary'],
  //   title: 'Forward View',
  //   isHideByDefault: false,
  //   showPreviousPeriod: false,
  //   icons: [({ small }) => <Icon name="forward-view" size={small ? 11 : 16} />],
  //   allowToPickForCustomSection: false,
  //   tiles: [
  //     'forwardViewTotalRevenue',
  //     'forwardViewNcCustomerCntAttribution',
  //     'forwardViewProductOrdersCount',
  //   ],
  //   widgets: [ForwardView],
  //   disabledSwitchMode: true,
  //   services: ['forecasting'],
  // },
  // {
  //   id: 'pacing',
  //   types: ['summary'],
  //   title: 'Pacing',
  //   isHideByDefault: false,
  //   showPreviousPeriod: false,
  //   icons: [({ small }) => <Icon name="pacing" size={small ? 11 : 22} />],
  //   allowToPickForCustomSection: false,
  //   tiles: [
  //     'pacingTotalRevenue',
  //     'pacingReturningCustomersRevenue',
  //     'pacingNewCustomersRevenue',
  //     // 'pacingTotalNetProfit',
  //     'pacingBlendedAdSpend',
  //     'pacingFacebookAdsConversionValue',
  //     'pacingGoogleAdsConversionValue',
  //     'pacingPinterestAdsConversionValue',
  //     'pacingSnapchatAdsConversionValue',
  //     'pacingTiktokAdsConversionValue',
  //   ],
  //   widgets: [PacingWidget],
  //   disabledSwitchMode: true,
  //   services: ['forecasting'],
  //   FilterBar: <PacingWidgetFilterBar />,
  // },
  {
    id: 'pinned',
    types: ['summary'],
    title: 'Pins',
    icons: [PinIconBase64],
    emptySectionTitle: 'No pinned tiles... ',
    emptySectionLabel: 'Tip: you can pin tiles from any section!',
    showPinned: true,
    allowToPickForCustomSection: true,
    tiles: [],
    sensitiveToCurrencies: true,
    showPreviousPeriod: true,
    defaultFreeConfig: {
      tilesToShow: ['sales', 'roas', 'blendedAds'],
    },
    tooltip: (
      <>
        See the data that matters to you by pinning important metrics in this section.
        <br />
        Hover your mouse over the metric tile and click the 📌 icon in the upper-right corner.
      </>
    ),
  },
  {
    id: 'customMetrics',
    types: ['summary'],
    title: 'Custom Metrics',
    icons: [
      () => {
        const doDarkMode = useDarkMode();
        const color: FormattedColor = doDarkMode ? 'gray.2' : 'black';
        return <Icon name="calculator" width={16} color={color} />;
      },
    ],
    tiles: statsTiles,
    hiddenTiles: statsHiddenTiles,
    isDynamicSection: true,
    showPreviousPeriod: false,
    allowToPickForCustomSection: true,
    services: ['triple-whale'],
    sensitiveToCurrencies: true,
    defaultFreeConfig: {
      tilesToShow: ['roas', 'blendedAds', 'mer', 'newCustomersCpa', 'newCustomersRoas'],
    },
    FilterBar: <CreateCustomMetricModalToggle />,
    // addNewBox: {
    //   title: 'Create Custom Metric',
    //   action: toggleCustomMetricModal({ isModalOpen: true }),
    //   limitation: FeatureFlag.CUSTOM_METRICS_FF,
    // },
  },
  {
    id: 'pixel',
    title: 'Attribution',
    icons: [Attribution],
    tiles: [],
    allowToPickForCustomSection: false,
    widgets: [SummaryPixelWidget],
    types: ['summary'],
    services: ['pixel'],
    FilterBar: <PixelSummaryFilterBar />,
    disabledSwitchMode: true,
    showPreviousPeriod: false,
  },
  {
    id: 'webAnalytics',
    title: 'Web Analytics',
    icons: [Attribution],
    tiles: [
      'pixelConversionRate',
      'pixelUniqueVisitors',
      'pixelVisitors',
      'pixelAvgPagesPerSession',
      'pixelAvgSessionDuration',
      'pixelBounceRate',
      'pixelNewVisitors',
      'pixelPercentNewVisitors',
      'pixelUniqueSessionsAtc',
      'pixelPercentAtc',
      'pixelCostPerAtc',
      'pixelCostPerSession',
    ],
    defaultFreeConfig: {
      tilesToShow: [
        'pixelConversionRate',
        'pixelUniqueVisitors',
        'pixelVisitors',
        'pixelAvgPagesPerSession',
        'pixelAvgSessionDuration',
        'pixelBounceRate',
        'pixelNewVisitors',
        'pixelPercentNewVisitors',
        'pixelUniqueSessionsAtc',
        'pixelPercentAtc',
        'pixelCostPerAtc',
        'pixelCostPerSession',
      ],
    },
    allowToPickForCustomSection: true,
    types: ['summary'],
    showPreviousPeriod: true,
    services: ['pixel'],
    msgForNewUsers: (ffComputer) => {
      return (
        <>
          <Text as="p" variant="headingSm">
            * Data available after June 26, 2023
          </Text>
        </>
      );
    },
  },
  {
    id: 'store',
    types: ['summary'],
    title: 'Store',
    icons: [SalesPlatformIcon],
    showPreviousPeriod: true,
    services: ['shopify'],
    tiles: [
      'sales',
      'orders',
      'totalRefunds',
      'taxes',
      'shopifyAov',
      'shopifyAovIncludeZero',
      'newCustomersPercent',
      'grossSales',
      'oldCustomersPercent',
      'shopifyOrdersWithAmount',
      'newCustomersOrders',
      'totalVariantsSold',
      'newCustomerSales',
      'rcRevenue',
      'discounts',
      'netSales',
      'taxesSales',
    ],
    defaultFreeConfig: {
      tilesToShow: ['sales', 'shopifyAov', 'shopifyAovIncludeZero', 'netSales', 'orders'],
    },
    allowToPickForCustomSection: true,
    sensitiveToCurrencies: true,
    enableSegmentation: true,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        <br />
        Here, <SalesPlatformName /> is simply passing us data from your store, and we are displaying
        them here -- while using them to create aggregated metrics in the{' '}
        <strong>Custom Metrics</strong> section above.
        <br />
        <br />
        <strong>
          Do these match metrics in <SalesPlatformName /> ?
        </strong>
        <br />
        Not necessarily! Hover your mouse over the ❓ in the top of each tile to see how we're
        calculating each metric.
      </>
    ),
  },
  {
    id: 'facebookAds',
    types: ['summary'],
    title: 'Meta Ads',
    services: ['facebook-ads'],
    showPreviousPeriod: false,
    icons: [FacebookIconBase64],
    tiles: [
      'facebookAds',
      'facebookRoas',
      'facebookCpc',
      'facebookCpm',
      'facebookMetaPurchases',
      'facebookWebPurchases',
      'facebookWebConversionValue',
      'facebookMetaConversionValue',
      'facebookConversionValue',
      'facebookCpoc',
      'facebookViewThrough',
      'facebookViewThroughRoas',
      'facebookViewThroughCpa',
      'facebookCtr',
      'facebookRevenuePerClick',
      'facebookPurchases',
      'facebookCpa',
      //'facebookOutboundClicksCtr',
    ],
    defaultFreeConfig: {
      tilesToShow: ['facebookAds', 'facebookRoas'],
    },
    indicationWhileShopIsLoading: true,
    invalidConnectionShopField: 'facebookInvalidConnectionState',
    invalidConnectionReasonShopField: 'facebookInvalidConnectionReason',
    allowToPickForCustomSection: true,
    enableSegmentation: true,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        <br />
        Meta is simply passing us these numbers, and we are displaying them here while using them to
        create aggregated metrics in the <strong>Custom Metrics</strong> section above.
        <br />
        <br />
        <strong>What about Pixel data?</strong>
        <br />
        To see the first-party attribution metrics we've tracked for Meta using our Pixel, see the{' '}
        <a href="https://app.triplewhale.com/attribution/ads/facebook-ads">Pixel &gt; Ads</a> page.
      </>
    ),
  },
  {
    id: 'GOOGLE_ADS',
    types: ['summary'],
    showPreviousPeriod: false,
    services: ['google-ads'],
    title: 'Google Ads',
    icons: [GoogleAdsIconBase64],
    tiles: [
      'googleAds',
      'googleConversionValue',
      'googleRoas',
      'ga_all_transactions_adGroup',
      'totalGoogleAdsCtr',
      'googleAllCpa',
      'totalGoogleAdsCpm',
      'totalGoogleAdsClicks',
      'totalGoogleAdsImpressions',
      'ga_all_transactionsRevenue_adGroup',
      'totalAllRoas',
      'ga_transactions_adGroup',
      'googleCpa',
    ],
    defaultFreeConfig: {
      tilesToShow: ['googleAds', 'googleRoas'],
    },
    indicationWhileShopIsLoading: true,
    invalidConnectionShopField: 'googleAdsInvalidConnectionState',
    invalidConnectionReasonShopField: 'googleAdsInvalidConnectionReason',
    allowToPickForCustomSection: true,
    enableSegmentation: true,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        <br />
        Google is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the Custom Metrics section above.
        <br />
        <br />
        <strong>What about Pixel data?</strong>
        <br />
        To see the first-party attribution metrics we've tracked for Google using our Pixel, see the{' '}
        <a href="https://app.triplewhale.com/attribution/ads/google-ads">
          {' '}
          Pixel &gt; Ads
        </a> page.{' '}
      </>
    ),
  },
  {
    id: 'tiktok',
    types: ['summary'],
    title: 'Tiktok',
    icons: [TiktokIconBase64],
    services: ['tiktok-ads'],
    showPreviousPeriod: false,
    tiles: [
      'tiktokAds',
      'tiktokRoas',
      'tiktokImpressions',
      'tiktokCpm',
      'tiktokCpc',
      'tiktokCtr',
      'tiktokCpa',
      'tiktokConversionValue',
      'tiktokPurchases',
      'tiktokShopConversions',
      'tiktokShopConversionsValue',
      'tiktokWebPurchases',
      'tiktokWebConversionsValue',
      'tiktokNonTrackedSpend',
    ],
    defaultFreeConfig: {
      tilesToShow: ['tiktokAds', 'tiktokRoas'],
    },
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: true,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        <br />
        TikTok is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
        <br />
        <br />
        <strong>What about Pixel data?</strong>
        <br />
        To see the first-party attribution metrics we've tracked for TikTok using our Pixel, see the{' '}
        <a href="https://app.triplewhale.com/attribution/ads/tiktok-ads"> Pixel &gt; Ads </a> page.
        <br />
        <br />
        <strong>GMV Max Ads</strong>
        <br />
        TikTok doesn't provide performance data for GMV Max ads, so their spend is not included in
        overall channel performance metrics (e.g., ROAS, CPA)
      </>
    ),
  },
  {
    id: 'amazon-ads',
    types: ['summary'],
    title: 'Amazon Ads',
    icons: [AmazonIconBase64],
    showPreviousPeriod: false,
    enableSegmentation: true,
    services: ['amazon'],
    FilterBar: <AmazonNotice />,
    tiles: [
      'amazonAds',
      'amazonROAS',
      'amazonCPC',
      'amazonClicks',
      'amazonPurchases',
      'amazonTACos',
      'amazonAdsConversionValue',
    ],
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    dataType: 'ads-metrics',
  },
  {
    id: 'bing',
    types: ['summary'],
    title: 'Microsoft',
    showPreviousPeriod: false,
    icons: [MicrosoftIcon],
    services: ['bing'],
    tiles: [
      'bingAdSpend',
      'bingConversions',
      'bingConversionValue',
      'bingClicks',
      'bingImpressions',
      'bingRoas',
      'bingCtr',
      'bingCpa',
      'bingCpm',
    ],
    defaultFreeConfig: {
      tilesToShow: ['bingAdSpend', 'bingRoas'],
    },
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: true,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        This is not Triple Pixel data. All the data on the Summary page is channel-native data -- in
        other words, data imported directly from your ad channel accounts.
        <br />
        Microsoft is simply passing us these numbers, and we are displaying them here while using
        them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
        <br />
        <br />
        <strong>What about Pixel data?</strong>
        <br />
        To see the first-party attribution metrics we've tracked for Snapchat using our Pixel, see
        the <a href="https://app.triplewhale.com/attribution/ads/bing">Pixel &gt; Ads</a> page.
        <br />
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'recharge',
    types: ['summary'],
    title: 'Recharge',
    showPreviousPeriod: true,
    icons: [RechargeBase64],
    services: ['recharge'],
    tiles: [
      'rechargeSubscription1stOrderSales',
      'rechargeSubscriptionRecurringOrder',
      'rechargeTotalSubscriptionSales',
      'rechargeSubscriptionSalesPercentage',
      'rechargeReactivatedSubscribers',
      'rechargeTotalNewSubscriptions',
      'rechargeTotalCancelledSubscriptions',
      'rechargeTotalChurnedSubscribers',
      'rechargeTotalActiveSubscriptions',
      'rechargeTotalActiveSubscribers',
    ],
    defaultFreeConfig: {
      tilesToShow: ['rechargeSubscription1stOrderSales', 'rechargeSubscriptionRecurringOrder'],
    },
    hiddenTiles: [],
    // hiddenTiles: ['rechargeAverageActiveDays', 'rechargeAverageSubscriptionsPerCustomer'],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        This is not Triple Pixel data. All the data on the Summary page is channel-native data -- in
        other words, data imported directly from your ad channel accounts.
        <br />
        Recharge is simply passing us these numbers, and we are displaying them here while using
        them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'subscriptions',
  },
  {
    id: 'skio',
    types: ['summary'],
    title: 'Skio',
    showPreviousPeriod: true,
    icons: [SkioIconBase64],
    services: ['skio'],
    tiles: [
      'skioSubscription1stOrderSales',
      'skioSubscriptionRecurringOrder',
      'skioTotalSubscriptionSales',
      'skioSubscriptionSalesPercentage',
      'skioReactivatedSubscribers',
      'skioTotalNewSubscriptions',
      'skioTotalCancelledSubscriptions',
      'skioTotalChurnedSubscribers',
      'skioTotalActiveSubscriptions',
      'skioTotalActiveSubscribers',
    ],
    defaultFreeConfig: {
      tilesToShow: ['skioSubscription1stOrderSales', 'skioSubscriptionRecurringOrder'],
    },
    hiddenTiles: [],
    // hiddenTiles: ['skioAverageActiveDays', 'skioAverageSubscriptionsPerCustomer'],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        This is not Triple Pixel data. All the data on the Summary page is channel-native data -- in
        other words, data imported directly from your ad channel accounts.
        <br />
        skio is simply passing us these numbers, and we are displaying them here while using them to
        create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'subscriptions',
  },
  {
    id: 'woocommerce-subscriptions',
    types: ['summary'],
    title: 'WooCommerce Subscriptions',
    showPreviousPeriod: true,
    icons: [WoocommerceIconBase64],
    services: ['woocommerce'],
    tiles: [
      'woocommerceSubscription1stOrderSales',
      'woocommerceSubscriptionRecurringOrder',
      'woocommerceTotalSubscriptionSales',
      'woocommerceSubscriptionSalesPercentage',
      'woocommerceReactivatedSubscribers',
      'woocommerceTotalNewSubscriptions',
      'woocommerceTotalCancelledSubscriptions',
      'woocommerceTotalChurnedSubscribers',
      'woocommerceTotalActiveSubscriptions',
      'woocommerceTotalActiveSubscribers',
    ],
    defaultFreeConfig: {
      tilesToShow: [
        'woocommerceSubscription1stOrderSales',
        'woocommerceSubscriptionRecurringOrder',
      ],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        This is not Triple Pixel data. All the data on the Summary page is channel-native data -- in
        other words, data imported directly from your ad channel accounts.
        <br />
        skio is simply passing us these numbers, and we are displaying them here while using them to
        create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'subscriptions',
  },
  {
    id: 'mountain',
    types: ['summary'],
    title: 'MNTN',
    showPreviousPeriod: true,
    icons: [MountainIconBase64],
    services: ['mountain'],
    tiles: [
      'mountainSpend',
      'mountainRoas',
      'mountainImpressions',
      'mountainConversions',
      'mountainConversionRate',
      'mountainConversionValue',
      'mountainAverageOrderValue',
      'mountainCostPerAcquisition',
      'mountainCostPerVisit',
    ],
    defaultFreeConfig: {
      tilesToShow: ['mountainSpend', 'mountainRoas'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        MNTN is simply passing us these numbers, and we are displaying them here while using them to
        create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'criteo',
    types: ['summary'],
    title: 'Criteo',
    showPreviousPeriod: true,
    icons: [CriteoIconBase64],
    services: ['criteo'],
    tiles: [
      'criteoSpend',
      'criteoRoas',
      'criteoDisplays',
      'criteoConversions',
      'criteoConversionRate',
      'criteoVisits',
      'criteoClicks',
      'criteoAverageOrderValue',
      'criteoCostPerAcquisition',
      'criteoCostPerVisit',
      'criteoConversionValue',
    ],
    defaultFreeConfig: {
      tilesToShow: ['criteoSpend', 'criteoRoas'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Criteo is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'smsbump',
    types: ['summary'],
    title: 'Yotpo SMS and Email',
    showPreviousPeriod: true,
    icons: [SmsbumpYotpoEmailSMSIconBase64],
    services: ['smsbump'],
    tiles: [
      'smsbumpSpend',
      'smsbumpRoas',
      'smsbumpRevenue',
      'smsbumpConversions',
      'smsbumpSent',
      'smsbumpCtr',
      'smsbumpAov',
      'smsbumpUnsubscribed',
      'smsbumpClicks',
      'smsbumpDelivered',
      'smsbumpCampaignRevenue',
      'smsbumpFlowRevenue',
      'smsbumpRoi',
    ],
    defaultFreeConfig: {
      tilesToShow: ['smsbumpSpend', 'smsbumpRoas'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Yotpo is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    isSensoryAssetType: true,
    dataType: 'ads-metrics',
  },
  {
    id: 'smsbump-reviews',
    types: ['summary'],
    title: 'Yotpo Reviews',
    showPreviousPeriod: true,
    icons: [SmsbumpYotpoReviewsIconBase64],
    services: ['smsbump'],
    tiles: [
      'smsbumpReviewsCountReview',
      'smsbumpRecomendedReview',
      'smsbumpAvarageRatingReview',
      'smsbumpRecomendedRateReview',
    ],
    defaultFreeConfig: {
      tilesToShow: ['smsbumpRecomendedReview', 'smsbumpReviewsCountReview'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Yotpo is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    isSensoryAssetType: true,
    dataType: 'reviews',
  },
  {
    id: 'okendo',
    types: ['summary'],
    title: 'Okendo',
    showPreviousPeriod: true,
    icons: [OkendoIconBase64],
    services: ['okendo'],
    tiles: [
      'okendoReviewsCountReview',
      'okendoRecomendedReview',
      'okendoAvarageRatingReview',
      'okendoRecomendedRateReview',
    ],
    defaultFreeConfig: {
      tilesToShow: ['okendoRecomendedReview', 'okendoReviewsCountReview'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Yotpo is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'reviews',
  },
  {
    id: 'postscript',
    types: ['summary'],
    title: 'Postscript',
    showPreviousPeriod: true,
    icons: [PostscriptIconBase64],
    services: ['postscript'],
    tiles: [
      'postscriptRevenue',
      'postscriptConversions',
      'postscriptSent',
      'postscriptDelivered',
      'postscriptSubscribed',
      'postscriptUnsubscribed',
      'postscriptClicks',
      'postscriptCtr',
      'postscriptAov',
    ],
    defaultFreeConfig: {
      tilesToShow: ['smsbumpSpend', 'smsbumpRoas'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Postscript is simply passing us these numbers, and we are displaying them here while using
        them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'gorgias',
    types: ['summary'],
    title: 'Gorgias',
    showPreviousPeriod: true,
    icons: [GorgiasIconBase64],
    services: ['gorgias'],
    tiles: [
      'gorgiasSensoryTicketsCreated',
      'gorgiasSensoryTicketsReplied',
      'gorgiasSensoryAvgResponseTime',
      'gorgiasSensoryAvgResolutionTime',
    ],
    defaultFreeConfig: {
      tilesToShow: ['gorgiasSensoryTicketsCreated'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        Within a few short clicks, you can connect your Gorgias account to Triple Whale.
        <br />
        You'll be able to track the total number of tickets, replies, average response time, and
        average resolution time.
      </>
    ),
  },
  {
    id: 'shipstation',
    types: ['summary'],
    title: 'Shipstation',
    showPreviousPeriod: true,
    icons: [ShipstationIconBase64],
    services: ['shipstation'],
    tiles: [
      'shipstationCost',
      'shipstationShipments',
      'shipstationOrders',
      'shipstationOrdersShipped',
      'shipstationOrdersUnshipped',
      'shipstationAvgCostPerShipment',
      'shipstationEstimation',
    ],
    defaultFreeConfig: {
      tilesToShow: ['shipstationCost'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        This is not Triple Pixel data. All the data on the Summary page is channel native data. in
        other words, data imported directly from your ShipStation account. ShipStation is simply
        passing us these numbers, and we are displaying them here while using them to create
        aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'shipping',
  },
  {
    id: 'shipbob',
    types: ['summary'],
    title: 'ShipBob',
    showPreviousPeriod: true,
    icons: [ShipbobIconBase64],
    services: ['shipbob'],
    tiles: [
      'shipbobOrders',
      'shipbobOrdersFulfilled',
      'shipbobPercentOfOrdersFulfilledOnTime',
      'shipbobFulfillmentCost',
      'shipbobAvgFulfillmentCost',
      'shipbobEstimation',
    ],
    defaultFreeConfig: {
      tilesToShow: ['shipbobOrders'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        This is not Triple Pixel data. All the data on the Summary page is channel native data. in
        other words, data imported directly from your ShipBob account. ShipBob is simply passing us
        these numbers, and we are displaying them here while using them to create aggregated metrics
        in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'shipping',
  },
  // {
  //   id: 'stripe-subscription',
  //   types: ['summary'],
  //   title: 'Stripe Subscription',
  //   showPreviousPeriod: true,
  //   icons: [SalesPlatformIcon],
  //   services: ['stripe'],
  //   tiles: [
  //     'stripeSubscriptionUpgradeCount',
  //     'stripeSubscriptionUpgradeAmount',
  //     'stripeSubscriptionDowngradeCount',
  //     'stripeSubscriptionDowngradeAmount',
  //     'stripeSubscriptionDeletedCount',
  //     'stripeSubscriptionDeletedAmount',
  //   ],
  //   hiddenTiles: [],
  //   allowToPickForCustomSection: true,
  //   enableSegmentation: false,
  //   tooltip: (
  //     <>
  //       <strong>Where is this data coming from?</strong>
  //       <br />
  //       This is not Triple Pixel data. All the data on the Summary page is channel-native data -- in
  //       other words, data imported directly from your ad channel accounts.
  //       <br />
  //       Recharge is simply passing us these numbers, and we are displaying them here while using
  //       them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
  //     </>
  //   ),
  //   msp: ['stripe'],
  //   dataType: 'subscriptions',
  // },
  {
    id: 'pinterest',
    types: ['summary'],
    title: 'Pinterest',
    showPreviousPeriod: false,
    icons: [PinterestIconBase64],
    services: ['pinterest-ads'],
    tiles: [
      'pinterestAds',
      'pinterestRoas',
      'pinterestClicks',
      'pinterestCpc',
      'pinterestCpm',
      'pinterestConversionValue',
      'pinterestPurchases',
      'pinterestImpressions',
      'pinterestCtr',
    ],
    defaultFreeConfig: {
      tilesToShow: ['pinterestAds', 'pinterestRoas'],
    },
    indicationWhileShopIsLoading: true,
    invalidConnectionShopField: 'pinterestTokenInvalidConnectionState',
    invalidConnectionReasonShopField: 'pinterestTokenInvalidConnectionReason',
    allowToPickForCustomSection: true,
    enableSegmentation: true,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        <br />
        Pinterest is simply passing us these numbers, and we are displaying them here while using
        them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
        <br />
        <br />
        <strong>What about Pixel data?</strong>
        <br />
        To see the first-party attribution metrics we've tracked for Pinterest using our Pixel, see
        the <a href="https://app.triplewhale.com/attribution/ads/pinterest-ads">
          {' '}
          Pixel &gt; Ads
        </a>{' '}
        page.
      </>
    ),
  },
  {
    id: 'snapchat',
    types: ['summary'],
    title: 'Snapchat',
    showPreviousPeriod: false,
    icons: [SnapchatIconBase64],
    services: ['snapchat-ads'],
    tiles: [
      'snapchatAds',
      'snapchatRoas',
      'snapchatImpressions',
      'snapchatSwipes',
      'snapchatCpm',
      'snapchatCpa',
      'snapchatConversionValue',
    ],
    defaultFreeConfig: {
      tilesToShow: ['snapchatAds', 'snapchatRoas'],
    },
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: true,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        This is not Triple Pixel data. All the data on the Summary page is channel-native data -- in
        other words, data imported directly from your ad channel accounts.
        <br />
        Snapchat is simply passing us these numbers, and we are displaying them here while using
        them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
        <br />
        <br />
        <strong>What about Pixel data?</strong>
        <br />
        To see the first-party attribution metrics we've tracked for Snapchat using our Pixel, see
        the <a href="https://app.triplewhale.com/attribution/ads/pinterest-ads">
          Pixel &gt; Ads
        </a>{' '}
        page.
        <br />
      </>
    ),
  },
  {
    id: 'twitter',
    types: ['summary'],
    title: 'Twitter',
    icons: [TwitterIconBase64],
    services: ['twitter-ads'],
    showPreviousPeriod: false,
    tiles: [
      'twitterAds',
      'twitterRoas',
      'twitterImpressions',
      'twitterCpm',
      'twitterCpa',
      'twitterConversionValue',
      'twitterPurchases',
      'twitterEngagements',
      'twitterFollows',
      'twitterClicks',
    ],
    defaultFreeConfig: {
      tilesToShow: ['twitterAds', 'twitterRoas'],
    },
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: true,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        <br />
        TikTok is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
        <br />
        What about Pixel data?
        <br />
        To see the first-party attribution metrics we've tracked for Twitter using our Pixel, see
        the <a href="https://app.triplewhale.com/attribution/ads/twitter-ads">
          {' '}
          Pixel &gt; Ads{' '}
        </a>{' '}
        page.
      </>
    ),
  },
  {
    id: 'klaviyo',
    types: ['summary'],
    title: 'Klaviyo',
    icons: [KlaviyoIconBase64],
    services: ['klaviyo'],
    showPreviousPeriod: false,
    isHideByDefault: true,
    tiles: [
      'klaviyoPlacedOrderTotalPriceFlows',
      'klaviyoPlacedOrderTotalPriceFlowsPercent',
      'klaviyoPlacedOrderTotalPriceCampaigns',
      'klaviyoPlacedOrderTotalPriceCampaignsPercent',
      'klaviyoPlacedOrderSales',
      'klaviyoSalesPercent',
      'klaviyoUnsubscribed',
      'klaviyoNewSubscribed',
      'klaviyoNetNewSubscribers',
    ],
    defaultFreeConfig: {
      tilesToShow: [
        'klaviyoPlacedOrderSales',
        'klaviyoPlacedOrderTotalPriceFlows',
        'klaviyoPlacedOrderTotalPriceCampaigns',
      ],
    },
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        <br />
        Klaviyo is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
        <br />
        <br />
        <strong>Why does the data not match my Klaviyo dashboard?</strong>
        By default, Klaviyo's Dashboard analytics dashboards based on the send date/time of your
        campaign and flow (i.e. if someone makes an order from Campaign A on January 1, but Campaign
        A was sent on December 25, that order revenue is attributed back to December 25). Triple
        Whale's reported metrics are on the order date, and will therefore most closely resemble the
        dashboards generated in Klaviyo's{' '}
        <a href="https://www.klaviyo.com/analytics/metrics/feed"> Metric Analytics.</a>
        <br />
        <br />
        <strong>What about Pixel data?</strong>
        <br />
        To see the first-party attribution metrics we've tracked for Klaviyo using our Pixel, see
        the{' '}
        <a href="https://app.triplewhale.com/attribution/email/ALL_CHANNELS">
          {' '}
          Pixel &gt; Email & SMS
        </a>{' '}
        page.
      </>
    ),
  },
  {
    id: 'enquirelabs',
    types: ['summary'],
    title: 'Fairing',
    showPreviousPeriod: false,
    isHideByDefault: true,
    icons: [EnquirelabsIconBase64],
    services: ['enquirelabs'],
    tiles: [
      'totalFacebookEnq',
      'totalGoogleEnq',
      'totalSnapchatEnq',
      'totalTiktokEnq',
      'totalPinterestEnq',
      'totalInstagramEnq',
      'responsesPrecent',
    ],
    shouldShowTilesIcons: true,
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
  },
  {
    id: 'kno',
    types: ['summary'],
    title: 'Kno',
    icons: [KnoLogoSVG],
    showPreviousPeriod: false,
    isHideByDefault: true,
    services: ['kno'],
    tiles: [
      'totalFacebookKno',
      'totalGoogleKno',
      'totalSnapchatKno',
      'totalTiktokKno',
      'totalPinterestKno',
      'totalInstagramKno',
      'responsesPrecentKno',
    ],
    shouldShowTilesIcons: true,
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
  },
  {
    id: 'amazon-sales',
    types: ['summary'],
    title: 'Amazon Sales',
    icons: [AmazonIconBase64],
    showPreviousPeriod: false,
    services: ['amazon'],
    tiles: [
      'amazonProductItemPrice',
      'amazonOrders',
      'amazonSalesTaxes',
      'amazonItems',
      'amazonFees',
      'amazonSales',
    ],
    hiddenTiles: [
      'amazonSalesAFN',
      'amazonProductItemPriceAFN',
      'amazonOrdersAFN',
      'amazonSalesTaxesAFN',
      'amazonItemsAFN',
      'amazonFeesAFN',
      'amazonSalesMFN',
      'amazonProductItemPriceMFN',
      'amazonOrdersMFN',
      'amazonSalesTaxesMFN',
      'amazonItemsMFN',
      'amazonFeesMFN',
      'canceledAmazonOrders',
      'canceledAmazonProductItemPrice',
      'canceledAmazonItems',
      'canceledAmazonOrdersAFN',
      'canceledAmazonItemsAFN',
      'canceledAmazonOrdersMFN',
      'canceledAmazonItemsMFN',
      'canceledAmazonProductItemPriceAFN',
      'canceledAmazonProductItemPriceMFN',
    ],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    dataType: 'orders',
  },
  {
    id: 'amazon-stats',
    types: ['summary'],
    title: 'Amazon Stats',
    icons: [AmazonIconBase64],
    showPreviousPeriod: false,
    services: ['amazon'],
    FilterBar: <AmazonNotice />,
    tiles: [
      'amazonNetSales',
      'amazonNetProfit',
      'amazonROAS',
      'amazonMER',
      'amazonCPA',
      'amazonExpenses',
    ],
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
  },
  {
    id: 'taboola',
    types: ['summary'],
    title: 'Taboola',
    showPreviousPeriod: true,
    icons: [TaboolaIconBase64],
    services: ['taboola'],
    tiles: [
      'taboolaSpend',
      'taboolaImpressions',
      'taboolaClicks',
      'taboolaVisits',
      'taboolaVCTR',
      'taboolaCPC',
      // 'taboolaAllConversions',
      'taboolaConversionRate',
      'taboolaCPA',
      'taboolaVCPM',
      'taboolaConversionValue',
      'taboolaRoas',
      'taboolaAov',
      'taboolaCPV',
    ],
    defaultFreeConfig: {
      tilesToShow: ['taboolaSpend', 'taboolaRoas'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Taboola is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'stackadapt',
    types: ['summary'],
    title: 'StackAdapt',
    showPreviousPeriod: true,
    icons: [StackAdaptIconBase64],
    services: ['stackadapt'],
    tiles: [
      'stackadaptSpend',
      'stackadaptImpressions',
      'stackadaptClicks',
      'stackadaptVCTR',
      'stackadaptCPC',
      'stackadaptClickConversions',
      'stackadaptAllConversions',
      'stackadaptConversionRate',
      'stackadaptCPA',
      'stackadaptTotalVideoViews',
      'stackadaptVideoThruplay',
      'stackadaptVCPM',
      'stackadaptConversionValue',
      'stackadaptRoas',
      'stackadaptAov',
      'stackadaptCPV',
    ],
    defaultFreeConfig: {
      // tilesToShow: [],
      tilesToShow: ['stackadaptSpend'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        StackAdapt is simply passing us these numbers, and we are displaying them here while using
        them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'outbrain',
    types: ['summary'],
    title: 'Outbrain',
    showPreviousPeriod: true,
    icons: [OutbrainIconBase64],
    services: ['outbrain'],
    tiles: [
      'outbrainSpend',
      'outbrainImpressions',
      'outbrainClicks',
      'outbrainVCTR',
      // 'outbrainActualCPC',
      'outbrainConversionRate',
      'outbrainConversions',
      'outbrainAllConversions',
      'outbrainCPA',
      'outbrainVCPM',
      'outbrainConversionValue',
      'outbrainRoas',
      'outbrainAov',
    ],
    defaultFreeConfig: {
      tilesToShow: ['outbrainSpend', 'outbrainRoas'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Outbrain is simply passing us these numbers, and we are displaying them here while using
        them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'applovin',
    types: ['summary'],
    title: 'Applovin',
    showPreviousPeriod: true,
    icons: [ApplovinIconBase64],
    services: ['applovin'],
    tiles: [
      'applovinSpend',
      'applovinImpressions',
      'applovinClicks',
      'applovinVCTR',
      'applovinConversionRate',
      'applovinConversions',
      'applovinCPA',
      'applovinVCPM',
      'applovinConversionValue',
      'applovinRoas',
      'applovinAov',
    ],
    defaultFreeConfig: {
      tilesToShow: ['applovinSpend', 'applovinRoas'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Applovin is simply passing us these numbers, and we are displaying them here while using
        them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'reddit',
    types: ['summary'],
    title: 'Reddit',
    showPreviousPeriod: true,
    icons: [RedditIconBase64],
    services: ['reddit'],
    tiles: [
      'redditSpend',
      'redditImpressions',
      'redditClicks',
      'redditVCTR',
      'redditActualCPC',
      'redditConversionRate',
      'redditConversions',
      'redditCPA',
      'redditVCPM',
      'redditConversionValue',
      'redditRoas',
      'redditAov',
      'redditTotalVideoViews',
      'redditVideoThruplay',
    ],
    defaultFreeConfig: {
      tilesToShow: ['redditSpend'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Reddit is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'sendlane',
    types: ['summary'],
    title: 'Sendlane',
    showPreviousPeriod: true,
    icons: [SendlaneIconBase64],
    services: ['sendlane'],
    tiles: [
      'sendlaneSpend',
      'sendlaneImpressions',
      'sendlaneClicks',
      'sendlaneVCTR',
      'sendlaneConversionRate',
      'sendlaneConversions',
      'sendlaneCPA',
      'sendlaneVCPM',
      'sendlaneConversionValue',
      'sendlaneRoas',
      'sendlaneAov',
    ],
    defaultFreeConfig: {
      tilesToShow: ['sendlaneSpend'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Sendlane is simply passing us these numbers, and we are displaying them here while using
        them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'rakuten',
    types: ['summary'],
    title: 'Rakuten',
    showPreviousPeriod: true,
    icons: [RakutenIconBase64],
    services: ['rakuten'],
    tiles: [
      'rakutenSpend',
      'rakutenImpressions',
      'rakutenClicks',
      'rakutenConversions',
      'rakutenConversionValue',
      'rakutenRoas',
    ],
    defaultFreeConfig: {
      tilesToShow: ['rakutenSpend', 'rakutenRoas'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Rakuten is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'impact',
    types: ['summary'],
    title: 'Impact',
    showPreviousPeriod: true,
    icons: [ImpactIconBase64],
    services: ['impact'],
    tiles: [
      'impactSpend',
      'impactClicks',
      'impactConversions',
      'impactConversionValue',
      'impactRoas',
    ],
    defaultFreeConfig: {
      tilesToShow: ['impactSpend', 'impactRoas'],
    },
    hiddenTiles: ['impactImpressions'],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Impact Radius is simply passing us these numbers, and we are displaying them here while
        using them to create aggregated metrics in the <strong>Custom Metrics</strong> section
        above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'shareasale',
    types: ['summary'],
    title: 'Shareasale',
    showPreviousPeriod: true,
    icons: [ShareasaleIconBase64],
    services: ['shareasale'],
    tiles: [],
    defaultFreeConfig: {
      tilesToShow: [],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Shareasale is simply passing us these numbers, and we are displaying them here while using
        them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },

  {
    id: 'linkedin',
    types: ['summary'],
    title: 'LinkedIn',
    showPreviousPeriod: true,
    icons: [LinkedinIconBase64],
    services: ['linkedin'],
    tiles: [
      'linkedinSpend',
      'linkedinImpressions',
      'linkedinClicks',
      'linkedinVCTR',
      'linkedinConversionRate',
      'linkedinConversions',
      'linkedinCPA',
      'linkedinVCPM',
      'linkedinConversionValue',
      'linkedinRoas',
      'linkedinAov',
    ],
    defaultFreeConfig: {
      tilesToShow: ['linkedinSpend', 'linkedinRoas'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        LinkedIn is simply passing us these numbers, and we are displaying them here while using
        them to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
  {
    id: 'influencers',
    types: ['summary'],
    title: 'Affluencer',
    icons: [AffluencerIcon],
    tiles: [
      'influencerConversionValue',
      'influencerRoas',
      'influencerSpend',
      'influencerPurchases',
      'influencerNcPurchases',
      'influencerNcConversionValue',
    ],
    isHideByDefault: true,
    allowToPickForCustomSection: true,
    showPreviousPeriod: false,
    sensitiveToCurrencies: true,
    tooltip: (
      <>
        With the Affluencer Hub you can track, manage and analyze all your affluencers (influencers
        & affiliates) across every platform using a combination of Discount Codes, the Triple Pixel
        and a Post Purchase Survey.{' '}
      </>
    ),
  },
  {
    id: 'expenses',
    types: ['summary'],
    title: 'Expenses',
    showPreviousPeriod: false,
    icons: [() => <TripleWhaleIconBase64 small={true} />],
    tiles: [
      'paymentGateways',
      'cogs',
      'handlingFees',
      'shipping',
      'customSpends',
      'totalCustomAdSpends',
      'totalCustomNonAdSpends',
      'totalOrdersCustomExpenses',
      'shippingPrice',
    ],
    allowToPickForCustomSection: true,
    sensitiveToCurrencies: true,
    tooltip: (
      <>
        <strong>How profitable is your store?</strong>
        <br />
        The <strong>Net Profit</strong> metric in your <strong>Custom Metrics</strong> section
        subtracts a set of expenses from your revenue so you can immediately see your current
        profitability.
        <br />
        <strong>Here's a quick breakdown of what you can customize:</strong>
        <ul>
          <li>
            <a href="https://app.triplewhale.com/cogs">Cost of Goods</a> import from{' '}
            <SalesPlatformName /> if you have those setup; otherwise they can be updated in-app;
          </li>
          <li>
            <a href="https://triplewhale.com/shipping"> Shipping Costs</a> - a default shipping cost
            applied on a per-order per-country basis
          </li>
          <li>
            <a href="https://triplewhale.com/paymentgatewaycosts"> Payment Gateway Costs</a> -
            payment processing fees
          </li>
          <li>
            <a href="https://triplewhale.com/spends"> Custom Expenses </a> - Any additional expenses
            you'd like to see deducted from your Net Profit.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'inventory',
    types: ['summary'],
    title: 'Inventory',
    msp: ['shopify'],
    isHideByDefault: true,
    icons: [SalesPlatformIcon],
    showPreviousPeriod: false,
    tiles: ['inventoryItems', 'inventoryItemsMissing', 'totalInventoryCost', 'totalInventoryValue'],
    allowToPickForCustomSection: true,
    sensitiveToCurrencies: true,
    tooltip: (
      <>
        Keep track of your inventory and related costs with these metrics, imported directly from
        your <SalesPlatformName /> store.
      </>
    ),
  },
  {
    id: 'salesBySegment',
    types: ['summary'],
    title: 'Sales By Segment',
    icons: [SalesPlatformIcon],
    msp: ['shopify'],
    isHideByDefault: true,
    tiles: [],
    isDynamicSection: true,
    showPreviousPeriod: false,
    allowToPickForCustomSection: true,
    isHideTilesByDefault: true,
    sensitiveToCurrencies: true,
    tooltip: (
      <>
        Here you can segment out your Sales using any <strong>Order Tags</strong> or{' '}
        <strong>Order Channels</strong> you utilize in your <SalesPlatformName /> store.
        <br />
        <br />
        For example: if you'd like to see the breakdown of POS or Wholesale orders distinguished
        from your online shop orders, you can do that here.
        <br />
        Simply click the button in the upper-right corner to select the segments you'd like to
        displays here.
      </>
    ),
  },
  {
    id: 'customersLifetimeValue',
    types: ['summary'],
    title: 'LTV',
    services: ['shopify'],
    msp: ['shopify'],
    icons: [SalesPlatformIcon],
    tiles: ['uniqueCustomers', 'uniqueCustomerSales', 'customerFrequency', 'ltvCpa'],
    allowToPickForCustomSection: true,
    isHideByDefault: true,
    showPreviousPeriod: false,
    sensitiveToCurrencies: true,
  },
  {
    id: '3roasToRuleThemAll',
    types: ['summary'],
    title: '👑 3 ROAS to Rule Them All',
    icons: [],
    showPreviousPeriod: false,
    tiles: ['roas', 'newCustomersRoas', 'poas', 'blendedAds'],
    isHideByDefault: true,
    shouldShowTilesIcons: true,
    allowToPickForCustomSection: false,
    sensitiveToCurrencies: true,
  },
  {
    id: 'shopify',
    types: ['summary'],
    title: '💰 Store',
    icons: [],
    showPreviousPeriod: false,
    tiles: ['sales', 'shopifyAov', 'orders', 'totalRefunds'],
    isHideByDefault: true,
    shouldShowTilesIcons: true,
    allowToPickForCustomSection: false,
    sensitiveToCurrencies: true,
    tooltip: (
      <>
        This data is imported directly from <SalesPlatformName />. The same data is aggregated in
        the "Custom Metrics" section.
      </>
    ),
  },
  {
    id: 'customerMix',
    types: ['summary'],
    title: '♻️ Customer Mix',
    icons: [],
    isHideByDefault: true,
    showPreviousPeriod: false,
    tiles: [
      'newCustomersCpa',
      'newCustomersRoas',
      'newCustomersPercent',
      'oldCustomersPercent',
      'newCustomersOrders',
    ],
    shouldShowTilesIcons: true,
    allowToPickForCustomSection: false,
    sensitiveToCurrencies: true,
    tooltip: (
      <>
        You can easily create your own Custom Metrics by clicking the Gear button in the upper-right
        corner and selecting <strong> Create Custom Metric.</strong>
      </>
    ),
  },

  {
    id: 'marketingEcosystem',
    types: ['summary'],
    title: '👩‍🌾 Marketing Ecosystem',
    icons: [],
    showPreviousPeriod: false,
    tiles: ['roas', 'blendedAds', 'facebookRoas', 'googleRoas'],
    isHideByDefault: true,
    shouldShowTilesIcons: true,
    allowToPickForCustomSection: false,
    sensitiveToCurrencies: true,
  },
  {
    id: 'paidMediaPerformance',
    types: ['summary'],
    title: '🚀 Paid Media Performance',
    icons: [],
    showPreviousPeriod: false,
    tiles: ['roas', 'blendedAds', 'facebookRoas', 'googleRoas', 'facebookAds', 'googleAds', 'mer'],
    isHideByDefault: true,
    shouldShowTilesIcons: true,
    allowToPickForCustomSection: false,
    sensitiveToCurrencies: true,
  },
  {
    id: 'adroll',
    types: ['summary'],
    title: 'Adroll',
    showPreviousPeriod: true,
    icons: [AdrollIconBase64],
    services: ['adroll'],
    tiles: [
      'adrollSpend',
      'adrollImpressions',
      'adrollClicks',
      'adrollVCTR',
      'adrollConversionRate',
      'adrollConversions',
      'adrollCPA',
      'adrollVCPM',
      'adrollConversionValue',
      'adrollRoas',
      'adrollAov',
    ],
    defaultFreeConfig: {
      tilesToShow: ['adrollSpend', 'adrollRoas'],
    },
    hiddenTiles: [],
    indicationWhileShopIsLoading: true,
    allowToPickForCustomSection: true,
    enableSegmentation: false,
    tooltip: (
      <>
        <strong>Where is this data coming from?</strong>
        <br />
        <strong>This is not Triple Pixel data.</strong> All the data on the Summary page is
        channel-native data -- in other words, data imported directly from your ad channel accounts.
        Adroll is simply passing us these numbers, and we are displaying them here while using them
        to create aggregated metrics in the <strong>Custom Metrics</strong> section above.
      </>
    ),
    dataType: 'ads-metrics',
  },
];

export const BENCHMARKS_SECTION: SummarySection[] = [
  {
    id: 'benchmarks',
    types: ['summary'],
    title: 'Benchmarks',
    showPreviousPeriod: false,
    icons: [() => <TripleWhaleIconBase64 small={true} />],
    tiles: [
      'benchmarksBlendedROAS',
      'benchmarksNCROAS',
      'benchmarksNCPA',
      'benchmarksFacebookRoas',
      'benchmarksCPC',
      'benchmarksCTR',
      'benchmarksCPM',
      'benchmarksGoogleRoas',
      'totalBenchmarksCPCGoogle',
      'totalBenchmarksCPMGoogle',
      'totalBenchmarksCTRGoogle',
      //------new by trends app
      // 'benchmarksCTR',
      // 'benchmarksCPC',
      // 'benchmarksFacebookRoas',
      // 'benchmarksFBAdsSpendAvg',
      // 'totalBenchmarksCTRGoogle',
      // 'totalBenchmarksCPCGoogle',
      // 'benchmarksGoogleRoas',
      // 'totalBenchmarksGoogleAdsSpendAvg',
      // 'totalBenchmarksCTRTiktok',
      // 'totalBenchmarksCPCTiktok',
      // 'benchmarksTiktokRoas',
      // 'totalBenchmarksTiktokAdsSpendAvg',
      // 'totalBenchmarksBlendedCTR',
      // 'totalBenchmarksBlendedCpc',
      // 'benchmarksBlendedROAS',
      // 'benchmarksBlendedSpendAvg',
    ],
    allowToPickForCustomSection: true,
    FilterBar: <BenchmarksFilterBar />,
    isHideByDefault: false,
    tooltip: (
      <>
        <strong>Is it you or is it Facebook?</strong>
        <br />
        Want to know how your store compares with similar brands?
        <br />
        Introducing: <strong>Benchmarks.</strong>
        <br />
        Simply enter your Industry and select your AOV and <strong>monthly</strong> ad spend
        bracket.
        <br />
        <br />
        We will show you how your "peers" (stores with matching selections) are performing -- so you
        can understand where your brand is thriving and how you can improve.
      </>
    ),
  },
];

export default SECTIONS;
