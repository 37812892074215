import { $combinedDashboard } from '$stores/willy/$combinedDashboards';
import { $combineShopAndCustomViewDashboards } from '$stores/willy/$customViews';
import { $globalAndShopSequences, $shopSequences } from '$stores/willy/$sequences';
import { useStoreValue } from '@tw/snipestate';
import { MultiSelect, NumberInput, Select, Text } from '@tw/ui-components';
import { Interval, INTERVAL_OPTIONS } from 'components/Willy/constants';
import {
  DayOfWeek,
  DayOfWeekArr,
  WillyDashboardElement,
  WillyDataSequence,
} from 'components/Willy/types/willyTypes';
import { useMemo, useState } from 'react';
import { capitalize } from 'lodash';

type AgentUsageCalculatorProps = {
  sequenceId: string;
};

export const AgentUsageCalculator: React.FC<AgentUsageCalculatorProps> = (props) => {
  const { sequenceId } = props;

  const costPerQuery = 0.1;

  const globalSequences = useStoreValue($globalAndShopSequences);
  const shopSequences = useStoreValue($shopSequences);
  const dashboards = useStoreValue($combinedDashboard);
  const shopAndViewDashboards = useStoreValue($combineShopAndCustomViewDashboards);

  const [iterations, setIterations] = useState(1);
  const [schedule, setSchedule] = useState<{
    interval: Interval | null;
    days?: DayOfWeek[];
    hours?: number[];
  }>({
    interval: '1d',
    days: [],
    hours: [],
  });

  const totalUsage = useMemo(() => {
    const globalAndShopSequences = [...globalSequences, ...shopSequences];
    const allDashboards = [...dashboards, ...shopAndViewDashboards];
    const sequence = globalAndShopSequences.find((s) => s.id === sequenceId);
    if (!sequence) {
      return 0;
    }
    return calculateAgentUsage(
      sequence,
      allDashboards,
      globalAndShopSequences,
      iterations,
      schedule,
    );
  }, [
    dashboards,
    shopAndViewDashboards,
    globalSequences,
    shopSequences,
    sequenceId,
    schedule,
    iterations,
  ]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        <Text fz={14} fw={600}>
          If you run this agent
        </Text>
        <Select
          label="Every"
          data={[
            {
              label: '-',
              value: '',
            },
            ...Object.values(INTERVAL_OPTIONS).map((option) => ({
              label: option.label,
              value: option.value,
            })),
          ]}
          onChange={(value) => {
            setSchedule({
              interval: value as Interval,
              days: [],
              hours: [],
            });
          }}
          value={schedule.interval}
        />
        <div className="flex items-start gap-2">
          <div className="flex flex-col basis-1/2 flex-shrink-0">
            <Text fz={14} fw={600}>
              or every day
            </Text>
            <MultiSelect
              data={DayOfWeekArr.map((day) => ({
                label: capitalize(day),
                value: day,
              }))}
              value={schedule.days}
              onChange={(value) => {
                setSchedule({
                  ...schedule,
                  days: value as DayOfWeek[],
                  interval: null,
                });
              }}
            />
          </div>

          <div className="flex flex-col basis-1/2 flex-shrink-0">
            <Text fz={14} fw={600}>
              At
            </Text>
            <MultiSelect
              disabled={!!schedule.interval}
              data={Array.from({ length: 24 }, (_, i) => {
                return {
                  label: i.toString().padStart(2, '0') + ':00',
                  value: i.toString(),
                };
              })}
              value={schedule.hours?.map((h) => h.toString())}
              onChange={(value) => {
                setSchedule({ ...schedule, hours: value.map((v) => parseInt(v)) });
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Text fz={14} fw={600}>
          On this number of entities
        </Text>
        <NumberInput
          min={1}
          value={iterations}
          onChange={(value) => {
            const num = Number(value);
            if (isNaN(num)) {
              return;
            }
            setIterations(num);
          }}
        />
      </div>

      <div className="flex items-center gap-1 font-bold text-3xl">
        <span>~</span>
        <span>
          {(totalUsage * costPerQuery).toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
        <span> / month</span>
      </div>
    </div>
  );
};

export function calculateAgentUsage(
  sequence: WillyDataSequence,
  dashboards: WillyDashboardElement[],
  sequences: WillyDataSequence[],
  iterations: number,
  schedule: {
    interval: Interval | null;
    days?: DayOfWeek[];
    hours?: number[];
  },
): number {
  const { steps } = sequence;

  function runsPerMonth() {
    if (!schedule) {
      return 0;
    }

    const interval = schedule.interval;
    const days = schedule.days?.length;
    const hours = schedule.hours?.length;

    if (interval) {
      const intervalData = INTERVAL_OPTIONS[interval];
      return (intervalData.runsPerDay || 1) * 30;
    } else if (days && hours) {
      const runsPerDay = days * hours;
      const runsPerMonth = runsPerDay * 4.3;
      return runsPerMonth;
    }

    return 0;
  }

  const totalTokens = steps.reduce((acc, step) => {
    if (
      step.stepType === 'tool' &&
      step.toolToUse &&
      ['TextToSQL', 'Forecasting', 'MarketingMixModel'].includes(step.toolToUse)
    ) {
      return acc + 1;
    } else if (step.stepType === 'runQuery') {
      return acc + 1;
    } else if (step.stepType === 'preloadData') {
      const { dashboardId, previousDate, isStandardDashboard } = step;
      if (isStandardDashboard) {
        return acc;
      }
      const loadForEachQuery = !previousDate || previousDate === 'none' ? 1 : 2;
      const dashboard = dashboards.find((dashboard) => dashboard.id === dashboardId);
      if (!dashboard) {
        return acc;
      }
      return dashboard.widgetIds.length * loadForEachQuery + acc;
    } else if (step.stepType === 'condition') {
      const { rules } = step;
      const fromRules = rules.reduce((acc, rule) => {
        return acc + (rule.ruleIds.length || 0);
      }, 0);
      return fromRules + acc;
    } else if (step.stepType === 'subSequence') {
      const { sequenceIds } = step;
      if (!sequenceIds) {
        return acc;
      }
      const fromSubSequences = sequenceIds.reduce((acc, sequenceId) => {
        const subSequence = sequences.find((sequence) => sequence.id === sequenceId);
        if (!subSequence) {
          return acc;
        }
        const fromSubSequence = calculateAgentUsage(
          subSequence,
          dashboards,
          sequences,
          iterations,
          schedule,
        );
        return fromSubSequence + acc;
      }, 0);
      return fromSubSequences + acc;
    }
    return acc;
  }, 0);

  return totalTokens * iterations * runsPerMonth();
}
