import posthog from 'posthog-js';
import { datadogLogs } from '@datadog/browser-logs';
import amplitude from 'amplitude-js';

import { isInIframe, isStaging, isProduction, isLocalFunctions } from 'config';
import { EXTERNAL_PROVIDER_FAUTURE_FLAGS_LOADED } from 'ducks/constants';
import { $subscriptionActive, $createdAt, $pixelActive, $msp, $currentShopId } from '$stores/$shop';
import { $userId, $userEmail } from '$stores/$user';
import { SummarySectionsCustomization } from 'types/SummarySection';
import { datadogRum } from '@datadog/browser-rum';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { keys } from 'lodash';
import store from 'index';
const runEvent =
  window.location.hostname !== 'localhost' &&
  !isInIframe &&
  (isStaging || isProduction || isLocalFunctions);
window.dataLayer = window.dataLayer || [];
window.genericEventLoggerDebug = window.genericEventLoggerDebug || false;

export * from './constants';

// https://stackoverflow.com/questions/43673440/resetting-data-layer-variables-in-single-page-applications-google-tag-manager
export const boomDataLayer = () => {
  if (window.dataLayer !== undefined && window.google_tag_manager !== undefined) {
    window.dataLayer.length = 0;
    const gtmContainerReg = /GTM-/i;

    for (const gtmKey of Object.keys(window.google_tag_manager)) {
      if (
        gtmContainerReg.test(gtmKey) &&
        window.google_tag_manager[gtmKey].dataLayer &&
        window.google_tag_manager[gtmKey].dataLayer.reset
      ) {
        window.google_tag_manager[gtmKey].dataLayer.reset();
      }
    }
  }
};

export const dataLayerInformAuth = (user, data, dispatch) => {
  // user is firebase auth user, data is firestore user' data
  var { email, displayName, metadata, uid } = user;
  var { shops, notificationsToken, chromeExtension } = data;

  var hasPinned = !shops
    ? 0
    : Object.values(shops as Record<string, SummarySectionsCustomization>).reduce(
        (t: number, shop) => (t += shop?.ui?.summary?.pinned?.tiles?.length ?? 0),
        0,
      );
  var hasMobile = !!notificationsToken;

  const gtmUser = {
    accountType: data?.accountType,
    createdAt: user?.metadata?.creationTime,
    email: user?.email,
    emailVerified: user?.emailVerified,
    isCreatedFromInvitation: data?.isCreatedFromInvitation,
    isCreatedFromSignUp: data?.isCreatedFromSignUp,
    isEmailSubscribed: data?.isEmailSubscribe,
    name: user?.displayName,
    phoneNumber: data?.phone,
    userStatedReferrer: data?.referrer,
    shops: data?.shops,
    userId: user?.uid,
    workspaces: data?.workspaces,
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'getUserData', gtmUser });

  if (isProduction && !isInIframe) {
    amplitude.getInstance().setUserId(uid);
    amplitude.getInstance().setUserProperties({ email });
    amplitude.getInstance().logEvent('AUTH');
    posthog.identify(user.uid, { email });
    window.TriplePixel('identify', user.uid, {
      email,
      ...(data?.phone && { phone: data?.phone }),
    });

    posthog.onFeatureFlags((flagsKeys) => {
      const flags = flagsKeys.reduce((obj, key) => {
        const ffPayloads = posthog.getFeatureFlagPayload(key);

        if (!!ffPayloads && typeof ffPayloads === 'object') {
          return { ...obj, [key]: ffPayloads };
        } else {
          return { ...obj, [key]: posthog.isFeatureEnabled(key) };
        }
      }, {});

      dispatch({
        type: EXTERNAL_PROVIDER_FAUTURE_FLAGS_LOADED,
        payload: flags,
      });
    });
  }

  if (window.Intercom) {
    window.Intercom('update', {
      app_id: 'ogniecxz',
      name: displayName,
      email: email,
      created_at: new Date(metadata.creationTime).getTime(), // Signup date as a Unix timestamp
      Pinned: hasPinned,
      'Mobile app': hasMobile,
      'Chrome Extension': chromeExtension,
      hide_default_launcher: isInIframe || $activeAppVersion.get() === '3.0' ? true : false,
    });
  } else {
    // console.log('AUTH IS DONE, INTERCOM IS NOT');
  }

  // @ts-ignore
  if (window.analytics) {
    // @ts-ignore
    window.analytics.identify(uid, {
      name: displayName,
      email: email,
    });
  } else {
    // console.log('AUTH IS DONE, SEGMENT IS NOT');
  }
  datadogRum.setUser({
    id: uid,
    name: displayName,
    email: email,
  });
};

export const dataLayerLogout = () => {
  if (isProduction && !isInIframe) {
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().clearUserProperties();
    posthog.reset();
    window.TriplePixel('reset');
  }
};

export const dataLayerSetCurrentShop = (shopData?) => {
  if (!runEvent) {
    return;
  }

  try {
    if ($currentShopId.get()) {
      posthog.group('shop', $currentShopId.get()!, {
        shop_name: $currentShopId.get(),
      });
    } else {
      posthog.resetGroups();
    }
  } catch {}

  if (shopData) {
    const connectedServices = {
      'facebook-ads': !!shopData.facebookAccessToken,
      'google-ads': !!shopData.googleAdsAccessToken,
      'tiktok-ads': !!shopData.tiktokAccessToken,
      'pinterest-ads': !!shopData.pinterestToken,
      'snapchat-ads': !!shopData.snapchatAccessToken,
      klaviyo: !!shopData.klaviyoKey,
      fairing: !!shopData.enquirelabsToken,
      kno: !!shopData.knoClientSecret,
      slack: !!shopData.slackAccessToken,
      gorgias: !!shopData.gorgiasUsername || !!shopData.gorgiasAccessToken,
      'google-sheets': !!shopData.googleSheetsAccessToken,
    };

    try {
      window.dataLayer.push({ event: 'getCurrentShop', connectedServices });
    } catch {}
  }
};

export const enrichPayload = (payload) => {
  const userId = $userId.get();
  const userEmail = $userEmail.get();
  const activeAppVersion = $activeAppVersion.get();
  const is3_0 = activeAppVersion === '3.0';
  const shopId = $currentShopId.get();
  const msp = $msp.get();
  const subscriptionActive = $subscriptionActive.get();
  const pixelActive = $pixelActive.get();
  const posthog_feature_flags = store.getState().externalProviderFeatures;

  return {
    ...payload,
    shop: shopId,
    uid: userId,
    email: userEmail,
    version: activeAppVersion,
    msp,
    v3_0_enabled: is3_0,
    pixel_enabled: pixelActive,
    subscription_active: subscriptionActive,
    posthog_feature_flags,
  };
};

export const dataLayerPageView = (shop, pathname, user) => {
  const payload = enrichPayload({
    pathname,
    shop,
    uid: user.uid || '',
    email: user.email || '',
  });

  if (window.genericEventLoggerDebug === true) {
    console.log('dataLayerPageView', payload);
  }

  if (!runEvent) {
    return;
  }

  // skip paths that we do not want to track page views on.
  // const skip_paths = [
  // '/',
  // '/attribution/all',
  // '/attribution/ads',
  // '/attribution/email',
  // '/creative-cockpit',
  // '/creative-cockpit/undefined/ad',
  // ];

  // if (!skip_paths.includes(pathname)) {
  amplitude.getInstance().logEvent('Page View', { pathname });
  posthog.capture('$pageview', payload);
  window.TriplePixel('pageLoad');
  window.TriplePixel('custom', 'pageView', payload);
  // }
};

export const genericEventLogger = (eventName: string, payload?: object) => {
  if (payload) payload = enrichPayload(payload);

  if (window.genericEventLoggerDebug === true) {
    console.log('genericEventLogger', eventName, payload);
  }

  if (!runEvent) {
    return;
  }

  try {
    window.dataLayer.push({ event: eventName, payload });
    posthog.capture(eventName, payload);
    window.TriplePixel('custom', eventName, { ...payload, shop: $currentShopId.get() });
  } catch {}
};

export const posthogEventLogger = (eventName: string, payload: object) => {
  if (payload) payload = enrichPayload(payload);

  if (window.genericEventLoggerDebug === true) {
    console.log('posthogEventLogger', eventName, payload);
  }

  if (!runEvent) {
    return;
  }

  try {
    posthog.capture(eventName, payload);
  } catch {}
};

export const errorBoundaryLogger = (eventName: string, payload?: object) => {
  if (payload) payload = enrichPayload(payload);

  if (window.genericEventLoggerDebug === true) {
    console.log('errorBoundaryLogger', eventName, payload);
  }

  if (!runEvent) {
    return;
  }

  try {
    datadogLogs.logger.error(eventName, payload);
    posthog.capture(eventName, payload);
    window.TriplePixel('custom', eventName, { ...payload, shop: $currentShopId.get() });
  } catch {}
};

export const errorSlownessLogger = (eventName: string, payload?: object) => {
  if (payload) payload = enrichPayload(payload);

  if (!runEvent) {
    return;
  }

  try {
    datadogLogs.logger.error(eventName, payload);
    posthog.capture(eventName, payload);
    window.TriplePixel('custom', eventName, { ...payload, shop: $currentShopId.get() });
  } catch {}
};

export const lazyLogger = (eventName: string, payload?: object) => {
  if (payload) payload = enrichPayload(payload);

  if (window.genericEventLoggerDebug === true) {
    console.log('lazyLogger', eventName, payload);
  }

  if (!runEvent) {
    return;
  }

  try {
    datadogLogs.logger.error(eventName, payload);
    posthog.capture(eventName, payload);
    window.TriplePixel('custom', eventName, { ...payload, shop: $currentShopId.get() });
  } catch {}
};

export const datadogLogEvent = (
  eventName: string,
  payload?: object,
  options?: { level?: 'info' | 'warn' | 'error' },
) => {
  if (window.genericEventLoggerDebug === true) {
    console.log('datadogLogEvent', eventName, payload);
  }

  if (!runEvent) {
    return;
  }

  const level = options?.level || 'error';

  try {
    datadogLogs.logger[level](eventName, payload);
  } catch {}
};
