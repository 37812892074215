import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActionIcon, Button, Icon, Select, Text } from '@tw/ui-components';
import {
  CDPActionFilter,
  CDPActionFilterActions,
  CDPSegmentFilter,
  CDPActionProperty,
} from '@tw/types/module/services/cdp';
import {
  FILTER_PROPERTY_METADATA,
  FilterPropertyType,
  FilterComparator as FilterComparatorType,
  FilterProperty,
} from '@tw/types/module/services/insights';

import { type RootState } from 'reducers/RootType';
import FilterComparator from '../../Insights/Filters/FilterComparator';
import {
  getActionPropsByAction,
  USER_ACTION_OPTIONS,
  getDefaultPropertyForAction,
  filterUnSupportedProperties,
  filterUnSupportedActions,
} from './utils';
import { getOptionLabel } from './queryTranslation';
import { DEFAULT_COMPARATORS, getPropertyType } from 'components/Insights/Filters/constants';
import { getFilterQueryLabel } from 'components/Insights/Filters/queryTranslation';

type Props = {
  filter: CDPActionFilter;
  onChanged: (filter: CDPSegmentFilter) => void;
  isReadonly?: boolean;
};
const UserActionFilter: React.FC<Props> = (props) => {
  const { features } = useSelector((state: RootState) => state.currentShop);
  const [doesHaveAction, setDoesHaveAction] = useState<boolean>(() => {
    return (
      props.filter.definition?.action?.comparator !== FilterComparatorType.EQUAL ||
      props.filter.definition?.action?.value > 0
    );
  });

  const handleDoesHaveActionChanged = useCallback((doesHaveAction: boolean) => {
    handleActionComparatorChanged({
      comparator: FilterComparatorType.EQUAL,
      value: doesHaveAction ? null : 0,
    } as any);

    setDoesHaveAction(doesHaveAction);
  }, []);

  const handleActionChanged = useCallback((action: CDPActionFilterActions) => {
    setFilter((prevValue) => {
      if (prevValue.definition.action?.type === action) {
        return prevValue;
      }

      return {
        ...prevValue,
        definition: {
          ...prevValue.definition,
          action: {
            ...prevValue.definition.action,
            type: action,
          },
          property: getDefaultPropertyForAction(action),
        },
      } as CDPActionFilter;
    });
  }, []);

  const handleActionComparatorChanged = useCallback(
    ({ comparator, value, value1, value2, unit }) => {
      setFilter((prevValue) => {
        const newActionDefinition = {
          ...prevValue.definition.action,
          comparator,
          value,
          value1,
          value2,
          unit,
        };
        return {
          ...prevValue,
          definition: { ...prevValue.definition, action: newActionDefinition },
        };
      });
    },
    [],
  );

  const handleTimeComparatorChanged = useCallback(({ comparator, value, value1, value2, unit }) => {
    setFilter((prevValue) => {
      return {
        ...prevValue,
        definition: { ...prevValue.definition, time: { comparator, value, value1, value2, unit } },
      };
    });
  }, []);

  const handleActionProperyChanged = useCallback((property?: CDPActionProperty) => {
    setFilter((prevValue) => {
      return {
        ...prevValue,
        definition: {
          ...prevValue.definition,
          property: {
            property,
            comparator: property
              ? DEFAULT_COMPARATORS[FILTER_PROPERTY_METADATA[property].type]
              : null,
          },
        },
      } as CDPActionFilter;
    });
  }, []);

  const handleActionProperyDeleted = useCallback(() => {
    setFilter((prevValue) => {
      return {
        ...prevValue,
        definition: { action: prevValue.definition.action, time: prevValue.definition.time },
      } as CDPActionFilter;
    });
  }, []);

  const handleActopnProperyComparatorChanged = useCallback(
    ({ comparator, value, value1, value2, unit }) => {
      setFilter((prevValue) => {
        const newPropertyDefinition = {
          ...prevValue.definition.property,
          comparator,
          value,
          value1,
          value2,
          unit,
        };
        return {
          ...prevValue,
          definition: { ...prevValue.definition, property: newPropertyDefinition },
        } as CDPActionFilter;
      });
    },
    [],
  );

  const [filter, setFilter] = useState<CDPActionFilter>(props.filter);
  const [actionProperties, setActionProperties] = useState<CDPActionProperty[]>([]);
  const [propertyComparatorType, setPropertyComparatorType] = useState<FilterPropertyType>();

  const supportedActions = useMemo(() => {
    return filterUnSupportedActions(
      USER_ACTION_OPTIONS,
      features ?? [],
      filter.definition.action?.type,
    );
  }, [features, filter.definition.action?.type]);

  const suportedProperties = useMemo(() => {
    return filterUnSupportedProperties(actionProperties, filter.definition.property?.property);
  }, [filter.definition.property?.property, actionProperties]);

  const useV1AutoComplete = useMemo(() => {
    return (
      filter.definition?.property?.property == FilterProperty.ATTRIBUTION_SOURCE &&
      filter.definition.action.type == CDPActionFilterActions.CLICKED_AD
    );
  }, [filter.definition.action.type, filter.definition?.property?.property]);

  useEffect(() => {
    props.onChanged(filter);
  }, [filter]);

  useEffect(() => {
    setActionProperties(getActionPropsByAction(filter.definition.action.type));
  }, [filter.definition.action.type]);

  useEffect(() => {
    if (filter.definition.property?.property) {
      setPropertyComparatorType(getPropertyType(filter.definition.property.property));
    } else {
      setPropertyComparatorType(undefined);
    }
  }, [filter.definition.property?.property]);

  return (
    <React.Fragment>
      <Select
        w="130px"
        id="att-cdp-builder-action-have-not-select"
        data={[
          { label: 'Have', value: 'true' },
          { label: 'Have not', value: 'false' },
        ]}
        value={doesHaveAction.toString()}
        onChange={(val) => val && handleDoesHaveActionChanged(JSON.parse(val))}
        rightSection={props.isReadonly ? <></> : undefined}
      />
      <Select
        w="170px"
        id="att-cdp-builder-action-type-select"
        data={supportedActions.map((o) => ({ label: getOptionLabel(o), value: o }))}
        value={filter.definition.action.type}
        onChange={(val) => handleActionChanged(val as CDPActionFilterActions)}
        rightSection={props.isReadonly ? <></> : undefined}
      />

      {/* Action Comparator */}
      {filter.definition.action.type && doesHaveAction && (
        <FilterComparator
          comparatorType={FilterPropertyType.NUMBER}
          comparator={filter.definition.action.comparator}
          value={filter.definition.action.value}
          onChanged={handleActionComparatorChanged}
          isReadonly={props.isReadonly}
          required
        ></FilterComparator>
      )}

      <div className="basis-full"></div>

      {/* Time Comparator */}
      {filter.definition.action.type && (
        <FilterComparator
          id="att-cdp-builder-action-time-select"
          comparatorType={FilterPropertyType.TIME}
          comparator={filter.definition.time.comparator}
          value={filter.definition.time['value']}
          value1={filter.definition.time['value1']}
          value2={filter.definition.time['value2']}
          unit={filter.definition.time['unit']}
          onChanged={handleTimeComparatorChanged}
          isReadonly={props.isReadonly}
          required
        ></FilterComparator>
      )}

      {/* Action Property Comparator */}
      {filter.definition.action.type && (!props.isReadonly || filter.definition.property) && (
        <>
          <div className="basis-full"></div>

          <Button
            variant="activator"
            id="att-cdp-builder-action-where-button"
            onClick={() => handleActionProperyChanged()}
            leftSection={props.isReadonly ? undefined : <Icon name="plus" size={10} />}
          >
            Where
          </Button>
          {filter.definition.property && (
            <React.Fragment>
              <Select
                required
                w="200px"
                data={suportedProperties.map((o) => ({
                  label: getFilterQueryLabel(o),
                  value: o,
                }))}
                value={filter.definition.property?.property}
                onChange={(val) => val && handleActionProperyChanged(val as CDPActionProperty)}
                rightSection={props.isReadonly ? <></> : undefined}
              />
              {propertyComparatorType && (
                <FilterComparator
                  comparatorType={propertyComparatorType}
                  comparator={filter.definition.property.comparator}
                  value={filter.definition.property['value']}
                  value1={filter.definition.property['value1']}
                  value2={filter.definition.property['value2']}
                  unit={filter.definition.property['unit']}
                  property={filter.definition.property.property}
                  onChanged={handleActopnProperyComparatorChanged}
                  isReadonly={props.isReadonly}
                  required
                ></FilterComparator>
              )}
              {!props.isReadonly && (
                <ActionIcon
                  id="att-cdp-builder-action-where-cancel-button"
                  onClick={handleActionProperyDeleted}
                  icon="close"
                  iconSize={12}
                />
              )}
            </React.Fragment>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default UserActionFilter;
