import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { Icon } from '@tw/ui-components';
import { NavSection } from '../types';
import { IconComponent } from '../components';
import { $v3_0_Only } from '$stores/$v3_0_Enabled';
import { WillyDashSidePanel } from 'components/Willy/dashboardManagment/WillyDashSidePanel';
import { WillySidePanel } from 'components/Willy/WillySidePanel';
import { analyticsEvents } from 'utils/dataLayer';

export const WILLY_SECTION: NavSection = {
  title: 'Chat',
  icon: ({ selected }) => (
    <IconComponent
      selected={selected}
      icon={<Icon name="triple-whale-logo" color={selected ? 'one.5' : 'gray.5'} size={24} />}
    />
  ),
  routes: [
    {
      url: '/chat',
      dependsOnFFSystem: FeatureFlag.TW_3_0_FF,
      label: 'Moby',
      labs: true,
      isWillyPage: true,
      isMobyPage: true,
      hideSideMenu: true,
      exact: true,
      rawIconName: 'ai-chat-spark',
      header: () => <></>,
      icon: ({ selected, mobile }) => (
        <IconComponent
          mobile={mobile}
          selected={selected}
          icon={
            <Icon
              name="ai-chat-spark"
              color={selected ? 'one.5' : 'gray.5'}
              size={mobile ? 18 : 22}
            />
          }
        />
      ),
      subMenu: (
        <WillySidePanel
          clearConversation={() => {}}
          conversationId=""
          currentAnalyticsEvent={analyticsEvents.CHAT}
          inTooltip={true}
        />
      ),
    },
    {
      url: '/dashboards/:dashboardId',
      urlToCheck: '/dashboards',
      label: 'Dashboards',
      labs: true,
      dependsOnFFSystem: FeatureFlag.TW_3_0_FF,
      isWillyPage: true,
      rawIconName: 'tiles-view',
      header: () => <></>,
      icon: ({ selected, mobile }) => (
        <IconComponent
          mobile={mobile}
          selected={selected}
          icon={
            <Icon name="tiles-view" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 18 : 20} />
          }
        />
      ),
      subMenu: <WillyDashSidePanel />,
    },
    {
      url: '/templates',
      label: 'Templates',
      dependsOnFFSystem: FeatureFlag.TW_3_0_FF,
      rawIconName: 'global',
      isWillyPage: true,
      header: () => <></>,
      icon: ({ selected, mobile }) => (
        <IconComponent
          mobile={mobile}
          selected={selected}
          icon={
            <Icon name="global" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 18 : 20} />
          }
        />
      ),
    },
    // {
    //   url: '/workflows',
    //   label: 'Agents',
    //   dependsOnFFSystem: FeatureFlag.TW_3_0_FF,
    //   rawIconName: 'button-play',
    //   isWillyPage: true,
    //   isMobyPage: true,
    //   header: () => <></>,
    //   icon: ({ selected, mobile }) => (
    //     <IconComponent
    //       mobile={mobile}
    //       selected={selected}
    //       icon={
    //         <Icon
    //           name="button-play"
    //           color={selected ? 'one.5' : 'gray.5'}
    //           size={mobile ? 18 : 20}
    //         />
    //       }
    //     />
    //   ),
    // },
    {
      url: '/share',
      label: 'Share',
      dependsOnFFSystem: FeatureFlag.TW_3_0_FF,
      isWillyPage: true,
      hidden: true,
      header: () => <></>,
    },
  ],
};

export const WILLY_BUILD_SECTION: NavSection = {
  title: 'Data',
  icon: ({ selected, mobile }) => (
    <IconComponent
      mobile={mobile}
      selected={selected}
      icon={<Icon name="db" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 23 : 24} />}
    />
  ),
  routes: [
    {
      url: '/sql-editor',
      label: 'SQL',
      dependsOnFFSystem: FeatureFlag.TW_3_0_FF,
      header: () => <></>,
      isWillyPage: true,
      rawIconName: 'code-bracket',
      icon: ({ selected, mobile }) => (
        <IconComponent
          mobile={mobile}
          selected={selected}
          icon={
            <Icon
              name="code-bracket"
              color={selected ? 'one.5' : 'gray.5'}
              size={mobile ? 19 : 20}
            />
          }
        />
      ),
    },
    // {
    //   url: '/builder',
    //   label: 'Metrics Library',
    //   dependsOnFFSystem: FeatureFlag.TW_3_0_FF,
    //   header: () => <></>,
    //   isWillyPage: true,
    //   rawIconName: 'library',
    //   icon: ({ selected, mobile }) => (
    //     <IconComponent
    //       mobile={mobile}
    //       selected={selected}
    //       icon={
    //         <Icon name="library" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 23 : 27} />
    //       }
    //     />
    //   ),
    // },
  ],
};

export const GET_STARTED_SECTION: NavSection = {
  get title() {
    const isOnlyV3 = $v3_0_Only.get();
    return isOnlyV3 ? 'Get Started' : 'Discover';
  },
  routes: [
    {
      get label() {
        const isOnlyV3 = $v3_0_Only.get();
        return isOnlyV3 ? 'Get Started' : 'Discover';
      },
      shortLabel: 'Discover',
      url: '/get-started',
      isWillyPage: true,
      header: () => <></>,
      rawIconName: 'rocket',
      dependsOnFFSystem: FeatureFlag.TW_3_0_FF,
      icon: ({ selected, mobile }) => (
        <IconComponent
          mobile={mobile}
          selected={selected}
          icon={
            <Icon name="rocket" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 18 : 22} />
          }
        />
      ),
    },
  ],
};

// export const WILLY_BUILD_SECTION_MOBILE: NavSection = {
//   title: 'Data',
//   spread: false,
//   icon: ({ selected, mobile }) => (
//     <IconComponent
//       mobile={mobile}
//       selected={selected}
//       icon={<Icon name="db" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 23 : 24} />}
//     />
//   ),
//   routes: [
//     {
//       url: '/sql-editor',
//       label: 'Data',
//       dependsOnFFSystem: FeatureFlag.TW_3_0_FF,
//       header: () => <></>,
//       isWillyPage: true,
//       rawIconName: 'code-bracket',
//       icon: ({ selected, mobile }) => (
//         <IconComponent
//           mobile={mobile}
//           selected={selected}
//           icon={<Icon name="db" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 19 : 20} />}
//         />
//       ),
//     },
//   ],
// };

// export const WILLY_PIXEL_SECTION: NavSection = {
//   ...PIXEL_SECTION,
//   spread: false,
//   icon: ({ selected, mobile }) => (
//     <IconComponent
//       selected={selected}
//       mobile={mobile}
//       icon={<Icon name="pixel-cc" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 20 : 23} />}
//     />
//   ),
//   routes: PIXEL_SECTION.routes.map((x) => ({ ...x, isHybridPage: true })),
// };

// export const WILLY_PIXEL_SECTION_MOBILE: NavSection = {
//   ...WILLY_PIXEL_SECTION,
//   routes: [
//     {
//       url: '/attribution/all/all',
//       label: 'Pixel',
//       dependsOnFFSystem: FeatureFlag.TW_3_0_FF,
//       header: () => <></>,
//       isWillyPage: true,
//       rawIconName: 'pixel',
//       icon: ({ selected, mobile }) => (
//         <IconComponent
//           selected={selected}
//           mobile={mobile}
//           icon={
//             <Icon name="pixel-cc" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 20 : 23} />
//           }
//         />
//       ),
//     },
//   ],
// };
