import { Image, Text } from '@tw/ui-components';
import { WillySimpleText } from '../WillySimpleText';
import { InsightsWillyWidget } from '../InsightsWillyWidget';
import { Dialect, WorkflowInsightsResponse, WorkflowStepBaseResponse } from '../types/willyTypes';
import { Headline } from '../Headline';
import { UserAndDate } from '../UserAndDate';
import moment from 'moment';

export function replaceQueryIdsWithWidgets(
  response: WorkflowInsightsResponse & WorkflowStepBaseResponse,
  dialect: Dialect,
) {
  const { richText, timestamp, user } = response;
  if (!richText) {
    return null;
  }
  // Match all occurrences of [shortcode]<JSON_HERE>[/shortcode] where JSON_HERE is any JSON string
  // I'll take the JSON_HERE part and parse it as JSON
  // then I'll deal with the json inside the loop
  const regex = /\[shortcode\](.*?)\[\/shortcode\]/gs;
  // Split the insights string into parts
  const parts: React.ReactNode[] = [];

  let lastIndex = 0;

  let match: RegExpExecArray | null;
  let indexOfHeadline = 0;

  while ((match = regex.exec(richText)) !== null) {
    let text = richText.slice(lastIndex, match.index);
    // Add text before the current match
    if (text.replaceAll('\n', '') !== '' && match.index > lastIndex) {
      parts.push(
        <div key={`text-${lastIndex}-${match.index}`} className="w-full my-4">
          <WillySimpleText text={text} />
        </div>,
      );
    }

    // Add the Widget component with the extracted question ID
    try {
      const json = JSON.parse(match[1]);
      if (json.type === 'headline') {
        if (indexOfHeadline === 0) {
          parts.push(
            <div
              key={json.headline}
              className="relative z-[1] w-full mx-auto flex flex-col gap-4 my-4"
            >
              <Text as="h1" size="32px" lh="38px" weight="bold" align="left" fs="normal" my="lg">
                {json.headline}
              </Text>
              <div className="my-6.5">
                <UserAndDate user={user || ''} date={moment(timestamp).format('llll')} />
              </div>
            </div>,
          );
          indexOfHeadline++;
          // } else if (indexOfHeadline === 1) {
          // parts.push(
          //   <div className="flex flex-col my-4">
          //     <Text as="h3" size="md" color="gray.7" lh="20px" weight="normal" align="left">
          //       {json.headline}
          //     </Text>
          //     <div className="my-6.5">
          //       <UserAndDate user={user || ''} date={moment(timestamp).format('llll')} />
          //     </div>
          //   </div>,
          // );
          // indexOfHeadline++;
        } else {
          parts.push(
            <div
              key={json.headline}
              className="relative z-[1] h-98 overflow-auto w-full mx-auto my-4 block"
            >
              <Headline headline={json.headline} />
            </div>,
          );
        }
      } else if (json.type === 'question') {
        const queryId = json.id;
        const question = json.question;
        const dashboardId = json.dashboardId;
        parts.push(
          <div key={queryId} className="my-4">
            <InsightsWillyWidget
              queryId={queryId}
              dialect={dialect}
              question={question}
              dashboardId={dashboardId}
            />
          </div>,
        );
      } else if (json.type === 'image') {
        parts.push(
          <div key={json.id} className="block relative z-[1] w-full mx-auto mb-6.5">
            <Image src={json.url} radius="md" />
          </div>,
        );
      }
    } catch (error) {
      console.error('Error parsing JSON', error);
    }

    // Update the lastIndex
    lastIndex = regex.lastIndex;
  }

  // Add remaining text after the last match
  if (lastIndex < richText.length) {
    parts.push(
      <div key={`text-${lastIndex}-${richText.length}`} className="w-full my-4">
        <WillySimpleText text={richText.slice(lastIndex)} />
      </div>,
    );
  }

  return <div className="@container/insights flex flex-col gap-4">{parts}</div>;
}
