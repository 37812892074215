import React from 'react';
import { Banner } from '@shopify/polaris';
import { Text } from '@tw/ui-components';
import moment from '@tw/moment-cached';
import { showTotalImpactModal } from 'ducks/attribution/actions';
import { useAppDispatch } from 'index';
import { useSelector } from 'react-redux';
import type { RootState } from 'reducers/RootType';
import { selectHasSomePpsInstalled } from 'utils/selectors';
import { $activeAccounts } from '$stores/$shop';
import { useStoreValue } from '@tw/snipestate';

const ppsModelStartSinceDate = moment('2023-01-01');

interface PpsBannerProps {
  attributionModel: string;
  closedPpsBanner: boolean;
  onClose?: () => void;
}

export const PpsBanner: React.FC<PpsBannerProps> = ({
  attributionModel,
  closedPpsBanner,
  onClose,
}) => {
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const hasPpsInstalled = useSelector(selectHasSomePpsInstalled);
  const activeAccounts = useStoreValue($activeAccounts);
  const dispatch = useAppDispatch();
  const { missingDates, invalidSampleSizeDates } = useSelector(
    (state: RootState) => state.ppsStatus,
  );
  if (!mainDatePickerSelectionRange) {
    return null;
  }

  const { start, end } = mainDatePickerSelectionRange || {};

  if (attributionModel !== 'ppsViews' || closedPpsBanner || !start || !end) {
    return null;
  }

  const hasInvalidDates = invalidSampleSizeDates.some((date) =>
    moment(date).isBetween(start, end, 'day', '[]'),
  );
  const hasMissingDates = missingDates.some((date) =>
    moment(date).isBetween(start, end, 'day', '[]'),
  );
  const hasBeforePPSModelStarted =
    moment(start).isBefore(ppsModelStartSinceDate) || moment(end).isBefore(ppsModelStartSinceDate);

  const isCritical = !hasPpsInstalled || hasMissingDates || hasBeforePPSModelStarted;

  let message = '';
  if (activeAccounts?.length && activeAccounts?.length > 1) {
    message = 'Total Impact does not currently support multi-shop views.';
  } else if (!hasPpsInstalled) {
    message = 'Install or connect a Post-Purchase Survey to see your Total Impact model.';
  } else if (hasBeforePPSModelStarted) {
    message = 'The Total Impact model is available starting from Jan 1st, 2023';
  } else if (hasMissingDates) {
    message =
      'You do not have enough data in the date range selected to power the Total Impact Model. Please try a different date range.';
  } else if (hasInvalidDates) {
    message = `Your Total Impact model could be improved with more survey data. Increase the number of post-purchase survey responses you collect to unlock Total Impact's full potential.`;
  } else {
    return null;
  }

  return (
    <div className="pb-5">
      <Banner status={isCritical ? 'critical' : 'warning'} onDismiss={onClose}>
        <div className="w-full flex justify-between flex-wrap">
          <Text c="gray.7">
            {message}{' '}
            <a
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => dispatch(showTotalImpactModal(true))}
            >
              Learn more
            </a>
          </Text>
        </div>
      </Banner>
    </div>
  );
};
