import './Attribution.scss';
import '../../components/attribution/attribution-style.scss';

import AttributionChart from 'components/attribution-new/AttributionChart';
import ChannelOverlap from 'components/attribution-new/ChannelOverlaps';
import CreateAd from 'components/attribution-new/CreateAd';
import OrdersWidget from 'components/attribution-new/OrdersWidget';
import TWTable from 'components/library/TWTable/TWTable';
import {
  AttributionPageContext,
  LOADING_ROW_ID,
  MAX_SELECTED_ATTRIBUTIONS,
  UNMATCHED_ROWS_ID,
} from 'constants/attribution';
import { CHART_COLORS } from 'constants/general';
import allServices from 'constants/services';
import { lightboxClose, lightboxOpen } from 'ducks/actions';
import {
  attributionLastRefresh,
  chartOpenChanged,
  getAttributionComparison,
  getAttributionComparisonDates,
  initAttribution,
  initAttributionPage,
  showTotalImpactModal,
} from 'ducks/attribution/actions';
import { $socket } from '$stores/$socket';
import { useAppDispatch } from 'index';
//import InfluencersPage from '../InfluencersInsights/InfluencersPage';
import { findLastIndex, keyBy, orderBy } from 'lodash';
import moment from '@tw/moment-cached';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { type RootState } from 'reducers/RootType';
import {
  AttributionData,
  AttributionOverlapsResponse,
  AttributionRealtimeMessage,
  AttributionStatsRequest,
  AttributionTableMetadata,
  PixelColumn,
} from 'types/attribution';
import { AFFLUENCER, ALL_SOURCES_ID } from 'constants/types';
import {
  addRealtimeData,
  getAttributionData,
  getCampaignsExperimentsMetrics,
  getParams,
  getSourcesList,
} from 'utils/attributions';
import axiosInstance from 'utils/axiosInstance';
import { fillInBlanks } from 'utils/fill-in-blanks';
import { useAttributionParams } from 'utils/useAttributionParam';
import { Banner, Button, Collapsible, Layout, Page, Pagination, Tooltip } from '@shopify/polaris';
import { ConnectMinor } from '@shopify/polaris-icons';
import { Dialect, MetricsKeys, SortDirection } from '@tw/types';
import { TotalImpactModal } from 'components/attribution-new/TotaImpactModal';
import { InAppContextBanner } from 'components/InAppContextBanner';
import { facebookConnectOnPress } from '../../ducks/facebook';
import { selectHasSomePpsInstalled } from 'utils/selectors';
import AttributionSkusModal from 'components/attribution-new/AttributionSkusModal';
import {
  analyticsEvents,
  attributionActions,
  errorSlownessLogger,
  genericEventLogger,
} from 'utils/dataLayer';
import { useFeatureFlagComputer } from 'feature-flag-system';
import { CustomMetricsType, selectCustomMetricsForType } from 'ducks/customMetrics';
import { getCustomMetricsFromRowData } from 'utils/customMetrics';
import { getAllSensoryNewStats } from 'ducks/sensory';
import { SourceTypes } from 'types/services';
import { $allPixelColumnsIncludingCustom } from './selectPixelColumns';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { Button as UiButton, Checkbox, Modal, Select, Text } from '@tw/ui-components';
import { useEffectiveSelectedColumns } from './useEffectiveSelectedColumns';
import { shopIntegrations } from '../../ducks/shopIntegrations';
import { ServicesIds } from '@tw/types/module/services';
import { ShopIntegrationProperties } from '@tw/types/module/types/ShopProviders';
import { useComputedValue, useStoreValue, useWritableStore } from '@tw/snipestate';
import { $activeAccounts, $currency, $industry } from '../../$stores/$shop';
import { metrics } from 'constants/metrics/metrics';
import { WorkflowInAttributionPage } from './WorkflowInAttributionPage';
import { $prevDateIsNone } from '$stores/willy/$dateRange';
import { gradualReleaseFeatures } from 'ducks/shop';
import { runWorkflow } from 'components/Willy/utils/sequences';
import {
  $globalSequences,
  $isAgentSupported,
  $shopAiColumns,
  $shopSequences,
  updateShopColumn,
} from '$stores/willy/$sequences';
import {
  Column,
  ColumnName,
  RunSequenceRequest,
  SequenceProgressEvent,
} from 'components/Willy/types/willyTypes';
import { v4 as uuidV4 } from 'uuid';
import { useColumnsSocket } from 'components/Willy/sequenceBuilder/useColumnsSocket';
import { AiColumnModal } from 'components/Willy/WorkflowAiColumns';
import {
  $pixelPerformanceEnabled,
  $pixelPerformanceStartMs,
  $summaryPerformanceEnabled,
} from '../../$stores/$perfomence';
import { AiCellModal } from './AiCellModal';
import { datadogRum } from '@datadog/browser-rum';
import { PpsBanner } from 'components/attribution-new/PpsBanner';

const SurveyServiceIds = Object.values(allServices)
  .filter((s) => s.type === 'survey')
  .map((s) => s.id);

const ppsModelStartSinceDate = moment('2023-01-01');
const emptyArr = [];

const PER_PAGE = 30;
const STORAGE_KEY = 'attribution_per_page';
const campaignNameMap = new Map();
const adsetNameMap = new Map();
const adNameMap = new Map();

function filterStats(campaigns) {
  campaigns.forEach((campaign) => {
    if (campaign.campaignId && !String(campaign.campaignName)?.match(/^\d+$/)) {
      campaignNameMap.set(campaign.campaignId, campaign.campaignName);
    }
    if (campaign.adsetId && !String(campaign.adsetName)?.match(/^\d+$/)) {
      adsetNameMap.set(campaign.adsetId, campaign.adsetName);
    }
    if (campaign.adId && campaign.adName && !String(campaign.adName)?.match(/^\d+$/)) {
      adNameMap.set(campaign.adId, campaign.adName);
    }
  });

  // Filter out campaigns that have numeric names and a meaningful name exists in the map
  return campaigns.filter((campaign) => {
    if (campaign.entity === 'campaign' && String(campaign.campaignName)?.match(/^\d+$/)) {
      return !campaignNameMap.has(campaign.campaignId);
    } else if (campaign.entity === 'adset' && String(campaign.adsetName)?.match(/^\d+$/)) {
      return !adsetNameMap.has(campaign.adsetId);
    } else if (campaign.entity === 'ad' && String(campaign.name)?.match(/^\d+$/)) {
      return !adNameMap.has(campaign.id);
    }
    return true; // Keep the campaign if none of the above conditions match
  });
}

function getItemsByPage(list: AttributionData[], page: number, itemsPerPage: number) {
  const startIndex = page * itemsPerPage;
  const endIndex = (page + 1) * itemsPerPage;

  const result = list.reduce(
    (acc, item) => {
      if (item.entity !== 'campaign' && item.entity !== 'channel') {
        const isParentAlreadyInList = acc.items.some(
          (i) => i.id === item.campaignId || i.id === item.adsetId,
        );
        const isLoading =
          item.adId === 'loading_row_not_real' || item.adsetId === 'loading_row_not_real';
        if (isParentAlreadyInList || isLoading) {
          acc.items.push(item);
        }
        return acc;
      }
      if (acc.campaignCount < endIndex) {
        if (acc.campaignCount >= startIndex || acc.items.length > 0) {
          acc.items.push(item);
        }
        if (item.entity === 'campaign' || item.entity === 'channel') {
          acc.campaignCount += 1;
        }
      }
      return acc;
    },
    { items: [], campaignCount: 0 } as { items: AttributionData[]; campaignCount: number },
  ).items;

  return result;
}

interface AttributionProps {
  sourceCategory: SourceTypes;
  setDashboardPixelData?: (data: AttributionData[]) => void;
}

const Attribution: React.FC<AttributionProps> = ({ sourceCategory, setDashboardPixelData }) => {
  const socket = useStoreValue($socket);
  const ffComputer = useFeatureFlagComputer();
  const dispatch = useAppDispatch();
  const currentPeriodRequestRef = useRef<string>('');
  const isRunningRef = useRef(false);
  const channelId = useAttributionActiveSource();
  const isAgentSupported = useStoreValue($isAgentSupported);
  // const params = useParams<{ channelId: keyof typeof allServices | typeof ALL_SOURCES_ID }>();
  const location = useLocation();
  const navigate = useNavigate();
  const currency = useStoreValue($currency);
  const canUpdateAd = useSelector((state: RootState) => state.canUpdateAd);
  const canCreateAd = useSelector((state: RootState) => state.canCreateAd);
  const isLightboxOpen = useSelector((state: RootState) => state.isLightboxOpen);
  const sensoryIntegrations = useSelector((state: RootState) => state.sensory.sensoryIntegrations);
  const integrations = useSelector(shopIntegrations);
  const loadingCustomSpends = useSelector((state: RootState) => state.loadingCustomSpends);
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const { insights_summary_pixel: insightsSummaryPixelFF } = useSelector(gradualReleaseFeatures);
  const industry = useStoreValue($industry);
  const activeAccounts = useStoreValue($activeAccounts);
  const canUpdateChannelAds = useSelector((state: RootState) => state.canUpdateChannelAds);

  const {
    includeOneDayView,
    dateModel,
    attributionModel,
    wrapTableLinesMode,
    showOnlyActiveCampaigns,
    chartOpen,
    freeSearch,
    totalImpactModal,
    attributionWindow,
    useNewModels,
    workflowToRun,
  } = useSelector((state: RootState) => state.attribution);
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const mainDatePickerSpecialPeriod = useSelector(
    (state: RootState) => state.mainDatePickerSpecialPeriod,
  );
  const datesToCompare = useSelector((state: RootState) => state.datesToCompare);
  const prevDateIsNone = useStoreValue($prevDateIsNone);
  const facebookAdsScopes = useSelector((state: RootState) => state.shop?.facebookAdsScopes);
  const istiktokAdsEditScopes = useSelector((state: RootState) => state?.istiktokAdsEditScopes);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const customMetrics = useSelector(selectCustomMetricsForType(CustomMetricsType.Attribution));
  const hasPpsInstalled = useSelector(selectHasSomePpsInstalled);
  const sequences = useStoreValue($shopSequences);
  const [shopColumns, setShopColumns] = useWritableStore($shopAiColumns);
  const allPixelColumns = useStoreValue($allPixelColumnsIncludingCustom);
  const {
    hasPpsData,
    missingDates,
    invalidSampleSizeDates,
    status: ppsStatus,
  } = useSelector((state: RootState) => state.ppsStatus);

  const pageSourceKey: `TW_${SourceTypes}_page_attribution_source` = useMemo(() => {
    return `TW_${sourceCategory}_page_attribution_source` as const;
  }, [sourceCategory]);

  const { columns } = useColumnsSocket({ shopId: currentShopId });
  const [data, setData] = useState<AttributionData[]>([]);
  const [dataCustomMetricsTag, setDataCustomMetricsTag] = useState<{
    [key: string]: AttributionData[];
  }>({ '': [] });
  const [selectedAttributions, setSelectedAttributions] = useState<AttributionData[]>([]);
  const [loading, setLoading] = useState(false);
  const [createAdModalOpen, setCreateAdModalOpen] = useState(false);
  const [showAdSkusModal, setShowAdSkusModal] = useState<{ show: boolean; skuData: any[] }>({
    show: false,
    skuData: [],
  });
  const [sortDirection, setSortDirection] = useState<SortDirection>('descending');
  const [sortBy, setSortBy] = useState<string>('pixelConversionValue');
  const [activePage, setActivePage] = useState(0);
  const [perPage, setPerPage] = useState(() => {
    const storedValue = localStorage.getItem(STORAGE_KEY);
    return storedValue ? Number(storedValue) : PER_PAGE;
  });
  const [attributionInModal, setAttributionInModal] = useState<AttributionData | undefined>();
  const [workflowInModal, setWorkflowInModal] = useState<AttributionData | undefined>();
  const [selectedColumn, setSelectedColumn] = useState<ColumnName | null>(null);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [hasMoreOrders, setHasMoreOrders] = useState(true);
  const [loadingColumns, setLoadingColumns] = useState<Record<string, boolean>>({});
  const [updatedAiRows, setUpdatedAiRows] = useState<Record<string, Record<ColumnName, boolean>>>(
    {} as Record<ColumnName, Record<string, boolean>>,
  );
  const [activeWorkflowIds, setActiveWorkflowIds] = useState<Record<ColumnName, string>>(
    {} as Record<ColumnName, string>,
  );
  const [cancelledWorkflows, setCancelledWorkflows] = useState<Record<ColumnName, boolean>>(
    {} as Record<ColumnName, boolean>,
  );
  const [liveOrders, setLiveOrders] = useState({});
  const [moreOrders, setMoreOrders] = useState<any[]>([]);
  const [ordersLastSnapshot, setOrdersLastSnapshot] = useState<string | null>(null);
  const [loadingAttributionComparisons, setLoadingAttributionComparisons] = useState(false);
  const [loadingEntityProducts, setLoadingEntityProducts] = useState<boolean>(false);
  const [loadingOverlap, setLoadingOverlap] = useState(false);
  const [dateToCompare, setDateToCompare] = useState<{ start: string; end: string }>({
    start: mainDatePickerSelectionRange?.start?.format() || moment().format(),
    end: mainDatePickerSelectionRange?.end?.format() || moment().format(),
  });
  const [showUnmatchedRows, setShowUnmatchedRows] = useState(false);
  const [utmStatus, setUtmStatus] = useState(false);
  const [integrationsPopUpOpened, setIntegrationsPopUpOpened] = useState(false);
  const [experimentsData, setExperimentsData] = useState({});
  const [aiCellInModal, setAiCellInModal] = useState<{
    id: string;
    entity: string;
    column: string;
    value: {
      data: string;
      reason: string;
      createdAt: string;
    };
  } | null>(null);

  const attributionParams = useAttributionParams();
  const effectiveSelectedColumns = useEffectiveSelectedColumns();

  const needToShowFacebookGrantAccess =
    !facebookAdsScopes?.includes('ads_management') && canUpdateAd;

  const toggleCreateAdModalOpen = useCallback(() => {
    setCreateAdModalOpen((open) => !open);
  }, []);

  const [closedPpsBanner, setClosedPpsBanner] = useState(false);
  const [closeTIBanner, setCloseTIBanner] = useState(false);

  const { customMetricsWithTag, customMetricsWithoutTag } = useMemo(() => {
    return customMetrics.reduce(
      (acc, metric) => {
        // check if tag in metric and not empty string
        if ('tag' in metric && metric.tag) {
          acc.customMetricsWithTag.push(metric);
        } else {
          acc.customMetricsWithoutTag.push(metric);
        }
        return acc;
      },
      { customMetricsWithTag: [], customMetricsWithoutTag: [] },
    );
  }, [customMetrics]);

  const handlePagination = useCallback((newPage: number) => {
    // close all of the expanded rows
    // setData((oldData) => {
    //   return oldData.map((row) => ({
    //     ...row,
    //     expanded: false,
    //     adsets: row.adsets?.map((adset) => ({
    //       ...adset,
    //       expanded: false,
    //       ads: adset.ads?.map((ad) => ({
    //         ...ad,
    //         expanded: false,
    //       })),
    //     })),
    //   }));
    // });
    setActivePage(newPage);
  }, []);

  const hasUpdateScope: boolean = useMemo(() => {
    switch (channelId) {
      case 'facebook-ads':
        return !!facebookAdsScopes?.includes('ads_management');
      case 'tiktok-ads':
        // A workaraound to allow tiktok sensory integrations to edit scopes.
        const integrationId = sensoryIntegrations.find(
          (service) => service.provider_id === 'tiktok-ads',
        )?.id;
        return !!istiktokAdsEditScopes || integrationId?.length === 36;
      default:
        return true;
    }
  }, [channelId, facebookAdsScopes, istiktokAdsEditScopes, sensoryIntegrations]);

  const updateAdsAllowed = useMemo(() => {
    switch (channelId) {
      case 'facebook-ads':
        return canUpdateAd;
      default:
        return channelId ? !!canUpdateChannelAds[channelId] : false;
    }
  }, [canUpdateAd, canUpdateChannelAds, channelId]);

  useEffect(() => {
    if (typeof setDashboardPixelData === 'function') {
      const filteredData = data?.map((d) => {
        // filter object down to only include the effectiveSelectedColumns.keys
        const filteredKeys = Object.keys(d).filter((key) =>
          effectiveSelectedColumns.find((c) => c.key === key),
        );

        return {
          ...filteredKeys.reduce((acc, key) => {
            acc[key] = d[key];
            return acc;
          }, {}),
          active: d.active,
          serviceId: d.serviceId,
          id: d.id,
        };
      }) as AttributionData[];

      setDashboardPixelData(filteredData);
    }
  }, [data, setDashboardPixelData, effectiveSelectedColumns]);

  const toggleAttributionSelection = useCallback(async (item: AttributionData) => {
    setSelectedAttributions((selected) => {
      const index = selected.findIndex((x) => x.id === item.id);
      if (index > -1) {
        const copy = structuredClone(selected);
        copy.splice(index, 1);
        return copy;
      }
      if (selected.length >= MAX_SELECTED_ATTRIBUTIONS) {
        return selected;
      }
      return selected.concat(item).map((s, i) => ({
        ...s,
        color: CHART_COLORS[i % CHART_COLORS.length],
      }));
    });

    setData((oldCampaigns) => {
      const { entity } = item;
      return oldCampaigns.map((campaign) => {
        if (entity === 'channel') {
          return {
            ...campaign,
            selected: item.id === campaign.id ? !campaign.selected : campaign.selected,
          };
        }
        return {
          ...campaign,
          selected:
            item.campaignId === campaign.campaignId && entity === 'campaign'
              ? !campaign.selected
              : campaign.selected,
          adsets: campaign.adsets?.map((adset) => ({
            ...adset,
            selected:
              item.adsetId === adset.adsetId && entity === 'adset'
                ? !adset.selected
                : adset.selected,
            ads: adset.ads?.map((ad) => ({
              ...ad,
              selected: item.adId === ad.adId && entity === 'ad' ? !ad.selected : ad.selected,
            })),
          })),
        };
      });
    });
  }, []);

  const sortCb = useCallback(
    (element: AttributionData, sortBy: string, sortDirection: SortDirection) => {
      if (SurveyServiceIds.includes(element.id as any)) {
        if (sortBy === 'name' || sortBy === 'status') {
          // hack to get pp surveys always at the bottom. undefined is last and empty string is first
          return sortDirection === 'ascending' ? undefined : '';
        }
        return sortDirection === 'ascending' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      if (typeof element[sortBy] === 'undefined' || element[sortBy] === null) {
        if (sortBy === 'name') {
          return element.id;
        } else if (sortBy === 'status') {
          if (sortDirection === 'ascending') {
            return element.status === 'ACTIVE' ? '1' : '2';
          } else {
            return element.status === 'ACTIVE' ? '2' : '1';
          }
        }
        return 0;
      }
      return element[sortBy];
    },
    [],
  );

  const filterCb = useCallback((freeSearch: string, element: AttributionData) => {
    if (!freeSearch.trimStart().trimEnd()) {
      return true;
    }
    const lower = freeSearch.toLowerCase();
    return (
      String(element.id)?.toLowerCase()?.includes(lower) ||
      String(element.name)?.toLowerCase()?.includes(lower)
    );
  }, []);

  const sourcesList = useMemo(() => {
    if (sourceCategory === 'all') {
      return emptyArr;
    }
    return getSourcesList(sourceCategory || 'all');
  }, [sourceCategory]);

  const defaultSource: string = useMemo(() => {
    const categoryToDefaultMap: Record<
      Extract<SourceTypes, 'all' | 'ads' | 'email' | 'social'>,
      string
    > = {
      all: 'all',
      ads: 'facebook-ads',
      email: 'klaviyo',
      social: 'tw_referrer',
    };

    return categoryToDefaultMap[sourceCategory];
  }, [sourceCategory]);

  const dataWithCustomMetrics = useMemo(() => {
    return data.map<AttributionData>((row) => {
      return {
        ...row,
        ...getCustomMetricsFromRowData(customMetricsWithoutTag, row),
        ...(row.comparisons
          ? {
              comparisons: {
                ...row.comparisons,
                ...getCustomMetricsFromRowData(customMetricsWithoutTag, row.comparisons),
              },
            }
          : {}),
        adsets: row.adsets?.map((adset) => {
          return {
            ...adset,
            ...getCustomMetricsFromRowData(customMetricsWithoutTag, adset),
            ads: adset.ads?.map((ad) => {
              return {
                ...ad,
                ...getCustomMetricsFromRowData(customMetricsWithoutTag, ad),
              };
            }),
          };
        }),
      };
    });
  }, [data, customMetricsWithoutTag]);

  useEffect(() => {
    if (sensoryIntegrations.length > 0) {
      dispatch(
        getAllSensoryNewStats(
          integrations as { [key in ServicesIds]: ShopIntegrationProperties[] },
        ),
      );
    }
  }, [dispatch, sensoryIntegrations, integrations]);

  useEffect(() => {
    // on columns change, update the data in the relevant rows
    if (!Object.keys(columns).length) {
      return;
    }

    setData((oldData) => {
      return oldData.map((row) => {
        const idExists = Object.values(columns).find(
          (col) => col.id === row.id && col.entity === row.entity,
        );
        if (idExists) {
          return {
            ...row,
            ...columns[row.id!],
          };
        }
        return row;
      });
    });

    setUpdatedAiRows((prev) => ({
      ...prev,
      ...Object.values(columns).reduce((acc, attribution) => {
        if (!attribution.id) {
          return acc;
        }
        if (!acc[attribution.id]) {
          acc[attribution.id] = {};
        }
        acc[attribution.id] = {
          ...acc[attribution.id],
          [attribution.column]: true,
        };
        return acc;
      }, {}),
    }));
  }, [columns]);

  useEffect(() => {
    setUtmStatus(false);
    if (!['google-ads', 'facebook-ads'].includes(channelId)) return;

    (async () => {
      try {
        switch (channelId) {
          case 'google-ads':
            const { data: gaData } = await axiosInstance.get(
              '/v2/google-ads/ad-groups/' + currentShopId,
            );
            setUtmStatus(
              gaData?.ads?.length || gaData?.adGroups?.length || gaData?.campaign?.length,
            );
            break;
          case 'facebook-ads':
            const { data: fbData } = await axiosInstance.post(
              '/v2/facebook-ads/get-bad-utms-ads-facebook',
              {
                data: { shopDomain: currentShopId },
              },
            );
            setUtmStatus(!!fbData?.length);
            break;
          default:
            break;
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [channelId, currentShopId]);

  const getRequestParams = useCallback(
    (campaignId?: string, adsetId?: string, parentValues?: any) => {
      if (!channelId) {
        return;
      }
      return getParams(
        { attributionParams, sourceCategory, activeSource: channelId, sourcesList },
        campaignId,
        adsetId,
        parentValues,
      );
    },
    [attributionParams, sourceCategory, sourcesList, channelId],
  );

  const setComparisons = useCallback(
    async (requestParams) => {
      if (prevDateIsNone) {
        setLoadingAttributionComparisons(false);
        return;
      }
      if (!requestParams) {
        return;
      }
      const {
        period: { start, end, isOneDay },
        params,
      } = requestParams;
      const dateToCompare = getAttributionComparisonDates(
        start,
        end,
        mainDatePickerSpecialPeriod,
        datesToCompare,
      );

      setDateToCompare(dateToCompare);

      let comparison = await getAttributionComparison(
        params,
        dateToCompare.start,
        dateToCompare.end,
      );

      comparison = fillInBlanks(comparison!, {
        start: dateToCompare.start,
        end: dateToCompare.end,
        granularity: isOneDay ? 'hour' : 'day',
      });

      const comparisonDictionary = keyBy(comparison, 'id');

      setData((oldData) => {
        const attributions: AttributionData[] = (oldData || []).map((sourceStats) => ({
          ...sourceStats,
          active: true,
          comparisons: comparisonDictionary[sourceStats.id!]
            ? comparisonDictionary[sourceStats.id!]
            : sourceStats.comparisons,
          adsets: sourceStats.adsets?.map((adset) => ({
            ...adset,
            comparisons: comparisonDictionary[adset.id!]
              ? comparisonDictionary[adset.id!]
              : adset.comparisons,
            ads: adset.ads?.map((ad) => ({
              ...ad,
              comparisons: comparisonDictionary[ad.id!]
                ? comparisonDictionary[ad.id!]
                : ad.comparisons,
            })),
          })),
        }));
        return attributions;
      });
      setSelectedAttributions((selected) => {
        return selected.map((campaign) => ({
          ...campaign,
          comparisons: comparisonDictionary[campaign.id!] || campaign.comparisons,
          adsets: campaign.adsets?.map((adset) => ({
            ...adset,
            comparisons: comparisonDictionary[adset.id!] || adset.comparisons,
            ads: adset.ads?.map((ad) => ({
              ...ad,
              comparisons: comparisonDictionary[ad.id!] || ad.comparisons,
            })),
          })),
        }));
      });
      setLoadingAttributionComparisons(false);
    },
    [datesToCompare, prevDateIsNone, mainDatePickerSpecialPeriod],
  );

  const setOverlaps = useCallback(async (requestParams) => {
    const { params } = requestParams;
    if (params.model !== 'lastPlatformClick-v2') {
      return;
    }
    setLoadingOverlap(true);
    const result = await axiosInstance.post<
      any,
      { data: AttributionOverlapsResponse },
      AttributionStatsRequest
    >(`/v2/attribution/overlaps`, params);
    setData((oldData) => {
      return oldData.map((attributionData) => ({
        ...attributionData,
        overlaps:
          (result?.data?.overlaps || {})[attributionData?.id || ''] || attributionData.overlaps,
        adsets: attributionData.adsets?.map((adset) => ({
          ...adset,
          overlaps: (result?.data?.overlaps || {})[adset?.id || ''] || adset.overlaps,
          ads: adset.ads?.map((ad) => ({
            ...ad,
            overlaps: (result?.data?.overlaps || {})[ad?.id || ''] || ad.overlaps,
          })),
        })),
      }));
    });
    setLoadingOverlap(false);
  }, []);

  const fetchData = useCallback(
    async (campaignId?: string, adsetId?: string, parentValues?: any, customMetricTag?: string) => {
      setLoadingEntityProducts(true);
      const requestParams = getRequestParams(campaignId, adsetId, parentValues);
      if (!requestParams) {
        return;
      }
      const {
        period: { startDate, endDate, start, end, isOneDay },
        params,
      } = requestParams;
      const { breakdown, attributionFilters } = params;
      // if missing dates array contains a date between the start and end dates show warning and return
      setClosedPpsBanner(false);
      const missingDatesBetweenStartAndEnd = missingDates?.some((date) => {
        return moment(date).isBetween(start, end, 'day', '[]');
      });

      if (requestParams.params.model === 'ppsViews')
        if (
          missingDatesBetweenStartAndEnd ||
          !hasPpsInstalled ||
          !hasPpsData ||
          moment(start).isBefore(ppsModelStartSinceDate) ||
          moment(end).isBefore(ppsModelStartSinceDate)
        ) {
          setData([]);
          return;
        }

      if (!campaignId && !adsetId) {
        $pixelPerformanceStartMs.set(performance.now());
        $pixelPerformanceEnabled.set(true);
        console.log('fetchData start');
        setLoading(true);
      }

      setLoadingAttributionComparisons(true);

      currentPeriodRequestRef.current = `${startDate}_${endDate}`;
      const v = new URLSearchParams(window.location.search).get('pixelVersion');
      const pixelVersion = parseInt(v || '');
      let fetchData;
      params.customTag = customMetricTag ? customMetricTag : '';
      fetchData = await getAttributionData({
        ...params,
        ...(pixelVersion && {
          eventVersion: pixelVersion,
        }),
      });
      let statsData = fetchData?.data?.data?.stats ?? fetchData?.data?.data;

      statsData = filterStats(statsData);
      if (customMetricTag) {
        return statsData;
      }
      if (currentPeriodRequestRef.current !== `${startDate}_${endDate}`) {
        return;
      }
      genericEventLogger(analyticsEvents.ATTRIBUTION, {
        action: attributionActions.DATA_FETCHED,
        ...params,
        hasPpsInstalled,
        hasPpsData,
      });

      const withBlanksFilled: AttributionData[] = fillInBlanks(statsData, {
        start: start.format(),
        end: end.format(),
        granularity: isOneDay ? 'hour' : 'day',
      });
      let attributions: AttributionData[] = (withBlanksFilled || []).map((sourceStats) => ({
        ...sourceStats,
        ...sourceStats?.metrics,
        active: true,
        matched: !!sourceStats.status,
      }));

      dispatch(initAttribution(attributions));

      const isInitialRequest = !campaignId && !adsetId;

      if (isInitialRequest) {
        if (params.sources?.find((x) => x === 'klaviyo')) {
          attributions = attributions.filter((campaign) => campaign.id !== 'Everyone');
        }

        if (params.breakdown === 'source') {
          attributions = attributions.map((entity) => {
            return entity.name === 'Klaviyo' ? { ...entity, conversionValue: 0 } : entity;
          });
        }
        setData((old) => {
          const expandedCampaigns = old.filter((x) => x.isExpanded);
          const expandedAdsets = expandedCampaigns.flatMap(
            (x) => x.adsets?.filter((y) => y.isExpanded) || [],
          );

          const rowsWithExpandedCampaigns = attributions.map((campaign) => {
            return {
              ...campaign,
              isExpanded: expandedCampaigns.some((x) => x.id === campaign.id),
            };
          });
          const rowsWithExpandedAdsets = rowsWithExpandedCampaigns.map((campaign) => {
            if (!campaign.isExpanded) return campaign;
            const expandedAdsetsOfCampaign = expandedAdsets.filter(
              (x) => x.campaignId === campaign.id,
            );
            const adsets = campaign.adsets
              ? [...campaign.adsets]
              : expandedAdsetsOfCampaign.map((adset) => {
                  return {
                    isExpanded: true,
                    id: adset.adsetId,
                    campaignId: adset.campaignId,
                    name: adset.name,
                    selected: adset.selected,
                    entity: adset.entity,
                    isFake: true,
                  } as AttributionData;
                });
            return {
              ...campaign,
              adsets,
            };
          });
          return rowsWithExpandedAdsets;
        });
        console.log('fetchData false');
        const endTime = performance.now();
        const startTime = $pixelPerformanceStartMs.get();
        const pixelPerformanceEnabled = $pixelPerformanceEnabled.get();
        const durationMs = endTime - startTime;
        const payload = {
          durationMs,
          startDate,
          endDate,
          days: moment(endDate).diff(moment(startDate), 'days') + 1,
        };
        datadogRum.addAction(analyticsEvents.PIXEL_LOADING, payload);
        if (pixelPerformanceEnabled) {
          console.debug('Pixel performance', payload);
        }
        $summaryPerformanceEnabled.set(false);
        setLoading(false);
      } else if (params.breakdown === 'adsetId') {
        setData((oldCampaigns) => {
          return oldCampaigns.map((campaign) => {
            return {
              ...campaign,
              adsets:
                campaignId === campaign.id
                  ? (statsData || []).map((newAdset) => ({
                      ...newAdset,
                      id: newAdset.esKey || newAdset.id,
                      name: newAdset.esKey || newAdset.name,
                      campaignId: campaignId,
                      isExpanded: campaign?.adsets?.find((adset) => adset.id == newAdset.adsetId)
                        ?.isExpanded,
                      active: true,
                    }))
                  : campaign.adsets,
            };
          });
        });
      } else if (params.breakdown === 'adId') {
        setData((oldCampaigns) => {
          return oldCampaigns.map((campaign) => {
            return {
              ...campaign,
              adsets: campaign.adsets?.map((adset) => ({
                ...adset,
                ads:
                  adsetId === adset.id && campaignId === campaign.id
                    ? (statsData || []).map((a) => ({
                        ...a,
                        id: a.esKey || a.id,
                        name: a.esKey || a.name,
                        campaignId: campaignId,
                        adsetId: adsetId,
                        active: true,
                      }))
                    : adset.ads,
                active: true,
              })),
            };
          });
        });
      }

      if (isInitialRequest) {
        dispatch(attributionLastRefresh(moment()));
        setSelectedAttributions((selected) => {
          const selectedIds = selected.map((a) => a.id);
          const newItemsThatShouldBeSelected = statsData?.filter((x) => selectedIds.includes(x.id));
          return selected
            .map((s) => {
              const selectedThatAppearsInNew = newItemsThatShouldBeSelected.find(
                (x) => x.id === s.id,
              );
              return {
                ...s,
                metricsBreakdown: selectedThatAppearsInNew?.metricsBreakdown!,
              };
            })
            .filter((x) => x.metricsBreakdown);
        });
      }
      await Promise.all([setComparisons(requestParams), setOverlaps(requestParams)]);
    },
    // can't pass setComparisons callback as a dependency because it changes every time you change dates
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // setComparisons,
      getRequestParams,
      missingDates,
      hasPpsInstalled,
      hasPpsData,
      setOverlaps,
      dispatch,
      currentShopId,
    ],
  );

  const fetchDataCustomMetricsTag = useCallback(() => {
    let setDataCustomMetricsTagArray: any = [];
    return customMetricsWithTag.map(async (customMetricTag) => {
      const { parentValues } = location.state || {};
      if (!setDataCustomMetricsTagArray.find((obj) => obj.hasOwnProperty(customMetricTag.tag))) {
        const dataCustomMetricsTag = await fetchData(
          undefined,
          undefined,
          parentValues,
          customMetricTag.tag,
        );
        setDataCustomMetricsTag((prevDataCustomMetricsTag) => {
          if (dataCustomMetricsTag) {
            setDataCustomMetricsTagArray.push({
              [customMetricTag.tag]: dataCustomMetricsTag,
            });
            return {
              ...prevDataCustomMetricsTag,
              ...{ [customMetricTag.tag]: dataCustomMetricsTag },
            };
          }
          return prevDataCustomMetricsTag;
        });
      }
    });
  }, [customMetricsWithTag, fetchData, location.state]);

  const dataWithCustomMetricsWithTag = useMemo(() => {
    return customMetricsWithTag.map((customMetricTag) => {
      const dataCustomMetricsWithTag = dataCustomMetricsTag?.[customMetricTag.tag];
      return dataCustomMetricsWithTag?.map((row) => {
        return {
          ...row,
          ...getCustomMetricsFromRowData([customMetricTag], row),
          adsets: row.adsets?.map((adset) => {
            return {
              ...adset,
              ...getCustomMetricsFromRowData([customMetricTag], { adset, ...adset.metrics }),
              ads: adset.ads?.map((ad) => {
                return {
                  ...ad,
                  ...getCustomMetricsFromRowData([customMetricTag], { ad, ...ad.metrics }),
                };
              }),
            };
          }),
        };
      });
    });
  }, [dataCustomMetricsTag, customMetricsWithTag]);

  useEffect(() => {
    (async () => {
      if (isRunningRef.current) return;
      isRunningRef.current = true;
      // fetch data for expanded campaigns or adsets
      const expandedCampaigns = data.filter(
        (x) => x.isExpanded && (!x.adsets?.length || x.adsets?.[0]?.isFake),
      );
      const expandedAdsets = data
        .map((c) => c.adsets || [])
        .flat()
        .filter((x) => x.isExpanded && !x.ads?.length);

      const entitiesToExpand = expandedCampaigns?.length ? expandedCampaigns : expandedAdsets;

      await Promise.all(
        entitiesToExpand.map((campaignOrAdset) => {
          let parentValues: any = undefined;
          if (attributionModel === 'ppsViews') {
            parentValues = campaignOrAdset?.metricsBreakdown?.map((x) => ({
              date: x.date,
              pixelPurchases:
                x.metrics.pixelPurchases -
                (x.metrics.metaPurchases || x.metrics.tiktokShopPurchases || 0),
              pixelConversionValue:
                x.metrics.pixelConversionValue -
                (x.metrics.metaConversionValue || x.metrics.tiktokShopConversionValue || 0),
              pixelNcPurchases: x.metrics.pixelNcPurchases,
              pixelNcConversionValue: x.metrics.pixelNcConversionValue,
            }));
          }
          let campaignId, adsetId;
          if (campaignOrAdset.entity === 'campaign') {
            campaignId = campaignOrAdset.id;
          } else {
            campaignId = campaignOrAdset.campaignId;
            adsetId = campaignOrAdset.id;
          }
          return fetchData(campaignId, adsetId, parentValues);
        }),
      );

      isRunningRef.current = false;
    })();
  }, [data, fetchData, attributionModel, channelId]);

  useEffect(() => {
    // TODO remove the code (remove by Chaim request)
    if (false && !localStorage.getItem('totalImpactModalShown')) {
      dispatch(showTotalImpactModal(true));
      localStorage.setItem('totalImpactModalShown', 'true');
    }
  }, [hasPpsData, dispatch]);

  useEffect(() => {
    const { parentValues } = location.state || {};
    fetchData(undefined, undefined, parentValues);
    fetchDataCustomMetricsTag();
    // location.state in dependency causes unwanted re-fetches
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  useEffect(() => {
    const requestParams = getRequestParams();
    if (!requestParams) return;

    const { params } = requestParams;
    getCampaignsExperimentsMetrics(params).then((res) => {
      setExperimentsData(
        res.reduce((acc, item) => {
          acc[item.campaign_id] = item;
          return acc;
        }, {}),
      );
    });
  }, [getRequestParams]);

  useEffect(() => {
    dispatch(initAttributionPage(allPixelColumns as PixelColumn[]));
    let channelFromRoute = channelId;

    if (channelFromRoute && sourceCategory !== 'all') {
      localStorage.setItem(pageSourceKey, channelId);
    }
    if (!channelFromRoute && location.pathname.includes('attribution')) {
      channelFromRoute = localStorage.getItem(pageSourceKey) || defaultSource;
      let id = sourceCategory === ALL_SOURCES_ID ? 'all' : channelFromRoute;
      if (id === 'undefined') id = 'all';

      const newPath = `${
        location.pathname.includes('dashboards') ? '/dashboards' : ''
      }/attribution/${sourceCategory}/${id}`;
      navigate(
        {
          pathname: newPath,
          search: location.search,
        },
        {
          replace: true,
        },
      );
    }
  }, [
    sourceCategory,
    dispatch,
    allPixelColumns,
    channelId,
    pageSourceKey,
    navigate,
    location,
    defaultSource,
  ]);

  useEffect(() => {
    if (attributionInModal) {
      dispatch(
        lightboxOpen({
          resources: [
            {
              url: attributionInModal.imageUrl,
              type: 'photo',
              altTag: attributionInModal.name,
              thumbnail: attributionInModal.imageUrl,
              extraData: {
                serviceId: channelId,
                assetId: attributionInModal.id,
                accountId: attributionInModal.accountId,
              },
            },
          ],
        }),
      );
    } else {
      dispatch(lightboxClose());
    }
  }, [attributionInModal, dispatch, channelId]);

  useEffect(() => {
    if (!isLightboxOpen.open) {
      setAttributionInModal(undefined);
    }
  }, [isLightboxOpen.open]);

  // set live orders
  useEffect(() => {
    const fetchOrders = async ({ start, end }) => {
      setLoadingOrders(true);
      setLiveOrders({});
      const req = getRequestParams();
      const params = {
        startDate: start,
        endDate: end,
        shopDomain: currentShopId,
        additionalShopIds: activeAccounts,
        useNewModels: req?.params?.useNewModels,
        timezone: req?.params?.timezone,
      };
      const { data } = await axiosInstance.post(`/v2/attribution/get-live-orders`, params);
      const ordersKeyed = keyBy(data.orders, 'orderId');
      setLiveOrders(ordersKeyed);
      setLoadingOrders(false);
      setOrdersLastSnapshot(data.orders[data.orders.length - 1]?.eventDate);
    };

    if (!mainDatePickerSelectionRange) return;
    fetchOrders(mainDatePickerSelectionRange);
  }, [mainDatePickerSelectionRange, currentShopId, getRequestParams, activeAccounts]);

  // realtime
  const updateRealtime = useCallback(
    async (message: AttributionRealtimeMessage) => {
      if (message?.eventType !== 'attribution_update_v2') {
        return;
      }
      const { data: msgData } = message;
      if (!Array.isArray(msgData) || !msgData?.length) {
        return;
      }
      if (!mainDatePickerSelectionRange) {
        return;
      }
      const { start, end } = mainDatePickerSelectionRange;
      if (!moment(msgData[0].eventDate).isBetween(start, end)) {
        return;
      }
      if (dateModel === 'clickDate') {
        return;
      }
      const relevantModels = msgData.filter((x) => x.model === attributionModel);
      if (!relevantModels.length) {
        return;
      }

      setData((oldData) => {
        return oldData.map((existing) => {
          const fieldToLookAt = channelId === ALL_SOURCES_ID ? 'source' : 'campaignId';
          const newFromRealtime = relevantModels.find((x) => {
            if (attributionWindow === 'lifetime') {
              return existing.id === x[fieldToLookAt];
            }
            return existing.id === x[`d${attributionWindow}`]?.[fieldToLookAt];
          });
          if (!newFromRealtime) {
            return existing;
          }

          const topLevelStats = addRealtimeData(existing, newFromRealtime);

          if (channelId === ALL_SOURCES_ID) {
            return topLevelStats as AttributionData;
          }

          const adset = existing.adsets?.find((x) => {
            if (attributionWindow === 'lifetime') {
              return x.adsetId === newFromRealtime.adsetId;
            }
            return x.adsetId === newFromRealtime[`d${attributionWindow}`]?.adsetId;
          });
          if (!adset) {
            return topLevelStats;
          }

          const adsetLevelStats = addRealtimeData(adset, newFromRealtime);

          const withAdset = {
            ...topLevelStats,
            adsets: existing?.adsets?.map((x) =>
              x.id === adsetLevelStats.id ? adsetLevelStats : x,
            ),
          };

          const ad = adset.ads?.find((x) => {
            if (attributionWindow === 'lifetime') {
              return x.adId === newFromRealtime.adId;
            }
            return x.adId === newFromRealtime[`d${attributionWindow}`]?.adId;
          });

          if (!ad) {
            return withAdset;
          }

          const adLevelStats = addRealtimeData(ad, newFromRealtime);

          const withAd = {
            ...topLevelStats,
            adsets: withAdset?.adsets?.map((adset) => ({
              ...adset,
              ads: adset?.ads?.map((ad) => (ad.id === adLevelStats.id ? adLevelStats : ad)),
            })),
          };

          return withAd;
        });
      });

      setTimeout(() => {
        setData((old) =>
          old?.map((campaign) => ({
            ...campaign,
            shouldHighlight: false,
            adsets: campaign?.adsets?.map((adset) => ({
              ...adset,
              shouldHighlight: false,
              ads: adset?.ads?.map((ad) => ({
                ...ad,
                shouldHighlight: false,
              })),
            })),
          })),
        );
      }, 3000);
    },
    [attributionModel, dateModel, mainDatePickerSelectionRange, channelId, attributionWindow],
  );

  const cancelWorkflow = useCallback(
    async (column: ColumnName) => {
      const workflowId = activeWorkflowIds[column];
      if (!workflowId) {
        return;
      }
      await axiosInstance.post('/v2/sequences/workflows/cancel', {
        shopId: currentShopId,
        workflowId: workflowId,
      });
      setCancelledWorkflows((old) => ({
        ...old,
        [column]: true,
      }));
    },
    [currentShopId, activeWorkflowIds],
  );

  useEffect(() => {
    if (!socket) return;
    const a = socket.on('message', updateRealtime);
    return () => {
      a.off('message', updateRealtime);
    };
  }, [updateRealtime, socket]);

  const liveOrdersUpdate = useCallback(
    async (message: AttributionRealtimeMessage) => {
      const eventType = useNewModels ? 'attribution_update_v2' : 'attribution_update';
      if (message?.eventType !== eventType) {
        return;
      }
      const { data: msgData } = message;
      if (!Array.isArray(msgData) || !msgData?.length) {
        return;
      }
      if (!mainDatePickerSelectionRange) {
        return;
      }
      const { start, end } = mainDatePickerSelectionRange;
      if (!moment(msgData[0].eventDate).isBetween(start, end)) {
        return;
      }

      setLiveOrders((old) => {
        const newOrders = msgData.reduce((acc, order) => {
          if (!acc[order.orderId]) {
            acc[order.orderId] = { ...order, sources: [] };
          }
          if (!acc[order.orderId].sources.includes(order.source)) {
            acc[order.orderId].sources.push(order.source);
          }
          return acc;
        }, {});

        return {
          ...old,
          ...newOrders,
        };
      });
    },
    [mainDatePickerSelectionRange, useNewModels],
  );
  useEffect(() => {
    if (!socket) return;

    const a = socket.on('message', liveOrdersUpdate);
    return () => {
      a.off('message', liveOrdersUpdate);
    };
  }, [liveOrdersUpdate, socket]);

  const fetchOrdersOnScroll = useCallback(
    async ({ start, end }) => {
      const params = {
        startDate: start,
        endDate: ordersLastSnapshot || end,
        shopDomain: currentShopId,
        additionalShopIds: activeAccounts,
        useNewModels,
        timezone: shopTimezone,
      };
      const { data } = await axiosInstance.post(`/v2/attribution/get-live-orders`, params);
      const moreOrders = data.orders;

      setHasMoreOrders(data.hasMoreOrders);

      setOrdersLastSnapshot(moreOrders[moreOrders.length - 1]?.eventDate);
      setMoreOrders(moreOrders);
    },
    [currentShopId, ordersLastSnapshot, useNewModels, shopTimezone, activeAccounts],
  );

  const toggleChartOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(chartOpenChanged(isOpen));
    },
    [dispatch],
  );

  const runAiColumn = useCallback(
    async (column: Column<ColumnName>) => {
      if (!column.sequenceId) {
        return;
      }
      const sequence = sequences.find((s) => s.id === column.sequenceId);
      if (!sequence) {
        return;
      }
      const currentMessageId = uuidV4();
      const newRunId = uuidV4();
      const requestParams: RunSequenceRequest = {
        source: 'sequence',
        sequenceId: sequence.id,
        shopId: currentShopId,
        conversationId: newRunId,
        additionalShopIds: activeAccounts ?? [],
        messageId: currentMessageId,
        question: '<question will generate from sequence>',
        generateInsights: true,
        stream: false,
        currency,
        timezone: shopTimezone,
        dialect: sequence?.dialect as Dialect,
        industry: industry || 'other',
        asDraft: false,
        conversationLink: window.location.href,
      };
      setCancelledWorkflows((old) => ({
        ...old,
        [column.key]: false,
      }));
      try {
        const response = await runWorkflow(requestParams);
        return response.workflowId;
      } catch (error) {
        console.error(error);
      }
    },
    [activeAccounts, currency, currentShopId, industry, sequences, shopTimezone],
  );

  useEffect(() => {
    const updateAgentColumn = async (msg: SequenceProgressEvent) => {
      const { sequenceId: seqId, type, text, runId: id, workflowId } = msg;

      const column = shopColumns.data?.find((c) => c.sequenceId === seqId);
      if (!column) {
        return;
      }

      if (cancelledWorkflows[column.key]) {
        return;
      }

      if (type === 'sequence-started' || type === 'step-started' || type === 'step-progress') {
        setActiveWorkflowIds((old) => ({
          ...old,
          [column.key]: workflowId,
        }));
        setLoadingColumns((old) => ({
          ...old,
          [column.key]: true,
        }));
      } else if (type === 'sequence-done' || type === 'sequence-error') {
        setLoadingColumns((old) => ({
          ...old,
          [column.key]: false,
        }));
        setUpdatedAiRows((old) => {
          const updated = Object.entries(old).reduce((acc, [campaignId, columns]) => {
            return {
              ...acc,
              [campaignId]: {
                ...columns,
                [column.key]: true,
              },
            };
          }, {});

          return updated;
        });
      }

      if (type === 'sequence-started') {
        setUpdatedAiRows((old) => {
          const updated = Object.entries(old).reduce((acc, [campaignId, columns]) => {
            return {
              ...acc,
              [campaignId]: {
                ...columns,
                [column.key]: false,
              },
            };
          }, {});

          return updated;
        });
        setCancelledWorkflows((old) => ({
          ...old,
          [column.key]: false,
        }));
      } else if (type === 'sequence-error') {
        setShopColumns((old) => {
          return {
            ...old,
            data: old.data?.map((c) => {
              if (c.sequenceId === seqId) {
                return { ...c, error: text };
              }
              return c;
            }),
          };
        });
        await updateShopColumn(currentShopId, column.key, {
          error: text,
        });
      } else if (type === 'sequence-done') {
        setShopColumns((old) => {
          return {
            ...old,
            data: old.data?.map((c) => {
              if (c.sequenceId === seqId) {
                return { ...c, error: null };
              }
              return c;
            }),
          };
        });
        await updateShopColumn(currentShopId, column.key, {
          error: null,
        });
      }
    };

    const func = async (msg) => {
      if (msg.eventType !== 'workflow-progress' || msg.account !== currentShopId) {
        return;
      }

      await updateAgentColumn(msg.data);
    };

    if (!socket) {
      return;
    }

    const listener = socket.on('message', func);
    return () => {
      listener.off('message', func);
    };
  }, [currentShopId, shopColumns.data, setShopColumns, socket, cancelledWorkflows]);

  const mergeCustomMetrics = useMemo(() => {
    const arrays = [...dataWithCustomMetricsWithTag, dataWithCustomMetrics];
    const definedArrays = arrays.filter((arr) => arr !== undefined);

    // Merge the defined arrays into a single array
    const mergedArray = definedArrays.reduce((result, currentArray) => {
      currentArray.forEach((obj, index) => {
        if (result[index] === undefined) {
          result[index] = {};
        }
        result[index] = { ...result[index], ...obj };
      });
      return result;
    }, []);
    return mergedArray;
  }, [dataWithCustomMetrics, dataWithCustomMetricsWithTag]);

  const filteredRows = useMemo(() => {
    return mergeCustomMetrics.filter((campaign) => filterCb(freeSearch, campaign));
  }, [mergeCustomMetrics, freeSearch, filterCb]);

  const totalsAttribution = useMemo(() => {
    const allMetrics: MetricsKeys[] = Object.keys(filteredRows[0] || {}) as MetricsKeys[];
    const dataKeyedByDate = filteredRows
      .flatMap((x) => x.metricsBreakdown)
      .reduce((acc, curr) => {
        if (!acc[curr.date]) {
          acc[curr.date] = [];
        }
        acc[curr.date].push(curr.metrics);
        return acc;
      }, {});

    const totals = allMetrics.reduce(
      (acc: AttributionData, curr) => {
        const metric = metrics[curr];
        if (!metric?.calculateSum) {
          return acc;
        }

        acc[curr] = metric.calculateSum(filteredRows);

        acc.metricsBreakdown = acc.metricsBreakdown.map((y) => {
          const dataOfThisDate = dataKeyedByDate[y.date];

          return {
            date: y.date,
            metrics: {
              ...y.metrics,
              [curr]: metric.calculateSum?.(dataOfThisDate),
            },
          };
        });

        return acc;
      },
      {
        id: 'totals',
        active: true,
        name: 'Total',
        entity: 'total',
        metricsBreakdown: filteredRows[0]?.metricsBreakdown.map((x) => {
          return {
            date: x.date,
            metrics: {},
          };
        }),
      } as unknown as AttributionData,
    );

    return totals;
  }, [filteredRows]);

  const totalsElement = useMemo(() => {
    return (
      <div className="p-4 flex justify-center items-center h-full">
        <Checkbox
          checked={!!selectedAttributions.find((x) => x.id === 'totals')}
          onChange={(newChecked) => {
            if (!totalsAttribution) {
              return;
            }
            toggleAttributionSelection!(totalsAttribution);
            if (newChecked) {
              dispatch(chartOpenChanged(true));
            }
          }}
        />
      </div>
    );
  }, [dispatch, selectedAttributions, toggleAttributionSelection, totalsAttribution]);

  const integrationsPopUp = useMemo(() => {
    return (
      <div>
        {!hasUpdateScope && updateAdsAllowed && (
          <Modal
            title="Additional Scopes Required"
            opened={integrationsPopUpOpened}
            onClose={() => {
              setIntegrationsPopUpOpened(false);
            }}
          >
            <div className="overflow-hidden w-full flex flex-col gap-4">
              <Text>
                {`Triple Whale requires additional permissions from ${channelId?.split('-')[0]} to enable budget, status and bid amount edits.
                    To use this feature, you must first disconnect and reconnect your ${channelId?.split('-')[0]} account`}
              </Text>
              <UiButton onClick={() => navigate('/integrations')}>Go to Integrations</UiButton>
            </div>
          </Modal>
        )}
      </div>
    );
  }, [hasUpdateScope, updateAdsAllowed, integrationsPopUpOpened, channelId, navigate]);

  const rows = useMemo(() => {
    let allRows: AttributionData[] = [];
    const unmatchedRows: AttributionData[] = [];
    const sortedCampaigns = orderBy(
      mergeCustomMetrics,
      (campaign) => sortCb(campaign, sortBy, sortDirection),
      sortDirection === 'descending' ? 'desc' : 'asc',
    ).filter((campaign) => filterCb(freeSearch, campaign));
    for (const campaign of sortedCampaigns) {
      const isPps = SurveyServiceIds.includes(campaign.id as any);
      const arrayToPush =
        campaign.matched ||
        isPps ||
        channelId === ALL_SOURCES_ID ||
        sourceCategory !== 'ads' ||
        channelId === AFFLUENCER
          ? allRows
          : unmatchedRows;
      arrayToPush.push(campaign);

      if (campaign.entity === 'campaign' && campaign.isExpanded) {
        if (!campaign.adsets) {
          arrayToPush.push({
            adsetId: LOADING_ROW_ID,
          } as unknown as AttributionData);
        }
        if (campaign.adsets && !campaign.adsets.length) {
          arrayToPush.push({ adsetId: 'no_data', name: 'No Adsets' } as AttributionData);
        }
        const sortedAdsets = orderBy(
          campaign.adsets,
          (adset) => sortCb(adset, sortBy, sortDirection),
          sortDirection === 'descending' ? 'desc' : 'asc',
        );

        for (const adset of sortedAdsets) {
          arrayToPush.push({
            ...adset,
            unmatchedCampaigns: campaign.unmatchedIds,
          });
          if (adset.entity === 'adset' && adset.isExpanded) {
            if (!adset.ads) {
              arrayToPush.push({ adId: LOADING_ROW_ID } as AttributionData);
            }
            if (campaign.ads && !campaign.ads.length) {
              arrayToPush.push({ adsetId: 'no_data', name: 'No Ads' } as AttributionData);
            }
            const sortedAds = orderBy(
              adset.ads,
              (ad) => sortCb(ad, sortBy, sortDirection),
              sortDirection === 'descending' ? 'desc' : 'asc',
            );
            for (const ad of sortedAds) {
              arrayToPush.push({
                ...ad,
                unmatchedCampaigns: campaign.unmatchedIds,
                unmatchedAdsets: adset.unmatchedIds,
              });
            }
          }
        }
      }
    }
    // filter out inactive matched campaigns in case user choose it
    allRows = allRows.filter(
      (c) =>
        c.entity !== 'campaign' ||
        !showOnlyActiveCampaigns ||
        c.status?.toLocaleUpperCase() === 'ACTIVE' ||
        c.pixelPurchases > 0 ||
        channelId === AFFLUENCER,
    );
    if (unmatchedRows.length > 0) {
      allRows.push({ id: UNMATCHED_ROWS_ID } as AttributionData);
    }
    if (showUnmatchedRows) {
      allRows = allRows.concat(unmatchedRows);
    }
    allRows = allRows.map((row) => ({
      ...row,
      experimentData: row.campaignId ? experimentsData[row.campaignId] : {},
    }));

    return allRows;
  }, [
    mergeCustomMetrics,
    sortDirection,
    showUnmatchedRows,
    sortCb,
    sortBy,
    filterCb,
    freeSearch,
    channelId,
    sourceCategory,
    showOnlyActiveCampaigns,
    experimentsData,
  ]);

  const paginatedRows = useMemo(() => {
    return getItemsByPage(rows, activePage, perPage);
  }, [activePage, rows, perPage]);

  const pixelGlobalWorkflows = useComputedValue($globalSequences, (gs) => {
    return gs.filter((s) => s.pixelSettings);
  });

  const pixelInstallStatus = useSelector((state: RootState) => state.pixelInstallStatus);

  const metadata: AttributionTableMetadata = useMemo(() => {
    return {
      data: mergeCustomMetrics,
      currency,
      sourceId: channelId,
      selectedAttributions,
      loading,
      loadingOverlap,
      loadingAttributionComparisons,
      loadingEntityProducts,
      sourceCategory,
      sources: sourcesList,
      includeOneDayView,
      showComparisons: !prevDateIsNone,
      mainDatePickerSelectionRange,
      wrapTableLinesMode,
      canUpdateAd,
      updateAdsAllowed,
      hasUpdateScope,
      setIntegrationsPopUpOpened,
      hasFacebookAdMgmtPermission: facebookAdsScopes?.includes('ads_management'),
      hasTTAdMgmtPermission: istiktokAdsEditScopes,
      filteredRows: filteredRows,
      activeModel: attributionModel,
      hasWorkflowPixelIntegrated: insightsSummaryPixelFF,
      setShowUnmatchedRows,
      toggleAttributionSelection,
      setData,
      fetchData,
      setAttributionInModal,
      setWorkflowInModal,
      setShowAdSkusModal,
      toggleChartOpen,
      loadingColumns,
      updatedAiRows,
      setLoadingColumns,
      setActiveWorkflowIds,
      activeWorkflowIds,
      runAiColumn,
      cancelWorkflow,
      navigate,
      setSelectedColumn,
      setAiCellInModal,
      location,
      featureFlagComputer: ffComputer,
      skipChannelMapping: [...(hasPpsInstalled ? [] : ['organic'])],
      pixelWorkflows: pixelGlobalWorkflows,
    };
  }, [
    mergeCustomMetrics,
    currency,
    channelId,
    selectedAttributions,
    loading,
    loadingOverlap,
    loadingAttributionComparisons,
    loadingEntityProducts,
    sourceCategory,
    sourcesList,
    includeOneDayView,
    prevDateIsNone,
    mainDatePickerSelectionRange,
    wrapTableLinesMode,
    canUpdateAd,
    hasUpdateScope,
    updateAdsAllowed,
    setIntegrationsPopUpOpened,
    loadingColumns,
    updatedAiRows,
    facebookAdsScopes,
    istiktokAdsEditScopes,
    filteredRows,
    attributionModel,
    insightsSummaryPixelFF,
    toggleAttributionSelection,
    fetchData,
    toggleChartOpen,
    navigate,
    runAiColumn,
    location,
    ffComputer,
    hasPpsInstalled,
    pixelGlobalWorkflows,
    activeWorkflowIds,
    cancelWorkflow,
    setSelectedColumn,
    setAiCellInModal,
  ]);

  return (
    <AttributionPageContext.Provider
      value={{
        sourceId: channelId,
        sourceCategory,
        currency,
        selectedAttributions,
        loading,
        includeOneDayView,
        attributionInModal,
        dateToCompare,
        loadingAttributionComparisons,
        utmStatus,
        fetchData,
        setData,
        setAttributionInModal,
        setSelectedAttributions,
      }}
    >
      <div className="attribution" data-testid="attribution">
        {showAdSkusModal?.show && (
          <AttributionSkusModal {...{ showAdSkusModal, setShowAdSkusModal }} />
        )}

        {totalImpactModal && (
          <TotalImpactModal
            shop={currentShopId}
            ppsStatus={ppsStatus}
            isOpen={totalImpactModal}
            setIsOpen={(isOpen) => dispatch(showTotalImpactModal(isOpen))}
          />
        )}
        <Page fullWidth>
          <Layout>
            <div className="w-full">
              <InAppContextBanner page="attribution" className="Polaris-Layout__Section" />
            </div>

            {needToShowFacebookGrantAccess && channelId === 'facebook-ads' && (
              <Layout.Section fullWidth>
                <h2 className="text-2xl mb-4 font-bold">
                  Update Triple Whale access to your Meta account to manage ads.
                </h2>
                <Tooltip content="Reconnect to your Meta account">
                  <Button onClick={facebookConnectOnPress} icon={ConnectMinor} external primary>
                    Grant Access
                  </Button>
                </Tooltip>
              </Layout.Section>
            )}
            <div className="w-full">
              <Collapsible open={chartOpen} id="attribution-collapsible">
                <Layout.Section fullWidth>
                  <AttributionChart />
                </Layout.Section>
              </Collapsible>
            </div>
            <Layout.Section fullWidth>
              {/* <div className="pb-5">
                <AttributionHealthIndicator />
              </div> */}
              {/*{!pixelInstallStatus?.status && <InstallPixelBanner />}*/}
              {
                <PpsBanner
                  attributionModel={attributionModel}
                  closedPpsBanner={closedPpsBanner}
                  onClose={() => setClosedPpsBanner(true)}
                />
              }
              {integrationsPopUp}
              <TWTable
                id="attribution-pixel-table"
                columns={effectiveSelectedColumns}
                data={paginatedRows}
                totals={rows}
                metadata={metadata}
                loading={loading}
                stickyHeader
                stickyColumnIndex={
                  findLastIndex(
                    effectiveSelectedColumns,
                    (x) => typeof x.isFixed === 'boolean' && x.isFixed,
                  ) + 1
                }
                height={'72vh'}
                padding="0"
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSort={(columnIndex, direction) => {
                  const col = effectiveSelectedColumns[columnIndex];
                  setSortBy(col.key);
                  setSortDirection(direction);
                }}
                totalsName={{
                  singular: totalsElement,
                  plural: totalsElement,
                }}
              />
            </Layout.Section>
            {rows.length > 0 && (
              <Layout.Section fullWidth>
                <div className="flex gap-2 justify-between">
                  <div className="">
                    <Pagination
                      hasNext={activePage < Math.floor(rows.length / perPage)}
                      onNext={() => handlePagination(activePage + 1)}
                      hasPrevious={activePage > 0}
                      onPrevious={() => handlePagination(activePage - 1)}
                      label={`Page ${activePage + 1} of ${Math.ceil(rows.length / perPage)}`}
                    />
                  </div>
                  <div className="flex gap-2 items-center">
                    <Select
                      styles={{
                        input: { width: '80px' },
                      }}
                      comboboxProps={{
                        styles: {
                          dropdown: {
                            minWidth: 'max-content',
                          },
                          options: {
                            minWidth: 'max-content',
                          },
                        },
                      }}
                      value={perPage.toString()}
                      data={[
                        { label: '10', value: '10' },
                        { label: PER_PAGE.toString(), value: PER_PAGE.toString() },
                        { label: '50', value: '50' },
                        { label: '100', value: '100' },
                        { label: '200', value: '200' },
                        { label: '300', value: '300' },
                        { label: '400', value: '400' },
                        { label: '500', value: '500' },
                      ]}
                      onChange={(value) => {
                        if (!value) {
                          return;
                        }
                        setPerPage(Number(value));
                        localStorage.setItem(STORAGE_KEY, value);
                      }}
                    />
                    <Text size="sm">Rows per page</Text>
                  </div>
                </div>
              </Layout.Section>
            )}
            <Layout.Section oneHalf>
              <ChannelOverlap getRequestParams={getRequestParams} attributionData={data} />
            </Layout.Section>
            <Layout.Section oneHalf>
              <OrdersWidget
                loading={loadingOrders}
                ordersKeyed={liveOrders}
                fetchData={async () =>
                  await fetchOrdersOnScroll(
                    mainDatePickerSelectionRange || { start: null, end: null },
                  )
                }
                moreOrders={moreOrders}
                hasMore={hasMoreOrders}
              />
            </Layout.Section>
          </Layout>
          {createAdModalOpen && canCreateAd && (
            <CreateAd
              open={createAdModalOpen}
              onClose={toggleCreateAdModalOpen}
              campaigns={mergeCustomMetrics.filter((x) => x.name)}
              getRequestParams={getRequestParams}
            />
          )}
        </Page>
      </div>
      {!!workflowInModal && (
        <WorkflowInAttributionPage
          opened={!!workflowInModal}
          onClose={() => setWorkflowInModal?.(undefined)}
          attribution={workflowInModal}
        />
      )}
      {!!selectedColumn && (
        <AiColumnModal
          column={selectedColumn}
          opened={!!selectedColumn}
          onClose={() => setSelectedColumn(null)}
          allowTest={false}
          onlyOneColumn
        />
      )}
      {!!aiCellInModal && (
        <AiCellModal
          opened={!!aiCellInModal}
          onClose={() => setAiCellInModal(null)}
          cell={aiCellInModal}
        />
      )}
    </AttributionPageContext.Provider>
  );
};

export default Attribution;
