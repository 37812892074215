import { Checkbox, Flex, isDefined, Select, Text, TextInput } from '@tw/ui-components';
import { FC } from 'react';
import { EditorCollapse } from './EditorCollapse';
import { AxisDomain, WidgetChartLabel } from '../types/willyTypes';
import { LabelPosition } from 'recharts/types/component/Label';
import { ColorPickerPopover } from '../ColorPicker';

type ChartCustomizeProps = {
  showPreviousPeriod?: boolean;
  setShowPreviousPeriod?: (val: boolean) => void;
  allowDataOverflow?: boolean;
  setAllowDataOverflow: (val: boolean) => void;
  yAxisDomain: AxisDomain;
  setYAxisDomain: (val: AxisDomain) => void;
  skinny: boolean;
  setSkinny: (val: boolean) => void;
  stacked: boolean;
  setStacked: (val: boolean) => void;
  chartLabel?: WidgetChartLabel;
  setChartLabel?: (val: WidgetChartLabel) => void;
  rightYAxisLabel?: string;
  setRightYAxisLable?: (val: string) => void;
  leftYAxisLabel: string;
  setLeftYAxisLable: (val: string) => void;
  xAxisLabel: string;
  setXAxisLable: (val: string) => void;
};

const minmax = [
  { value: 'min', label: 'Min' },
  { value: 'max', label: 'Max' },
] as const;

export const ChartCustomize: FC<ChartCustomizeProps> = ({
  showPreviousPeriod,
  setShowPreviousPeriod,
  allowDataOverflow,
  setAllowDataOverflow,
  yAxisDomain,
  setYAxisDomain,
  skinny,
  setSkinny,
  stacked,
  setStacked,
  chartLabel = { position: null },
  setChartLabel,
  rightYAxisLabel,
  setRightYAxisLable,
  leftYAxisLabel,
  setLeftYAxisLable,
  xAxisLabel,
  setXAxisLable,
}) => {
  return (
    <Flex direction="column" gap="lg" px="md">
      <Checkbox
        label="Show previous period"
        checked={showPreviousPeriod}
        onChange={(v) => setShowPreviousPeriod?.(v)}
      />
      <EditorCollapse title="Display">
        <Flex direction="column" gap="md" pt="sm">
          {minmax.map((m) => (
            <Flex direction="column" gap="sm" key={m.value}>
              <Text weight={500} size="sm">
                {m.label}:{' '}
              </Text>
              <Checkbox
                checked={yAxisDomain?.[m.value] === 'auto'}
                label="Auto"
                onChange={(checked) => {
                  setYAxisDomain({
                    ...yAxisDomain,
                    [m.value]: checked ? 'auto' : 0,
                  });
                }}
              />
              <Checkbox
                checked={yAxisDomain?.[m.value] === m.label}
                label={`Data ${m.label}`}
                onChange={(checked) => {
                  setYAxisDomain({
                    ...yAxisDomain,
                    [m.value]: checked ? m.label : 0,
                  });
                }}
              />
              <TextInput
                disabled={yAxisDomain[m.value] === m.label || yAxisDomain[m.value] === 'auto'}
                label="Custom"
                type="number"
                value={
                  typeof yAxisDomain[m.value] === 'number' ? yAxisDomain[m.value].toString() : '0'
                }
                onChange={(v) => {
                  setYAxisDomain({
                    ...yAxisDomain,
                    [m.value]: parseInt(v),
                  });
                }}
              />
            </Flex>
          ))}
          <Checkbox
            checked={allowDataOverflow}
            label="Allow data overflow from the domain"
            onChange={(checked) => {
              setAllowDataOverflow(checked);
            }}
          />

          {rightYAxisLabel && (
            <TextInput
              label="Right Y axis name"
              value={rightYAxisLabel}
              onChange={(value) => setRightYAxisLable?.(value)}
            />
          )}

          <TextInput
            label="Left Y axis name"
            value={leftYAxisLabel}
            onChange={(value) => setLeftYAxisLable(value)}
          />

          <TextInput
            label="X axis name"
            value={xAxisLabel}
            onChange={(value) => setXAxisLable(value)}
          />
        </Flex>
      </EditorCollapse>

      <EditorCollapse title="Bar Display">
        <Flex direction="column" gap="md" pt="sm">
          <Checkbox
            label="Skinny bars"
            checked={skinny}
            onChange={(v) => setSkinny(v)}
            description="Force Bars in the chart to be Skinny"
          />
          <Checkbox
            label="Stacked Left Y Axis"
            checked={stacked}
            onChange={(v) => setStacked?.(v)}
            description="Stacked bars on the left y axis will be stacked together"
          />
        </Flex>
      </EditorCollapse>
      <EditorCollapse title="Data label">
        <Flex direction="column" gap="sm" pt="xs">
          <Select
            allowDeselect={false}
            label="Position"
            defaultValue={'none'}
            value={!isDefined(chartLabel?.position) ? 'none' : (chartLabel.position as string)}
            data={['inside', 'top', 'none']}
            onChange={(v) => {
              setChartLabel?.({
                ...chartLabel,
                position: v === 'none' ? null : (v as LabelPosition),
              });
            }}
          />
          {stacked && (
            <Select
              allowDeselect={false}
              label="Number Format"
              defaultValue={'value'}
              value={chartLabel?.valueType as string}
              data={['value', 'percent']}
              onChange={(v) => {
                if (!isDefined(v)) return;
                setChartLabel?.({ ...chartLabel, valueType: v as 'value' | 'percent' });
              }}
            />
          )}
          <ColorPickerPopover
            label="Label Color"
            saveOnChange
            labelSize="sm"
            labelWeight="500"
            activatorClassName="w-[20px] h-[20px] !rounded-[4px]"
            className="justify-between gap-[12px] pt-1"
            color={chartLabel.color || 'black'}
            onChange={(color) => {
              setChartLabel?.({ ...chartLabel, color });
            }}
            showColorValue
          />
        </Flex>
      </EditorCollapse>
    </Flex>
  );
};
