import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router';
import moment from 'moment-timezone';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { Button, confirm, Text, Loader } from '@tw/ui-components';
import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import {
  analyticsEvents,
  chatActions,
  genericEventLogger,
  sequencesActions,
} from 'utils/dataLayer';
import axiosInstance from 'utils/axiosInstance';
import _db, { firestoreRef } from 'utils/DB';
import { $currentShopId } from '$stores/$shop';
import { $globalAndShopSequences, $shopSequences } from '$stores/willy/$sequences';
import { $dialect, $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import { useFilteredItems } from './hooks/useFilteredItems';
import { WillySearchInput } from './WillySearchInput';
import { convertSequenceToGlobal, duplicateSequence } from './utils/sequences';
import { WillyDataSequence, Conversation } from './types/willyTypes';
import { WillySelect } from './dashboardManagment/WillySelect';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { deleteSchedule } from './utils/sequenceSchedules';
import { SequenceListItem } from './SequenceListItem';

export type SequencesListProps = {};

const userSortOptions = [
  { value: 'edited', label: 'Recently Edited' },
  { value: 'created', label: 'Recently Added' },
];

const ITEMS_PER_PAGE = 10;

export const SequencesList: React.FC<SequencesListProps> = () => {
  const { search } = useLocation();
  const isSmall = useIsSmall();
  const navigate = useNavigate();
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);

  const userFilterOptions = useMemo(() => {
    const filters = [
      { value: 'all', label: 'All' },
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
      { value: 'user', label: 'My Agents' },
    ];
    if (isTwGlobalDashboardCreatorClaim) {
      return [...filters, { value: 'global', label: 'Global' }];
    }
    return filters;
  }, [isTwGlobalDashboardCreatorClaim]);

  const currentShopId = useStoreValue($currentShopId);
  const dialect = useStoreValue($dialect);

  const params = new URLSearchParams(search);
  const defaultFilter = params.get('workflowsFilter') || userFilterOptions[0].value;
  const defaultSort = params.get('workflowsSort') || userSortOptions[0].value;
  const [deleting, setDeleting] = useState(false);
  const [userFilter, setUserFilter] = useState(defaultFilter);
  const [userSort, setUserSort] = useState(defaultSort);
  const [freeSearch, setFreeSearch] = useState('');
  const [displayCount, setDisplayCount] = useState(ITEMS_PER_PAGE);

  const globalSequences = useStoreValue($globalAndShopSequences);
  const [sequences, setSequences] = useWritableStore(
    userFilter === 'global' && isTwGlobalDashboardCreatorClaim
      ? $globalAndShopSequences
      : $shopSequences,
  );

  const filteredSequences = useFilteredItems(sequences, freeSearch, ['name', 'id'], userFilter);
  const sortedSequences = useMemo(() => {
    return filteredSequences.sort((a, b) => {
      if (userSort === 'edited') {
        const aTime = a.updatedAt
          ? a.updatedAt.seconds * 1000 + a.updatedAt.nanoseconds / 1000000
          : 0;
        const bTime = b.updatedAt
          ? b.updatedAt.seconds * 1000 + b.updatedAt.nanoseconds / 1000000
          : 0;

        if (aTime === 0) return 1;
        if (bTime === 0) return -1;

        return bTime - aTime;
      } else if (userSort === 'created') {
        const aTime = a.createdAt
          ? a.createdAt.seconds * 1000 + a.createdAt.nanoseconds / 1000000
          : 0;
        const bTime = b.createdAt
          ? b.createdAt.seconds * 1000 + b.createdAt.nanoseconds / 1000000
          : 0;

        if (aTime === 0) return 1;
        if (bTime === 0) return -1;

        return bTime - aTime;
      }
      return 0;
    });
  }, [filteredSequences, userSort]);

  const paginatedSequences = useMemo(() => {
    return sortedSequences.slice(0, displayCount);
  }, [sortedSequences, displayCount]);

  const hasMoreItems = useMemo(() => {
    return displayCount < sortedSequences.length;
  }, [displayCount, sortedSequences.length]);

  // Reset display count when filters change
  useEffect(() => {
    setDisplayCount(ITEMS_PER_PAGE);
  }, [freeSearch, userFilter, userSort]);

  const hasSearchFilterOrSort = useMemo(() => {
    return (
      freeSearch !== '' ||
      userFilter !== userFilterOptions[0].value ||
      userSort !== userSortOptions[0].value
    );
  }, [freeSearch, userFilter, userSort, userFilterOptions]);

  const updateUrlParams = useCallback(
    (key: string, value: string) => {
      const params = new URLSearchParams(search);
      params.set(key, value);

      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [navigate, search],
  );

  const handleFilterChange = (value: string) => {
    setUserFilter(value);
    updateUrlParams('workflowsFilter', value);
  };

  const handleSortChange = (value: string) => {
    setUserSort(value);
    updateUrlParams('workflowsSort', value);
  };

  const toggleSequenceActionMenu = useCallback(
    (id: string, open: boolean) => {
      setSequences((old) => {
        return old.map((x) => {
          return {
            ...x,
            actionsMenuOpen: x.id === id ? open : false,
          };
        });
      });
    },
    [setSequences],
  );

  const deleteSequences = useCallback(
    async (sequences: WillyDataSequence[]) => {
      const promises: Promise<void>[] = [];
      setDeleting(true);
      for (const sequence of sequences) {
        const ref = sequence.isGlobal
          ? firestoreRef().collection('global_data_sequences')
          : _db().collection('data_sequences');

        if (!sequence.isGlobal) {
          const runs = await ref.doc(sequence.id).collection('runs').get();
          runs.forEach((run) => {
            promises.push(run.ref.delete());
          });
        }

        if (sequence.schedule && sequence.schedule.id && currentShopId && sequence.user) {
          promises.push(
            deleteSchedule(sequence.id, sequence.schedule.id, currentShopId, sequence.user),
          );
        }

        promises.push(ref.doc(sequence.id).set({ deleted: true }, { merge: true }));
        if (sequence.globalDashboardId) {
          promises.push(
            axiosInstance.post('v2/willy/update-stats', {
              shopId: currentShopId,
              sequenceId: sequence.globalDashboardId,
              actionType: 'uninstalled',
            }),
          );
        }
      }
      await Promise.all(promises);
      setDeleting(false);
    },
    [currentShopId],
  );

  const menuItems = useCallback(
    (c: WillyDataSequence) => [
      {
        content: 'View Details',
        onAction: () => {
          navigate({
            pathname: `/workflows/${c.id}`,
          });
        },
      },
      {
        content: 'View Last Run',
        onAction: () => {
          genericEventLogger(analyticsEvents.SEQUENCES, {
            action: sequencesActions.VIEW_RUN,
            sequence_id: c.id,
            sequence_name: c.name,
            source: 'shop_list_menu',
          });

          navigate({
            pathname: `/workflows/view/${c.id}`,
          });
        },
      },
      {
        content: 'Edit Agent',
        // icon: () => <Icon name="edit" />,
        disabled: !c.canEdit,
        onAction: () => {
          navigate({
            pathname: `/workflows/create/${c.id}`,
          });
        },
      },
      {
        content: 'Rename Agent',
        // icon: () => <Icon name="refresh" />,
        disabled: !c.canEdit,
        onAction: () => {
          setSequences((old) => {
            return old.map((x) => {
              if (x.id === c.id) {
                return {
                  ...x,
                  renameMode: true,
                };
              }
              return x;
            });
          });
        },
      },
      {
        content: 'Run Agent',
        // icon: () => <Icon name="play-arrow" />,
        onAction: () => {
          const sequence = sequences.find((s) => s.id === c.id);
          if (!sequence) {
            return;
          }

          const params = new URLSearchParams(search);

          params.set('run', 'true');
          navigate({
            pathname: `/workflows/create/${sequence.id}`,
            search: params.toString(),
          });

          genericEventLogger(analyticsEvents.CHAT, {
            action: chatActions.RUN_SEQUENCE,
            id: sequence.id,
            text: sequence.name || '',
            conversationId: sequence.id,
          });
        },
      },
      {
        content: 'Duplicate Agent',
        onAction: async () => {
          const sequence = sequences.find((s) => s.id === c.id);
          if (!sequence) {
            return;
          }

          const conf = await confirm({
            title: 'Duplicate Agent?',
            message: 'This will make a copy of the current agent.',
          });
          if (!conf) return;

          const { error, success, message, conversationData } = await duplicateSequence(sequence);

          if (error) {
            toast.error(error);
          } else if (success) {
            genericEventLogger(analyticsEvents.SEQUENCES, {
              action: sequencesActions.DUPLICATE_SEQUENCE,
              sequence_id: conversationData?.id,
              sequence_name: conversationData?.name,
            });
            toast.success(message);
          }

          toggleSequenceActionMenu(c.id, false);
        },
      },
      // {
      //   content: 'Last Runs',
      //   // icon: () => <Icon name="clock" />,
      //   onAction: () => {
      //     setOpened(false);
      //     setRunsOpen(c.id);
      //   },
      // },
      ...(isTwGlobalDashboardCreatorClaim && !c.isGlobal
        ? [
            {
              content: 'Turn Into Template',
              // icon: () => <Icon name="global" />,
              onAction: async () => {
                const subSequenceIds = c.steps
                  .filter((step) => step.stepType === 'subSequence')
                  .flatMap((step) => step.sequenceIds || []);

                const subSequences = subSequenceIds
                  .map((id) => globalSequences.find((s) => s.id === id))
                  .filter((x) => !!x);

                const conf = await confirm({
                  title: 'Turn Agent into template?',
                  message: `This will make a copy of this agent${
                    subSequences.length > 0 ? ` and ${subSequences.length} sub-agents` : ''
                  }, and publish${subSequences.length > 0 ? ' them' : ' it'} to the template library.`,
                });
                if (!conf || !currentShopId) {
                  return;
                }

                const promises: Promise<{
                  error: string | undefined;
                  success: boolean | undefined;
                  message: string | undefined;
                  conversationData: Conversation | null | undefined;
                }>[] = subSequences.map((s) => convertSequenceToGlobal(s, currentShopId, dialect));

                const res = await Promise.all(promises);

                const { error, success, message, conversationData } = await convertSequenceToGlobal(
                  c,
                  currentShopId,
                  dialect,
                );

                const hasSubError = res.some((x) => !!x.error);
                const allSuccess = res.every((x) => !!x.success);

                if (error || hasSubError) {
                  toast.error(error || hasSubError);
                } else if (allSuccess && success) {
                  genericEventLogger(analyticsEvents.SEQUENCES, {
                    action: sequencesActions.CREATE_SEQUENCE,
                    sequence_id: conversationData?.id,
                    sequence_name: conversationData?.title,
                  });
                  toast.success(message);
                }

                toggleSequenceActionMenu(c.id, false);
              },
            },
          ]
        : []),
    ],
    [
      isTwGlobalDashboardCreatorClaim,
      navigate,
      setSequences,
      sequences,
      search,
      toggleSequenceActionMenu,
      currentShopId,
      dialect,
      globalSequences,
    ],
  );

  const scheduleText = useCallback((c: WillyDataSequence) => {
    if (!c.schedule) return '-';

    if (c.schedule.status === 'running') return 'Running';
    if (c.schedule.status === 'paused') return 'Paused';
    if (c.schedule.status === 'scheduled') {
      const now = moment();

      // Handle interval-based schedules
      if (c.schedule.interval) {
        const intervalMatch = c.schedule.interval.match(/(\d+)([mhd])/);
        if (intervalMatch) {
          const [_, amount, unit] = intervalMatch;
          const nextRun = moment();

          // Convert interval to minutes for calculation
          const intervalMinutes =
            unit === 'm'
              ? Number(amount)
              : unit === 'h'
                ? Number(amount) * 60
                : Number(amount) * 24 * 60;

          // Calculate minutes since midnight
          const minutesSinceMidnight = now.hours() * 60 + now.minutes();
          // Calculate intervals passed today
          const intervalsPassed = Math.floor(minutesSinceMidnight / intervalMinutes);
          // Calculate next interval
          const nextIntervalMinutes = (intervalsPassed + 1) * intervalMinutes;

          nextRun.startOf('day').add(nextIntervalMinutes, 'minutes');

          // If next run is in the past, add one more interval
          if (nextRun.isBefore(now)) {
            nextRun.add(intervalMinutes, 'minutes');
          }

          return `${nextRun.format('MMM D, h:mm A')}`;
        }
        return '-';
      }

      // Original day/hour based schedule logic
      const nextRun = moment();
      const daysOfWeek = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ];

      // Find the next scheduled day
      let daysUntilNext = 7;
      if (!!c?.schedule?.days) {
        for (const day of c?.schedule?.days) {
          const dayIndex = daysOfWeek.indexOf(day.toLowerCase());
          const daysUntil = (dayIndex - now.day() + 7) % 7;
          if (daysUntil < daysUntilNext) {
            daysUntilNext = daysUntil;
          }
        }
      }

      nextRun.add(daysUntilNext, 'days');

      // Set the hour for the next run
      const scheduledHours = c?.schedule?.hours?.map(Number) || [];
      scheduledHours.sort((a, b) => a - b);

      let nextHour = scheduledHours[0] || 0;
      for (const hour of scheduledHours) {
        if (daysUntilNext === 0 && hour > now.hour()) {
          nextHour = hour;
          break;
        }
      }

      nextRun.hour(nextHour).minute(0).second(0);

      // If nextRun is in the past, find the next available hour
      if (nextRun.isBefore(now)) {
        const nextHourIndex = scheduledHours.find((hour) => hour > now.hour());
        if (nextHourIndex !== undefined) {
          nextRun.hour(nextHourIndex).minute(0).second(0);
        } else {
          // If no future hour today, move to the first hour of the next scheduled day
          nextRun.add(1, 'days').hour(scheduledHours[0]).minute(0).second(0);
        }
      }

      return `${nextRun.format('MMM D, h:mm A')}`;
    }

    return '-';
  }, []);

  const scheduleDate = useCallback((c: WillyDataSequence) => {
    const date = c.updatedAt ?? c.createdAt;
    return moment(date?.seconds * 1000 + date?.nanoseconds / 1000000)?.fromNow();
  }, []);

  const [bulkActionItems, setBulkActionItems] = useState<WillyDataSequence[]>([]);

  const loadMoreRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasMoreItems) {
          setDisplayCount((prev) => prev + ITEMS_PER_PAGE);
        }
      },
      {
        root: scrollContainerRef.current,
        threshold: 0.1,
      },
    );

    const currentRef = loadMoreRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [hasMoreItems]);

  return (
    <div
      ref={scrollContainerRef}
      className="flex flex-col overflow-hidden h-full xl:max-w-[85%] m-auto"
    >
      <div className={`p-8 pb-0 flex gap-4 w-full`}>
        <div className="w-full flex gap-6.5">
          <div className="w-full flex items-center gap-4">
            <WillySearchInput
              value={freeSearch}
              onChange={(v) => setFreeSearch(v)}
              placeholder="Search Agents"
              className="!p-0 overflow-visible w-full"
            />
            {hasSearchFilterOrSort && (
              <div className="flex-shrink-0">
                <Button
                  variant="white"
                  onClick={() => {
                    setFreeSearch('');
                    handleFilterChange(userFilterOptions[0].value);
                    handleSortChange(userSortOptions[0].value);
                  }}
                >
                  Clear
                </Button>
              </div>
            )}
          </div>
          <div className="flex items-center justify-between flex-shrink-0 gap-4">
            <WillySelect
              data={userFilterOptions.map((item) => ({ value: item.value, label: item.label }))}
              value={userFilter}
              onChange={handleFilterChange}
              targetClassName="mr-4"
            />
            <WillySelect
              data={userSortOptions.map((item) => ({ value: item.value, label: item.label }))}
              value={userSort}
              onChange={handleSortChange}
            />
          </div>
        </div>
      </div>
      <div></div>

      <div className={`overflow-auto h-full`}>
        <div className={'w-full min-w-[768px]'}>
          <div className="grid grid-cols-[minmax(auto,40%)_1fr] gap-4 w-full pt-14 px-8 ">
            <div className="flex items-center justify-between h-10">
              {bulkActionItems.length > 0 && (
                <Button
                  loading={deleting}
                  leftSection="trash"
                  variant="white"
                  size="xs"
                  onClick={async () => {
                    const conf = await confirm({
                      title: 'Delete selected agents?',
                      message: 'This action cannot be undone',
                    });
                    if (!conf) return;
                    await deleteSequences(bulkActionItems);
                    setBulkActionItems([]);
                  }}
                >
                  Delete Selected
                </Button>
              )}
            </div>
            <div className="grid grid-cols-4 gap-4">
              <Text color="gray.5" fw="500" size="sm">
                Active
              </Text>
              <Text color="gray.5" fw="500" size="sm">
                Scheduled
              </Text>
              <Text color="gray.5" fw="500" size="sm">
                Last Edited
              </Text>
              <div className="text-right">
                <Text color="gray.5" fw="500" size="sm">
                  Actions
                </Text>
              </div>
            </div>
          </div>
          <div className={`p-8 flex flex-col gap-6 w-full`}>
            {sortedSequences.length === 0 && (
              <div className="p-4">
                <p>No agents found</p>
              </div>
            )}
            {sortedSequences.length > 0 && (
              <>
                {paginatedSequences.map((sequence, i) => (
                  <SequenceListItem
                    key={i}
                    sequence={sequence}
                    isTwGlobalDashboardCreatorClaim={isTwGlobalDashboardCreatorClaim}
                    bulkActionItems={bulkActionItems}
                    setBulkActionItems={setBulkActionItems}
                    setSequences={setSequences}
                    toggleSequenceActionMenu={toggleSequenceActionMenu}
                    deleteSequences={deleteSequences}
                    menuItems={menuItems}
                    scheduleText={scheduleText}
                    scheduleDate={scheduleDate}
                  />
                ))}

                {hasMoreItems && (
                  <div ref={loadMoreRef} className="flex items-center justify-center h-12">
                    <Loader size="sm" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
