import { useState, useEffect, useMemo, useRef } from 'react';
import { useDarkMode } from 'dark-mode-control';
import { IconName, Popover, Size, Text, Tooltip, useDisclosure } from '@tw/ui-components';
import { cx } from 'utils/cx';
import { useDropdownLabel } from './hooks/useDropdownLabel';
import { DropdownActivator } from './DropdownActivator';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';
import { DropdownFilter } from './DropdownFilter';
import { DropdownOption, DropdownSection, ExpandableDropdownOption } from './types';
import { ExpandableOptionList } from './ExpandableOptionList';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectHasSomePpsInstalled } from 'utils/selectors';
import { useDropdownIcon } from './hooks/useDropdownIcon';

export type AttributionMultipaneDropdownProps = {
  /** Allow user to select multiple choices. */
  allowMultiple?: boolean;
  closeOnSelect?: boolean;
  /** When a custom activator is used, the `activatorIcon` prop has no effect.  */
  customActivator?: (label: string | React.ReactNode) => React.ReactNode;
  disabled?: boolean;
  /** Value in callback can either be a string or array of strings depending on if `allowMultiple` is true. */
  handleSelect?: (value: string | string[]) => any;
  /** Determines whether or not to show a text field to filter options. */
  hasFilter?: boolean;
  /** If provided, a activator gets wrapped in a tooltip. */
  hint?: string;
  id?: string;
  loading?: boolean;
  openController?: {
    opened: boolean;
    toggle: (isOpen?: boolean) => void;
  };
  onChange?: (value: any) => void;
  options?: ExpandableDropdownOption[];
  sections?: DropdownSection[];
  theme?: 'light' | 'dark' | 'none';
  /** Title shown on default activator component. */
  title?: string;
  /** Selected value. */
  value: string; // must be a string to match Polaris option
  zIndex?: number;
  activatorSize?: Exclude<Size, 0>;
};

export const AttributionMultipaneDropdown: React.FC<AttributionMultipaneDropdownProps> = ({
  allowMultiple = false,
  customActivator,
  closeOnSelect = true,
  disabled = false,
  hint = '',
  handleSelect,
  hasFilter = false,
  id,
  loading = false,
  onChange,
  openController,
  options = [],
  sections,
  title = '',
  theme = 'dark',
  value,
  zIndex,
  activatorSize,
}) => {
  //
  // STATE
  //
  const [filter, setFilter] = useState('');
  const darkMode = useDarkMode();
  const [_popoverActive, { close, toggle }] = useDisclosure(false);
  const label = useDropdownLabel({ options, sections, value });
  const icon = useDropdownIcon({ options, sections, value });
  const hasPpsInstalled = useSelector(selectHasSomePpsInstalled);
  const popoverActive = openController?.opened ?? _popoverActive;

  //
  // DERIVED STATE
  //

  const formattedFilter = filter.toLowerCase().trim();

  const filteredOptions = useMemo(() => {
    let _options = options;

    if (!hasPpsInstalled) {
      _options = _options.map((x) => {
        if (x.id === 'Total Impact') {
          return {
            ...x,
            disabled: true,
            label: (
              <div className="flex flex-col gap-1">
                <span>{x.label}</span>
                <Text size="xs" weight={500}>
                  <span>PPS is not installed.</span>{' '}
                  <Link to="/post-purchase-survey" target="_blank">
                    Install Now
                  </Link>
                </Text>
              </div>
            ),
          };
        }
        return x;
      });
    }

    if (!hasFilter || !formattedFilter.length) {
      return _options;
    }
    return _options?.filter(buildOptionsFilter(formattedFilter));
  }, [options, formattedFilter, hasFilter, hasPpsInstalled]);

  const sectionsWithFilteredOptions = useMemo(() => {
    if (!hasFilter || !formattedFilter.length) return sections;
    return sections?.map(({ options, ...x }) => ({
      ...x,
      options: options.filter(buildOptionsFilter(formattedFilter)),
    }));
  }, [sections, formattedFilter, hasFilter]);

  //
  // HANDLERS
  //
  const handleClose = () => {
    if (openController) {
      openController.toggle(false);
    } else {
      close();
    }
  };

  const handleChange = (selected: string[]) => {
    if (closeOnSelect) handleClose();
    return handleSelect?.(allowMultiple ? selected : selected[0]);
  };

  //
  // EFFECTS
  //
  useEffect(() => {
    onChange?.(popoverActive);
  }, [popoverActive, onChange]);

  //
  // VIEW
  //
  const activator = useMemo(
    () => (
      <ConditionalWrapper condition={!!hint} wrapper={(x) => <Tooltip label={hint}>{x}</Tooltip>}>
        <DropdownActivator
          active={popoverActive}
          disabled={disabled}
          modelIcon={icon}
          customActivator={customActivator ? (label) => customActivator?.(label) : undefined}
          title={title}
          loading={loading}
          toggle={() => openController?.toggle() ?? toggle()}
          label={label}
          size={activatorSize}
        />
      </ConditionalWrapper>
    ),
    [
      hint,
      disabled,
      icon,
      customActivator,
      title,
      loading,
      toggle,
      label,
      activatorSize,
      popoverActive,
      openController,
    ],
  );

  return (
    <div
      id={id}
      className={cx('tw-nice', {
        'tw-nice-light': theme === 'light',
        'tw-nice-dark': theme === 'dark' && darkMode,
        'tw-nice--hasValue': value !== undefined,
      })}
    >
      <Popover
        zIndex={zIndex}
        shadow="sm"
        opened={popoverActive}
        onClose={handleClose}
        middlewares={{
          size: {
            apply({ availableHeight, elements }) {
              elements.floating.style.maxHeight = `${availableHeight}px`;
            },
          },
        }}
        position="bottom"
      >
        <Popover.Target>{activator}</Popover.Target>
        <Popover.Dropdown fz="sm" p={0} h="500px" maw="90vw" overflow="scroll" bg="white">
          {hasFilter && <DropdownFilter filter={filter} setFilter={setFilter} />}
          <ExpandableOptionList
            allowMultiple={allowMultiple}
            options={filteredOptions}
            value={value}
            handleChange={handleChange}
            sections={sectionsWithFilteredOptions}
          />
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

//
// UTILS
//
function buildOptionsFilter(filter: string) {
  return ({ label, value }: DropdownOption) => {
    const _label = typeof label === 'string' ? label : '';
    return _label.toLowerCase().includes(filter) || value.toLowerCase().includes(filter);
  };
}
