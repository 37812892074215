import { BaseSummaryMetric, SummaryMetricIdsTypes } from '@tw/types/module/SummaryMetrics';
import {
  CustomMetricsType,
  initializeCustomMetricModal,
  selectCustomMetricsForType,
} from 'ducks/customMetrics';
import { getTileValue } from 'ducks/summary';
import { formatNumber } from './formatNumber';
import { Tooltip } from '@shopify/polaris';
import { QuestionMarkMinor } from '@shopify/polaris-icons';
import { metrics } from '@tw/constants/module/Metrics/allMetrics';
import { TableCellLoader } from 'components/library/Loaders/TableCellLoader';
import { createSelector } from 'reselect';
import { MetricComparison } from 'components/attribution-new/columns';
import { PixelColumn } from 'types/attribution';

export const getCustomMetricsFromRowData = (customMetrics, rowData) => {
  return customMetrics.reduce((acc, customMetric) => {
    const originalMetric: BaseSummaryMetric<any> = {
      id: customMetric.id,
      title: customMetric.title,
      color: '#7D4DFF',
      valueToFixed: 0,
      type: customMetric.metricType,
      metricId: 'customMetricsAttribution',
      tip: customMetric.description,
      services: [],
      isDynamicMetric: true,
      statObjectKey: customMetric.id as SummaryMetricIdsTypes,
      isCustomMetric: true,
      chart: 'customMetricsCharts',
      icon: 'tripleWhale',
    };

    const value = getTileValue(
      { ...rowData, customMetrics: { metricsAttribution: customMetrics } as any },
      originalMetric,
    );

    return {
      ...acc,
      [customMetric.id]: value,
    };
  }, {});
};

export const selectCustomMetricsColumns = (
  customMetricsType: CustomMetricsType,
  sortable = true,
) => {
  return createSelector([selectCustomMetricsForType(customMetricsType)], (customMetrics: any) => {
    return (customMetrics || []).map((customMetric: any) => {
      return {
        id: customMetric.id,
        metricId: 'customMetrics',
        statObjectKey: customMetric.id,
        key: customMetric.id,
        name: customMetric.title,
        dataType: 'numeric',
        sortable: sortable,
        isDefault: false,
        source: customMetric?.source,
        onEdit: () => initializeCustomMetricModal(customMetricsType, customMetric.id),
        Heading: () => (
          <div className="flex items-center gap-4 font-medium justify-center w-full">
            <span>{customMetric.title}</span>
            <span className="absolute left-0 top-0">
              {customMetric.description && (
                <Tooltip content={customMetric.description}>
                  <span>
                    <QuestionMarkMinor width={14} height={14} className="opacity-50" />
                  </span>
                </Tooltip>
              )}
            </span>
          </div>
        ),
        Value: (row, metadata) => {
          const rowData = { ...row, ...(row.metrics || {}) }; // support case where metrics are nested inside the row
          const {
            loadingColumn,
            currency = 'USD',
            loadingAttributionComparisons,
            showComparisons,
          } = metadata;

          const value = rowData[customMetric.id];
          const prevPeriod = rowData.comparisons || {};
          const prevValue = prevPeriod[customMetric.id];

          const formatedValue = formatCustomMetricValue(value, customMetric, currency);

          return (
            <div className="px-4 py-2 flex items-center h-full attribution-table-campaign-row">
              <div className="flex gap-4 items-center justify-center w-full">
                {loadingColumn ? (
                  <TableCellLoader />
                ) : (
                  <>
                    <span>{formatedValue}</span>
                    {showComparisons && (
                      <MetricComparison
                        value={value}
                        metric={customMetric}
                        prevValue={prevValue}
                        currency={currency}
                        isLoading={!!loadingAttributionComparisons}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          );
        },
        Total: (totals, metadata) => {
          const totalRowsData = (totals || []).map((row) => ({ ...row, ...(row.metrics || {}) })); // support case where metrics are nested inside the row
          const { loadingColumn, currency = 'USD' } = metadata || {};
          const isAvg = customMetric.metricType === '%';

          const sumValue = totalRowsData.reduce(
            (acc, curr) => acc + (curr[customMetric.id] || 0),
            0,
          );
          let value = isAvg ? sumValue / totalRowsData.length : sumValue;
          const isDivision = customMetric.expression?.includes('/');
          if (isDivision) {
            try {
              const [hashValue1, hashValue2] = customMetric.expression.split('/');
              const value1 = customMetric.stats[hashValue1].value;
              const value2 = customMetric.stats[hashValue2].value;
              const totalValue1 = totalRowsData.reduce((acc, curr) => acc + (curr[value1] || 0), 0);
              const totalValue2 = totalRowsData.reduce((acc, curr) => acc + (curr[value2] || 0), 0);
              value = (totalValue1 / totalValue2) * 100;
            } catch (e) {
              console.error(e);
            }
          }

          const formatedValue = formatCustomMetricValue(value, customMetric, currency);

          return (
            <div className={'px-4 py-2 flex items-center justify-center '}>
              {loadingColumn ? (
                <TableCellLoader />
              ) : (
                <p className="text-inherit">{formatedValue}</p>
              )}
            </div>
          );
        },
      };
    });
  });
};

export function formatCustomMetricValue(value, customMetric, currency) {
  const firstMetric = Object.values(customMetric.stats)[0] as { value: string };
  const firstMetricValue = firstMetric?.value ?? value;
  const originalFirstMetric = Object.values(metrics).find((m) => m.key === firstMetricValue);

  value = customMetric.metricType === '%' ? value / 100 : value;

  return formatNumber(value, {
    style:
      customMetric.metricType === '$'
        ? 'currency'
        : customMetric.metricType === '%'
          ? 'percent'
          : 'decimal',
    currency,
    minimumFractionDigits: originalFirstMetric?.toFixed ?? 2,
    maximumFractionDigits: originalFirstMetric?.toFixed ?? 2,
  });
}
