import { Button, Checkbox, Loader, Select, Table, Text, TextInput } from '@tw/ui-components';
import { AgentCheckoutItem } from './AgentsLibraryCollection';
import { useStoreValue } from '@tw/snipestate';
import {
  $defaultAiColumns,
  $globalAndShopSequences,
  $globalSequences,
  $shopSequences,
} from '$stores/willy/$sequences';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { $combinedDashboard } from '$stores/willy/$combinedDashboards';
import { $combineShopAndCustomViewDashboards } from '$stores/willy/$customViews';
import { Interval, INTERVAL_OPTIONS } from '../constants';
import { calculateAgentUsage } from 'pages/Usage/AgentUsageCalculator';
import { copyGlobalSequenceToShop } from '../utils/willyUtils';
import { analyticsEvents, genericEventLogger, templateLibraryActions } from 'utils/dataLayer';
import { useNavigate } from 'react-router-dom';
import { DayOfWeek } from '../types/willyTypes';
import { CustomScheduleModal } from './CustomScheduleModal';

type AgentScheduleCalculate = {
  agentId: string;
  interval: Interval | null;
  entities: string;
  hours: number[];
  days: DayOfWeek[];
};

export const Checkout: React.FC<{ agentIds: AgentCheckoutItem[] }> = ({ agentIds }) => {
  const navigate = useNavigate();
  const costPerQuery = 0.1;

  const globalSequences = useStoreValue($globalAndShopSequences);
  const shopSequences = useStoreValue($shopSequences);
  const dashboards = useStoreValue($combinedDashboard);
  const shopAndViewDashboards = useStoreValue($combineShopAndCustomViewDashboards);

  const [selectedAgentIds, setSeletedAgentIds] = useState<AgentCheckoutItem[]>(agentIds);
  const [loadingAddingAgents, setLoadingAddingAgents] = useState(false);

  const [agentSchedules, setAgentSchedules] = useState<AgentScheduleCalculate[]>([]);
  const [customScheduleOpen, setCustomScheduleOpen] = useState(false);

  const [customSchedule, setCustomSchedule] = useState<{
    hours: number[];
    days: DayOfWeek[];
    agentId: string;
  }>({
    days: [],
    hours: [],
    agentId: '',
  });

  useEffect(() => {
    if (!agentSchedules.length) {
      const schedules: AgentScheduleCalculate[] = agentIds.map((agent) => {
        return {
          agentId: agent.id,
          interval: '1d',
          entities: '10',
          days: [],
          hours: [],
        };
      });
      setAgentSchedules(schedules);
    }
  }, [agentIds, agentSchedules.length]);

  const agentPrices = useMemo(() => {
    return agentIds.map((agent) => {
      const agentData = globalSequences.find((seq) => seq.id === agent.id);
      const schedule = agentSchedules.find((sch) => sch.agentId === agent.id);
      const price =
        agentData && schedule
          ? calculateAgentUsage(
              agentData,
              [...dashboards, ...shopAndViewDashboards],
              [...globalSequences, ...shopSequences],
              parseInt(!!schedule.entities ? schedule.entities : '0'),
              { interval: schedule.interval, hours: schedule.hours, days: schedule.days },
            )
          : 0;
      return { agentId: agent.id, price: price };
    });
  }, [agentSchedules, globalSequences, dashboards, shopAndViewDashboards, shopSequences, agentIds]);

  const toggleFromSelected = (id) => {
    setSeletedAgentIds((prev) => {
      const exists = prev.some((item) => item.id === id);
      return exists ? prev.filter((item) => item.id !== id) : [...prev, { id, type: 'agent' }];
    });
  };

  const totalPrice = useMemo(
    () =>
      selectedAgentIds.reduce((total, agentId) => {
        const agentPrice =
          (agentPrices.find((sch) => sch.agentId === agentId.id)?.price ?? 0) * costPerQuery;
        return total + agentPrice;
      }, 0),
    [selectedAgentIds, agentPrices],
  );

  const addSelectedAgents = useCallback(async () => {
    setLoadingAddingAgents(true);
    await Promise.all(
      selectedAgentIds.map(async (agent) => {
        const schedule = agentSchedules.find((sch) => sch.agentId === agent.id);
        const newId = await copyGlobalSequenceToShop(agent.id, schedule);
        genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
          action: templateLibraryActions.ADD_TEMPLATE_TO_WORKSPACE,
          sequence_id: agent.id,
        });
      }),
    );
    setLoadingAddingAgents(false);
    navigate(`/workflows`);
  }, [agentSchedules, selectedAgentIds, navigate]);

  const onSelectInterval = (value, agentId, schedule) => {
    if (value === 'custom') {
      setCustomScheduleOpen(true);
      setCustomSchedule({ days: schedule.days, hours: schedule.hours, agentId: agentId });
    } else {
      setAgentSchedules((prev) => {
        const newSchedules = [...prev];
        const sheduleToChangeIndex = newSchedules.findIndex((sch) => sch.agentId === agentId);
        if (sheduleToChangeIndex === -1) {
          return prev;
        }
        newSchedules[sheduleToChangeIndex] = {
          ...newSchedules[sheduleToChangeIndex],
          interval: value as Interval,
          hours: [],
          days: [],
        };
        return newSchedules;
      });
    }
  };

  const onSaveCustomSchedule = (hours, days) => {
    setAgentSchedules((prev) => {
      const newSchedules = [...prev];
      const sheduleToChangeIndex = newSchedules.findIndex(
        (sch) => sch.agentId === customSchedule.agentId,
      );
      if (sheduleToChangeIndex === -1) {
        return prev;
      }
      newSchedules[sheduleToChangeIndex] = {
        ...newSchedules[sheduleToChangeIndex],
        hours: hours,
        days: days,
        interval: null,
      };
      return newSchedules;
    });
    closeCustomScheduleModal();
  };

  const closeCustomScheduleModal = () => {
    setCustomScheduleOpen(false);
    setCustomSchedule({ days: [], hours: [], agentId: '' });
  };

  return (
    <div className="flex flex-col gap-10">
      <Text fz={22} fw={600}>
        Agent Estimated Costs
      </Text>
      <table className="min-w-full  rounded-lg">
        <thead className="py-5">
          <tr>
            <th className="px-4 py-2 text-left text-gray-600"></th>
            <th className="px-4 py-2 text-left">
              <Text fw={600}>Agent Name</Text>
            </th>
            <th className="px-4 py-2 text-left text-gray-600">
              <Text fw={600}>Frequency</Text>
            </th>
            <th className="px-4 py-2 text-left text-gray-600">
              <Text fw={600}>Entities</Text>
            </th>
            <th className="px-4 py-2 text-left text-gray-600">
              <Text fw={600}>Price</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {agentIds.map((agent, index) => {
            const includedInTotal = selectedAgentIds.some((item) => item.id === agent.id);
            const agentData = globalSequences.find((seq) => seq.id === agent.id);
            const schedule = agentSchedules.find((sch) => sch.agentId === agent.id);
            const price = agentPrices.find((sch) => sch.agentId === agent.id)?.price ?? 0;

            return agentData && schedule ? (
              <tr key={agent.id} className={`py-5`}>
                <td>
                  <Checkbox
                    checked={includedInTotal}
                    onChange={() => toggleFromSelected(agent.id)}
                  />
                </td>
                <td className="px-4 py-2">
                  <Text fw={500}>{agentData.name}</Text>
                </td>
                <td className="px-4 py-2">
                  <Select
                    data={[
                      {
                        label: '-',
                        value: '',
                      },
                      ...Object.values(INTERVAL_OPTIONS).map((option) => ({
                        label: option.label,
                        value: option.value,
                      })),
                      ...(schedule.hours.length && schedule.days.length
                        ? [
                            {
                              label: schedule.days
                                .map(
                                  (day) => day.charAt(0).toUpperCase() + day.slice(1).toLowerCase(),
                                )
                                .join(', '),
                              value: 'setCustom',
                            },
                          ]
                        : []),
                      {
                        label: 'Custom...',
                        value: 'custom',
                      },
                    ]}
                    onChange={(value) => {
                      onSelectInterval(value, agent.id, schedule);
                    }}
                    value={
                      !!schedule.hours.length && !!schedule.days.length
                        ? 'setCustom'
                        : schedule.interval
                    }
                  />
                </td>
                <td className="px-4 py-2">
                  <TextInput
                    value={schedule.entities}
                    onChange={(value) => {
                      if (!/^\d*$/.test(value)) return;

                      setAgentSchedules((prev) => {
                        const newSchedules = [...prev];
                        const sheduleToChangeIndex = newSchedules.findIndex(
                          (sch) => sch.agentId === agent.id,
                        );
                        if (sheduleToChangeIndex === -1) {
                          return prev;
                        }
                        newSchedules[sheduleToChangeIndex] = {
                          ...newSchedules[sheduleToChangeIndex],
                          entities: value,
                        };
                        return newSchedules;
                      });
                    }}
                  />
                </td>
                <td className="px-4 py-2">
                  {(price * costPerQuery).toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
            ) : null;
          })}
        </tbody>
      </table>
      <div className="flex justify-end">
        <div className="flex flex-col items-end gap-0">
          <Text fw="700" fz="28">
            Estimated Cost:{' '}
            {totalPrice.toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
          </Text>
          {/* <div className="font-medium text-[16px] text-gray-700">(approx.)</div> */}
        </div>
      </div>
      <div className="flex justify-end">
        <Button onClick={() => addSelectedAgents()} disabled={loadingAddingAgents}>
          Add All
        </Button>
        {loadingAddingAgents && <Loader />}
      </div>
      <CustomScheduleModal
        open={customScheduleOpen}
        onClose={() => closeCustomScheduleModal()}
        days={customSchedule.days}
        hours={customSchedule.hours}
        onSave={(hours, days) => onSaveCustomSchedule(hours, days)}
      />
    </div>
  );
};
