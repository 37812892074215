import React, { useEffect, useState } from 'react';

import { TIME_UNIT_OPTIONS } from './constants';
import { getFilterQueryLabel } from './queryTranslation';
import { FilterTimeUnit } from '@tw/types/module/services/insights';
import { Select } from '@tw/ui-components';

type Props = {
  value: FilterTimeUnit;
  handleSelect: (value: FilterTimeUnit) => void;
  isReadonly?: boolean;
};
const TimeUnitDropDown: React.FC<Props> = (props) => {
  const [value, setValue] = useState<FilterTimeUnit>(props.value);

  useEffect(() => {
    props.handleSelect(value);
  }, [value]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Select
      w="100px"
      data={TIME_UNIT_OPTIONS.map((o) => ({ label: getFilterQueryLabel(o), value: o }))}
      value={value}
      onChange={(val) => setValue(val as FilterTimeUnit)}
      rightSection={props.isReadonly ? <></> : undefined}
    />
  );
};

export default TimeUnitDropDown;
