import { Skeleton, Text } from '@tw/ui-components';
import { WillyUserAvatar } from './WillyUserAvatar';
import { useMemo } from 'react';
import { $shopUsers } from '$stores/$users';
import { useStoreValue } from '@tw/snipestate';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

type UserAndDateProps = {
  user: string;
  date: string | null | undefined;
  forceSmallVersion?: boolean;
};
export const UserAndDate: React.FC<UserAndDateProps> = ({ user, date, forceSmallVersion }) => {
  const { data: shopUsers, loading: loadingUsers } = useStoreValue($shopUsers);
  const isSmall = useIsSmall();
  const useSmallVersion = forceSmallVersion || isSmall;

  const shopUsersMap = useMemo(() => {
    return shopUsers?.reduce(
      (acc, user) => {
        acc[user.id] = user.name;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [shopUsers]);
  const userToDisplay = shopUsersMap?.[user] || '';

  return (
    <div className={`flex items-center ${useSmallVersion ? 'gap-2' : 'gap-4'}`}>
      <div className={`${useSmallVersion ? 'hidden' : 'block'}`}>
        <WillyUserAvatar userId={user} userName={user} size="small" round={true} />
      </div>
      <div className="flex flex-col gap-0 leading-snug">
        {loadingUsers && <Skeleton height="16px" width="100px" />}
        {!loadingUsers && (
          <span className="line-clamp-1 text-[12px] no-underline text-black overflow-hidden">
            {userToDisplay || 'External user'}
          </span>
        )}

        {!!date && (
          <span className="line-clamp-2 overflow-hidden text-[12px] text-gray-500 whitespace-nowrap">
            {date}
          </span>
        )}
      </div>
    </div>
  );
};
