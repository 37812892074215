import { Checkbox, Modal, Select } from '@tw/ui-components';
import { WorkflowStepInsights } from '../types/willyTypes';
import { $isAdminClaim } from '$stores/$user';
import { useStoreValue } from '@tw/snipestate';

type InsightsOutputSettingsModalProps = {
  opened: boolean;
  onClose: () => void;
  step: WorkflowStepInsights;
  stepChange: (step: WorkflowStepInsights) => void;
  readOnly: boolean;
};

export const InsightsOutputSettingsModal: React.FC<InsightsOutputSettingsModalProps> = ({
  opened,
  onClose,
  step,
  stepChange,
  readOnly,
}) => {
  const isAdmin = useStoreValue($isAdminClaim);

  return (
    <Modal opened={opened} onClose={onClose} title="Output Settings">
      <Modal.Body>
        <Select
          data={[
            { label: 'Auto', value: '' },
            { label: 'o3 Mini', value: 'o3-mini' },
            {
              label: 'TW Model',
              value: 'ft:gpt-4o-mini-2024-07-18:triple-whale:agents-0115:AqAu4HZj',
            },
            { label: 'GPT-4o', value: 'gpt-4o-2024-11-20' },
            { label: 'Gemini 1.5 Pro', value: 'gemini-1.5-pro' },
            ...(isAdmin
              ? [
                  { label: 'Cerebras', value: 'llama-3.3-70b' },
                  { label: 'Claude 3.5 Sonnet', value: 'claude-3-5-sonnet-20241022' },
                  {
                    label: 'DeepSeek Reasoner',
                    value: 'deepseek-reasoner',
                  },
                  {
                    label: 'DeepSeek Chat',
                    value: 'deepseek-chat',
                  },
                ]
              : []),
          ]}
          label={'Model'}
          disabled={readOnly}
          value={step.model}
          onChange={(value) => {
            stepChange({
              ...step,
              model: value,
            });
          }}
        />
        <Select
          data={[
            { label: 'Text', value: 'text' },
            { label: 'Rich Text', value: 'rich_text' },
          ]}
          label="Output"
          disabled={readOnly}
          value={step.outputType}
          onChange={(value) =>
            stepChange({
              ...step,
              outputType: value as 'text' | 'rich_text' | null,
            })
          }
        />
        {step.outputType === 'rich_text' && (
          <Checkbox
            label="Add extra data"
            checked={step.addExtraData}
            onChange={() => {
              stepChange({
                ...step,
                addExtraData: !step.addExtraData,
              });
            }}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
