import React, { useState } from 'react';
import { CDPRFMFilter, type CDPSegmentFilter, RFM_PROPERTIES } from '@tw/types/module/services/cdp';
import { getFilterQueryLabel } from 'components/Insights/Filters/queryTranslation';
import FilterComparator from 'components/Insights/Filters/FilterComparator';
import { FilterPropertyType } from '@tw/types/module/services/insights';
import { Button, Select } from '@tw/ui-components';

type Props = {
  filter: CDPRFMFilter;
  onChanged: (filter: CDPSegmentFilter) => void;
  isReadonly?: boolean;
};
const RFMFilter: React.FC<Props> = (props) => {
  const propertiesOptions = RFM_PROPERTIES;

  const [filter, setFilter] = useState<CDPRFMFilter>(props.filter);

  return (
    <React.Fragment>
      {filter.definition.properties.map((property, i) => {
        const isLast = i === filter.definition.properties.length - 1;
        return (
          <>
            <Select
              data={propertiesOptions.map((o) => ({ label: getFilterQueryLabel(o), value: o }))}
              value={property.property}
              onChange={() => {}}
              rightSection={props.isReadonly ? <></> : undefined}
            />
            {property.property && (
              <FilterComparator
                comparatorType={FilterPropertyType.LIST}
                comparator={property.comparator}
                value={property.value}
                property={property.property}
                onChanged={() => {}}
                isReadonly={props.isReadonly}
              ></FilterComparator>
            )}
            {!isLast && <Button variant="activator">AND</Button>}
          </>
        );
      })}
    </React.Fragment>
  );
};

export default RFMFilter;
