import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField } from '@shopify/polaris';
import {
  FilterProperty,
  FilterPropertyType,
  FilterComparator as FilterComparatorType,
} from '@tw/types/module/services/insights';
import moment from '@tw/moment-cached';
import PropertyAutoComplete from './PropertyAutoComplete';
import {
  getComparatorsByType,
  getPropertyType,
  isAutoComplete,
  isMultipleValuesComparator,
} from './constants';
import { getFilterQueryLabel } from './queryTranslation';
import TimeUnitDropDown from './TimeUnitDropDown';
import { NumberInput, Select, Text, TextInput } from '@tw/ui-components';

export type FilterComparatorChanged = {
  comparator: FilterComparatorType;
  value?: any;
  value1: any;
  value2?: any;
  unit?: any;
};

type Props = {
  id?: string;
  comparatorType: FilterPropertyType;
  comparator: FilterComparatorType;
  value?: any;
  value1?: any;
  value2?: any;
  unit?: any;
  property?: FilterProperty;
  onChanged: (FilterComparatorChanged) => void;
  availableComparators?: FilterComparatorType[];
  isReadonly?: boolean;
  required?: boolean;
};
const FilterComparator: React.FC<Props> = (props) => {
  const [comparator, setComparator] = useState<FilterComparatorType>(props.comparator);
  const [value, setValue] = useState<any>(props.value);
  const [value1, setValue1] = useState<any>(props.value1);
  const [value2, setValue2] = useState<any>(props.value2);
  const [unit, setUnit] = useState<any>(props.unit);
  const [property, setProperty] = useState<FilterProperty | undefined>(
    props.property ? (props.property as unknown as FilterProperty) : undefined,
  );

  const availableComparators = useMemo(() => {
    const comparatorsByType = getComparatorsByType(props.comparatorType);
    return props.availableComparators?.length
      ? comparatorsByType.filter((c) => props.availableComparators?.includes(c))
      : comparatorsByType;
  }, [props.availableComparators, props.comparatorType]);

  const handleComparatorChanged = useCallback(
    (newcomparator: FilterComparatorType) => {
      setComparator(newcomparator);
      setValue(undefined);
      setValue1(undefined);
      setValue2(undefined);
      setUnit(undefined);
      props.onChanged({
        comparator: newcomparator,
        value: undefined,
        value1: undefined,
        value2: undefined,
        unit: undefined,
      });
    },
    [props],
  );

  const handleValueChanged = useCallback(
    (newValue: any) => {
      setValue(newValue);
      props.onChanged({ comparator, value: newValue, value1, value2, unit });
    },
    [comparator, props, unit, value1, value2],
  );

  const handleValue1Changed = useCallback(
    (newValue1: any) => {
      setValue1(newValue1);
      props.onChanged({ comparator, value, value1: newValue1, value2, unit });
    },
    [comparator, props, unit, value, value2],
  );

  const handleValue2Changed = useCallback(
    (newValue2: any) => {
      setValue2(newValue2);
      props.onChanged({ comparator, value, value1, value2: newValue2, unit });
    },
    [comparator, props, unit, value, value1],
  );

  const handleUnitChanged = useCallback(
    (newUnit: any) => {
      setUnit(newUnit);
      props.onChanged({ comparator, value, value1, value2, unit: newUnit });
    },
    [comparator, props, value, value1, value2],
  );

  useEffect(() => {
    setComparator(props.comparator);
    setValue(props.value);
    setValue1(props.value1);
    setValue2(props.value2);
    setUnit(props.unit);
    setProperty(props.property as unknown as FilterProperty);
  }, [
    props.comparatorType,
    props.comparator,
    props.value,
    props.value1,
    props.value2,
    props.unit,
    props.property,
  ]);

  const isBooleanProperty = useMemo(() => {
    return property && getPropertyType(property) === FilterPropertyType.BOOLEAN;
  }, [property]);

  useEffect(() => {
    if (isBooleanProperty) {
      props.onChanged({ ...props, comparator: FilterComparatorType.EQUAL, value: true });
    }
  }, [isBooleanProperty]);

  const valueComponent = useCallback(() => {
    if (!comparator) {
      return null;
    }

    if (
      comparator === FilterComparatorType.IS_SET ||
      comparator === FilterComparatorType.IS_NOT_SET ||
      comparator === FilterComparatorType.OVER_ALL_TIME
    ) {
      return null;
    }

    if (property && isAutoComplete(comparator, property)) {
      const allowMultiple = isMultipleValuesComparator(comparator);
      return (
        <div className="min-w-[170px]">
          <PropertyAutoComplete
            required={props.required}
            key={comparator}
            fetchOptionsList={true}
            property={property}
            value={value}
            allowMultiple={allowMultiple}
            onChanged={handleValueChanged}
            closeMenuOnSelect={!allowMultiple}
            isReadonly={props.isReadonly}
          />
        </div>
      );
    }

    if (property && isMultipleValuesComparator(comparator)) {
      return (
        <PropertyAutoComplete
          required={props.required}
          key={comparator}
          fetchOptionsList={false}
          property={property}
          value={value}
          allowMultiple={true}
          onChanged={handleValueChanged}
          closeMenuOnSelect={true}
          isReadonly={props.isReadonly}
        />
      );
    }

    if (props.comparatorType === FilterPropertyType.STRING) {
      return (
        <TextInput
          required={props.required}
          id="att-cdp-builder-comparator-value-text-field"
          type="text"
          value={value}
          onChange={handleValueChanged}
        />
      );
    }

    if (props.comparatorType === FilterPropertyType.NUMBER) {
      return (
        <div className="w-[110px]">
          <NumberInput
            required={props.required}
            id="att-cdp-builder-comparator-value-number-field"
            min={0}
            value={value?.toString()}
            onChange={(val) => handleValueChanged(Number(val))}
            autoComplete="off"
            rightSection={props.isReadonly ? <></> : undefined}
          />
        </div>
      );
    }

    if (comparator === FilterComparatorType.BEFORE || comparator === FilterComparatorType.AFTER) {
      return (
        <TextField
          id="att-cdp-builder-comparator-value-date-field"
          label=""
          type="date"
          value={moment(value).utc().format('YYYY-MM-DD')}
          onChange={handleValueChanged}
          autoComplete="off"
        />
      );
    }

    if (comparator === FilterComparatorType.UNDER || comparator === FilterComparatorType.OVER) {
      return (
        <React.Fragment>
          <div className="w-[90px]">
            <NumberInput
              required={props.required}
              min={0}
              value={value?.toString()}
              onChange={(val) => handleValueChanged(Number(val))}
              autoComplete="off"
              rightSection={props.isReadonly ? <></> : undefined}
            />
          </div>
          <TimeUnitDropDown
            value={unit}
            handleSelect={handleUnitChanged}
            isReadonly={props.isReadonly}
          />
          <Text size="xs">ago</Text>
        </React.Fragment>
      );
    }

    if (comparator === FilterComparatorType.BETWEEN) {
      return (
        <React.Fragment>
          <TextField
            label=""
            type="date"
            value={value1 ? moment(value1).utc().format('YYYY-MM-DD') : undefined}
            onChange={handleValue1Changed}
            autoComplete="off"
          />
          <Text size="xs">and</Text>
          <TextField
            label=""
            type="date"
            min={value1}
            value={value2 ? moment(value2).utc().format('YYYY-MM-DD') : undefined}
            onChange={handleValue2Changed}
            autoComplete="off"
          />
        </React.Fragment>
      );
    }

    if (comparator === FilterComparatorType.WITHIN) {
      return (
        <React.Fragment>
          <div className="w-[90px]">
            <NumberInput
              required={props.required}
              min={0}
              value={value1?.toString()}
              onChange={(val) => handleValue1Changed(Number(val))}
              autoComplete="off"
              rightSection={props.isReadonly ? <></> : undefined}
            />
          </div>
          <Text size="xs">and</Text>
          <div className="w-[90px]">
            <NumberInput
              required={props.required}
              min={value1}
              value={value2?.toString()}
              onChange={(val) => handleValue2Changed(Number(val))}
              autoComplete="off"
              rightSection={props.isReadonly ? <></> : undefined}
            />
          </div>
          <TimeUnitDropDown value={unit} handleSelect={handleUnitChanged} />
          <Text size="xs">ago</Text>
        </React.Fragment>
      );
    }
  }, [
    comparator,
    property,
    props.comparatorType,
    value,
    handleValueChanged,
    unit,
    handleUnitChanged,
    value1,
    handleValue1Changed,
    value2,
    handleValue2Changed,
  ]);

  return (
    <React.Fragment>
      {!isBooleanProperty ? (
        <Select
          required={props.required}
          w={150}
          id="att-cdp-builder-comparator-select"
          data={availableComparators.map((o) => ({ label: getFilterQueryLabel(o), value: o }))}
          value={comparator}
          onChange={(val) => handleComparatorChanged(val as FilterComparatorType)}
          rightSection={props.isReadonly ? <></> : undefined}
        />
      ) : (
        <Select
          required={props.required}
          id="att-cdp-builder-comparator-value-boolean"
          data={[
            { label: 'is true', value: 'true' },
            { label: 'is false', value: 'false' },
          ]}
          value={value.toString()}
          onChange={(val) => val && handleValueChanged(JSON.parse(val))}
          rightSection={props.isReadonly ? <></> : undefined}
        />
      )}
      {valueComponent()}
    </React.Fragment>
  );
};

export default FilterComparator;
