import { Alert, Anchor, Button, Card, Flex, Icon, Modal, Select, Text } from '@tw/ui-components';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { SegmentDataView } from './utils';
import { CreateSegmentReq, SegmentSyncReq } from '@tw/types/module/services/cdp';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'index';
import axiosInstance from 'utils/axiosInstance';
import { setCDPSegmentsFromServer } from 'ducks/cdp/segments';
import { toast } from 'react-toastify';
import { services } from '@tw/types/module/services';
import { MARKETS_LIST } from './markets';
import TWAlert from 'components/library/TWAlert/TWAlert';
import { shopProvidersStatus } from 'ducks/shopIntegrations';
import { ShopProviderStatusEnum } from '@tw/types/module/types/ShopProviders';
import SegmentSyncs from './SegmentSyncs';

type SyncProvider = {
  value: 'facebook-ads' | 'klaviyo' | 'smsbump';
  hasAccounts: boolean;
  hasMarkets: boolean;
  segmentTerminology: string;
  name: string;
  enabled?: boolean;
};

type ProviderValue = SyncProvider['value'];

type SyncModalProps = {
  isOpened: boolean;
  onClose: () => void;
  onNewSegmentCreated?: (segmentId: string) => void;
  segment: SegmentDataView;
  showExistingSyncs?: boolean;
};
const SyncModal: React.FC<SyncModalProps> = ({
  onClose,
  onNewSegmentCreated,
  isOpened,
  segment,
  showExistingSyncs = true,
}) => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const facebookAdsAccounts = useSelector((state: RootState) => state.facebookAdsAccounts);
  const isBacebookConnected = useSelector((state: RootState) => state.isFacebookConnected);
  const providersStatus = useSelector(shopProvidersStatus);

  const dispatch = useAppDispatch();

  const [selectedProvider, setSelectedProvider] = useState<SyncProvider>();
  const [selectedAccountId, setSelectedAccountId] = useState<string>();
  const [selectedMarketKey, setSelectedMarketKey] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{ title: string; detail?: string }>();

  const PROVIDERS: SyncProvider[] = useMemo(
    () => [
      {
        value: 'facebook-ads',
        hasAccounts: true,
        hasMarkets: true,
        segmentTerminology: 'audience',
        name: services['facebook-ads'].name,
        enabled: true,
      },
      {
        value: 'klaviyo',
        hasAccounts: false,
        hasMarkets: false,
        segmentTerminology: 'list',
        name: services['klaviyo'].name,
        enabled: true,
      },
      {
        value: 'smsbump',
        hasAccounts: false,
        hasMarkets: false,
        segmentTerminology: 'list',
        name: services['smsbump'].name,
        enabled: true,
      },
    ],
    [],
  );

  useEffect(() => {
    setErrorMessage(undefined);
  }, [selectedProvider, selectedAccountId, selectedMarketKey]);

  const selectProvider = useCallback((provider: ProviderValue) => {
    setSelectedProvider(PROVIDERS.find((p) => p.value === provider));
    setSelectedAccountId(undefined);
    setSelectedMarketKey(undefined);
  }, []);

  const clearSelectedProvider = useCallback(() => {
    setSelectedProvider(undefined);
    setSelectedAccountId(undefined);
    setSelectedMarketKey(undefined);
  }, []);

  const handleSubmit = async () => {
    if (!selectedProvider) return;

    setErrorMessage(undefined);

    try {
      setIsLoading(true);

      let segmentId = segment.id;
      if (!segmentId) {
        const request: CreateSegmentReq = {
          name: segment.name,
          description: segment.description,
          type: segment.type,
          query: segment.query!,
        };
        const { data } = await axiosInstance.post(
          `/v2/cdp/create-segment/${currentShopId}`,
          request,
        );
        segmentId = data.segment.id;
        onNewSegmentCreated?.(segmentId);
      }

      const request: SegmentSyncReq = {
        segmentId,
        shopDomain: currentShopId,
        providerId: selectedProvider.value,
        accountId: selectedAccountId,
        market: selectedMarketKey,
      };
      await axiosInstance.post(`/v2/cdp/integrations/create-sync`, request);

      dispatch(setCDPSegmentsFromServer());
      toast.success('Sync created successfully');
      toast.info(
        'Your request is being processed. This may take a few minutes, depending on the size of the segment, you can close this modal and check the status in the list.',
      );
      clearSelectedProvider();
      onClose();
    } catch (error) {
      const errorData = error.data?.error;

      if (errorData) {
        setErrorMessage({
          title: errorData.errorMessage,
          detail: errorData.errorDetail,
        });
      } else {
        console.log(error);
        toast.error("Couldn't create sync, please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const buildOptionName = (label: string, disabled: boolean) => {
    return `${label} ${disabled ? '(Already in use, see the list above)' : ''}`;
  };

  const availableAccouts: {
    value: string;
    label: string;
    disabled: boolean;
  }[] = useMemo(() => {
    if (selectedProvider?.value === 'facebook-ads') {
      return facebookAdsAccounts.map((account) => {
        const alreadyInUse = segment.integrationsSyncDetails?.some(
          (int) => int.accountId === account.id,
        );
        return {
          value: account.id,
          label: buildOptionName(`${account.name} - (#${account.account_id})`, alreadyInUse),
          disabled: alreadyInUse,
        };
      });
    } else {
      return [];
    }
  }, [facebookAdsAccounts, segment.integrationsSyncDetails, selectedProvider?.value]);

  const isSelectedProviderConnected = useMemo(() => {
    if (selectedProvider?.value === 'facebook-ads') {
      return isBacebookConnected;
    } else if (selectedProvider?.value === 'klaviyo') {
      return (
        !!providersStatus['klaviyo']?.status &&
        providersStatus['klaviyo'].status != ShopProviderStatusEnum.disconnected
      );
    } else if (selectedProvider?.value === 'smsbump') {
      return (
        !!providersStatus['smsbump']?.status &&
        providersStatus['smsbump'].status != ShopProviderStatusEnum.disconnected
      );
    }

    return false;
  }, [isBacebookConnected, selectedProvider?.value, providersStatus]);

  const providersOptions = useMemo(() => {
    return PROVIDERS.filter((f) => f.enabled).map((provider) => {
      const isDisabled =
        !provider.hasAccounts &&
        segment.integrationsSyncDetails.some((i) => i.providerId === provider.value);
      return {
        value: provider.value,
        label: buildOptionName(provider.name, isDisabled),
        disabled: isDisabled,
      };
    });
  }, [PROVIDERS, segment.integrationsSyncDetails]);

  const isSubmitEnabled = useMemo(() => {
    return (
      selectedProvider &&
      isSelectedProviderConnected &&
      (!selectedProvider.hasAccounts || selectedAccountId) &&
      (!selectedProvider.hasMarkets || selectedMarketKey)
    );
  }, [isSelectedProviderConnected, selectedAccountId, selectedMarketKey, selectedProvider]);

  return (
    <Modal
      title={
        <Text size="lg" fw={500}>
          Syncs for "{segment.name}"
        </Text>
      }
      onClose={() => {
        clearSelectedProvider();
        setErrorMessage(undefined);
        onClose();
      }}
      opened={isOpened}
      headerBorder
      size="lg"
    >
      <Flex gap="md" direction="column" pt="md">
        {showExistingSyncs && (
          <>
            {segment.integrationsSyncDetails.length > 0 ? (
              <Card shadow="sm" p={0} overflow="hidden">
                <SegmentSyncs segment={segment} />
              </Card>
            ) : (
              <Alert icon={<Icon name="info" color="one.4" />}>
                <Text color="one.8" size="sm">
                  This audience segment has not been synced with a provider.
                </Text>
                <Text color="one.8" size="sm">
                  Create Segment Sync below to get started.
                </Text>
              </Alert>
            )}
          </>
        )}

        <Flex direction="column" gap="md">
          <Text weight={500} color="gray.9">
            Create new sync
          </Text>
          <Flex direction="column" gap="xs">
            <Select
              label="Provider"
              placeholder="Select provider"
              data={providersOptions}
              onChange={(v) => selectProvider(v as ProviderValue)}
              value={selectedProvider?.value || null}
              error={
                selectedProvider && !isSelectedProviderConnected ? (
                  <>
                    You don't seems to have any {selectedProvider.name} accounts connected, please{' '}
                    <Anchor size="xs" href="/integrations" target="_blank">
                      {' '}
                      connect one first.
                    </Anchor>{' '}
                  </>
                ) : undefined
              }
            />

            {selectedProvider?.hasAccounts && (
              <Select
                label={`${selectedProvider.name} Account`}
                placeholder="Select account"
                data={availableAccouts}
                onChange={(val) => setSelectedAccountId(val!)}
              />
            )}

            {selectedProvider?.hasMarkets && (
              <Select
                placeholder="Start typing to search for a market"
                searchable
                label="Lookalike audience market"
                data={MARKETS_LIST}
                onChange={(val) => setSelectedMarketKey(val!)}
              />
            )}
          </Flex>
          <Button
            variant="secondary"
            onClick={handleSubmit}
            disabled={!isSubmitEnabled || isLoading}
            loading={isLoading}
          >
            Submit
          </Button>
          {selectedProvider && isSubmitEnabled && !errorMessage && (
            <Alert icon={<Icon name="info" color="one.4" />}>
              <Text color="one.8" size="sm">
                New {selectedProvider.segmentTerminology} (named{' '}
                <b>"Triple Whale Generated SCDP Audience {segment.name}"</b>) will be created in
                your {selectedProvider.name} account.
              </Text>
            </Alert>
          )}
        </Flex>

        {errorMessage && (
          <TWAlert type="error" title={errorMessage.title} message={errorMessage.detail}></TWAlert>
        )}
      </Flex>
    </Modal>
  );
};

export default SyncModal;
