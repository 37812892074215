import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { metrics } from '@tw/constants/module/Metrics/allMetrics';
import { type RootState } from 'reducers/RootType';
import { getParams, getSourcesList } from 'utils/attributions';
import { genericEventLogger, analyticsEvents, attributionActions } from 'utils/dataLayer';
import { useAttributionParams } from 'utils/useAttributionParam';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { useEffectiveSelectedColumns } from 'pages/Attribution/useEffectiveSelectedColumns';
import allServices from 'constants/services';
import { AFFLUENCER, ALL_SOURCES_ID } from 'constants/types';
import { CustomMetricsType, selectCustomMetricsForType } from 'ducks/customMetrics';
import { Flex, Icon, Text } from '@tw/ui-components';
import { PixelColumn } from 'types/attribution';
import axiosInstance from 'utils/axiosInstance';
import { CustomMetric } from 'components/CustomMetricsModal/types';

const errorToastMsg = 'Failed to generate CSV report. Please try again.';
const successToastMsg = 'CSV report downloaded successfully.';

const AttributionCsvExport = () => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [csvLoading, setCsvLoading] = useState(false);

  const attributionParams = useAttributionParams();
  const activeSource = useAttributionActiveSource();
  const sourceCategory = useAttributionActivePage();
  const customMetrics = useSelector(selectCustomMetricsForType(CustomMetricsType.Attribution));

  const effectiveSelectedColumns = useEffectiveSelectedColumns();

  async function downloadCSV(args: {
    requestParams: any;
    currentShopId: string;
    selectedColumns: PixelColumn[];
    metrics: any;
    activeSource: string;
    customMetrics: CustomMetric[];
  }) {
    const { requestParams, currentShopId, selectedColumns, metrics, activeSource, customMetrics } =
      args;
    const { isOneDay } = requestParams.period;
    let params = { ...requestParams.params, isOneDay } as any;
    const isAdSource =
      params.sources?.length > 0 &&
      (params.sources[0].includes('ads') ||
        ['bing', 'applovin', 'criteo', 'outbrain', 'taboola'].some((source) =>
          params.sources[0].includes(source),
        ));

    let columns = getCsvColumns({
      selectedColumns,
      metrics,
      params,
      activeSource,
      customMetrics,
      isAdSource,
    });
    const fileName = getFileName(currentShopId, requestParams.period, params);
    try {
      params.breakdown = params.breakdown !== 'source' && isAdSource ? 'adId' : params.breakdown;

      const res = await axiosInstance.post(`/v2/attribution/get-csv-data`, {
        ...params,
        columns,
        isOneDay,
        orderBy: [isOneDay ? 'event_hour' : 'event_date', 'spend desc'],
        downloadFileName: fileName,
      });
      const url = res?.data?.url as string;
      if (!url) {
        throw new Error('No url returned from server');
      }

      downloadFileFromUrl(url);
      return url;
    } catch (error) {
      throw new Error('Failed to download CSV');
    }
  }

  const getFileName = (currentShopId, period, params) => {
    const { startDate, endDate, isOneDay } = period;

    let sourceName = ALL_SOURCES_ID;
    if (params.sources) {
      const isMultipleSources = params.sources.length > 1;
      const firstSource = params.sources[0];

      if (isMultipleSources || params.breakdown === 'source') {
        const sourceType = allServices[firstSource]?.type;
        if (sourceType) {
          sourceName = `all-${sourceType}`;
        }
      } else {
        sourceName = allServices[firstSource]?.title?.replaceAll(/\s/g, '-') || firstSource;
      }
    }

    const dateStr = isOneDay
      ? startDate?.split('T')[0]
      : `${startDate?.split('T')[0]}-${endDate?.split('T')[0]}`;

    return `${currentShopId}-${sourceName}-${dateStr}.csv`;
  };

  type ColumnType = 'text' | 'numeric' | 'currency' | 'expression';
  type Column = {
    label: string;
    key: string;
    type: ColumnType;
  };

  function getCsvColumns(args: {
    selectedColumns: (PixelColumn & { metricId?: string })[];
    metrics: any;
    params: any;
    activeSource: string;
    customMetrics: CustomMetric[];
    isAdSource: boolean;
  }): Column[] {
    const { selectedColumns, metrics, params, activeSource, customMetrics, isAdSource } = args;
    const COLUMNS_TO_IGNORE = [
      'status',
      'urlParams',
      'showInGraph',
      'overlap',
      'budget',
      'bidAmount',
      'budgetCurrency',
      'destinationUrl',
      'customSpend',
      'day',
      'allConversionValue',
      ...selectedColumns.filter((c) => c.key.includes('ai_')).map((c) => c.key),
    ];
    const CURRENCY_COLUMNS = [
      'spend',
      'pixelConversionValue',
      'pixelNcConversionValue',
      'pixelCpa',
      'pixelNcCpa',
      'pixelAov',
      'pixelProfit',
    ];
    const NUMERIC_COLUMNS = [
      'pixelRoas',
      'pixelPurchases',
      'pixelNcPurchases',
      'pixelNcRoas',
      'pixelVisitors',
      'pixelUniqueVisitors',
      'pixelNewVisitors',
      'pixelEmailSignup',
      'pixelUniqueAtc',
      'pixelUniqueCheckout',
      'pixelUniqueAddress',
      'pixelUniqueShipping',
      'pixelUniqueContact',
      'pixelUniquePayment',
      'pixelConversionRate',
      'pixelBounceRate',
    ];
    const columns: Column[] = selectedColumns
      .filter((c) => !COLUMNS_TO_IGNORE.includes(c.key) && c.metricId !== 'customMetrics')
      .map((c) => {
        const label = metrics[c.key]?.label || c.name || c.key;
        return {
          label,
          key: c.key,
          type: CURRENCY_COLUMNS.includes(c.key)
            ? 'currency'
            : NUMERIC_COLUMNS.includes(c.key)
              ? 'numeric'
              : 'text',
        };
      });

    // intersect custom metrics with selected columns on the id.
    const customMetricsToAdd = customMetrics.filter((c) =>
      // @ts-ignore - its not on the type but it actually there.
      selectedColumns.some((sc) => sc.id === c.id),
    );

    for (const customMetric of customMetricsToAdd) {
      const { expression, stats, title } = customMetric;
      if (!expression || !stats || !title) {
        continue;
      }
      const computedExpression = expression?.replace(/field_\d+#/g, (match) => {
        const stat = stats?.[match];
        return `#_${stat?.value}_#`;
      });
      columns.push({
        label: title,
        key: computedExpression,
        type: 'expression',
      });
    }

    // For Affluencer: Add Affluencer Id and Name.
    if (activeSource === AFFLUENCER) {
      return [
        { label: 'Affluencer Id', key: 'id', type: 'text' },
        { label: 'Affluencer Name', key: 'name', type: 'text' },
        ...columns,
      ];
    }

    // For Source breakdown: Add Date and Rename Channel to Source.
    if (params.breakdown === 'source') {
      return [
        { label: 'Date', key: params.isOneDay ? 'event_hour' : 'event_date', type: 'text' },
        { label: 'Source', key: 'serviceId', type: 'text' },
        ...columns.filter((c) => !['name', 'serviceId'].includes(c.key)),
      ];
    }

    // For Klaviyo: Add Date, and campaign name columns.
    if (params.sources[0].includes('klaviyo')) {
      return [
        { label: 'Date', key: params.isOneDay ? 'event_hour' : 'event_date', type: 'text' },
        { label: 'Campaign Name', key: 'campaignName', type: 'text' },
        ...columns.filter((c) => !['name'].includes(c.key)),
      ];
    }

    // for any other source except for ads just add name and date.
    if (!isAdSource) {
      return [
        { label: 'Date', key: params.isOneDay ? 'event_hour' : 'event_date', type: 'text' },
        { label: 'Campaign Name', key: 'name', type: 'text' },
        ...columns.filter((c) => !['name'].includes(c.key)),
      ];
    }

    // else - its ads provider we want to get ad level data.
    return [
      { label: 'Date', key: params.isOneDay ? 'event_hour' : 'event_date', type: 'text' },
      { label: 'Channel', key: 'serviceId', type: 'text' },
      { label: 'Campaign Id', key: 'campaignId', type: 'text' },
      { label: 'Campaign Name', key: 'campaignName', type: 'text' },
      { label: 'Adset Id', key: 'adsetId', type: 'text' },
      { label: 'Adset Name', key: 'adsetName', type: 'text' },
      { label: 'Ad Id', key: 'adId', type: 'text' },
      { label: 'Ad Name', key: 'name', type: 'text' },
      ...columns.filter((c) => !['name', 'serviceId'].includes(c.key)),
    ];
  }

  function downloadFileFromUrl(url: string) {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const sourcesList = useMemo(() => {
    if (sourceCategory === ALL_SOURCES_ID) {
      return [];
    }
    return getSourcesList(sourceCategory || ALL_SOURCES_ID);
  }, [sourceCategory]);

  const exportCSV = async () => {
    setCsvLoading(true);
    const requestParams = getParams({
      attributionParams,
      sourceCategory,
      activeSource,
      sourcesList,
    });

    if (!requestParams) {
      setCsvLoading(false);
      return;
    }

    const {
      period: { startDate, endDate },
    } = requestParams;

    genericEventLogger(analyticsEvents.ATTRIBUTION, {
      action: attributionActions.EXPORT_TO_CSV,
      shop: currentShopId,
      dateTo: startDate?.split('T')[0],
      dateFrom: endDate?.split('T')[0],
    });

    try {
      await downloadCSV({
        requestParams,
        currentShopId,
        selectedColumns: effectiveSelectedColumns,
        metrics,
        activeSource,
        customMetrics,
      });
      toast.success(successToastMsg);
      setCsvLoading(false);
    } catch (e) {
      console.log(e.message);
      toast.error(errorToastMsg);
      setCsvLoading(false);
    }
  };

  return (
    <Flex onClick={exportCSV} align="center" justify="space-between" id="exportCsv">
      <Text size="sm" weight={500} color="named2.0">
        Export CSV
      </Text>
      {csvLoading && <Icon name="loader-1" />}
    </Flex>
  );
};

export default AttributionCsvExport;
