import { Fragment } from 'react/jsx-runtime';
import {
  Dialect,
  DialectWithBoth,
  StepWsStatus,
  WillyDataSequence,
  WorkflowStep,
} from '../types/willyTypes';
import { TreeHorizontalLine } from './TreeHorizontalLine';
import { TreeVerticalLine } from './TreeVerticalLine';
import { SequenceFlowStep } from './SequenceFlowStep';
import { noop } from 'lodash';
import { SequenceFlow } from './SequenceFlow';
import { Icon } from '@tw/ui-components';
import { useCallback } from 'react';
import { getParentRuleId } from '../utils/sequences';
import { useStoreValue } from '@tw/snipestate';
import { $shopSequences } from '$stores/willy/$sequences';

type SequenceFlowRuleStepProps = {
  sequenceId: string;
  step: WorkflowStep;
  steps: WorkflowStep[];
  wsSteps: Record<string, StepWsStatus>;
  depth: number;
  space: number;
  onGenerateAnswer: (upToStepId?: string, onlyStepId?: string) => void;
  stepChange: (newPrompt: WorkflowStep) => void;
  deleteStep: (stepId: string) => void;
  toggleCollapse: (stepId: string) => void;
  collapsedSteps: string[];
  loadingAnswers: boolean;
  allowRegenerate: boolean;
  saveSequenceIdsToStep: (sequences: string[], messageId: string) => void;
  readOnly: boolean;
  shelfIsOpen?: boolean;
  setOpenRuleStepSettingsDrawer: (open: boolean) => void;
  setRuleStepSettingDrawerRef: (ref: { stepId: string; type: 'passed' | 'failed' }) => void;
  currentRunId?: string;
  rephraseStepsBasedOnConversation?: string;
  dialect: DialectWithBoth;
  setSequence: React.Dispatch<React.SetStateAction<WillyDataSequence>>;
  isGlobal: boolean;
};

export const SequenceFlowRuleStep: React.FC<SequenceFlowRuleStepProps> = ({
  sequenceId,
  step,
  steps,
  depth,
  space,
  wsSteps,
  onGenerateAnswer,
  stepChange,
  deleteStep,
  toggleCollapse,
  collapsedSteps,
  currentRunId,
  rephraseStepsBasedOnConversation,
  loadingAnswers,
  allowRegenerate,
  saveSequenceIdsToStep,
  readOnly,
  shelfIsOpen,
  setOpenRuleStepSettingsDrawer,
  setRuleStepSettingDrawerRef,
  dialect,
  setSequence,
  isGlobal,
}) => {
  const allSequences = useStoreValue($shopSequences);

  const ruleDescendants = useCallback(
    (stepId: string, type: 'passed' | 'failed') => {
      return steps?.filter((s) => s.parentRuleId === getParentRuleId(stepId, type));
    },
    [steps],
  );
  const passedSteps = ruleDescendants(step.id, 'passed');
  const failedSteps = ruleDescendants(step.id, 'failed');

  return (
    <div>
      <div className="flex">
        <div className="flex flex-col items-center">
          <div className={` flex flex-col items-center ${depth === 0 ? '' : 'h-full'}`}>
            <TreeHorizontalLine
              isOnlySibling={false}
              isFirstSibling={true}
              isLastSibling={false}
              depth={depth + 1}
            />
            <TreeVerticalLine />
            <div className="flex flex-col items-center">
              <div className="flex w-full justify-center">
                <div
                  className={`group flex p-5 rounded  items-center gap-3 justify-center cursor-pointer border border-solid border-gray-300`}
                  style={{
                    width: `calc(700px - ${depth * space}px)`,
                  }}
                >
                  Passed
                </div>
              </div>
            </div>
            {passedSteps.map((s, i) => {
              const generatedAnswer = wsSteps[s.id];
              return (
                <Fragment key={s.id}>
                  <SequenceFlowStep
                    dialect={dialect}
                    sequenceId={sequenceId}
                    step={s}
                    stepNumber={i + 1}
                    onGenerateAnswer={depth === 0 ? onGenerateAnswer : noop}
                    stepChange={stepChange}
                    deleteStep={deleteStep}
                    toggleCollapse={toggleCollapse}
                    isCollapsed={collapsedSteps.includes(s.id)}
                    loadingAnswers={loadingAnswers}
                    allowRegenerateAnswers={allowRegenerate}
                    stepRunInfo={generatedAnswer}
                    depth={depth}
                    saveSequences={saveSequenceIdsToStep}
                    readOnly={readOnly}
                    isLastStep={true}
                    fromRule={true}
                    siblingSteps={steps}
                    isGlobal={isGlobal}
                  />
                  {s?.stepType === 'subSequence' && !!s?.sequenceIds?.length && (
                    <div>
                      <div className="flex">
                        {s?.sequenceIds?.map((subSeq, i) => {
                          const sequence = allSequences.find((s) => s.id === subSeq);
                          if (!sequence) return null;
                          const flowIsParent = subSeq === sequenceId;

                          return (
                            <SequenceFlow
                              disabledFlow={flowIsParent}
                              key={subSeq}
                              sequence={sequence}
                              setSequence={setSequence}
                              depth={depth + 1}
                              isFirstSibling={i === 0}
                              isLastSibling={
                                s?.sequenceIds ? i === s?.sequenceIds?.length - 1 : true
                              }
                              noEdit={true}
                              parentRunId={currentRunId}
                              rephraseStepsBasedOnConversation={rephraseStepsBasedOnConversation}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {(s?.stepType === 'condition' || s?.stepType === 'rule') && (
                    <SequenceFlowRuleStep
                      step={s}
                      sequenceId={sequenceId}
                      depth={depth + 1}
                      steps={steps}
                      wsSteps={wsSteps}
                      space={space}
                      onGenerateAnswer={depth === 0 ? onGenerateAnswer : noop}
                      stepChange={stepChange}
                      deleteStep={deleteStep}
                      toggleCollapse={toggleCollapse}
                      loadingAnswers={loadingAnswers}
                      allowRegenerate={allowRegenerate}
                      saveSequenceIdsToStep={saveSequenceIdsToStep}
                      readOnly={readOnly}
                      collapsedSteps={collapsedSteps}
                      currentRunId={currentRunId}
                      shelfIsOpen={shelfIsOpen}
                      setOpenRuleStepSettingsDrawer={setOpenRuleStepSettingsDrawer}
                      setRuleStepSettingDrawerRef={setRuleStepSettingDrawerRef}
                      dialect={dialect}
                      setSequence={setSequence}
                      isGlobal={isGlobal}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
          <>
            <TreeVerticalLine />
            <div
              className={`flex justify-center ${shelfIsOpen ? '' : 'cursor-pointer'}`}
              onClick={() => {
                setOpenRuleStepSettingsDrawer(true);
                setRuleStepSettingDrawerRef({
                  stepId: step.id,
                  type: 'passed',
                });
              }}
            >
              <Icon name="plus-1" color={shelfIsOpen ? 'gray.3' : 'named.6'} size={45} />
            </div>
          </>
        </div>
        <div>
          <div className="flex flex-col items-center">
            <TreeHorizontalLine
              isOnlySibling={false}
              isFirstSibling={false}
              isLastSibling={true}
              depth={depth + 1}
            />
            <TreeVerticalLine />
            <div className="flex flex-col items-center">
              <div className="flex w-full justify-center">
                <div
                  className={`group flex p-5 rounded  items-center gap-3 justify-center cursor-pointer border border-solid border-gray-300`}
                  style={{
                    width: `calc(700px - ${depth * space}px)`,
                  }}
                >
                  Failed
                </div>
              </div>
            </div>
            {failedSteps.map((s, i) => {
              const generatedAnswer = wsSteps[s.id];
              return (
                <Fragment key={s.id}>
                  <SequenceFlowStep
                    dialect={dialect}
                    sequenceId={sequenceId}
                    step={s}
                    stepNumber={i + 1}
                    onGenerateAnswer={depth === 0 ? onGenerateAnswer : noop}
                    stepChange={stepChange}
                    deleteStep={deleteStep}
                    toggleCollapse={toggleCollapse}
                    isCollapsed={collapsedSteps.includes(s.id)}
                    loadingAnswers={loadingAnswers}
                    allowRegenerateAnswers={allowRegenerate}
                    stepRunInfo={generatedAnswer}
                    depth={depth}
                    saveSequences={saveSequenceIdsToStep}
                    readOnly={readOnly}
                    isLastStep={true}
                    fromRule={true}
                    siblingSteps={steps}
                    isGlobal={isGlobal}
                  />
                  {s?.stepType === 'subSequence' && !!s?.sequenceIds?.length && (
                    <div>
                      <div className="flex">
                        {s?.sequenceIds
                          ?.filter((item): item is string => item !== null)
                          ?.map((subSeq, i) => {
                            const flowIsParent = subSeq === sequenceId;
                            const sequence = allSequences.find((s) => s.id === subSeq);
                            if (!sequence) return null;

                            return (
                              <SequenceFlow
                                disabledFlow={flowIsParent}
                                key={subSeq}
                                sequence={sequence}
                                setSequence={setSequence}
                                depth={depth + 1}
                                isFirstSibling={i === 0}
                                isLastSibling={
                                  s?.sequenceIds ? i === s?.sequenceIds?.length - 1 : true
                                }
                                noEdit={true}
                                parentRunId={currentRunId}
                              />
                            );
                          })}
                      </div>
                    </div>
                  )}
                  {(s?.stepType === 'condition' || s?.stepType === 'rule') && (
                    <SequenceFlowRuleStep
                      step={s}
                      sequenceId={sequenceId}
                      depth={depth + 1}
                      steps={steps}
                      wsSteps={wsSteps}
                      space={space}
                      onGenerateAnswer={depth === 0 ? onGenerateAnswer : noop}
                      stepChange={stepChange}
                      deleteStep={deleteStep}
                      toggleCollapse={toggleCollapse}
                      loadingAnswers={loadingAnswers}
                      allowRegenerate={allowRegenerate}
                      saveSequenceIdsToStep={saveSequenceIdsToStep}
                      readOnly={readOnly}
                      collapsedSteps={collapsedSteps}
                      currentRunId={currentRunId}
                      shelfIsOpen={shelfIsOpen}
                      setOpenRuleStepSettingsDrawer={setOpenRuleStepSettingsDrawer}
                      setRuleStepSettingDrawerRef={setRuleStepSettingDrawerRef}
                      dialect={dialect}
                      setSequence={setSequence}
                      isGlobal={isGlobal}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>

          <>
            <TreeVerticalLine />
            <div
              className={`flex justify-center ${shelfIsOpen ? '' : 'cursor-pointer'}`}
              onClick={() => {
                setOpenRuleStepSettingsDrawer(true);
                setRuleStepSettingDrawerRef({
                  stepId: step.id,
                  type: 'failed',
                });
              }}
            >
              <Icon name="plus-1" color={shelfIsOpen ? 'gray.3' : 'named.6'} size={45} />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
