import { START_FREE_TRIAL } from 'ducks/constants';

/*

---
Data Layer Constants
Loom Video: https://www.loom.com/share/df125e495509405d99bd141689181209
Example Usage: https://github.com/Triple-Whale/triplewhale-client/pull/2613/files
---

Each "event" corresponds to an area within our app.
Each "action" corresponds to a specific action within that area.

Generally, we should use the "genericEventLogger", which currently pushes to GTM and Posthog.
If we need to log an event that is not covered by the genericEventLogger, we can use the
"posthogEventLogger" or "datadogEventLogger" respsectively.

The idea is that if in the future we ever want to switch Analytics platforms, we can do so
by only changing the genericEventLogger, and not have to change every single event that we
log throughout the app.

*/
export const analyticsEvents = {
  SUMMARY: 'summary',
  ATTRIBUTION: 'attribution',
  GENERAL: 'general',
  LIGHTHOUSE: 'lighthouse',
  BACKGROUND_DIFFUSION: 'background_diffusion',
  COCKPIT: 'ad_builder',
  FACEBOOK: 'facebook',
  TIKTOK: 'tiktok',
  PRODUCT_ANALYTICS: 'product_analytics',
  TRAFFIC_RULES: 'traffic_rules',
  FREE_WILLY: 'free_willy',
  UPGRADE: 'upgrade',
  ERROR_BOUNDARY: 'error_boundary',
  SUMMARY_LOADING: 'summary_loading',
  PIXEL_LOADING: 'pixel_loading',
  ERROR_BOUNDARY_RESET_BUTTON: 'error_boundary_reset_button',
  ERROR_BOUNDARY_RELOAD_BUTTON: 'error_boundary_reload_button',
  LAZY: 'lazy',
  GOOGLE_SHEETS: 'google_sheets',
  COHORTS: 'cohorts',
  CART_ANALYSIS: 'cart_analysis',
  FORECASTING: 'forecasting',
  API_KEYS: 'api_keys',
  FOUNDERS_DASH: 'founders_dash',
  PPS: 'pps',
  // 3.0 events
  CHAT: 'chat',
  SQWHALE: 'sqwhale',
  TEMPLATE_LIBRARY: 'template_library',
  DASHBOARDS: 'dashboards',
  METRIC_BUILDER: 'metric_builder',
  TOGGLE_VERSION: 'toggle_version',
  SEQUENCES: 'sequences',
  PROMPT_LIBRARY: 'prompt_library',
  FEEDBACK: 'feedback',
  IN_APP_NOTIFICATION: 'in_app_notification',
} as const;

export const forecastingActions = {
  SLIDE_SLIDER: 'slide_slider',
  CREATE_GOAL_MODAL_OPEN: 'create_goal_modal_open',
  CREATE_GOAL_MODAL_SAVE: 'create_goal_modal_save',
  COMPARE: 'compare',
  SAVE_SCENARIO: 'save_scenario',
  LOAD_GOALS: 'load_goals',
  TOGGLE_ROWS: 'toggle_rows',
  CHANGE_DATES: 'change_dates',
  TOGGLED_VIEW: 'toggled_view',
  TOGGLE_GRAPH: 'toggle_graph',
  LADDER_GOAL: 'ladder_goal',
  TOGGLE_ACTUALS: 'toggle_actuals',
  TOGGLE_PREDICTIONS: 'toggle_predictions',
  TOGGLE_GOALS: 'toggle_goals',
  PACING_VIEWED: 'pacing_viewed',
  FORWARD_VIEW_VIEWED: 'forward_view_viewed',
  TOGGLE_TABLE_TABS: 'toggle_table_tabs',
  TOGGLE_PREDICTION_MODEL: 'toggle_prediction_model',
  GOAL_MODAL_SHOW_MORE_GRANULAR: 'goal_modal_show_more_granular',
  GOAL_MODAL_SHOW_LESS_GRANULAR: 'goal_modal_show_less_granular',
  PACING_OPEN_DEEP_DIVE: 'pacing_open_deep_dive',
  PACING_OPEN_VIEW_MORE: 'pacing_open_view_more',
  PACING_OPEN_VIEW_LESS: 'pacing_open_view_less',
  TRIGGER_PLANNER_ORCHESTRATION: 'trigger_planner_orchestration',
  GET_NOTIFIED_BUTTON_CLICKED: 'get_notified_button_clicked',
  FORWARD_VIEW_CHANGE_TIME_PERIOD: 'forward_view_change_time_period',
  FORWARD_VIEW_CHANGE_DATA_SOURCE: 'forward_view_change_data_source',
  FORWARD_VIEW_TILE_CLICKED: 'forward_view_tile_clicked',
  ENABLE_NOTIFICATIONS: 'enable_notifications',
  GROUP_BY_ON_FORECATING_PAGE_CHANGED: 'group_by_on_forecasting_page_changed',
  GROUP_BY_ON_GOALS_MODAL_CHANGED: 'group_by_on_goals_modal_changed',
} as const;

export const insightsActions = {
  FILTER_CHANGED: 'filter_changed',
  DOWNLOAD_CSV: 'download_csv',
  SHARE_GOOGLE_SHEET: 'share_google_sheet',
} as const;

export const cartAnalysisActions = {
  PRODUCTS_WINDOW_OPENED: 'products_window_opened',
} as const;

export const aiActions = {
  POPUP_OPEN: 'popup_open',
  FINISH_EDITING: 'finish_editing',
  RUN_NEW_PROMPT: 'run_new_prompt',
} as const;

export const attributionActions = {
  SAVE_BUDGET: 'save_budget',
  SAVE_BUDGET_SUCCESS: 'save_budget_success',
  SAVE_BUDGET_ERROR: 'save_budget_error',
  SAVE_BID: 'save_bid',
  SAVE_BID_SUCCESS: 'save_bid_success',
  SAVE_BID_ERROR: 'save_bid_error',
  DATA_FETCHED: 'data_fetched',
  PPS_BANNER_DISMISSED: 'pps_banner_dismissed',
  CLICKED_TOGGLE_STATUS: 'clicked_toggled_status',
  TOGGLE_STATUS_SUCCESS: 'toggle_status_success',
  TOGGLE_STATUS_ERROR: 'toggle_status_error',
  TOGGLE_DARKMODE: 'toggle_darkmode',
  EXPORT_TO_CSV: 'export_pixel_data_to_csv',
  FILTER_ATTRIBUTION_BY_SUBSCRIPTION: 'filter_attribution_by_subscription',
  OPEN_PIXEL_WORKFLOWS_POPUP: 'open_pixel_workflows_popup',
} as const;

export const attributionMessages = {
  SAVE_BUDGET_SUCCESS: 'budget_successfully_updated',
  SAVE_BUDGET_ERROR: 'error_updating_budget',
  SAVE_BID_SUCCESS: 'bid_successfully_updated',
  SAVE_BID_ERROR: 'error_updating_bid',
} as const;

export const cockpitActions = {
  AD_CREATED: `${analyticsEvents.COCKPIT}_ad_created`,
  PREVIEW_AD: `${analyticsEvents.COCKPIT}_preview_ad`,
} as const;

export const errorBoundaryActions = {
  BOUNDARY_HIT: 'hit_boundary',
} as const;

export const lazyActions = {
  MODULE_RETRY_FAILED: 'module_retry_failed',
  MODULE_RETRY_SUCCESS: 'module_retry_success',
  MODULE_RETRY_FATAL: 'module_retry_fatal',
} as const;

export const facebookActions = {
  SERVICE: 'facebook_ads',
  UPDATE_SUCCESS: 'update_success',
  UPDATE_ERROR: 'update_error',
} as const;

export const googleActions = {
  SERVICE: 'google_ads',
} as const;

export const generalActions = {
  IMAGE_ERROR: 'image_error',
  CHANGE_DATE: 'change_date',
  CHANGE_COMPARE_DATE: 'change_compare_date',
  TOGGLE_DARKMODE: 'toggle_darkmode',
} as const;

export const lighthouseActions = {
  OPEN: 'open_lighthouse',
  CLOSE: 'close_lighthouse',
  VIEW_SETTINGS: 'view_settings',
  NOTIFICATION_IMPRESSION: 'notification_impression',
  NOTIFICATION_CLICK: 'notification_click',
  SHOW_CATEGORY: 'show_category',
  CLOSE_CATEGORY: 'close_category',
  VIEW_ARCHIVE: 'view_archive',
} as const;

export const productAnalyticsActions = {
  SELECT_CREATIVE: 'select_creative',
  DESELECT_CREATIVE: 'deselect_creative',
} as const;

export const summaryActions = {
  CHART_ERROR: 'chart_error',
  TILE_FAILED: 'tile_failed',
  SELECT_TILE: 'select_tile',
  SELECT_TILE_FAILED: 'select_tile_failed',
  GET_TILE_VALUE: 'get_tile_value',
  GET_TILE_VALUE_FAILED: 'get_tile_value_failed',
  SELECT_TILE_CHART: 'select_tile_chart',
  SELECT_TILE_CHART_FAILED: 'select_tile_chart_failed',
  GET_TILE_CHART: 'get_tile_chart',
  GET_TILE_CHART_FAILED: 'get_tile_chart_failed',
  MOBILE_COLUMN_CHANGE: 'mobile_column_change',
  DESKTOP_COLUMN_CHANGE: 'desktop_column_change',
  DESKTOP_GRID_CHANGE: 'desktop_grid_change',
  DESKTOP_FULL_WIDTH_CHANGE: 'desktop_full_width_change',
  TOGGLE_DARKMODE: 'toggle_darkmode',
  OPEN_METRIC_POPUP_WORKFLOW_TAB: 'open_metric_popup_workflow_tab',
  GENERATE_GENERAL_INSIGHTS: 'generate_general_insights',
} as const;

export const summaryMessages = {
  CHART_ERROR: 'Error loading chart',
  MOBILE_COLUMN_CHANGE: (columns) =>
    `Mobile Columns: Changed to ${columns ? '2' : '1'} column${columns ? 's' : ''}`,
  DESKTOP_GRID_CHANGE: (grid) => `Desktop Layout: Changed to ${grid ? 'grid' : 'flex'}`,
  DESKTOP_COLUMN_CHANGE: (columns) => `Desktop Columns: Changed to ${columns} columns`,
  DESKTOP_FULL_WIDTH_CHANGE: (isFullWidth) =>
    `Desktop Full Width: Changed to ${isFullWidth ? 'full width' : 'max width (constrained)'}`,
} as const;

export const tiktokActions = {
  SETTINGS_SAVED: 'settings_saved',
  SETTINGS_ERROR: 'settings_error',
} as const;

export const tiktokMessages = {
  SETTINGS_SAVED: 'TikTok settings saved',
  SETTINGS_ERROR: (shopDomain) =>
    `update serviceConfiguration error, shopDomain: ${shopDomain} serviceId: 'tiktok-ads'`,
} as const;

export const trafficRulesActions = {
  SAVE_RULE: 'save_traffic_rules',
  SAVE_STRATEGY: 'save_traffic_rule_strategy',
  CLICK_RULE: 'clicked_rule_toggled_status',
  CLICK_STRATEGY: 'clicked_rule_strategy_toggled_status',
} as const;

export const freeWillyActions = {
  SIGNED_UP: 'sign_up',
} as const;

export const upgradeActions = {
  UPGRADE_CLICKED: 'upgrade_click',
  UPGRADE_STARTED: 'start_upgrade',
  UPGRADE_COMPLETED: 'complete_upgrade',
} as const;

export const language_models = ['ChatGPT', 'Triple Whale'] as const;

export const googleSheetsActions = {
  DISCONNECT: 'disconnect_account',
} as const;

export const apiKeysActions = {
  CREATE: 'create_api_key',
  REVOKE: 'revoke_api_key',
} as const;

export const foundersDashActions = {
  CREATE_FREE_SHOP: 'create_free_shop',
} as const;

// 3.0 "Core" Actions
// some may be duplicated from above,
// but we want to keep them separate for now
export const chatActions = {
  START_CHAT: 'start_chat',
  CHOOSE_EXAMPLE: 'choose_example',
  CHOOSE_EXISTING: 'choose_existing',
  RUN_SEQUENCE: 'run_sequence',
  SEND_MESSAGE: 'send_message',
  CHAT_RESPONSE: 'chat_response',
  MESSAGE_DONE: 'message_done',
  CLEAR_CHAT: 'clear_chat',
  CLICK_FOLLOWUP: 'click_followup',
  RATE: 'rate',
  VIEW_SQL: 'view_sql',
  PIN_RESULT: 'pin_result',
  REMOVE_WIDGET: 'remove_widget',
  COPY_QUERY: 'copy_query',
  OPEN_IN_SQWHALE_EDITOR: 'open_in_sqwhale_editor',
  CHANGE_VIZ_TYPE: 'change_viz_type',
  FEEDBACK: 'feedback',
  EXPORT_CSV: 'export_csv',
  COPIED_QUERY: 'copied_query',
  TOGGLE_DYNAMIC_FIELD: 'toggle_dynamic_field',
  CREATE_SEQUENCE: 'create_sequence',
  UPDATE_SEQUENCE: 'update_sequence',
  TOGGLE_LEGEND: 'toggle_legend',
  SELECT_DEFAULT_PILL: 'select_default_pill',
  CHOOSE_SUGGESTED_MESSAGE: 'choose_suggested_message',
  OPEN_PROMPT_LIBRARY: 'open_prompt_library',
  CHOOSE_PROMPT_LIBRARY_PROMPT: 'select_prompt_library_prompt',
  OPEN_CHAT_HISTORY: 'open_chat_history',
  NEW_CONVERSATION: 'new_conversation',
  EXPAND_RELATED_SECTION: 'expand_related_section',
  EXPAND_ANSWER_DETAILS: 'expand_answer_details',
  OPEN_ADD_TO_REPORT: 'open_add_to_report',
  CLEAR_CONVERSATION: 'clear_conversation',
  TOGGLE_CHAT_BUILD_MODE: 'toggle_chat_build_mode',
  TOGGLE_CHAT_FORECAST_MODEL: 'toggle_chat_forecast_model',
  TOGGLE_CHAT_MODEL: 'toggle_chat_model',
  OPEN_PROMPT_CATEGORY_SELECT: 'open_prompt_category_select',
} as const;

export const sqwhaleActions = {
  RUN_QUERY: 'run_query',
  RUN_PYTHON: 'run_python',
  SAVE_NEW_QUERY: 'save_new_query',
  SAVE_QUERY: 'save_query',
  PIN_RESULT: 'pin_result',
  REMOVE_WIDGET: 'remove_widget',
  CHANGE_VIZ_TYPE: 'change_viz_type',
  OPEN_IN_SQWHALE_EDITOR: 'open_in_sqwhale_editor',
  SQL_RESULT: 'sql_result',
  CHANGE_RESULT_VIEW: 'change_result_view',
  OPEN_CHAT: 'open_chat',
  SEND_MESSAGE: 'send_message',
  CHAT_RESPONSE: 'chat_response',
  MESSAGE_DONE: 'message_done',
  CLEAR_CHAT: 'clear_chat',
  CLICK_FOLLOWUP: 'click_followup',
  VIEW_SQL: 'view_sql',
  USE_THIS_QUERY: 'use_this_query',
  COPY_QUERY_FROM_CHAT: 'copy_query_from_chat',
  ASK_AN_EXPERT: 'ask_an_expert',
  EXPORT_CSV: 'export_csv',
  COPIED_QUERY: 'copied_query',
  TOGGLE_DYNAMIC_FIELD: 'toggle_dynamic_field',
  TOGGLE_LEGEND: 'toggle_legend',
  CHOOSE_SUGGESTED_MESSAGE: 'choose_suggested_message',
  CLEAR_CONVERSATION: 'clear_conversation',
  OPEN_ADD_TO_REPORT: 'open_add_to_report',
  SELECT_QUERY: 'select_query',
  SELECT_SCHEMA: 'select_schema',
  SELECT_UNDERLYING_VIEW: 'select_underlying_view',
  DELETE_CUSTOM_SCHEMA: 'delete_custom_schema',
  CREATE_WORKFLOW_WITH_MOBY: 'create_workflow_with_moby',
  GO_TO_DATA_DICTIONARY: 'go_to_data_dictionary',
} as const;

export const templateLibraryActions = {
  VIEW_TEMPLATE_LIBRARY: 'view_template_library',
  VIEW_TEMPLATE_LIBRARY_MODAL: 'view_template_library_modal',
  SEARCH_TEMPLATES: 'search_templates',
  VIEW_TEMPLATE_LP: 'view_template_lp',
  PREVIEW_TEMPLATE: 'preview_template',
  VIEW_SEQUENCE_LP: 'view_sequence_lp',
  PREVIEW_SEQUENCE: 'preview_sequence',
  GET_TEMPLATE: 'get_template',
  ADD_TEMPLATE_TO_WORKSPACE: 'add_template_to_workspace',
  ADD_SEQUENCE_TO_WORKSPACE: 'add_sequence_to_workspace',
  ADD_COLUMN_TO_WORKSPACE: 'add_column_to_workspace',
  UPDATE_TEMPLATE_VERSION: 'update_template_version',
  RUN_SEQUENCE: 'run_sequence',
  CREATE_SEQUENCE: 'create_sequence',
  UPDATE_SEQUENCE: 'update_sequence',
  TEMPLATE_PREVIEW_HEADER_AB_TEST_Q42024: 'Template_Preview_HeaderABTest_Q42024',
  TEMPLATE_REVIEW_MODAL_OPEN_IN_DASHBOARD: 'template_review_modal_open_in_dashboard',
  TEMPLATE_REVIEW_MODAL_CLOSED: 'template_review_modal_closed',
  TEMPLATE_REVIEW_SUCCESS: 'template_review_success',
  ADD_REVIEW_BUTTON_CLICKED: 'add_review_button_clicked',
} as const;

export const sequencesActions = {
  CREATE_SEQUENCE: 'create_sequence',
  UPDATE_SEQUENCE: 'update_sequence',
  DUPLICATE_SEQUENCE: 'duplicate_sequence',
  // sequence = workflow = agent
  RUN_WORKFLOW_INSIGHT: 'run_workflow_insight',
  WORKFLOW_FINISHED_LOADING: 'workflow_finished_loading',
  VIEW_RUN: 'view_run',
  SHOW_FULL_OUTPUT: 'show_full_output',
  BOOK_DEMO: 'book_demo',
} as const;

export const dashboardsActions = {
  CREATE_NEW: 'create_new',
  VIEW_DASHBOARD: 'view_dashboard',
  VIEW_STANDARD_DASHBOARD: 'view_standard_dashboard',
  ADD_CONTENT: 'add_content',
  OPEN_CHAT: 'open_chat',
  SEND_MESSAGE: 'send_message',
  CHAT_RESPONSE: 'chat_response',
  MESSAGE_DONE: 'message_done',
  CLEAR_CHAT: 'clear_chat',
  COPY_QUERY: 'copy_query',
  CLICK_FOLLOWUP: 'click_followup',
  PIN_RESULT: 'pin_result',
  REMOVE_WIDGET: 'remove_widget',
  SHARE_DASHBOARD: 'share_dashboard',
  CHANGE_VIZ_TYPE: 'change_viz_type',
  OPEN_IN_SQWHALE_EDITOR: 'open_in_sqwhale_editor',
  ADD_TO_FAVORITES: 'add_to_favorites',
  TOGGLE_FULL_WIDTH: 'toggle_full_width',
  TOGGLE_DND: 'toggle_dnd',
  TOGGLE_DYNAMIC_FIELD: 'toggle_dynamic_field',
  EXPORT_CSV: 'export_csv',
  VIEW_SQL: 'view_sql',
  EDIT_QUERY: 'edit_query',
  COPIED_QUERY: 'copied_query',
  CHANGE_CURRENCY: 'change_currency',
  HIDE_COLUMN: 'hide_column',
  SHOW_COLUMN: 'show_column',
  RIGHT_PANEL_TAB_CHANGE: 'right_panel_tab_change',
  TOGGLE_LEGEND: 'toggle_legend',
  CHANGE_DATE: 'change_date',
  CHANGE_COMPARE_DATE: 'change_compare_date',
  CHOOSE_SUGGESTED_MESSAGE: 'choose_suggested_message',
  CLEAR_CONVERSATION: 'clear_conversation',
  OPEN_ADD_TO_REPORT: 'open_add_to_report',
  CLICKED_QUICK_FILTERS: 'clicked_quick_filters',
  SAVE_QUICK_FILTER: 'save_quick_filter',
  SAVE_AND_APPLY_QUICK_FILTER: 'save_and_apply_quick_filter',
  ENABLE_QUICK_FILTER: 'enable_quick_filter',
  DISABLE_QUICK_FILTER: 'disable_quick_filter',
  GENERATE_WIDGET_INSIGHTS: 'generate_widget_insights',
  VIEW_WIDGET_INSIGHTS: 'view_widget_insights',
} as const;

export const metricBuilderActions = {
  OPEN_METRIC_BUILDER: 'open_metric_builder',
  OPEN_CUSTOM_METRIC_BUILDER: 'open_custom_metric_builder',
  CREATE_METRIC: 'create_metric',
  TEST_METRIC: 'test_metric',
  EDIT_METRIC: 'edit_metric',
  DELETE_METRIC: 'delete_metric',
  DUPLICATE_METRIC: 'duplicate_metric',
  RESET_METRIC: 'reset_metric',
  CREATE_CUSTOM_METRIC: 'create_custom_metric',
  TEST_CUSTOM_METRIC: 'test_custom_metric',
  EDIT_CUSTOM_METRIC: 'edit_custom_metric',
  ADD_METRIC_TO_PLAYGROUND: 'add_metric_to_playground',
  GO_TO_DATA_DICTIONARY: 'go_to_data_dictionary',
} as const;

export const upgradeCtaActions = {
  FREE_TRIAL_POPUP_VIEWED: 'viewed_free_trial_popup',
  START_FREE_TRIAL: 'start_free_trial',
  VIEW_PREMIUM_PLUS_TO_MOBY_UPGRADE_POPUP: 'view_premium_plus_to_moby_upgrade_popup',
  VIEWED_INITIAL_FREE_TRIAL_POPUP: 'viewed_initial_free_trial_popup',
  CLOSE_INITIAL_FREE_TRIAL_POPUP: 'close_initial_free_trial_popup',
  COMPLETE_UPGRADE: 'complete_upgrade',
  HIT_CALL_TO_ACTION: 'hit_call_to_action',
  HIT_LOCK_SCREEN: 'hit_lock_screen',
  HIT_CALL_TO_ACTION_NO_REVENUE: 'hit_call_to_action_no_revenue',
  PLAN_SELECTED_NO_REVENUE: 'plan_selected_no_revenue',
  PLAN_SELECTED: 'plan_selected',
  BOOK_DEMO: 'book_demo',
  ADD_ON_SELECTED: 'add_on_selected',
  CONFIRM_ADD_ON: 'confirm_add_on',
  PAYMENT_METHOD_ADDED: 'payment_method_added',
  UPGRADE_COMPLETED: 'upgrade_completed',
  BANNER_CLICK: 'banner_click',
} as const;

export const ppsActions = {
  AUDIENCE_SEGMENTATION: 'Audience Segmentation',
  ANSWER_RANDOMIZATION: 'Answer Randomization',
  EDIT_QUESTION: 'Edit Question',
  SAVE_LOGIC: 'Save Logic',
  QUESTION_LOGIC: 'Question Logic',
  REMOVE_LOGIC: 'Remove Logic',
  ADD_QUESTION: 'Add Question',
  ADD_QUESTION_OPEN_ENDED: 'Add-Question-Open-Ended',
  ADD_QUESTION_MULTIPLE_CHOICE: 'Add-Question-Multiple-Choice',
  INSTALL_SURVEY: 'Install Survey',
  UNINSTALL_SURVEY: 'Uninstall Survey',
} as const;

export const feedbackActions = {
  BUTTON_CLICK: 'button_click',
  CLOSE_BUTTON: 'close_button',
  SUBMISSION: 'submission',
  CLOSE_FEEDBACK_FORM: 'close_feedback_form',
  HIDE_FEEDBACK_BUTTON: 'hide_feedback_button',
  OPEN_FEEDBACK_FORM: 'open_feedback_form',
  UNHIDE_FEEDBACK_BUTTON: 'unhide_feedback_button',
};
