import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { isLocalhost, isProduction, firebase } from 'config';
import { isInIframe } from '../config';

if (!isInIframe) {
  try {
    // we need to check production and !localhost because local can be run in production mode
    // but we only want to run datadog RUM on real production
    if (isProduction || isLocalhost) {
      initDataDogRum();
    }

    // run datadoglogs on all environments
    initDataDogLogs();
  } catch {}
}

function initDataDogRum() {
  datadogRum.init({
    applicationId: '92d08acb-21a6-49f5-9928-25ea14f1ded5',
    clientToken: 'pubb001436d6ccc8709a112402e3f0f6b83',
    site: 'us5.datadoghq.com',
    service: 'app',
    version: '1.0.0',
    env: firebase?.projectId,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0, // no session replay
    traceSampleRate: 0, // no trace api calls
    trackResources: false,
    trackUserInteractions: false, // No clicks, scrolls, etc.
    trackLongTasks: false, // No long task monitoring
    enableExperimentalFeatures: [], // Disable experimental features
    allowedTracingUrls: [
      (url) => {
        return (
          url.startsWith('https://api.triplewhale.com') ||
          url.startsWith('https://app.triplewhale.com')
        );
      },
    ],
    defaultPrivacyLevel: 'mask-user-input',
  });
}

function initDataDogLogs() {
  datadogLogs.init({
    clientToken: 'pubb001436d6ccc8709a112402e3f0f6b83',
    site: 'us5.datadoghq.com',
    forwardErrorsToLogs: true,
    env: firebase?.projectId,
    sessionSampleRate: 100,
  });
}
