import { AFFLUENCER } from 'constants/types';
import { $currentShopId, $activeAccounts } from '$stores/$shop';
import moment from '@tw/moment-cached/module/timezone';
import axiosInstance from 'utils/axiosInstance';

export const getConversions = async (
  dateModel,
  attributionModel,
  source,
  type,
  id,
  unmatchedIds,
  start,
  end,
  attributionWindow,
  useNewModels,
  integrationId,
  campaignIds,
  adsetId,
  esKey = '',
  metricKey,
) => {
  const attributionFilters: any = [];

  const handleSingleValue = (value) => {
    // `(not-set)` should be simple string
    return value?.length === 1 ? value[0] : value;
  };

  // push current entity filter
  let entityId;
  if (source === AFFLUENCER) {
    entityId = id;
  } else {
    entityId = type === 'campaignId' && esKey ? esKey : id;
  }
  const filterValue = [...(entityId ? [entityId] : []), ...(unmatchedIds || [])];
  attributionFilters.push({ key: type, value: handleSingleValue(filterValue) });

  //  push parent entities filter
  if (campaignIds.length && type !== 'campaignId') {
    attributionFilters.push({ key: 'campaignId', value: handleSingleValue(campaignIds) });
  }
  if (adsetId.length && type !== 'adsetId') {
    attributionFilters.push({ key: 'adsetId', value: handleSingleValue(adsetId) });
  }
  const name = metricKey.split('_conversion')[0];
  const params: any = {
    shopDomain: $currentShopId.get(),
    additionalShopIds: $activeAccounts.get() || [],
    integrationId,
    model: attributionModel,
    attributionFilters,
    startDate: start,
    endDate: end,
    isClickDate: dateModel === 'clickDate',
    source: source === 'tw_referrer' ? 'organic_and_social' : source,
    attributionWindow,
    useNewModels,
    name,
  };

  let response;
  ({ data: response } = await axiosInstance.post(
    `v2/attribution/get-attributed-conversions`,
    params,
  ));

  const conversions =
    response?.conversions?.sort((a, b) => (a.eventDate < b.eventDate ? 1 : -1)) || [];
  return conversions;
};

export const reducers = {};
