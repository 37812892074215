import { useEffect, useState, useCallback, useRef } from 'react';
import { RunDoc, WillyDataSequenceWithRuns, WorkflowWithRun } from '../types/willyTypes';
import { $currentShopId, $shop } from '$stores/$shop';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import _db, { toArray } from 'utils/DB';
import { $shopSequences } from '$stores/willy/$sequences';
import { add } from 'lodash';

export const usePinnedWorkflows = () => {
  const currentShopId = useStoreValue($currentShopId);
  const shopWorkflows = useStoreValue($shopSequences);
  const pinnedWorkflowIds = useComputedValue($shop, (shop) => shop.pinnedSequences || []);
  const [pinnedWorkflows, setPinnedWorkflows] = useState<WillyDataSequenceWithRuns[]>([]);
  const [lastRunWorkflowRun, setLastRunWorkflowRun] = useState<WillyDataSequenceWithRuns | null>(
    null,
  );
  const [loadingLastRun, setLoadingLastRun] = useState(false);
  const [loading, setLoading] = useState(true);
  const isInitialMount = useRef(true);

  const fetchWorkflowWithLastRun = useCallback(
    async (workflowId: string) => {
      const ref = _db(currentShopId).collection('data_sequences');
      const wf = shopWorkflows.find((x) => x.id === workflowId);
      if (!wf) return null;

      const sequenceRuns = await ref
        .doc(workflowId)
        .collection('runs')
        .orderBy('runAt', 'desc')
        .limit(1)
        .get();
      const lastSequenceRun = sequenceRuns?.docs?.[0]?.data();
      return { ...wf, runs: [lastSequenceRun] };
    },
    [currentShopId, shopWorkflows],
  );

  const fetchPinnedWorkflows = useCallback(async () => {
    try {
      setLoading(true);
      const pinnedWorkflows = await Promise.all(
        pinnedWorkflowIds?.map(async (sequence) => {
          return await fetchWorkflowWithLastRun(sequence);
        }),
      );
      setPinnedWorkflows(pinnedWorkflows.filter(Boolean) as WillyDataSequenceWithRuns[]);
    } catch (error) {
      console.error('Error fetching pinned workflows:', error);
    } finally {
      setLoading(false);
    }
  }, [pinnedWorkflowIds, fetchWorkflowWithLastRun]);

  const addPinnedWorkflow = async (workflowId) => {
    const workflowWithLastRun = await fetchWorkflowWithLastRun(workflowId);
    setPinnedWorkflows((prev) => {
      const newList = [...prev, workflowWithLastRun];
      return newList.filter(Boolean) as WillyDataSequenceWithRuns[];
    });
  };

  const removePinnedWorkflow = async (workflowId) => {
    setPinnedWorkflows((prev) => {
      const newList = prev.filter((wf) => wf.id !== workflowId);
      return newList;
    });
  };

  // const fetchLastRunWorkflowRun = useCallback(async () => {
  //   setLoadingLastRun(true);
  //   let query = _db(currentShopId)
  //     .collection('data_sequences')
  //     .where('lastRunAt', '!=', null)
  //     .orderBy('lastRunAt', 'desc')
  //     .limit(1);
  //   const workflowRef = await query.get();
  //   const asArray = toArray(workflowRef) as WorkflowWithRun[];
  //   const lastWorkflow = asArray?.[0];
  //   const lastRunRef = await _db(currentShopId)
  //     .collection('data_sequences')
  //     .doc(lastWorkflow.id)
  //     .collection('runs')
  //     .orderBy('finishedAt', 'desc')
  //     .limit(1)
  //     .get();
  //   const runAsArray = toArray(lastRunRef) as RunDoc[];

  //   const lastRun = runAsArray?.[0];
  //   setLastRunWorkflowRun({ ...lastWorkflow, runs: [lastRun] } as WillyDataSequenceWithRuns);
  //   setLoadingLastRun(false);
  // }, [currentShopId]);

  useEffect(() => {
    if (isInitialMount.current && !!shopWorkflows.length && !!pinnedWorkflowIds.length) {
      isInitialMount.current = false;
      fetchPinnedWorkflows();
      // fetchLastRunWorkflowRun();
    }
  }, [fetchPinnedWorkflows, shopWorkflows.length, pinnedWorkflowIds]);

  const refresh = useCallback(() => {
    fetchPinnedWorkflows();
    // fetchLastRunWorkflowRun();
  }, [fetchPinnedWorkflows]);

  return {
    pinnedWorkflows,
    loading: loading && loadingLastRun,
    refresh: refresh,
    lastRunWorkflowRun,
    addPinnedWorkflow,
    removePinnedWorkflow,
  };
};
