import WillyDashNewActions from './category-management/WillyDashNewActions';
import { FavoriteDashboardOptionItem } from './category-management/WillyDashboardOptionItem';
import {
  $dashSidePanelOpenMainCats,
  $filteredDashboardsBySearch,
  $dashSearch,
  toggleSidePanelMainCats,
} from './category-management/stores';
import { WillyDashSidePanelCategories } from './category-management/WillyDashSidePanelCategories';
import { useComputedValue, useStoreValue, useWritableStore } from '@tw/snipestate';
import { Box, Flex, Icon, Text, TextInput } from '@tw/ui-components';
import { MobileDrawer } from '@tw/ui-components';
import { $reportSelectorDrawer, closeReportSelectorDrawer } from '$stores/willy/$mobileDrawers';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { WillyDashSidePanelStandardCats } from './category-management/WillyDashSidePanelStandardCats';
import { NavLink } from 'react-router-dom';
import { PlanUpgradeCTA } from './PlanUpgradeCTA';
import { $isFreeShop } from '$stores/willy/$subscription';
import { useEffect } from 'react';

export function WillyDashSidePanel() {
  return <DashMenuContent />;
}

export const MobileDrawerDashReportSelector = () => {
  const drawerOpen = useComputedValue($reportSelectorDrawer, (r) => r.opened);
  return (
    <MobileDrawer
      opened={drawerOpen}
      onClose={() => closeReportSelectorDrawer()}
      title="Select Dashboard"
    >
      <div className="m-[-16px]">
        <DashMenuContent />
      </div>
    </MobileDrawer>
  );
};

function SidePanelSearchInput() {
  const [freeSearch, setFreeSearch] = useWritableStore($dashSearch);

  useEffect(() => {
    return () => {
      setFreeSearch('');
    };
  }, [setFreeSearch]);

  return (
    <div className="bg-white overflow-hidden sticky top-0 z-10 dark:tw-nice-dark p-6.5">
      <TextInput
        placeholder="Search Dashboards"
        value={freeSearch}
        onChange={setFreeSearch}
        leadingIcon={<Icon name="search-major" size={14} />}
      />
    </div>
  );
}

function WillyDashSidePanelFavorites() {
  const filteredDashboards = useStoreValue($filteredDashboardsBySearch);
  const isOpen = useComputedValue($dashSidePanelOpenMainCats, (cats) => cats.includes('favorites'));

  return (
    <div className="m-2 cursor-pointer">
      <div
        className="flex items-center p-4 gap-[6px]"
        onClick={(e) => {
          e.stopPropagation();
          toggleSidePanelMainCats('favorites');
        }}
      >
        <span className="lg:opacity-0 group-hover/report-side-panel:opacity-100 ">
          <span className="render-collapse-icon">
            <div
              style={{
                transform: !isOpen ? 'rotate(-90deg)' : '',
              }}
            >
              <Icon name="arrow-down-3" size={9} color="gray.4" />
            </div>
          </span>
        </span>
        <Text fz="xs" fw="500" c="gray.5" tt="uppercase">
          Favorites
        </Text>
      </div>
      {isOpen && (
        <div className="ml-[13px]">
          <FavoriteDashboardOptionItem dashboards={filteredDashboards} />
        </div>
      )}
    </div>
  );
}

function DashMenuContent() {
  const isSmall = useIsSmall();
  const isFreeShop = useStoreValue($isFreeShop);

  return (
    <>
      <div className="bg-white sticky top-0 z-10">
        {!isSmall && (
          <>
            <Flex justify="space-between" align="center" p="md" h={65}>
              <Text fz="xl" fw="500">
                Dashboards
              </Text>
              <WillyDashNewActions />
            </Flex>

            <div className="border-t border-solid border-l-0 border-r-0 border-b-0 willy-border" />
          </>
        )}
        <SidePanelSearchInput />
      </div>

      <div className="willy-side-panel dash-panel group/report-side-panel">
        <WillyDashSidePanelFavorites />

        <WillyDashSidePanelStandardCats />

        <WillyDashSidePanelCategories />

        <NavLink to="/templates" className="no-underline text-[var(--mantine-color-one-5)]">
          <div className="flex gap-2 p-6 items-center">
            <Icon name="global" size={14} color="named.6" />
            <Text size="xs" color="named.6" fw={500}>
              Explore Templates
            </Text>
          </div>
        </NavLink>
      </div>

      {isFreeShop && (
        <Box p="sm">
          <PlanUpgradeCTA
            withIcon
            buttonProps={{ size: 'sm' }}
            customMessages={{
              Growth: 'Create your own custom dashboards. Upgrade to premium features.',
            }}
          />
        </Box>
      )}
    </>
  );
}
