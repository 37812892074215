import { Header, Flex } from '@tw/ui-components';
import { $topBarHeight, useActiveRoute } from '$stores/nav-config-stores';
import { TOP_BAR_BG_VAR, TOP_BAR_BORDER_VAR } from './constants';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { FREE_TRIAL_BANNER_HEIGHT } from '$stores/willy/$upgradePopupManager';
import { useShowFreeTrialBanner } from 'components/Willy/UpgradePlan/FreeTrialBanner';

const BASE_HEADER_HEIGHT = 66;
const PADDING = 24;

export const AppsTopBar: React.FC = () => {
  const { activeRoute, isLocked } = useActiveRoute();
  const InnerHeader = !isLocked ? activeRoute?.header ?? null : null;
  const showFreeTrialBanner = useShowFreeTrialBanner();

  const setInnerHeaderRef = useResizeObserver((entries) => {
    if (!activeRoute) return;

    const entry = entries[0];
    if (!entry) {
      $topBarHeight.set(BASE_HEADER_HEIGHT);
      return;
    }

    const innerHeight = entry.contentBoxSize[0].blockSize + PADDING;
    $topBarHeight.set(Math.max(BASE_HEADER_HEIGHT, innerHeight));
  });

  return (
    <Header
      bg={TOP_BAR_BG_VAR}
      borderBottom={TOP_BAR_BORDER_VAR}
      top={showFreeTrialBanner ? FREE_TRIAL_BANNER_HEIGHT : undefined}
    >
      <Flex
        ref={setInnerHeaderRef}
        direction="column"
        justify="center"
        p="sm"
        align="flex-start"
        mih={BASE_HEADER_HEIGHT}
      >
        {InnerHeader && (
          <div className="w-full">
            <InnerHeader />
          </div>
        )}
      </Flex>
    </Header>
  );
};
