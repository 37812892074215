import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  type CDPUserPropertyFilter,
  type CDPSegmentFilter,
  CDPUserProperty,
} from '@tw/types/module/services/cdp';
import FilterComparator from '../../Insights/Filters/FilterComparator';
import { USER_PROPERTIES_OPTIONS, filterUnSupportedProperties } from './utils';
import { getPropertyType } from 'components/Insights/Filters/constants';
import { getFilterQueryLabel } from 'components/Insights/Filters/queryTranslation';
import { Select } from '@tw/ui-components';

type Props = {
  filter: CDPUserPropertyFilter;
  onChanged: (filter: CDPSegmentFilter) => void;
  isReadonly?: boolean;
};
const UserPropertyFilter: React.FC<Props> = (props) => {
  const propertiesOptions = USER_PROPERTIES_OPTIONS;

  const handlePropertyChanged = useCallback((property: CDPUserProperty) => {
    setFilter((prevValue) => {
      if (prevValue.definition.property?.property === property) {
        return prevValue;
      }

      return {
        ...prevValue,
        definition: { ...prevValue.definition, property: { property } },
      } as CDPUserPropertyFilter;
    });
  }, []);

  const handleComparatorChanged = useCallback(({ comparator, value, value1, value2, unit }) => {
    setFilter((prevValue) => {
      return {
        ...prevValue,
        definition: {
          ...prevValue.definition,
          property: {
            ...prevValue.definition.property,
            comparator: comparator || prevValue.definition.property.comparator,
            value,
            value1,
            value2,
            unit,
          },
        },
      };
    });
  }, []);

  const getComparatorType = () => {
    return getPropertyType(filter.definition.property.property);
  };

  const [filter, setFilter] = useState<CDPUserPropertyFilter>(props.filter);

  const suportedProperties = useMemo(() => {
    return filterUnSupportedProperties(propertiesOptions, filter.definition.property.property);
  }, [filter.definition.property.property, propertiesOptions]);

  useEffect(() => {
    props.onChanged(filter);
  }, [filter]);

  return (
    <React.Fragment>
      <Select
        w={180}
        data={suportedProperties.map((o) => ({ label: getFilterQueryLabel(o), value: o }))}
        value={filter.definition.property.property}
        onChange={(val) => handlePropertyChanged(val as CDPUserProperty)}
      />
      {filter.definition.property.property && (
        <FilterComparator
          comparatorType={getComparatorType()}
          comparator={filter.definition.property.comparator}
          value={filter.definition.property.value}
          property={filter.definition.property.property}
          onChanged={handleComparatorChanged}
          isReadonly={props.isReadonly}
          required
        ></FilterComparator>
      )}

      <div className="basis-full"></div>
    </React.Fragment>
  );
};

export default UserPropertyFilter;
