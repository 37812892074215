import { useNavigate, useParams } from 'react-router-dom';
import { analyticsEvents } from 'utils/dataLayer/constants';

import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useLocation } from 'react-router-dom';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { genericEventLogger, chatActions } from 'utils/dataLayer';
import { $mainChatStore, setSqlGeneratingModel } from '$stores/willy/$mainChat';
import { ActionIcon, Anchor, Icon, Text, Tooltip } from '@tw/ui-components';
import { Flex, Switch } from '@tw/ui-components';
import { WILLY_NAV_ITEMS } from './willyNavItems';
import { WillyNavItem } from './WillyNavItem';
import { $isAdminClaim } from '$stores/$user';
import { WillySidePanelWorkflows } from './WillySidePanelWorkflows';
import { DEFAULT_MODEL } from './constants';

type WillySidePanelProps = {
  currentPage?: string;
  buildMode?: boolean;
  setBuildMode?: React.Dispatch<React.SetStateAction<boolean>>;
  clearConversation: () => void;
  conversationId?: string;
  currentAnalyticsEvent: string;
  inTooltip?: boolean;
};

export const WillySidePanel: React.FC<WillySidePanelProps> = ({
  currentPage = '',
  buildMode,
  setBuildMode,
  clearConversation,
  conversationId,
  currentAnalyticsEvent,
  inTooltip,
}) => {
  const isSmall = useIsSmall();
  const { search } = useLocation();
  const params = useParams<{ workflowId: string; workflowRunId: string }>();
  const navigate = useNavigate();
  const sqlGeneratingModel = useComputedValue($mainChatStore, (r) => r.sqlGeneratingModel);
  const isAdmin = useStoreValue($isAdminClaim);

  const activeNavItem = currentPage;

  const updateBuildMode = (value) => {
    if (!!setBuildMode) {
      const newMode = value ? 'build' : 'analyze';
      setBuildMode(value);
      genericEventLogger(analyticsEvents.CHAT, {
        action: chatActions.TOGGLE_CHAT_BUILD_MODE,
        buildMode: newMode,
      });
    }
  };

  const updateModel = (value) => {
    const newModel = value ? DEFAULT_MODEL : 'default';
    setSqlGeneratingModel(newModel);
    genericEventLogger(analyticsEvents.CHAT, {
      action: chatActions.TOGGLE_CHAT_MODEL,
      model: newModel,
    });
  };

  return (
    <div className="flex flex-col h-full willy-side-panel">
      <div className="bg-white sticky top-0 z-10 ">
        {!isSmall && (
          <>
            <Flex justify="space-between" align="center" p="md" h={65}>
              <Text fz="xl" fw="500">
                Moby
              </Text>
              <Tooltip lightTooltip={true} label="Start new chat">
                <ActionIcon
                  icon="chat"
                  display="block"
                  iconColor="gray.5"
                  iconSize={20}
                  onClick={() => {
                    clearConversation();
                    const params = new URLSearchParams(search);
                    params.delete('conversationId');
                    params.delete('sequenceId');
                    params.delete('runId');
                    params.delete('tab');
                    params.delete('prompt');
                    navigate({
                      pathname: '/chat',
                      search: params.toString(),
                      hash: '',
                    });
                    if (conversationId) {
                      genericEventLogger(currentAnalyticsEvent, {
                        action: chatActions.NEW_CONVERSATION,
                        conversationId,
                      });
                    }
                  }}
                />
              </Tooltip>
            </Flex>

            <div className="border-t border-solid border-l-0 border-r-0 border-b-0 willy-border" />
          </>
        )}
      </div>
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col gap-20">
          <div className="p-4 gap-4 flex flex-col">
            {WILLY_NAV_ITEMS.filter((item) => !item.hideFromSidePanel).map((item) => (
              <WillyNavItem key={item.id} item={item} activeTab={activeNavItem} />
            ))}
          </div>
          <WillySidePanelWorkflows
            chatWithSequenceId={params?.workflowId ?? null}
            inTooltip={inTooltip}
            currentPage={currentPage}
          />
        </div>
        {!inTooltip && (
          <div>
            <div className="border-t border-solid border-l-0 border-r-0 border-b-0 willy-border" />
            <div className="p-4 gap-4 flex flex-col">
              {!isSmall && (
                <Tooltip
                  label={
                    <div className="flex flex-col gap-1 p-3">
                      <Text fw={500}>Build Mode</Text>
                      <Text fz={14}>
                        Build Mode enables you to generate dashboards from your conversations with
                        just one click.
                      </Text>
                    </div>
                  }
                  lightTooltip={true}
                  multiline={true}
                  w={300}
                >
                  <span>
                    <Switch
                      size="sm"
                      label={
                        <Text span fz="sm" c="gray.8" fw={500}>
                          Build Mode
                        </Text>
                      }
                      onChange={(e) => updateBuildMode(e.target.checked)}
                      checked={buildMode}
                    />
                  </span>
                </Tooltip>
              )}
              <Tooltip
                label={
                  <div className="flex flex-col gap-1 p-3">
                    <Text fw={500}>Pro Model</Text>
                    <Text fz={14}>
                      A slightly slower model that excels at complex queries through deeper
                      analysis.
                    </Text>
                  </div>
                }
                lightTooltip={true}
                multiline={true}
                w={300}
              >
                <span>
                  <Switch
                    size="sm"
                    label={
                      <Text span fz="sm" c="gray.8" fw={500}>
                        Pro Model
                      </Text>
                    }
                    onChange={(e) => updateModel(e.target.checked)}
                    checked={sqlGeneratingModel === 'o3-mini'}
                  />
                </span>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
