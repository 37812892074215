import { Icon, Text, Button, Tooltip } from '@tw/ui-components';
import { WILLY_SEQUENCE_STEP_CATEGORIES, WILLY_SEQUENCE_STEP_OPTIONS } from '../constants';
import { SequenceBuilderDrawer } from './SequenceBuilderDrawer';
import { WorkflowStep, WorkflowStepTypes, shelfCategoryType } from '../types/willyTypes';
import { useMemo, useState } from 'react';
import { StepSelector } from './StepSelector';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';

type StepDrawerProps = {
  addNewStep: (step: Partial<WorkflowStep>, ruleDescendant?: boolean) => void;
  open: boolean;
  onClose: () => void;
  fromRule?: boolean;
  sequenceId?: string | undefined;
  depth: number;
  stepsUntilNow?: WorkflowStep[];
};

export const StepDrawer: React.FC<StepDrawerProps> = ({
  addNewStep,
  open,
  onClose,
  fromRule,
  sequenceId,
  depth,
  stepsUntilNow,
}) => {
  const currentSlackChannel = useSelector((state: RootState) => state.currentSlackChannel);
  const [activeCategory, setActiveCategory] = useState<shelfCategoryType | null>(null);
  const isGoogleSheetsConnected = useSelector((state: RootState) => state.isGoogleSheetsConnected);

  const isFirstStep = depth === 0 && stepsUntilNow?.length === 0;

  const onClickCategory = (category: shelfCategoryType) => {
    const stepsInCategory = WILLY_SEQUENCE_STEP_OPTIONS.filter((step) => {
      return step.category === category.id;
    });
    if (stepsInCategory.length === 1) {
      const action = stepsInCategory[0];
      addNewStep({ createdAt: new Date().toISOString(), stepType: action.type }, fromRule);
    } else {
      setActiveCategory((old) => (old === category ? null : category));
    }
  };

  const closeCategoryDrawer = () => {
    setActiveCategory(null);
  };

  const disabledSteps = useMemo(() => {
    const disabledSteps: Partial<Record<WorkflowStepTypes, boolean>> = {};

    const previousStep = stepsUntilNow?.at(-1);

    if (isFirstStep) {
      disabledSteps.sendToDashboard = true;
      disabledSteps.sendToEmail = true;
      disabledSteps.sendToSlack = true;
      disabledSteps.sendToGoogleSheet = true;
      disabledSteps.sendToWebhook = true;
      disabledSteps.rule = true;
    }

    if (!sequenceId) {
      disabledSteps.preloadRuns = true;
    }

    if (!currentSlackChannel) {
      disabledSteps.sendToSlack = true;
    }

    if (!isGoogleSheetsConnected) {
      disabledSteps.sendToGoogleSheet = true;
      disabledSteps.preloadGoogleSheet = true;
    }

    // return true if previousStep is not runQuery and not tool with toolToUse TextToSQL
    if (
      (previousStep?.stepType !== 'runQuery' && previousStep?.stepType !== 'tool') ||
      (previousStep?.stepType === 'tool' &&
        previousStep?.toolToUse !== 'TextToSQL' &&
        previousStep?.toolToUse !== 'TextToPython')
    ) {
      disabledSteps.loop = true;
    }

    if (previousStep?.stepType === 'rule') {
      disabledSteps.rule = true;
      disabledSteps.condition = true;
    }

    if (previousStep?.stepType === 'condition') {
      disabledSteps.condition = true;
      disabledSteps.rule = true;
    }

    if (previousStep?.stepType === 'sendToDashboard') {
      disabledSteps.sendToDashboard = true;
    }

    if (previousStep?.stepType === 'sendToEmail') {
      disabledSteps.sendToEmail = true;
    }

    if (previousStep?.stepType === 'sendToSlack') {
      disabledSteps.sendToSlack = true;
    }

    if (previousStep?.stepType === 'sendToGoogleSheet') {
      disabledSteps.sendToGoogleSheet = true;
    }

    if (previousStep?.stepType === 'sendToWebhook') {
      disabledSteps.sendToWebhook = true;
    }

    return disabledSteps;
  }, [stepsUntilNow, isFirstStep, sequenceId, currentSlackChannel, isGoogleSheetsConnected]);

  const categorySequenceSteps = useMemo(() => {
    if (!activeCategory) return [];
    return WILLY_SEQUENCE_STEP_OPTIONS.filter((step) => step.category === activeCategory?.id);
  }, [activeCategory]);

  return (
    <>
      <SequenceBuilderDrawer open={open} title="Add Step" onClose={() => onClose()}>
        <div className="p-5 flex flex-col gap-5">
          <Text fz={14}>
            These are the events that start a sequence of actions, like date or data changes.
          </Text>
          {WILLY_SEQUENCE_STEP_CATEGORIES.map((category) => {
            return (
              <div className="flex flex-col gap-5 w-full" key={category.id}>
                <Tooltip withArrow w={250} multiline label={category.tooltip}>
                  <Button
                    variant="white"
                    justify="start"
                    size="md"
                    onClick={() => onClickCategory(category)}
                    leftSection={<Icon name={category.icon} />}
                  >
                    {category.name}
                  </Button>
                </Tooltip>
                {activeCategory?.id === category.id && (
                  <div className="pl-8 flex flex-col gap-5">
                    {categorySequenceSteps.map((action) => {
                      const shouldBeDisabled = disabledSteps[action.type] ?? false;
                      return (
                        <StepSelector
                          key={action.type === 'tool' ? action.toolName : action.type}
                          action={action}
                          shouldBeDisabled={shouldBeDisabled}
                          fromRule={fromRule}
                          addNewStep={addNewStep}
                          closeCategoryDrawer={closeCategoryDrawer}
                          onClose={onClose}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </SequenceBuilderDrawer>
    </>
  );
};
