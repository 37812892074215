import { FC, Fragment, useCallback, useMemo } from 'react';
import { Flex, Popover, Text, Tooltip, Icon as TwIcon } from '@tw/ui-components';
import { NavLink } from 'react-router-dom';
import { useFilterMenu } from '../../../hooks/useFilterMenu';
import { $navigationSections, useActiveRoute } from '$stores/nav-config-stores';
import store, { useAppDispatch } from 'index';
import { changeWillySideMenuWidth } from 'ducks/willy';
import { useStoreValue } from '@tw/snipestate';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';
import { MoreSectionPopover } from './MoreSectionPopover';
import { NavSection, NavSectionRoute } from 'constants/routes/types';
import { TOP_BAR_BORDER_VAR } from 'components/TopBar/constants';
import { useDelayedDisclosure } from 'hooks/useDelayedDisclosure';

export const MainLinksSection = () => {
  const navigationSections = useStoreValue($navigationSections);
  const alanSections = useFilterMenu(navigationSections, 'alan');
  const { top, bottom } = useMemo(() => {
    return alanSections.reduce(
      (acc, s) => {
        if (s.routes.some((r) => r.url === '/get-started')) acc.bottom.push(s);
        else acc.top.push(s);
        return acc;
      },
      { top: [] as NavSection[], bottom: [] as NavSection[] },
    );
  }, [alanSections]);

  return (
    <>
      <LinksSection sections={top} />

      <MoreSectionPopover />

      {!!bottom.length && (
        <>
          <div className="my-4 -mx-[15px]" style={{ borderBottom: TOP_BAR_BORDER_VAR }} />
          <LinksSection sections={bottom} />
        </>
      )}
    </>
  );
};

function LinksSection({ sections }: { sections: NavSection[] }) {
  const dispatch = useAppDispatch();
  const { activeRoute, activeSection } = useActiveRoute();

  return (
    <Flex direction="column" align="center" gap="xs">
      {sections.map((section, i) => {
        const { icon: Icon, title, routes, spread, rawIconName } = section;

        if (spread !== false) {
          return (
            <Fragment key={(title || '') + i}>
              {routes
                .filter((r) => !r.hidden)
                .map((r) => (
                  <NavLinkWithTooltip
                    key={`nav-link-tp-sm-${r.url}-${i}`}
                    route={r}
                    index={i}
                    selected={activeRoute === r}
                    showTooltip={false}
                  />
                ))}
            </Fragment>
          );
        }

        return (
          <NavLink
            className="flex flex-col items-center cursor-pointer mb-2 no-underline"
            to={{ pathname: routes[0].url }}
            key={`nav-link-sm-${routes[0].label}-${i}`}
            onClick={(e) => {
              const clickCount = e.detail;
              const willySideMenuWidth = store.getState().willy.willySideMenuWidth;
              if (clickCount < 2 || willySideMenuWidth > 0) return;
              dispatch(changeWillySideMenuWidth(250));
            }}
          >
            {Icon ? (
              <Icon width={28} height={28} selected={activeSection?.title === title} />
            ) : (
              rawIconName && <TwIcon name={rawIconName} />
            )}
            <Text fz={12} weight={500}>
              {title}
            </Text>
          </NavLink>
        );
      })}
    </Flex>
  );
}

type NavLinkWithTooltipProps = {
  route: NavSectionRoute;
  selected: boolean;
  index: number;
  showTooltip?: boolean;
};

const NavLinkWithTooltip: FC<NavLinkWithTooltipProps> = ({
  route,
  selected,
  index,
  showTooltip,
}) => {
  const { opened, open, close } = useDelayedDisclosure({
    secondsToWait: 0.15,
    waitBeforeClose: true,
    waitBeforeOpen: true,
  });

  const dispatch = useAppDispatch();

  const icon = useMemo(
    () => typeof route.icon === 'function' && route.icon?.({ selected: selected || opened }),
    [route.icon, selected, opened],
  );

  const handleDropdownClick = useCallback(
    (e: React.MouseEvent) => {
      const isInputOrChildOfInput = (e.target as HTMLElement).closest('input');
      if (!isInputOrChildOfInput) close();
    },
    [close],
  );

  const NavLinkItem = useMemo(() => {
    return (
      <NavLink
        className="flex flex-col items-center cursor-pointer mb-2 no-underline"
        to={{ pathname: route.url, search: window.location.search }}
        key={`nav-link-sm-${route.url}-${index}`}
        onClick={(e) => {
          const clickCount = e.detail;
          const willySideMenuWidth = store.getState().willy.willySideMenuWidth;
          if (clickCount < 2 || willySideMenuWidth > 0) return;
          dispatch(changeWillySideMenuWidth(250));
        }}
      >
        {icon && <Flex>{icon}</Flex>}
        <ConditionalWrapper
          condition={!!route.shortLabel && route.label !== route.shortLabel}
          wrapper={(x) => <Tooltip label={route.label}>{x}</Tooltip>}
        >
          <Text fz={12} weight={500} ta="center">
            {route.shortLabel || route.label}
          </Text>
        </ConditionalWrapper>
      </NavLink>
    );
  }, [route, index, icon, dispatch]);

  return showTooltip ? (
    <div onMouseEnter={open} onMouseLeave={close} className="w-full">
      <Popover
        opened={opened}
        position="right-start"
        offset={{ alignmentAxis: -20, mainAxis: -10 }}
        shadow="0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)"
        onClose={close}
        radius="xl"
      >
        <Popover.Target>{NavLinkItem}</Popover.Target>
        {route.subMenu && (
          <Popover.Dropdown p={0} mah={500} w={250} onClick={handleDropdownClick} overflow="auto">
            {route.subMenu}
          </Popover.Dropdown>
        )}
      </Popover>
    </div>
  ) : (
    NavLinkItem
  );
};
