import SummaryDatePicker from 'components/SummaryDatePicker';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { AllServicesIds } from 'types/attribution';
import AttributionDateModelPicker from 'components/attribution-new/AttributionDateModelPicker';
import AttributionModelPicker from 'components/attribution-new/AttributionModelPicker';
import moment from '@tw/moment-cached/module/timezone';
import AttributionSettings from 'components/attribution-new/AttributionSettings';
import { useCallback, useState } from 'react';
import { selectHasSomePpsInstalled } from 'utils/selectors';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { SourceTypesWithExtra } from 'types/services';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { Flex, Icon, Text } from '@tw/ui-components';

const ActivityPortalHeader: React.FC = () => {
  const isSmall = useIsSmall();
  const { hasPpsData } = useSelector((state: RootState) => state.ppsStatus);
  const timezone = useSelector((state: RootState) => state.shopTimezone);
  const { useNewModels } = useSelector((state: RootState) => state.attribution);
  const sourceCategory = useAttributionActivePage();
  const activeSource = useAttributionActiveSource();
  const hasPixelInActivityFeed = useSelector((state: RootState) => state.hasPixelActivityFeed);
  const [attributionSettingsOpen, setAttributionSettingsOpen] = useState(false);
  const toggleAttributionSettingsOpen = useCallback(() => {
    setAttributionSettingsOpen((o) => !o);
  }, []);
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  return (
    <div className={`flex items-center gap-4 ${!isThreePointOh && 'flex-wrap'}`}>
      {isThreePointOh && (
        <Flex align="center" pl="sm" gap="sm">
          <Icon name="attribution2" color={'gray.5'} size={18} />
          <Text color="gray.7" weight={500} size="lg">
            Activity Feed
          </Text>
        </Flex>
      )}
      {hasPpsData && hasPixelInActivityFeed && (
        <>
          {!isSmall && <AttributionDateModelPicker />}
          {!isSmall && (
            <AttributionModelPicker
              type={sourceCategory as SourceTypesWithExtra}
              sourceId={activeSource as AllServicesIds | 'tw_referrer'}
            />
          )}
          <div id="att-header-settings">
            <AttributionSettings
              hideCurrencyButton={true}
              isOpen={attributionSettingsOpen}
              onOpen={toggleAttributionSettingsOpen}
              type="ads"
              hideAttributionModel={!isSmall}
              hideDateModel={true}
              hideOneDayConversionValue={
                activeSource !== 'facebook-ads' && sourceCategory !== 'ads'
              }
              hideExportCsv={true}
              hideWrapLines={true}
            />
          </div>
        </>
      )}
      <div>
        <SummaryDatePicker
          showGroupByButton={true}
          showCompareButton={true}
          earliestDateProp={useNewModels ? moment('2022-12-31').tz(timezone).toDate() : null}
        />
      </div>
    </div>
  );
};

export default ActivityPortalHeader;
