import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { AttributionData } from 'types/attribution';
import { AttributionTableMetadata } from 'types/attribution';
import UpdatableColumn from './updatableColumn';
import { useContext, useMemo } from 'react';
import axiosInstance from 'utils/axiosInstance';
import {
  genericEventLogger,
  analyticsEvents,
  attributionActions,
  attributionMessages,
} from 'utils/dataLayer';
import { AttributionPageContext } from 'constants/attribution';
import { useStoreValue } from '@tw/snipestate';
import { $shopCurrency } from '../../$stores/$shop';
import { providers } from 'ducks/shopIntegrations';
import { useAttributionParams } from 'utils/useAttributionParam';
import allServices from 'constants/services';
import { UpdateEntityParamsFromClient } from '@tw/types/module/pixel';

type UpdatableBidAmountProps = {
  attribution: AttributionData;
  metadata?: AttributionTableMetadata;
};

enum bidStrategyEnum {
  LOWEST_COST_WITH_BID_CAP = 'Bid Cap',
  COST_CAP = 'Cost Cap',
  LOWEST_COST_WITHOUT_CAP = 'Lowest Cost Without Cap',
}

const UpdatableBidAmount: React.FC<UpdatableBidAmountProps> = ({ attribution, metadata }) => {
  const isReadOnly = !allServices[attribution.serviceId!]?.updatableFields?.['bidAmount'];

  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const shopCurrency = useStoreValue($shopCurrency);
  const accCurrency = useSelector((state: RootState) => state.facebookAdsAccounts[0]?.currency);
  const { setData } = useContext(AttributionPageContext);
  const active = attribution.status === 'ACTIVE';
  const value = attribution['bidAmount'] || 0;
  const { hasUpdateScope, updateAdsAllowed, setIntegrationsPopUpOpened } = metadata || {};
  const providerList = useSelector(providers);

  const tooltipText = updateAdsAllowed
    ? 'Edit Bid Amount'
    : 'Contact the Customer Experience team to enable this feature for your account';

  const formatNoBidAmountText = useMemo(() => {
    if (attribution.entity === 'ad') {
      return '';
    }
    if (attribution.entity === 'adset') {
      return '-';
    }
    if (active) {
      return 'Adset Bid Amount';
    }
    return 'No Bid Amount';
  }, [attribution, active]);

  const updateBidAmount = async (newBidAmount: number, oldBidAmount: number) => {
    if (!hasUpdateScope) {
      if (setIntegrationsPopUpOpened) {
        setIntegrationsPopUpOpened(true);
      }
      return { message: 'More permissions are needed', success: false };
    }
    let message = '',
      success = false;
    try {
      const [serviceId, props] =
        Object.entries(providerList).find(([id, v]) => id === attribution.serviceId) || [];
      let updateURI = '';
      if (serviceId && props?.isSensory) {
        updateURI = `/v2/integration/integrations/update-entity`;
      } else {
        updateURI =
          attribution.serviceId === 'facebook-ads'
            ? '/v2/facebook-ads/update-ad'
            : `/v2/${attribution.serviceId}/update-entity`;
      }
      const { data } = await axiosInstance.post(updateURI, {
        shopId: currentShopId,
        accountId: attribution.accountId,
        entity: attribution.entity,
        id: attribution.id,
        field: `bid_amount`,
        value: attribution.serviceId === 'facebook-ads' ? newBidAmount * 100 : newBidAmount,
        oldValue: attribution.serviceId === 'facebook-ads' ? oldBidAmount * 100 : oldBidAmount,
        integrationId: attribution?.integrationId,
      } as UpdateEntityParamsFromClient);

      if (data.error) {
        message = data.error;
        genericEventLogger(analyticsEvents.ATTRIBUTION, {
          action: attributionActions.SAVE_BID_ERROR,
          message: attributionMessages.SAVE_BID_ERROR,
          error: message,
          shop: currentShopId,
          id: attribution.id,
          campaignId: attribution.campaignId,
          campaignName: attribution.campaignName,
          adsetId: attribution.adsetId,
          adsetName: attribution.adsetName,
        });
      } else {
        message = attributionMessages.SAVE_BID_SUCCESS;
        success = true;
        setData((old) => {
          return old.map((x) => ({
            ...x,
            bidAmount: x.id === attribution.id ? newBidAmount : x.bidAmount,
            adsets: x.adsets?.map((set) => ({
              ...set,
              bidAmount: set.id === attribution.id ? newBidAmount : set.bidAmount,
            })),
          }));
        });
        genericEventLogger(analyticsEvents.ATTRIBUTION, {
          action: attributionActions.SAVE_BID_SUCCESS,
          shop: currentShopId,
          id: attribution.id,
          campaignId: attribution.campaignId,
          campaignName: attribution.campaignName,
          adsetId: attribution.adsetId,
          adsetName: attribution.adsetName,
          message,
          newBidAmount,
        });
      }
    } catch (e) {
      message = e.message;
      genericEventLogger(analyticsEvents.ATTRIBUTION, {
        action: attributionActions.SAVE_BID_ERROR,
        message: attributionMessages.SAVE_BID_ERROR,
        shop: currentShopId,
        id: attribution.id,
        campaignId: attribution.campaignId,
        campaignName: attribution.campaignName,
        adsetId: attribution.adsetId,
        adsetName: attribution.adsetName,
        error: message,
      });
    }
    genericEventLogger(analyticsEvents.ATTRIBUTION, {
      action: attributionActions.SAVE_BID,
      shop: currentShopId,
      id: attribution.id,
      campaignId: attribution.campaignId,
      campaignName: attribution.campaignName,
      adsetId: attribution.adsetId,
      adsetName: attribution.adsetName,
      message,
      success,
      newBidAmount,
    });
    return { message, success };
  };

  return (
    <>
      <UpdatableColumn
        update={updateBidAmount}
        tooltipText={tooltipText}
        permitted={updateAdsAllowed || false}
        noValueText={formatNoBidAmountText}
        value={value}
        canEdit={!!value && active}
        isReadOnly={isReadOnly}
        active={active}
        type={bidStrategyEnum[attribution['bidStrategy']]}
        currency={accCurrency || shopCurrency}
      />
    </>
  );
};

export default UpdatableBidAmount;
