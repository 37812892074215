import { useCallback, useEffect, useRef, useState } from 'react';

interface UseDelayedDisclosureProps {
  secondsToWait: number;
  waitBeforeOpen?: boolean;
  waitBeforeClose?: boolean;
}

interface UseDelayedDisclosureReturn {
  opened: boolean;
  open: () => void;
  close: () => void;
}

export function useDelayedDisclosure({
  secondsToWait,
  waitBeforeOpen = false,
  waitBeforeClose = true,
}: UseDelayedDisclosureProps): UseDelayedDisclosureReturn {
  const [opened, setOpened] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const clearCurrentTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  const open = useCallback(() => {
    clearCurrentTimeout();

    if (waitBeforeOpen) {
      timeoutRef.current = setTimeout(() => {
        setOpened(true);
      }, secondsToWait * 1000);
    } else {
      setOpened(true);
    }
  }, [secondsToWait, waitBeforeOpen, clearCurrentTimeout]);

  const close = useCallback(() => {
    clearCurrentTimeout();

    if (waitBeforeClose) {
      timeoutRef.current = setTimeout(() => {
        setOpened(false);
      }, secondsToWait * 1000);
    } else {
      setOpened(false);
    }
  }, [secondsToWait, waitBeforeClose, clearCurrentTimeout]);

  return { opened, open, close };
}
