import { services } from '@tw/types/module/services';
import { Badge, Button, Icon, IconName, Text } from '@tw/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCanAddTemplate } from '../dashboardManagment/template-management/useCanAddTemplate';
import { LimitedAccessTooltip } from 'components/Nav/components/navs/AlanNav/LimitedAccessTooltip';
import { copyGlobalSequenceToShop } from '../utils/willyUtils';
import { WillyDataSequence } from '../types/willyTypes';
import { toast } from 'react-toastify';
import { analyticsEvents, genericEventLogger, templateLibraryActions } from 'utils/dataLayer';
import { isGlobalDashboardExistsInShop } from '../dashContext';
import { Role } from '$stores/willy/$globalDashboardRoles';
import { useStoreValue } from '@tw/snipestate';
import { $isAgentSupported } from '$stores/willy/$sequences';
import { openUpgradeAgentsPopup } from '../$upgradeAgentsModal';

type WorkflowTemplateTileProps = {
  workflow: WillyDataSequence;
  workflowCategories: Role[];
  hideButtons?: boolean;
};

export const WorkflowTemplateTile: React.FC<WorkflowTemplateTileProps> = ({
  workflow,
  workflowCategories,
  hideButtons,
}) => {
  const navigate = useNavigate();
  const categories = workflow?.roles?.map((role) => {
    return workflowCategories.find((category) => category.id === role);
  });
  const isAgentSupported = useStoreValue($isAgentSupported);

  const randomCategory = categories?.[0];

  const templateAccess = useCanAddTemplate(workflow?.credits);

  const existInShop = useMemo(() => {
    if (workflow) {
      return isGlobalDashboardExistsInShop(workflow);
    }
  }, [workflow]);

  const [copyLoading, setCopyLoading] = useState(false);

  const onClickGetTemplate = () => {
    if (isAgentSupported) {
      copyToShop();
    } else {
      openUpgradeAgentsPopup(
        `You're one step away from adding ${workflow.name} agent!`,
        'add_agent',
      );
    }
  };

  const copyToShop = useCallback(async () => {
    setCopyLoading(true);
    const newId = await copyGlobalSequenceToShop(workflow.id);
    setCopyLoading(false);
    genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
      action: templateLibraryActions.ADD_TEMPLATE_TO_WORKSPACE,
      sequence_id: workflow.id,
      sequence_name: workflow.name,
    });
    toast.success(`Agent added to your shop`);
    navigate({
      pathname: `/workflows/create/${newId}`,
      search: 'openScheduler=true&fromTemplateLibrary=true',
    });
  }, [workflow, navigate]);

  const previewWorkflow = () => {
    genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
      action: templateLibraryActions.PREVIEW_TEMPLATE,
      sequence_id: workflow.id,
      sequence_name: workflow.name,
    });
    navigate({
      pathname: `/workflows/create/${workflow.id}`,
      search: 'run=true&fromTemplateLibrary=true&readOnly=true',
    });
  };

  return (
    <div className="border border-solid border-gray-200 rounded bg-white px-6 py-7 flex flex-col justify-between gap-6 cursor-pointer w-full h-full hover:bg-gray-50">
      <div className="flex space-between items-center">
        <Text fz={15} fw={500} maw={!!workflow?.providers?.length ? 300 : undefined} truncate="end">
          {workflow.name}
        </Text>
        {!!workflow?.providers?.length && (
          <div className="flex gap-[15px]">
            {workflow.providers?.map((provider) => {
              const service = services[provider];
              return <Icon key={provider} name={service?.smallIcon as IconName} size={18} />;
            })}
          </div>
        )}
      </div>
      <Text fz={12} lineClamp={3}>
        {workflow.description}
      </Text>
      <div className="flex flex-col md:flex-row space-between md:items-center gap-5">
        {randomCategory ? (
          <div>
            <Badge radius="sm" uppercase={false} color={randomCategory?.labelColor}>
              <Text fz={11} fw={500} color={randomCategory?.badgeColor} maw={130} truncate="end">
                {randomCategory?.name}
              </Text>
            </Badge>
          </div>
        ) : (
          <div></div>
        )}
        {!hideButtons && (
          <div className="flex gap-3">
            <Button size="xs" variant="secondary" onClick={() => previewWorkflow()}>
              Preview
            </Button>
            {!existInShop ? (
              <LimitedAccessTooltip
                accessLimit={templateAccess}
                target={
                  <Button
                    variant="secondary"
                    size="xs"
                    onClick={() => onClickGetTemplate()}
                    loading={copyLoading}
                    disabled={templateAccess !== 'can-access'}
                  >
                    Get Template
                  </Button>
                }
              />
            ) : (
              <Button
                size="xs"
                disabled={true}
                variant="secondary"
                leftSection={<Icon name="checkmark" size={14} color="gray.4" />}
              >
                Added
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
