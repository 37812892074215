import { services } from '@tw/types/module/services';

// auth
export const USER_DATA_ON_LOAD = 'USER_DATA_ON_LOAD';
export const SEGMENT_INIT = 'SEGMENT_INIT';
export const WORKSPACES_LOADED = 'WORKSPACES_LOADED';
export const WORKSPACES_SHOPS = 'WORKSPACES_SHOPS';
export const SELECT_WORKSPACE_FROM_DB = 'SELECT_WORKSPACE_FROM_DB';
export const ON_AUTH_STATE_CHANGED = 'ON_AUTH_STATE_CHANGED';
export const JUST_LOGGED_IN = 'JUST_LOGGED_IN';
export const DECODED_TOKEN = 'DECODED_TOKEN';
export const SET_CURRENT_SHOP = 'SET_CURRENT_SHOP';
export const START_SIGNIN_IN = 'START_SIGNIN_IN';
export const AUTH_FAILED = 'AUTH_FAILED';
export const START_SIGNIN_UP = 'START_SIGNIN_UP';
export const SET_WORKSPACE_ID = 'SET_WORKSPACE_ID';
export const PINNED_UPDATED = 'PINNED_UPDATED';
export const USER_UPDATED = 'USER_UPDATED';
export const AGENCY_SIGNUP_STORES = 'AGENCY_SIGNUP_STORES';
export const LOGOUT = 'LOGOUT';
export const ONLINE = 'ONLINE';
export const OFFLINE = 'OFFLINE';
export const HIDE_OFFLINE_BANNER = 'HIDE_OFFLINE_BANNER';
export const USER_REMOVE_ADMIN = 'USER_REMOVE_ADMIN';

export const APP_STARTED = 'APP_STARTED';

export const serviceAds = Object.keys(services).filter((service) =>
  Object.keys(services[service].dataTypes).includes('ads-metrics'),
);

//users
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_IS_USE_NEW_NAVIGATOR = 'SET_IS_USE_NEW_NAVIGATOR';

//notification settings
export const SHOP_NOTIFICATION_SETTINGS_CHANGE = 'SHOP_NOTIFICATION_SETTINGS_CHANGE';
export const USER_NOTIFICATION_SETTINGS_CHANGE = 'USER_NOTIFICATION_SETTINGS_CHANGE';
export const ACCOUNT_NOTIFICATION_SETTINGS_CHANGE = 'ACCOUNT_NOTIFICATION_SETTINGS_CHANGE';

// actions
export const SET_SHOWING_MODAL = 'SET_SHOWING_MODAL';
export const SET_ALL_SECTIONS = 'SET_ALL_SECTIONS';
export const SET_CURRENT_SECTION = 'SET_CURRENT_SECTION';
export const SET_ADD_MODAL_SECTION = 'SET_ADD_MODAL_SECTION';
export const SET_EDIT_MODAL_SECTION = 'SET_EDIT_MODAL_SECTION';
export const SET_CURRENT_CAMPAIGN = 'SET_CURRENT_CAMPAIGN';
export const SET_RANGE = 'SET_RANGE';
export const SET_CURRENT_UTMS = 'SET_CURRENT_UTMS';
export const SET_OPTIMISTIC_IMPORTING_FOR_SERVICE = 'SET_OPTIMISTIC_IMPORTING_FOR_SERVICE';
export const ADD_NEW_CUSTOM_SPEND_CATEGORY = 'ADD_NEW_CUSTOM_SPEND_CATEGORY';
export const IMPORTING_DETECTED = 'IMPORTING_DETECTED';
export const IMPORTING_NEW_INFRA_DETECTED = 'IMPORTING_NEW_INFRA_DETECTED';
export const SERVICE_FINISHED_IMPORTING = 'SERVICE_FINISHED_IMPORTING';
export const SET_EARLIEST_DATE = 'SET_EARLIEST_DATE';
export const INIT_UI = 'INIT_UI';
export const INIT_SHOP = 'INIT_SHOP';
export const SET_MSP_CONNECTED = 'SET_MSP_CONNECTED';
export const RECEIVED_SENSORY_INTEGRATIONS = 'RECEIVED_SENSORY_INTEGRATIONS';
export const UPDATE_SHOP = 'UPDATE_SHOP';
export const REMOVE_LOCAL_SHOP_PROP = 'REMOVE_LOCAL_SHOP_PROP';
export const GOT_PAYMENTS_DETAILS = 'GOT_PAYMENTS_DETAILS';
export const AUDIT_SEGMENT_INIT = 'AUDIT_SEGMENT_INIT';
export const ALL_SHOPS_STATS_RECEIVED = 'ALL_SHOPS_STATS_RECEIVED';
export const RECEIVED_PREVIOUS_PERIOD_STATS = 'RECEIVED_PREVIOUS_PERIOD_STATS';
export const RECEIVED_STATS_COMPARISONS = 'RECEIVED_STATS_COMPARISONS';
export const LOADING_STATS_COMPARISONS = 'LOADING_STATS_COMPARISONS';
export const DATES_TO_COMPARE = 'DATES_TO_COMPARE';
export const CHANGE_ACTIVE_PAGE = 'CHANGE_ACTIVE_PAGE';
export const CLEAR_INITIAL_DATA = 'CLEAR_INITIAL_DATA';
export const RECEIVE_CUSTOM_SPENDS = 'RECEIVE_CUSTOM_SPENDS';
export const UPDATE_CUSTOM_EXPENSE_CATEGORIES = 'UPDATE_CUSTOM_EXPENSE_CATEGORIES';
export const CUSTOM_EXPENSE_CALCULATE_COGS = 'CUSTOM_EXPENSE_CALCULATE_COGS';
export const LOADING_ORDERS = 'LOADING_ORDERS';
export const LOADING_STATS = 'LOADING_STATS';
export const LOADING_STATS_LTV = 'LOADING_STATS_LTV';
export const LOADING_REFUNDS = 'LOADING_REFUNDS';
export const LOADING_CUSTOM_SPENDS = 'LOADING_CUSTOM_SPENDS';
export const LIGHTBOX_OPEN = 'LIGHTBOX_OPEN';
export const LIGHTBOX_CLOSE = 'LIGHTBOX_CLOSE';
export const TRIGGER_RELOAD_CONTENT_HUB_TAB = 'TRIGGER_RELOAD_CONTENT_HUB_TAB';
export const FINISHED_RELOAD_CONTENT_HUB_TAB = 'FINISHED_RELOAD_CONTENT_HUB_TAB';
export const STATS_RECEIVED = 'STATS_RECEIVED';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const RECEIVE_REFUNDS = 'RECEIVE_REFUNDS';
export const INITIALIZING = 'INITIALIZING';
export const TOGGLE_TOPBAR_USER_MENU = 'TOGGLE_TOPBAR_USER_MENU';
export const NEW_CUSTOM_SPEND_START_DATE_PICKER_MONTH_CHANGE =
  'NEW_CUSTOM_SPEND_START_DATE_PICKER_MONTH_CHANGE';
export const NEW_CUSTOM_SPEND_END_DATE_PICKER_MONTH_CHANGE =
  'NEW_CUSTOM_SPEND_END_DATE_PICKER_MONTH_CHANGE';
export const MAIN_DATE_PICKER_SELECTION_CHANGE = 'MAIN_DATE_PICKER_SELECTION_CHANGE';
export const MAIN_DATE_PICKER_SPECIAL_PERIOD_CHANGE = 'MAIN_DATE_PICKER_SPECIAL_PERIOD_CHANGE';
export const COMPARE_DATE_PICKER_SELECTED_OPTION = 'COMPARE_DATE_PICKER_SELECTED_OPTION';
export const DURATION_FILTERS_VALUE_CHANGED = 'DURATION_FILTERS_VALUE_CHANGED';
export const NEW_CUSTOM_SPEND_START_DATE_ON_CHANGE = 'NEW_CUSTOM_SPEND_START_DATE_ON_CHANGE';
export const NEW_CUSTOM_SPEND_END_DATE_ON_CHANGE = 'NEW_CUSTOM_SPEND_END_DATE_ON_CHANGE';
export const COG_ON_CHANGE_HANDLING_FEES = 'COG_ON_CHANGE_HANDLING_FEES';
export const COG_ON_CHANGE_PRODUCT_COST = 'COG_ON_CHANGE_PRODUCT_COST';
export const COGS_UPLOAD_DIALOG_CANCEL_ON_PRESS = 'COGS_UPLOAD_DIALOG_CANCEL_ON_PRESS';
export const IMPORT_COSTS_ON_CLOSE = 'IMPORT_COSTS_ON_CLOSE';
export const IMPORT_COSTS_ON_PRESS = 'IMPORT_COSTS_ON_PRESS';
export const PRODUCTS_TABLE_PRODUCT_ON_CHANGE_PRODUCT_COST =
  'PRODUCTS_TABLE_PRODUCT_ON_CHANGE_PRODUCT_COST';
export const CUSTOM_SPEND_COST_ON_CHANGE = 'CUSTOM_SPEND_COST_ON_CHANGE';
export const CUSTOM_SPEND_START_DATE_ON_CHANGE = 'CUSTOM_SPEND_START_DATE_ON_CHANGE';
export const CUSTOM_SPEND_END_DATE_ON_CHANGE = 'CUSTOM_SPEND_END_DATE_ON_CHANGE';
export const IMPORT_PRODUCTS_TABLE_ON_PRESS = 'IMPORT_PRODUCTS_TABLE_ON_PRESS';
export const TOPBAR_MOBILE_NAVIGATION_TOGGLE = 'TOPBAR_MOBILE_NAVIGATION_TOGGLE';
export const TOPBAR_MOBILE_NAVIGATION_CLOSE = 'TOPBAR_MOBILE_NAVIGATION_CLOSE';
export const NEW_CUSTOM_SPEND_WINDOW_TOGGLE = 'NEW_CUSTOM_SPEND_WINDOW_TOGGLE';
export const NEW_METRIC_BASED_CUSTOM_SPEND_WINDOW_TOGGLE =
  'NEW_METRIC_BASED_CUSTOM_SPEND_WINDOW_TOGGLE';
export const IMPORT_CUSTOM_SPENDS_GS_MODAL_TOGGLE = 'IMPORT_CUSTOM_SPENDS_GS_MODAL_TOGGLE';
export const NEW_PAYMENT_GATEWAY_NAME_ON_CHANGE = 'NEW_PAYMENT_GATEWAY_NAME_ON_CHANGE';
export const NEW_CUSTOM_SPEND_TITLE_ON_CHANGE = 'NEW_CUSTOM_SPEND_TITLE_ON_CHANGE';
export const NEW_PAYMENT_GATEWAY_COST_ON_CHANGE = 'NEW_PAYMENT_GATEWAY_COST_ON_CHANGE';
export const NEW_CUSTOM_SPEND_COST_ON_CHANGE = 'NEW_CUSTOM_SPEND_COST_ON_CHANGE';
export const NEW_CUSTOM_SPEND_METRIC_ON_SELECT = 'NEW_CUSTOM_SPEND_METRIC_ON_SELECT';
export const NEW_CUSTOM_SPEND_PERCENT_ON_CHANGE = 'NEW_CUSTOM_SPEND_PERCENT_ON_CHANGE';
export const NEW_CUSTOM_SPEND_RECURRING_ON_CHANGE = 'NEW_CUSTOM_SPEND_RECURRING_ON_CHANGE';
export const CUSTOM_SPEND_ADSPEND_ON_CHANGE = 'CUSTOM_SPEND_ADSPEND_ON_CHANGE';
export const CUSTOM_SPEND_SAVE = 'CUSTOM_SPEND_SAVE';
export const EDIT_CUSTOM_SPEND = 'EDIT_CUSTOM_SPEND';
export const CUSTOM_SPEND_GOOGLE_SHEET_RECEIVED = 'CUSTOM_SPEND_GOOGLE_SHEET_RECEIVED';
export const SUMMARY_IS_EXPANDED_TOGGLE = 'SUMMARY_IS_EXPANDED_TOGGLE';
export const SUMMARY_CHART_POPUP_IS_OPEN_TOGGLE = 'SUMMARY_CHART_POPUP_IS_OPEN_TOGGLE';
export const SUMMARY_BOX_ON_PRESS = 'SUMMARY_BOX_ON_PRESS';
export const NEW_CUSTOM_SPEND_START_DATE_WINDOW_TOGGLE =
  'NEW_CUSTOM_SPEND_START_DATE_WINDOW_TOGGLE';
export const NEW_CUSTOM_SPEND_END_DATE_WINDOW_TOGGLE = 'NEW_CUSTOM_SPEND_END_DATE_WINDOW_TOGGLE';
export const CUSTOM_SPEND_START_DATE_WINDOW_TOGGLE = 'CUSTOM_SPEND_START_DATE_WINDOW_TOGGLE';
export const START_FREE_TRIAL = 'START_FREE_TRIAL';
export const SUMMARY_ON_REFRESH = 'SUMMARY_ON_REFRESH';
export const SUMMARY_BOX_SHOW_ON_PRESS = 'SUMMARY_BOX_SHOW_ON_PRESS';
export const CUSTOM_SPEND_ON_DELETE = 'CUSTOM_SPEND_ON_DELETE';
export const SET_CUSTOM_SPENDS_FLAG = 'SET_CUSTOM_SPENDS_FLAG';
export const HIDE_CUSTOM_SPEND_BANNER = 'HIDE_CUSTOM_SPEND_BANNER';
export const CUSTOM_SPENDS_UPLOAD_DIALOG_CANCEL_ON_PRESS =
  'CUSTOM_SPENDS_UPLOAD_DIALOG_CANCEL_ON_PRESS';
export const CUSTOM_SPENDS_EXPORT_CSV = 'CUSTOM_SPENDS_EXPORT_CSV';
export const EXPORT_CUSTOM_EXPENSES = 'EXPORT_CUSTOM_EXPENSES';
export const IMPORT_CUSTOM_SPENDS_TABLE_ON_PRESS = 'IMPORT_CUSTOM_SPENDS_TABLE_ON_PRESS';
export const MOBILE_NOTIFICATIONS_PERMISSION_GIVEN = 'MOBILE_NOTIFICATIONS_PERMISSION_GIVEN';
export const SHOW_PROMPT = 'SHOW_PROMPT';
export const HIDE_PROMPT = 'HIDE_PROMPT';
export const GET_CONVERSION_RATE = 'GET_CONVERSION_RATE';
export const LOADING_CONVERSION_RATE = 'LOADING_CONVERSION_RATE';
export const SET_CONVERSION_RATE = 'SET_CONVERSION_RATE';
export const SHOW_GENERAL_ERROR_TOAST = 'SHOW_GENERAL_ERROR_TOAST';
export const HIDE_GENERAL_ERROR_TOAST = 'HIDE_GENERAL_ERROR_TOAST';
export const SET_BIDIRECTIONAL_COGS = 'SET_BIDIRECTIONAL_COGS';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SAVE_SHOP_INDUSTRY = 'SAVE_SHOP_INDUSTRY';
export const UPDATE_CUSTOM_EXPENSE_ATTRIBUTION_TYPE = 'UPDATE_CUSTOM_EXPENSE_ATTRIBUTION_TYPE';
export const RECEIVE_INVOICES = 'RECEIVE_INVOICES';
export const GENERATIVE_AI_POPUP_OPENED = 'GENERATIVE_AI_POPUP_OPENED';
export const GENERATIVE_AI_POPUP_CLOSED = 'GENERATIVE_AI_POPUP_CLOSED';
export const GENERATIVE_AI_CHANGE_ENTITY = 'GENERATIVE_AI_CHANGE_ENTITY';
export const BACKGROUND_DIFFUSION_POPUP_OPENED = 'BACKGROUND_DIFFUSION_POPUP_OPENED';
export const BACKGROUND_DIFFUSION_POPUP_CLOSED = 'BACKGROUND_DIFFUSION_POPUP_CLOSED';
export const AD_PREVIEW_POPUP_OPENED = 'AD_PREVIEW_POPUP_OPENED';
export const AD_PREVIEW_POPUP_CLOSED = 'AD_PREVIEW_POPUP_CLOSED';
export const GENERATIVE_LOADING_AD_INFO = 'GENERATIVE_LOADING_AD_INFO';
export const GENERATIVE_LOADING_AD_INFO_FINISHED = 'GENERATIVE_LOADING_AD_INFO_FINISHED';
export const GENERATIVE_LOADING_PRODUCT_INFO = 'GENERATIVE_LOADING_PRODUCT_INFO';
export const GENERATIVE_LOADING_PRODUCT_INFO_FINISHED = 'GENERATIVE_LOADING_PRODUCT_INFO_FINISHED';
export const GENERATE_COPY_AI_POPUP_OPENED = 'GENERATE_COPY_AI_POPUP_OPENED';
export const GENERATE_COPY_AI_POPUP_CLOSED = 'GENERATE_COPY_AI_POPUP_CLOSED';
export const GENERATE_COPY_CHANGE_ENTITY = 'GENERAtE_COPY_CHANGE_ENTITY';
export const GENERATE_COPY_LOADING_AD_INFO = 'GENERATE_COPY_LOADING_AD_INFO';
export const GENERATE_COPY_LOADING_AD_INFO_FINISHED = 'GENERATE_COPY_LOADING_AD_INFO_FINISHED';
export const SET_GENERATED_TEXT = 'SET_GENERATED_TEXT';
export const UNSET_GENERATED_TEXT = 'UNSET_GENERATED_TEXT';
export const CHANGE_CONTENT_HUB_SEARCH_TERM = 'CHANGE_CONTENT_HUB_SEARCH_TERM';
export const OPEN_UPLOAD_MEDIA = 'OPEN_UPLOAD_MEDIA';
export const CLOSE_UPLOAD_MEDIA = 'CLOSE_UPLOAD_MEDIA';
export const CHANGE_CONTENT_HUB_FOLDER = 'CHANGE_CONTENT_HUB_FOLDER';

export const SELECTED_STATS_SERVCIES_CHANGED = 'SELECTED_STATS_SERVCIES_CHANGED';
export const IS_NAVIGATION_OPEN = 'IS_NAVIGATION_OPEN';
export const CHANGE_AD_BUILDER_SOURCE = 'CHANGE_AD_BUILDER_SOURCE';
export const TOGGLE_BULK_ADBUILDER = 'TOGGLE_BULK_ADBUILDER';
export const ADD_BRAND_COLOR = 'ADD_BRAND_COLOR';
export const REMOVE_BRAND_COLOR = 'REMOVE_BRAND_COLOR';
export const ADD_BRAND_FONT = 'ADD_BRAND_FONT';
export const REMOVE_BRAND_FONT = 'REMOVE_BRAND_FONT';
export const INIT_PROMPTS = 'INIT_PROMPTS';
export const ADD_PROMPT = 'ADD_PROMPT';
export const REMOVE_PROMPT = 'REMOVE_PROMPT';
export const PIN_PROMPT = 'PIN_PROMPT';
export const UNPIN_PROMPT = 'UNPIN_PROMPT';
export const CHANGE_PROMPT_ORDER = 'CHANGE_PROMPT_ORDER';
export const INIT_MULTIPLE_SHOPS_BILLING_INTERVAL = 'INIT_MULTIPLE_SHOPS_BILLING_INTERVAL';
export const SET_SHOP_BILLING_INTERVAL = 'SET_SHOP_BILLING_INTERVAL';
export const COPY_TO_CLIPBOARD_ACTIVE = 'COPY_TO_CLIPBOARD_ACTIVE';
export const COPY_TO_CLIPBOARD_INACTIVE = 'COPY_TO_CLIPBOARD_INACTIVE';
export const DARK_MODE_UPDATED = 'DARK_MODE_UPDATED';
export const SET_USE_SHIPPING_CHARGES = 'SET_USE_SHIPPING_CHARGES';
export const GOOGLE_ADS_FETCH_ACCOUNTS_ERROR = 'GOOGLE_ADS_FETCH_ACCOUNTS_ERROR';
export const SET_ACTIVE_APP = 'SET_ACTIVE_APP';

// google
export const GOOGLE_FETCHING_ACCOUNTS = 'GOOGLE_FETCHING_ACCOUNTS';

// shop
export const EXTERNAL_PROVIDER_FAUTURE_FLAGS_LOADED = 'EXTERNAL_PROVIDER_FAUTURE_FLAGS_LOADED';

// attribution
export const PPS_STATUS = 'PPS_STATUS';

//push notifications
export const LOADED_FROM_NOTIFICATION = 'LOADED_FROM_NOTIFICATION';

//integration save press
export enum IntegrationSaveErrors {
  ALREADY_REGISTERED = 'Already registered',
  UNIQUE_ERROR = 'These two values must be unique',
  CONNECTION_ERROR = "Connection Error. Please reach out to Triple Whale and your survey service provider's customer service teams to learn more.",
  KNO_INVALID_CLIENT = 'Invalid Client ID or Client Secret',
  ENQUIRELABS_INVALID_API_KEY = 'Invalid API Key',
}

// benchmarks
export const BENCHMARKS_INDUSTRY_CHANGED = 'BENCHMARKS_INDUSTRY_CHANGED';
export const BENCHMARKS_AOV_SEGMENT_CHANGED = 'BENCHMARKS_AOV_SEGMENT_CHANGED';
export const BENCHMARKS_SPEND_SEGMENT_CHANGED = 'BENCHMARKS_SPEND_SEGMENT_CHANGED';
export const BENCHMARKS_GET_ERROR_RESPONSE = 'BENCHMARKS_GET_ERROR_RESPONSE';
export const BENCHMARKS_INDUSTRY_RECEIVED = 'BENCHMARKS_INDUSTRY_RECEIVED';
export const SHOP_WITH_SENSORY = 'SHOP_WITH_SENSORY';
