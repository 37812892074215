import 'firebase/compat/firestore';
import axiosInstance from 'utils/axiosInstance';
import { callInitialImport } from 'utils/callInitialImport';

import db, { FieldValue, getShopData } from '../utils/DB';
import { setOptimisticImporting, toggleSaveIndicator } from './actions';
import { minimizeSectionOnPress } from './summary';
import {
  INIT_SHOP,
  SET_OPTIMISTIC_IMPORTING_FOR_SERVICE,
  SHOP_WITH_SENSORY,
} from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { type AppDispatch } from 'index';
import { Shop, ShopWithSensory } from '@tw/types';
import { DataTypesIds, services } from '@tw/types/module/services';
import { AmazonRegions } from '@tw/types/module/services/amazon';
import { closeRedirectNotice, startConnect } from './shopIntegrations';
import { toast } from 'react-toastify';
import { createSelector } from 'reselect';

const AMAZON_SERVICE_ID = 'amazon';

export const amazonConnectOnPress = (region: AmazonRegions) => async (dispatch, getState) => {
  try {
    dispatch(startConnect('amazon'));
    const response = await axiosInstance.get(
      `v2/amazon/login/get-url?shopId=${$currentShopId.get()}&region=${region}`,
    );
    const url = response?.data?.url;
    if (url) {
      const state = getState().integrations;
      if (state.redirectNoticeIsOpen) {
        window.location.replace(url);
      }
    } else {
      dispatch(closeRedirectNotice());
      toast.error(`Error while redirecting to Amazon authentication page`);
    }
  } catch (e) {
    console.log(` ERROR `, e);
    dispatch(closeRedirectNotice());
  }
};

export const TOGGLE_AMAZON_CONFIG_SCREEN_OPEN = 'TOGGLE_AMAZON_CONFIG_SCREEN_OPEN';
export const toggleAmazonConfigScreenOpen = (region?: AmazonRegions) => ({
  type: TOGGLE_AMAZON_CONFIG_SCREEN_OPEN,
  region,
});

export const AMAZON_CONFIG_SAVE = 'AMAZON_CONFIG_SAVE';
export const amazonConfigSave = () => {
  return {
    type: AMAZON_CONFIG_SAVE,
  };
};

export function amazonConfigCancelPress(sellerAccountId) {
  return (dispatch: AppDispatch) => {
    return db()
      .get()
      .then((doc) => {
        const shopData = doc.data() as Shop;
        const { amazon } = shopData;
        const { [sellerAccountId]: sellerAccount, ...accountsInConfigProcess } =
          amazon?.accountsInConfigProcess || {};
        const { region = 'na' } = sellerAccount || {};
        db()
          .set(
            {
              amazon: {
                accountsInConfigProcess: {
                  [sellerAccountId]: FieldValue.delete(),
                },
              },
            },
            { merge: true },
          )
          .then(() => {
            dispatch(toggleAmazonConfigScreenOpen(region));
            // dispatch(amazonConfigSave());
          });
      });
  };
}

export function amazonConfigSavePress(params: {
  sellerAccountId;
  marketplaceIds;
  isInitial;
  continueToAds;
  region: AmazonRegions;
}) {
  const { sellerAccountId, marketplaceIds, isInitial, continueToAds, region } = params;
  return (dispatch: AppDispatch) => {
    return db()
      .get()
      .then((doc) => {
        const shopData = doc.data() as Shop;
        const { amazon } = shopData;
        const { [sellerAccountId]: sellerAccount, ...accountsInConfigProcess } =
          amazon?.accountsInConfigProcess || {};
        db()
          .set(
            {
              amazon: {
                accountsInConfigProcess: {
                  [sellerAccountId]: FieldValue.delete(),
                },
                sellerAccounts: { [sellerAccountId]: { marketplaceIds } },
              },
            },
            { merge: true },
          )
          .then(() => {
            const url = `v2/amazon/login/update-marketplaceids`;
            axiosInstance
              .post(url, {
                shopDomain: $currentShopId.get(),
                sellerAccountId,
                marketplace_ids: marketplaceIds,
                continueToAds,
                shopId: $currentShopId.get(),
                region,
              })
              .then((data) => {
                const { marketplaceIds, url } = data.data;
                // dispatch(startImportingAmazonData(marketplaceIds, 'orders')); disabled and running from server
                dispatch(minimizeSectionOnPress(AMAZON_SERVICE_ID, false));
                // dispatch(toggleAmazonConfigScreenOpen());
                if (continueToAds) {
                  console.log(`url`, url);
                  if (url) {
                    window.location.replace(url);
                  } else {
                    amazonConnectAds(region);
                  }
                } else {
                  dispatch(toggleAmazonConfigScreenOpen(region));
                  // dispatch(amazonConfigSave());
                }
              });
          });
      });
  };
}

export const amazonConnectAds = (region: AmazonRegions) => {
  try {
    console.log('amazonConnectAds');
    const url = `v2/amazon/ads/login/get-url?shopId=${$currentShopId.get()}&region=${region}`;
    axiosInstance
      .get(url)
      .then((res) => {
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    console.log(` ERROR `, e);
  }
};
export const ACCOUNTS_IN_CONFIG_PROCESS_SET = 'ACCOUNTS_IN_CONFIG_PROCESS_SET';
export const accountsInConfigProcessSet = (state) => ({
  type: ACCOUNTS_IN_CONFIG_PROCESS_SET,
  ...state,
});

export function amazonConfigOpen(region: AmazonRegions) {
  return async (dispatch: AppDispatch) => {
    return getShopData().then((shopData: Shop) => {
      const sellerAccounts = Object.entries(shopData?.amazon?.sellerAccounts || {}).map(
        ([accountId, sellerAccount]) => ({
          sellerAccountId: accountId,
          region: sellerAccount.region || 'na',
        }),
      );
      if (sellerAccounts.length) {
        const accountsInConfigProcess = sellerAccounts
          .filter((x) => x.region === region)
          .reduce((acc, account) => {
            acc[account.sellerAccountId] = { initial: false, region: account.region };
            return acc;
          }, {});
        return db()
          .set(
            {
              amazon: {
                accountsInConfigProcess,
              },
            },
            { merge: true },
          )
          .then(() => {
            dispatch(toggleAmazonConfigScreenOpen(region));
          });
      }
    });
  };
}

export function startImportingAmazonData(marketplaceIds?: string[], dataType?: DataTypesIds) {
  return (dispatch) => {
    if (marketplaceIds?.length) {
      callInitialImport(AMAZON_SERVICE_ID, marketplaceIds, false, dataType).then(() => {
        dispatch(setOptimisticImporting(AMAZON_SERVICE_ID));
      });
    }
    // dispatch(setOptimisticImporting(AMAZON_SERVICE_ID));
    window.scrollTo(0, 0);
  };
}

export function amazonDisconnect(sellerAccountId: string, region: AmazonRegions) {
  return (dispatch, getState) => {
    toggleSaveIndicator();
    const url = `v2/amazon/login/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
        region,
        // sellerAccountId,
      })
      .then(() => {
        switch (region) {
          case 'na':
            dispatch(amazonDisconnectDoneNA());
            break;
          case 'eu':
            dispatch(amazonDisconnectDoneEU());
            break;
          case 'fe':
            dispatch(amazonDisconnectDoneFE());
            break;
        }
      });
  };
}

export const AMAZON_DISCONNECT_DONE_NA = 'AMAZON_DISCONNECT_DONE_NA';

export function amazonDisconnectDoneNA() {
  return {
    type: AMAZON_DISCONNECT_DONE_NA,
  };
}

export const AMAZON_DISCONNECT_DONE_EU = 'AMAZON_DISCONNECT_DONE_EU';

export function amazonDisconnectDoneEU() {
  return {
    type: AMAZON_DISCONNECT_DONE_EU,
  };
}

export const AMAZON_DISCONNECT_DONE_FE = 'AMAZON_DISCONNECT_DONE_FE';

export function amazonDisconnectDoneFE() {
  return {
    type: AMAZON_DISCONNECT_DONE_FE,
  };
}

/*
export const callToUpdateTodayKno = () => {
  return async (dispatch, getState) => {
    if (!getState().isKnoConnected) return;

    //dispatch({ type: LOADING_FACEBOOK_INSIGHTS });

    callUpdateToday(KNO_SERVICE_ID);
    dispatch(receiveKno());
    dispatch(setOptimisticImporting(KNO_SERVICE_ID));
  };
};

export const RECEIVE_KNO = 'RECEIVE_KNO';
export const receiveKno = () => ({
  type: RECEIVE_KNO,
});

export const KNO_CLIENTID_SAVE = 'KNO_CLIENTID_SAVE';
export function knoClientIdSave(knoClientId) {
  return {
    type: KNO_CLIENTID_SAVE,
    payload: knoClientId,
  };
}

export const KNO_CLIENTSECRET_SAVE = 'KNO_CLIENTSECRET_SAVE';
export function knoClientSecretSave(knoClientSecret) {
  return {
    type: KNO_CLIENTSECRET_SAVE,
    payload: knoClientSecret,
  };
}
*/
const amazonConnectConfigIsOpen = (
  state = { open: false },
  action,
): { open: boolean; region?: AmazonRegions } => {
  switch (action.type) {
    case INIT_SHOP:
      return { open: !!Object.keys(action.amazon?.accountsInConfigProcess || {})?.length };
    case TOGGLE_AMAZON_CONFIG_SCREEN_OPEN:
      return { open: !state?.open, region: action.region };
    case AMAZON_CONFIG_SAVE:
      return { open: false };
    default:
      return state as any;
  }
};
const amazonAccountsInConfigProcess = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return Object.keys(action.amazon?.accountsInConfigProcess || {}) || [];
    default:
      return state;
  }
};

const isAmazonConnectedNA = (state = false, action) => {
  switch (action.type) {
    case SHOP_WITH_SENSORY:
      return (
        action.shopWithSensory?.sensory &&
        !!(
          services['amazon-sales-na'].getAccounts(action.shopWithSensory as ShopWithSensory)
            ?.length ||
          services['amazon-ads-na'].getAccounts(action.shopWithSensory as ShopWithSensory)?.length
        )
      );
    case AMAZON_DISCONNECT_DONE_NA:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === AMAZON_SERVICE_ID || state;
    default:
      return state;
  }
};
const isAmazonConnectedEU = (state = false, action) => {
  switch (action.type) {
    case SHOP_WITH_SENSORY:
      return (
        action.shopWithSensory?.sensory &&
        !!(
          services['amazon-sales-eu'].getAccounts(action.shopWithSensory as ShopWithSensory)
            ?.length ||
          services['amazon-ads-eu'].getAccounts(action.shopWithSensory as ShopWithSensory)?.length
        )
      );
    case AMAZON_DISCONNECT_DONE_EU:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === AMAZON_SERVICE_ID || state;
    default:
      return state;
  }
};
const isAmazonConnectedFE = (state = false, action) => {
  switch (action.type) {
    case SHOP_WITH_SENSORY:
      return (
        action.shopWithSensory?.sensory &&
        !!(
          services['amazon-sales-fe'].getAccounts(action.shopWithSensory as ShopWithSensory)
            ?.length ||
          services['amazon-ads-fe'].getAccounts(action.shopWithSensory as ShopWithSensory)?.length
        )
      );
    case AMAZON_DISCONNECT_DONE_FE:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === AMAZON_SERVICE_ID || state;
    default:
      return state;
  }
};

export const reducers = {
  isAmazonConnectedNA,
  isAmazonConnectedEU,
  isAmazonConnectedFE,
  amazonConnectConfigIsOpen,
  amazonAccountsInConfigProcess,
};
