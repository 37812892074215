import { Header, Box } from '@tw/ui-components';
import TopBreadcrumbs from 'components/TopBreadcrumbs';
import MonitoringIncidents from 'components/MonitoringIncidents';
import { TOP_BAR_BG_VAR, TOP_BAR_BORDER_VAR, TOP_BAR_HEIGHT_VAR } from './constants';

export const SimpleTopBar: React.FC = () => {
  return (
    <Header borderBottom={TOP_BAR_BORDER_VAR} h={TOP_BAR_HEIGHT_VAR} bg={TOP_BAR_BG_VAR}>
      <Box pt="md" p="xs">
        <TopBreadcrumbs />
      </Box>
      <MonitoringIncidents />
    </Header>
  );
};
