import { Icon, Image, Text, ActionIcon } from '@tw/ui-components';
import { MobyUploadedFile } from './types/willyTypes';

export const ChatInputFile = ({
  file,
  removeFile,
  index,
  onClick,
}: {
  file: MobyUploadedFile;
  onClick?: () => void;
  removeFile?: (index: number) => void;
  index?: number;
}) => {
  const isImage = file.type.includes('image');

  const currentFileIcon = (file: MobyUploadedFile) => {
    if (file.type === 'text/csv') return <Icon name="google-sheets" size={40} />;
    if (file.type.includes('image'))
      return (
        <Image src={file.path ?? file.content} alt={file.name} width={40} height={40} radius="sm" />
      );
    return <Icon name="text-file" size={40} />;
  };

  const currentFileText = (file: MobyUploadedFile) => {
    if (file.type === 'text/csv') return 'Spreadsheet';
    if (file.type.includes('image')) return 'Image';
    if (file.type.includes('json')) return 'JSON File';
    return 'Text File';
  };

  const currentFileSize = (file: MobyUploadedFile) => {
    const blob = new Blob([file.path ?? file.content ?? '']);
    if (blob.size > 1024 * 1024) {
      return `${(blob.size / 1024 / 1024).toFixed(2)} MB`;
    }
    if (blob.size > 1024) {
      return `${(blob.size / 1024).toFixed(2)} KB`;
    }
    return `${blob.size} B`;
  };

  return isImage && !removeFile ? (
    <div className={` rounded  ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick}>
      <div className="rounded overflow-hidden inline-block cursor-pointer">
        <Image src={file.path ?? file.content} alt={file.name} width={150} radius="sm" />
      </div>
    </div>
  ) : (
    <div
      className={`border border-solid border-gray-200 rounded bg-white flex gap-3 items-center relative ${
        removeFile ? 'p-2' : 'cursor-pointer p-4'
      }`}
      onClick={onClick}
    >
      <div className="flex items-center justify-center">{currentFileIcon(file)}</div>
      <div className="flex gap-0 flex-col overflow-hidden">
        <Text fw={600} fz={13} lineClamp={1}>
          {file.name}
        </Text>
        <Text fz={13}>
          {currentFileText(file)} - {currentFileSize(file)}
        </Text>
      </div>
      {removeFile ? (
        <div
          className="scale-75 absolute top-1 right-1 -translate-y-1/2 translate-x-1/2 cursor-pointer transition-colors"
          onClick={() => removeFile(index ?? 0)}
        >
          <ActionIcon
            icon="close"
            radius="round"
            bg="black"
            iconColor="white"
            size="xs"
            iconSize={10}
          />
        </div>
      ) : null}
    </div>
  );
};
