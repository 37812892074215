import { Button, Text } from '@tw/ui-components';
import agentHeaderBg from 'components/Willy/AgentsLibrary/agent_header.png';
import { useNavigate } from 'react-router-dom';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useCallback, useEffect, useState } from 'react';
import { AgentCollection } from '../types/willyTypes';
import { firestoreRef, toArray } from 'utils/DB';

export const AgentsLibrary = () => {
  const navigate = useNavigate();
  const isSmall = useIsSmall();
  const [agentCollections, setAgentCollections] = useState<AgentCollection[]>([]);
  const [loadingCollections, setLoadingCollections] = useState(false);
  const getAgentCollections = useCallback(async () => {
    setLoadingCollections(true);
    const collections = await firestoreRef().collection('agent_collections').get();
    const asArray = toArray(collections);
    setAgentCollections(asArray.sort((a, b) => a.order - b.order) as AgentCollection[]);
    setLoadingCollections(false);
  }, []);
  useEffect(() => {
    getAgentCollections();
  }, [getAgentCollections]);
  return (
    <div className="flex flex-col  z-10 items-center">
      <div
        style={{ backgroundImage: `url(${agentHeaderBg})` }}
        className={`bg-blue-600 w-full flex justify-center md:h-[240px] items-center bg-cover`}
      >
        <div className="py-5 md:py-0 md:pb-[30px]">
          <Text fz={isSmall ? 20 : 34} color="white" fw={600}>
            Explore the Agent Library
          </Text>
        </div>
      </div>
      <div className="flex flex-col w-[95%]  py-20 gap-7">
        <div className=" flex flex-col mx-auto gap-7">
          {loadingCollections && <div>Loading...</div>}
          {!loadingCollections && (
            <Text fw={600} fz={21}>
              Agent Collections
            </Text>
          )}
          {!loadingCollections && (
            <div className="flex flex-col md:flex-row gap-10 flex-wrap">
              {agentCollections.map((folder) => {
                const bgUrl = `https://storage.googleapis.com/public-assets-shofifi/agents-library/${folder.color}_collection_bubbles.png`;
                return (
                  <div className="relative flex">
                    {folder.comingSoon && (
                      <div className="absolute top-[-10px] right-[20px] rounded bg-blue-200 px-3 py-1 z-10">
                        <Text fw={500} fz={12} color="one.8">
                          Coming Soon
                        </Text>
                      </div>
                    )}
                    <div
                      key={folder.id}
                      className="rounded h-full md:h-[400px] w-full md:w-[283px] relative bg-center overflow-hidden bg-gray-100"
                    >
                      <img
                        src={bgUrl}
                        alt={folder.name}
                        className="absolute inset-0 w-[110%] h-[110%] object-cover -top-[5%] -left-[5%] overflow-hidden"
                      />

                      <div className="relative z-10 p-10 flex flex-col gap-6">
                        <Text fw={700} fz={22} color="white">
                          {folder.name}
                        </Text>
                        <Text fz={14} color="white" lineClamp={10}>
                          {folder.description}
                        </Text>
                        {!folder.comingSoon && (
                          <div>
                            <Button
                              variant="white"
                              size="sm"
                              onClick={() => navigate(`/chat/agents/collections/${folder.id}`)}
                            >
                              View
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
