import { useCallback, useEffect, useState } from 'react';
import { ColumnName, SequenceProgressEvent, StructuredColumns } from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $socket } from '$stores/$socket';
import { AttributionData } from 'types/attribution';

type UseColumnsSocketProps = {
  shopId: string;
};

export function useColumnsSocket({ shopId }: UseColumnsSocketProps) {
  const socket = useStoreValue($socket);
  const [columns, setColumns] = useState<Record<string, AttributionData & { column: ColumnName }>>(
    {},
  );

  const onSequenceProgress = useCallback((data: SequenceProgressEvent) => {
    if (data.type !== 'sendToDatabase') {
      return;
    }

    const newColumns: Record<string, AttributionData & { column: ColumnName }> =
      data.data.columns.reduce((acc, column) => {
        acc[column.id] = {
          id: column.id,
          entity: column.entity,
          channel: column.provider_id,
          column: column.metric,
          [column.metric]: {
            reason: column.reason,
            value: column.value,
            created_at: column.created_at,
          },
        };
        return acc;
      }, {});

    setColumns((prev) => ({
      ...prev,
      ...newColumns,
    }));
  }, []);

  useEffect(() => {
    const func = (msg) => {
      if (msg.eventType !== 'workflow-progress' || msg.account !== shopId) {
        return;
      }
      onSequenceProgress(msg.data);
    };

    socket.on('message', func);

    return () => {
      socket.off('message', func);
    };
  }, [shopId, socket, onSequenceProgress]);

  return { columns };
}
