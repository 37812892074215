import { useCallback, useMemo, useState } from 'react';
import { copyGlobalColumnToShop } from '../utils/willyUtils';
import { analyticsEvents, genericEventLogger, templateLibraryActions } from 'utils/dataLayer';
import { toast } from 'react-toastify';
import { useStoreValue } from '@tw/snipestate';
import { $shopAiColumns, $shopSequences } from '$stores/willy/$sequences';
import { $currentShopId } from '$stores/$shop';
import { sleep } from 'utils/sleep';
import { Column, ColumnName } from '../types/willyTypes';

type UseAiColumnProps = {
  column: Column<ColumnName> | null;
};

export function useAiColumn(props: UseAiColumnProps) {
  const { column } = props;
  const shopColumns = useStoreValue($shopAiColumns);
  const [copyLoading, setCopyLoading] = useState(false);
  const shopSequences = useStoreValue($shopSequences);
  const currentShopId = useStoreValue($currentShopId);

  const existInShop = useMemo(() => {
    if (!column) return undefined;
    return shopSequences.find((sequence) => column.sequenceId === sequence.globalDashboardId);
  }, [shopSequences, column]);

  const copyToShop = useCallback(
    async (update?: boolean) => {
      if (!currentShopId || !column) {
        return;
      }
      setCopyLoading(true);
      await copyGlobalColumnToShop(column);
      genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
        action: templateLibraryActions.ADD_COLUMN_TO_WORKSPACE,
        column_id: column?.key,
        column_name: column?.label,
      });
      setCopyLoading(false);
      toast.success(`Column ${update ? 'updated' : 'added'}`, {
        autoClose: 1000,
      });
    },
    [column, currentShopId],
  );

  const columnInShopHasLatestVersion = useMemo(() => {
    if (!column) {
      return;
    }
    const columnInShop = shopColumns.data?.find((c) => c.key === column?.key);
    if (!columnInShop) {
      return true;
    }
    if (!columnInShop?.version && !column?.version) {
      return true;
    }
    if (!columnInShop?.version) {
      return false;
    }
    return !!columnInShop?.version && columnInShop?.version >= column.version;
  }, [shopColumns, column]);

  const updateColumn = useCallback(async () => {
    if (columnInShopHasLatestVersion || !currentShopId || !column) {
      return;
    }
    setCopyLoading(true);
    await copyToShop(true);
    await sleep(300);
    setCopyLoading(false);
  }, [column, columnInShopHasLatestVersion, currentShopId, copyToShop]);

  return {
    copyLoading,
    onCopy: copyToShop,
    existInShop,
    needsUpdate: !!existInShop && !columnInShopHasLatestVersion,
    onUpdate: updateColumn,
  };
}
