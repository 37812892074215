import { useCallback, useEffect, useMemo } from 'react';
import { useWindowSize } from 'utils/useWindowSize';
import { Flex, Text, Icon } from '@tw/ui-components';
import { Allotment } from 'allotment';
import lazyWithRetries from 'utils/lazyWithRetries';
import { useAppDispatch } from 'index';
import { switchStore } from 'ducks/auth';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { TopBarStoresSwitcher } from 'components/TopBar/TopBarStoresSwitcher';
import { resetStats } from 'ducks/newStats';
import { isMobileApp, isOrcaApp } from 'utils/Device';
import { toggleMobileSidePanel } from 'ducks/willy';
import { NavLink } from 'react-router-dom';
import { killIntercom, toggleLauncherVisibility } from 'utils/intercom';
import { clearDashContext, useDashContext } from 'components/Willy/dashContext';
import { $navigationSections, $navWidth, useActiveRoute } from '$stores/nav-config-stores';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { WillyRulePopup } from 'components/Willy/WillyRules/WillyRulePopup';
import { useStoreValue } from '@tw/snipestate';
import { useFilterMenu } from 'components/Nav/hooks/useFilterMenu';
import { MoreSectionPopover } from 'components/Nav/components/navs/AlanNav/MoreSectionPopover';
import { MoreSectionDrawer } from 'components/Nav/components/navs/AlanNav/MoreSectionDrawer';
import { TopSection } from 'components/Nav/components/blocks/NewSearchStorePopover';
import { ChangeCategoryModal } from 'components/Willy/dashboardManagment/category-management/ChangeCategoryModal';
import {
  initDashboardCategoryManager,
  turnOffDashboardCategoryManager,
} from 'components/Willy/dashboardManagment/category-management/stores';
import {
  useComputeFreeTrialBannerHeight,
  useShowFreeTrialBanner,
} from 'components/Willy/UpgradePlan/FreeTrialBanner';
import { FREE_TRIAL_BANNER_HEIGHT } from '$stores/willy/$upgradePopupManager';
import { openChatSelectorDrawer } from '$stores/willy/$mobileDrawers';
import { useInWillyDashboardContext } from 'components/Willy/hooks/useInWillyDashboardContext';
import { WillyDashboardMobileTopHeader } from 'components/Willy/dashboardManagment/dashboard-header/WillyDashboardMobileTopHeader';
import { MobileDashboardSelectorOpener } from 'components/Willy/dashboardManagment/dashboard-header/MobileDashboardSelectorOpener';
import { useIsFeedItemPage } from 'Router/hooks/useIsFeedItemPage';

const RouterFragment = lazyWithRetries(() => import('./RouterFragment'));

const MOBILE_SUB_MENU_SIZE = 66;
const MOBILE_FOOTER_HEIGHT = isMobileApp ? 80 : 70;

export const NewTemplateWrapper = () => {
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();
  const navWidth = useStoreValue($navWidth);
  const showFreeTrialBanner = useShowFreeTrialBanner();

  const isFeedItemPage = useIsFeedItemPage();

  const mobileFooterHeight = useMemo(() => {
    return isFeedItemPage ? 0 : MOBILE_FOOTER_HEIGHT;
  }, [isFeedItemPage]);

  const mobileTopHeaderHeight = useMemo(() => {
    return isFeedItemPage ? 0 : MOBILE_SUB_MENU_SIZE;
  }, [isFeedItemPage]);

  useComputeFreeTrialBannerHeight();

  useEffect(() => {
    // Cleanup - remove all dashboard firestore subscriptions when we're out of 3.0
    return () => {
      clearDashContext();
    };
  }, []);

  useEffect(() => {
    initDashboardCategoryManager();
    return turnOffDashboardCategoryManager;
  }, []);

  useEffect(() => {
    (windowSize.isSmall || isMobileApp || isOrcaApp) && killIntercom();
  }, [windowSize.isSmall]);

  useEffect(() => {
    toggleLauncherVisibility(false);
  }, []);

  useEffect(() => {
    dispatch(toggleMobileSidePanel(!(windowSize.isSmall || isMobileApp)));
  }, [dispatch, windowSize.isSmall]);

  return (
    <div className="new-template-wrapper">
      <Allotment vertical className="h-dvh max-h-dvh">
        <Allotment.Pane
          preferredSize={mobileTopHeaderHeight}
          maxSize={mobileTopHeaderHeight}
          minSize={mobileTopHeaderHeight}
          visible={windowSize.isSmall}
        >
          <MobileV3Header />
        </Allotment.Pane>
        <Allotment.Pane
          visible
          minSize={
            (windowSize.isSmall
              ? windowSize.windowSize.height - (mobileTopHeaderHeight + mobileFooterHeight)
              : windowSize.windowSize.height) +
            (showFreeTrialBanner ? -FREE_TRIAL_BANNER_HEIGHT : 0)
          }
          maxSize={
            (windowSize.isSmall
              ? windowSize.windowSize.height - (mobileTopHeaderHeight + mobileFooterHeight)
              : windowSize.windowSize.height) +
            (showFreeTrialBanner ? -FREE_TRIAL_BANNER_HEIGHT : 0)
          }
          className="no-sash-border"
        >
          <Allotment snap>
            {windowSize.isSmall ? (
              <RouterFragment />
            ) : (
              <Allotment.Pane visible minSize={windowSize.windowSize.width - navWidth}>
                <RouterFragment />
              </Allotment.Pane>
            )}
          </Allotment>
        </Allotment.Pane>
      </Allotment>

      <WillyRulePopup />
      <ChangeCategoryModal />
    </div>
  );
};

type MenuTemplateDesktopProps = { mobile: false; activator: () => JSX.Element };
type MenuTemplateMobileProps = { mobile: true };
type MenuTemplateProps<T> = T extends false ? MenuTemplateDesktopProps : MenuTemplateMobileProps;
export function MenuTemplate<T extends boolean>(props: MenuTemplateProps<T>) {
  const isFeedItemPage = useIsFeedItemPage();
  const { activeRoute } = useActiveRoute();
  const navigationSections = useStoreValue($navigationSections);
  const alanSections = useFilterMenu(navigationSections, 'alan');
  const dispatch = useAppDispatch();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const selectStoreHandler = useCallback(
    (s: any) => {
      dispatch(resetStats());
      dispatch(switchStore(s));
    },
    [dispatch],
  );

  return (
    <>
      {!props.mobile && (
        <TopBarStoresSwitcher
          value={currentShopId}
          handleSelect={selectStoreHandler}
          isFromAllShops={false}
          customActivatorClassName="flex flex-col justify-center items-center w-full min-w-[32px] min-h-[32px] rounded my-4"
          CustomActivator={props.activator}
        />
      )}
      <Flex
        w={500}
        mah={isFeedItemPage ? 0 : MOBILE_FOOTER_HEIGHT}
        gap="xl"
        py="xs"
        mb={isMobileApp ? 'lg' : undefined}
        px="xs"
        justify="space-evenly"
        overflow="scroll"
      >
        {alanSections
          .flatMap((s) => s.routes)
          .map((r, i) => {
            if (r.hidden) return null;

            const selected = activeRoute === r;
            const icon = typeof r.icon === 'function' && r.icon?.({ selected });

            return (
              <NavLink
                className="flex flex-col items-center cursor-pointer no-underline"
                to={{ pathname: r.url }}
                key={`nav-link-sm-${r.url}-${i}`}
              >
                {icon && <Flex>{icon}</Flex>}
                <Text fz={12} weight={500} ta="center">
                  {r.shortLabel || r.label}
                </Text>
              </NavLink>
            );
          })}
        {props.mobile ? <MoreSectionDrawer /> : <MoreSectionPopover />}
      </Flex>
    </>
  );
}

export function MobileV3Header() {
  const isSmall = useIsSmall();
  const { activeRoute } = useActiveRoute();
  const inDashboardContext = useInWillyDashboardContext();
  const showDropdown = useMemo(() => activeRoute?.url.includes('workflow'), [activeRoute?.url]);

  const hasSubMenu = useMemo(
    () => activeRoute?.url.includes('workflow') || activeRoute?.url?.includes('chat'),
    [activeRoute?.url],
  );

  const openDrawer = () => {
    if (activeRoute?.url.includes('chat') || activeRoute?.url.includes('workflows')) {
      openChatSelectorDrawer();
    }
  };

  return (
    <div
      className="flex items-center p-4 h-full gap-4 w-full"
      style={{ borderBottom: '1px solid var(--mantine-color-named2-3)' }}
    >
      <div className="flex items-center justify-between h-full gap-4">
        {!!inDashboardContext ? (
          <MobileDashboardSelectorOpener />
        ) : hasSubMenu ? (
          <div
            className="flex items-center gap-2"
            onClick={() => {
              isSmall ? openDrawer() : null;
            }}
          >
            {activeRoute?.icon && <activeRoute.icon mobile />}
            <div className="font-semibold">{activeRoute?.label || <></>}</div>
            {isSmall && showDropdown && <Icon name="up-down-arrows" size={12} color="gray.4" />}
          </div>
        ) : (
          <div className="flex items-center gap-2">
            {activeRoute?.icon && <activeRoute.icon mobile />}
            <div className="font-semibold">{activeRoute?.label || <></>}</div>
          </div>
        )}
      </div>
      <div className="ml-auto flex gap-4">
        {!!inDashboardContext && <MobileV3TopDashboardHeader />}
        <TopSection iconOnly />
      </div>
    </div>
  );
}

export function MobileV3FooterMenu() {
  const isSmall = useIsSmall();

  if (!isSmall) return null;

  return (
    <div
      id="apps-wrapper"
      className={`!bg-[var(--mantine-color-named-3)] w-full fixed
      bottom-0 flex items-center justify-around !p-0 mobile-menu-wrapper z-50`}
    >
      <MenuTemplate mobile />
    </div>
  );
}

export const MobileV3TopDashboardHeader = () => {
  const { $standardDashboard } = useDashContext();
  const standardDashboard = useStoreValue($standardDashboard);

  return standardDashboard && standardDashboard.mobileTopHeader ? (
    <standardDashboard.mobileTopHeader />
  ) : (
    <WillyDashboardMobileTopHeader />
  );
};
