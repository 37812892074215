import { closeChatSelectorDrawer } from '$stores/willy/$mobileDrawers';
import { Text, Tooltip } from '@tw/ui-components';
import { MouseEventHandler, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'reducers/RootType';
import { markRunAsRead } from './sequenceBuilder/hooks/useSequenceMessages';

type WillySidePanelWorkflowItemProps = {
  workflowId: string;
  lastestRunId: string | null;
  isActive: boolean;
  workflowName: string;
  read: boolean;
  inTooltip?: boolean;
};

export const WillySidePanelWorkflowItem: React.FC<WillySidePanelWorkflowItemProps> = ({
  workflowId,
  lastestRunId,
  isActive,
  workflowName,
  read,
  inTooltip,
}) => {
  const truncSize = useAppSelector((state) => state.willy.willySideMenuWidth - 20);
  const disabled = !lastestRunId;
  const [runRead, setRunRead] = useState(!!read);

  const onPinnedWorkflowClick = async () => {
    if (!read && lastestRunId) {
      await markRunAsRead(workflowId, lastestRunId);
      setRunRead(true);
    }
    closeChatSelectorDrawer();
  };

  return (
    <span className={`${disabled ? 'pointer-events-none' : ''}`}>
      <NavLink
        replace={false}
        to={`/chat/sequenceRun/${workflowId}`}
        className={
          'no-underline text-[var(--mantine-color-gray-7)] font-medium group/dashboard-label gap-2 ' +
          'render-item flex rounded-md items-center ' +
          'cursor-pointer hover:bg-[var(--gray-light-mode-300)] py-1 ' +
          (isActive ? 'bg-[var(--gray-light-mode-300)]' : '')
        }
        onClick={() => onPinnedWorkflowClick()}
      >
        <div className="flex items-center justify-between gap-2 pl-4 py-1 truncate">
          <Text
            fz="sm"
            c={disabled ? 'gray.4' : 'gray.8'}
            truncate
            maw={inTooltip ? undefined : truncSize}
            fw={500}
          >
            {workflowName}
          </Text>
          {!runRead && (
            <div className="text-white text-[10px] w-[10px] h-[10px] flex-col justify-center rounded-full bg-[var(--mantine-color-one-4)] flex-shrink-0"></div>
          )}
        </div>
      </NavLink>
    </span>
  );
};
