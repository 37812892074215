import { Flex, Switch, Text, Tooltip } from '@tw/ui-components';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { SegmentDataView } from './utils';
import { SegmentSync, SegmentSyncReq } from '@tw/types/module/services/cdp';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'index';
import axiosInstance from 'utils/axiosInstance';
import { setCDPSegmentsFromServer } from 'ducks/cdp/segments';
import { toast } from 'react-toastify';
import { ProviderIcon } from 'components/library/ProviderIcon';
import moment from '@tw/moment-cached';
import { services } from '@tw/types/module/services';
import TWTable from 'components/library/TWTable/TWTable';
import { BaseColumn } from 'components/library/TWTable/types';

const SegmentSyncs: React.FC<{ segment: SegmentDataView }> = ({ segment }) => {
  const facebookAdsAccounts = useSelector((state: RootState) => state.facebookAdsAccounts);

  const tableCols: BaseColumn<any, any>[] = [
    {
      key: 'provider',
      name: 'provider',
      dataType: 'text',
      sortable: false,
      Heading: () => (
        <Text size="sm" align="left" weight={500}>
          Provider
        </Text>
      ),
      Value: (syncDetails) => (
        <Flex align="center" gap="xs">
          <ProviderIcon provider={syncDetails.providerId} />
          <Text size="sm" weight={500}>
            {services[syncDetails.providerId].name}
          </Text>
        </Flex>
      ),
    },
    {
      key: 'account',
      name: 'account',
      dataType: 'text',
      sortable: false,
      Heading: () => (
        <Text size="sm" align="left" weight={500}>
          Account name
        </Text>
      ),
      Value: (syncDetails) => (
        <Text size="sm">
          {facebookAdsAccounts.find((acc) => acc.id === syncDetails.accountId)?.name ||
            syncDetails.accountId}
        </Text>
      ),
    },
    {
      key: 'last-updated',
      name: 'last-updated',
      dataType: 'text',
      sortable: false,
      Heading: () => (
        <Text size="sm" align="left" weight={500}>
          Last update
        </Text>
      ),
      Value: (syncDetails) => {
        const lastUpdatedAtText = syncDetails.lastUpdatedAt
          ? moment(syncDetails.lastUpdatedAt).fromNow()
          : '-';
        return (
          <>
            {syncDetails.error ? (
              <Tooltip
                color="red.1"
                multiline
                zIndex={99999}
                closeDelay={1500}
                label={
                  <Text span size="xs" c="red.4">
                    <Text fw={300}>{syncDetails.error.errorMessage}</Text>
                    <Text maw={500} fw={300}>
                      {syncDetails.error.errorDetail}
                    </Text>
                  </Text>
                }
              >
                <Text size="sm" c="red.6">
                  {lastUpdatedAtText}
                </Text>
              </Tooltip>
            ) : (
              <Text size="sm">{lastUpdatedAtText}</Text>
            )}
          </>
        );
      },
    },
    {
      key: 'is-active',
      name: 'is-active',
      dataType: 'text',
      sortable: false,
      Heading: () => (
        <Text size="sm" align="center" weight={500}>
          Active
        </Text>
      ),
      Value: (syncDetails) => {
        return <ToggleSyncColumn syncDetails={syncDetails} segmentId={segment.id} />;
      },
    },
  ];

  return (
    <TWTable
      className="table-light-style"
      data={segment.integrationsSyncDetails}
      columns={tableCols}
    />
  );
};

export default SegmentSyncs;

const ToggleSyncColumn: React.FC<{ segmentId: string; syncDetails: SegmentSync }> = ({
  segmentId,
  syncDetails,
}) => {
  const dispatch = useAppDispatch();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleSync = useCallback(async () => {
    const action = syncDetails.isSynced ? 'un-synced' : 'synced';

    try {
      setIsLoading(true);

      const request: SegmentSyncReq = {
        segmentId: segmentId,
        shopDomain: currentShopId,
        accountId: syncDetails?.accountId,
        providerId: syncDetails.providerId,
      };
      await axiosInstance.post('/v2/cdp/integrations/toggle-sync', request);
      dispatch(setCDPSegmentsFromServer());
      toast.success(`Segment was ${action} successfully.`);
    } catch (e) {
      toast.error(`Failed to ${action} segment.`);
    } finally {
      setIsLoading(false);
    }
  }, [
    currentShopId,
    dispatch,
    segmentId,
    syncDetails?.accountId,
    syncDetails.isSynced,
    syncDetails.providerId,
  ]);

  return (
    <Flex justify="center">
      <Switch size="xs" checked={syncDetails.isSynced} onChange={toggleSync} disabled={isLoading} />
    </Flex>
  );
};
