import { Button, Checkbox, Icon, Modal, Popover, Text } from '@tw/ui-components';
import { DayOfWeek } from '../types/willyTypes';
import { useEffect, useState } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { $timezone } from '$stores/$shop';

export const CustomScheduleModal: React.FC<{
  hours: number[];
  days: DayOfWeek[];
  onSave: (hours, days) => void;
  open: boolean;
  onClose: () => void;
}> = ({ hours, days, onSave, open, onClose }) => {
  const timezone = useStoreValue($timezone);

  const [tempHours, setTempHours] = useState(hours);
  const [tempDays, setTempDays] = useState(days);
  const [timePopoverOpen, setTimePopoverOpen] = useState(false);

  useEffect(() => {
    setTempDays(days);
    setTempHours(hours);
  }, [hours, days]);

  const toggleDay = (day) => {
    setTempDays((prev) => {
      const exists = prev.some((item) => item === day);
      return exists ? prev.filter((item) => item !== day) : [...prev, day];
    });
  };

  const toggleTime = (time) => {
    setTempHours((prev) => {
      const exists = prev.some((item) => item === time);
      return exists ? prev.filter((item) => item !== time) : [...prev, time];
    });
  };

  const timeOptions = Array(24)
    .fill(0)
    .map((_, i) => {
      return {
        label: `${i}:00`.padStart(5, '0'),
        value: i,
      };
    });
  return (
    <Modal
      opened={open}
      onClose={() => onClose()}
      title={
        <Text fw={500} fz={18}>
          Custom recurrence
        </Text>
      }
    >
      <div className="flex flex-col gap-5">
        <Text fz={14} fw={500}>
          Repeats every
        </Text>
        <div className="flex gap-5">
          {['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'].map(
            (day) => {
              const isSelected = tempDays.includes(day as DayOfWeek);
              return (
                <div
                  key={day}
                  className={`w-[30px] h-[30px] rounded-full fs-[14px] font-medium flex items-center justify-center cursor-pointer ${isSelected ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-400 '}`}
                  onClick={() => {
                    toggleDay(day as DayOfWeek);
                  }}
                >
                  {day.charAt(0)}
                </div>
              );
            },
          )}
        </div>
        <Text fz={14} fw={500}>
          At
        </Text>
        <Popover width="target" opened={timePopoverOpen}>
          <Popover.Target>
            <div
              className="w-full border border-solid border-gray-400 rounded-lg p-3 flex justify-between items-center"
              onClick={() => {
                setTimePopoverOpen(!timePopoverOpen);
              }}
            >
              <div className={`w-full h-full`}>
                {!!tempHours?.length ? (
                  <div className="flex items-center gap-3 w-full flex-wrap	">
                    {tempHours?.map((time) => {
                      const label = `${time}:00`.padStart(5, '0');
                      return (
                        <div
                          className="flex items-center gap-3 bg-gray-100 rounded-xl px-5"
                          key={time.toString()}
                        >
                          <Text fz={12}>{label}</Text>

                          <div
                            className="flex items-center cursor-pointer"
                            onClick={(e) => {
                              toggleTime(time);
                              e.stopPropagation();
                            }}
                          >
                            <Icon name="close" size={9} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="cursor-pointer w-full">
                    <Text fz={14} fw={500}>
                      Select a time
                    </Text>
                  </div>
                )}
              </div>
              <div className="flex-1 flex items-center justify-center">
                <Icon name="up-down-arrows" color="gray.5" size={15} />
              </div>
            </div>
          </Popover.Target>
          <Popover.Dropdown mah="300" overflow="auto">
            <div>
              {timeOptions.map((time) => {
                return (
                  <div className="flex items-center gap-3" key={time.value.toString()}>
                    <div>
                      <Checkbox
                        checked={tempHours?.includes(time.value)}
                        onChange={() => {
                          toggleTime(time.value);
                        }}
                      />
                    </div>
                    <span
                      className="p-3 cursor-pointer"
                      onClick={() => {
                        toggleTime(time.value);

                        setTimePopoverOpen(!timePopoverOpen);
                      }}
                    >
                      {time.label}
                    </span>
                  </div>
                );
              })}
            </div>
          </Popover.Dropdown>
        </Popover>
      </div>

      <Text fz={14} color="gray.5" pt={10}>
        Timezone: {timezone}
      </Text>
      <div className="flex justify-end w-full gap-5 pt-10">
        <Button variant="white" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          disabled={!tempDays.length || !tempHours.length}
          onClick={() => {
            onSave(tempHours, tempDays);
          }}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
