import { IconName } from '@tw/ui-components';
import { WillyDataSequence } from '../types/willyTypes';
import { useCallback, useMemo, useState } from 'react';
import { isGlobalDashboardExistsInShop } from '../dashContext';
import { copyGlobalSequenceToShop } from '../utils/willyUtils';
import { analyticsEvents, genericEventLogger, templateLibraryActions } from 'utils/dataLayer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { TemplateLibraryCard } from './TemplateLibraryCard';

export const AgentLibraryCard: React.FC<{
  agent: WillyDataSequence;
  onAddToCart?: (id) => void;
  itemInCart?: boolean;
}> = ({ agent, onAddToCart, itemInCart }) => {
  const navigate = useNavigate();

  const existInShop = useMemo(() => {
    if (agent) {
      return isGlobalDashboardExistsInShop(agent);
    }
  }, [agent]);

  const agentColor = agent?.themeColor ?? 'blue';
  const icon = (agent?.icon as IconName) ?? 'rocket';

  const [copyLoading, setCopyLoading] = useState(false);

  const copyToShop = useCallback(async () => {
    setCopyLoading(true);
    const newId = await copyGlobalSequenceToShop(agent.id);
    setCopyLoading(false);
    genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
      action: templateLibraryActions.ADD_TEMPLATE_TO_WORKSPACE,
      sequence_id: agent?.id,
      sequence_name: agent?.name,
    });
    toast.success(`Agent added to your shop`);
    navigate({
      pathname: `/workflows/create/${newId}`,
      search: 'openScheduler=true&fromTemplateLibrary=true',
    });
  }, [agent, navigate]);

  return (
    <TemplateLibraryCard
      exists={!!existInShop}
      themeColor={agentColor}
      onCopy={copyToShop}
      key={agent?.id}
      isLocked={!!agent?.isLocked || !!agent?.isProviderLocked || false}
      onPreview={() => {
        navigate(`/chat/agents/templates/${agent.id}`);
      }}
      name={agent?.name ?? ''}
      description={agent?.description ?? ''}
      providers={agent?.providers ?? []}
      copyLoading={copyLoading}
      needsUpdate={false}
      onUpdate={() => {}}
      iconSrc={
        !!agent?.iconSrc
          ? `https://storage.googleapis.com/file-hosting-bucket-shofifi/global-agent-image/${agent.id}.png`
          : undefined
      }
      onAddToCart={() => onAddToCart?.(agent.id)}
      itemInCart={itemInCart}
    />
  );
};
