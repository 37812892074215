import { Button } from '@tw/ui-components';
import { LimitedAccessTooltip } from 'components/Nav/components/navs/AlanNav/LimitedAccessTooltip';
import {
  WillyBaseMainElement,
  WillyDashboardElement,
  WillyDataSequence,
} from 'components/Willy/types/willyTypes';
import { copyGlobalDashboardToShop } from 'components/Willy/utils/copyGlobalDashboardToShop';
import { copyGlobalSequenceToShop } from 'components/Willy/utils/willyUtils';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { analyticsEvents, genericEventLogger, templateLibraryActions } from 'utils/dataLayer';
import { useCanAddTemplate } from './useCanAddTemplate';
import { useStoreValue } from '@tw/snipestate';
import { $dashboardCredits } from '$stores/willy/$credits';

type GetTemplateButtonProps = {
  template: WillyBaseMainElement;
  onClick?: () => void;
};
export const GetTemplateButton: FC<GetTemplateButtonProps> = ({ template, onClick }) => {
  const [copyLoading, setCopyLoading] = useState(false);
  const { credits, creditsLeft } = useStoreValue($dashboardCredits);
  const templateAccess = useCanAddTemplate(template.credits);
  const navigate = useNavigate();

  const creditsText = useMemo(() => {
    if (credits === Infinity) return '';
    if (!template?.credits || template.credits < 0) return '(Free)';
    return `(${template.credits} credit${template.credits > 1 ? 's' : ''})`;
  }, [credits, template.credits]);

  const logEvent = (action, template, isSequence = false) => {
    genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
      action,
      ...(!isSequence
        ? {
            dashboard_id: template.id,
            dashboard_name: template.name,
          }
        : {
            sequence_id: template.id,
            sequence_name: template.name,
          }),
    });
  };

  const copyToShop = useCallback(async () => {
    setCopyLoading(true);
    if (template.type === 'dashboard') {
      logEvent(templateLibraryActions.GET_TEMPLATE, template, false);
      const newId = await copyGlobalDashboardToShop(template as WillyDashboardElement);
      setCopyLoading(false);
      if (newId) {
        navigate(`/dashboards/${newId}`);
      }
    } else {
      const newId = await copyGlobalSequenceToShop(template.id);
      setCopyLoading(false);
      logEvent(templateLibraryActions.ADD_SEQUENCE_TO_WORKSPACE, template, true);
      toast.success(`Sequence added to your shop`);
      navigate(`/workflows/create/${newId}`);
    }
    onClick?.();
  }, [onClick, template, navigate]);

  return (
    <LimitedAccessTooltip
      accessLimit={templateAccess}
      target={
        <Button
          onClick={copyToShop}
          loading={copyLoading}
          size="xs"
          disabled={templateAccess !== 'can-access'}
        >
          Get {template.type === 'dashboard' ? 'Template' : 'Agent'} {creditsText}
        </Button>
      }
    />
  );
};
