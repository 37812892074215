import { $store } from '@tw/snipestate';

type upgradeSource = 'add_agent' | 'test_workflow';

export const $upgradeAgentsPopup = $store<{
  opened: boolean;
  title: string;
  source: upgradeSource | null;
}>({
  opened: false,
  title: '',
  source: null,
});

export const openUpgradeAgentsPopup = (title, source: upgradeSource) => {
  $upgradeAgentsPopup.set({ opened: true, title: title, source: source });
};

export const closeUpgradeAgentsPopup = () => {
  $upgradeAgentsPopup.set({ opened: false, title: '', source: null });
};
