import { useMemo, useEffect } from 'react';
import { Button, Text } from '@tw/ui-components';
import { WillyDashboardElement, WorkflowStep, WorkflowStepPreloadData } from '../types/willyTypes';
import AttributionDateModelPicker from 'components/attribution-new/AttributionDateModelPicker';
import AttributionModelPicker from 'components/attribution-new/AttributionModelPicker';
import { AttributionHeaderColumnSelector } from 'components/headers/AttributionHeader/AttributionHeaderColumnSelector';
import { AllServicesIds } from 'types/attribution';
import { SourceTypesWithExtra } from 'types/services';
import { useEffectiveSelectedColumns } from 'pages/Attribution/useEffectiveSelectedColumns';
import { useAttributionParams } from 'utils/useAttributionParam';
import { AttributionFiltersDropdown } from 'components/attribution-new/AttributionFiltersDropdown';

export const PixelMenu = ({
  currentDashboard,
  step,
  stepChange,
}: {
  currentDashboard: WillyDashboardElement;
  step: WorkflowStepPreloadData & WorkflowStep;
  stepChange: (step: WorkflowStepPreloadData & WorkflowStep) => void;
}) => {
  const attributionParams = useAttributionParams();
  const effectiveSelectedColumns = useEffectiveSelectedColumns();
  const isPixel = currentDashboard?.id.includes('pixel');

  const computedAttrOpts = useMemo(() => {
    const flattenedColumns = effectiveSelectedColumns.map((c) => c.key);
    // Flatten any nested arrays in the params
    const flattenedParams = {
      ...attributionParams,
      filters: attributionParams?.filters
        ? Object.fromEntries(
            Object.entries(attributionParams.filters).map(([key, value]) => {
              // If the value is an array of arrays, flatten it
              if (Array.isArray(value) && value.length > 0 && Array.isArray(value[0])) {
                return [key, value.flat()];
              }
              return [key, value];
            }),
          )
        : attributionParams?.filters ?? null,
    };

    return {
      params: flattenedParams,
      columns: flattenedColumns,
    };
  }, [attributionParams, effectiveSelectedColumns]);

  useEffect(() => {
    const newAttrOpts = isPixel ? computedAttrOpts : null;
    if (step.attributionOptions !== newAttrOpts) {
      stepChange({ ...step, attributionOptions: newAttrOpts });
    }
  }, [computedAttrOpts, isPixel, step, stepChange]);

  return (
    <div className="flex flex-col gap-6.5">
      <div className="flex gap-6.5">
        <div className="flex flex-col gap-4">
          <Text fw={500} size="sm">
            Date Model
          </Text>
          <AttributionDateModelPicker />
        </div>
        <div className="flex flex-col gap-4">
          <Text fw={500} size="sm">
            Attribution Model
          </Text>
          <AttributionModelPicker
            maxHeight="50%"
            zIndex={100000}
            type={'all' as SourceTypesWithExtra}
            sourceId={'all' as AllServicesIds}
          />
        </div>
        <div className="flex flex-col gap-4">
          <Text fw={500} size="sm">
            Filters
          </Text>
          <AttributionFiltersDropdown />
        </div>
        <div className="flex flex-col gap-4">
          <Text fw={500} size="sm">
            Columns
          </Text>
          <AttributionHeaderColumnSelector />
        </div>
      </div>
    </div>
  );
};
