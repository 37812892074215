import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { columns } from './adColumns';
import { ProductAdKeys, ProductAdsProps } from './types';
import { useSelector } from 'react-redux';
import type { RootState } from 'reducers/RootType';
import TWTable from 'components/library/TWTable/TWTable';
import { DraggableProvider } from 'components/DraggableProvider/DraggableProvider';
import { CancelMinor, FilterMinor } from '@shopify/polaris-icons';
import { AD_STATS, PRODUCT_SKUS_AD_STATS } from 'ducks/productAnalytics';
import { useAppDispatch } from 'index';
import _ from 'lodash';
import { orderBy } from 'lodash';
import { SortDirection, AttributionData } from '@tw/types';
import ProductAdsPopupHeader from './ProductAdsPopupHeader';
import { Banner, Collapsible, Icon, TextField } from '@shopify/polaris';
import SettingsButton from 'components/SettingsButton';
import { GOOGLE_SHEETS_BANNER_METADATA } from 'ducks/googleSheets';
import { useExport } from 'hooks/useExport';
import ExportModalForm from './ExportModalForm';
import { getSettingsItems } from './exportSettingsItems';
import { filterAdsData } from './utils';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../$stores/$shop';
const Handle = ProductAdsPopupHeader;

const ProductAdsPopup: FC<ProductAdsProps> = ({ action, product, source = 'product' }) => {
  const dispatch = useAppDispatch();
  const { isGoogleSheetsConnected, isSavingGoogleCSV, googleSheetsBannerMetadata } =
    useSelector((state: RootState) => state) || {};

  const productAdStats = useSelector((state: RootState) => state.productAnalytics.adStats);
  const skuAdStats = useSelector((state: RootState) => state.productAnalytics.productSkusAdStats);

  const adStats = useMemo(() => {
    const chStats = product.metrics['ads']
      ? [{ productId: product.id, ads: product.metrics['ads'] }]
      : undefined;
    return source === 'product' ? productAdStats || {} : skuAdStats || {};
  }, [product.id, product.metrics, productAdStats, skuAdStats, source]);

  const currency = useStoreValue($currency);
  const exportSheet = useExport();
  const [adsData, setAdsData] = useState<AttributionData[]>(
    adStats?.find((ad: { productId: string }) => ad.productId === product?.id)?.ads || [],
  );
  const [filteredAdsData, setFilteredAdsData] = useState<AttributionData[]>(
    adStats?.find((ad: { productId: string }) => ad.productId === product?.id)?.ads || [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [attributions, setAttributions] = useState<any[]>([]);
  const [sortColumnIndex, setSortColumnIndex] = useState<ProductAdKeys>('fullAdPixelRoas');
  const [sortOrder, setSortOrder] = useState<SortDirection>('descending');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showExport, setShowExport] = useState<boolean>(false);
  const [exportTitle, setExportTitle] = useState<string>('Export CSV');
  const [fileName, setFileName] = useState<string>('Title');
  const [sheetsAccount, setSheetsAccount] = useState<string>('');
  const { text, link, bannerTitle, showBanner, status } = googleSheetsBannerMetadata;

  useEffect(() => {
    if (!adStats || !attributions) return;
    const adStatsForProduct = adStats?.find(
      (ad: { productId: string }) => ad.productId === product?.id,
    );
    const oldAds = adStatsForProduct?.ads || [];
    const newAds = oldAds.map((ad: { adId: any; status: any }) => {
      const adAttribution = attributions.find((attr) => attr.id === ad.adId);
      const adStatus = adAttribution?.status || ad.status;
      return {
        ...ad,
        status: adStatus,
      };
    });

    if (!_.isEqual(newAds, oldAds)) {
      const newAdStats = adStats?.map((x: { productId: string }) => {
        if (x.productId === product?.id) {
          return {
            ...x,
            ads: newAds,
          };
        } else {
          return x;
        }
      });
      //this is to toggle the status switch in state
      dispatch({
        type: source === 'product' ? AD_STATS : PRODUCT_SKUS_AD_STATS,
        payload: newAdStats,
      });
    }
  }, [adStats, attributions, dispatch, product?.id, source]);

  useEffect(() => {
    setIsLoading(true);
    if (!adStats) return;
    const data =
      adStats?.find((ad: { productId: string }) => ad.productId === product?.id)?.ads || [];
    const defaultValues = {
      status: null,
      name: null,
      fullAdSpend: 0,
      productSpend: 0,
      fullAdPixelRoas: 0,
      pixelRoasWithoutProduct: 0,
      productPixelConversionValue: 0,
      fullPixelConversionValue: 0,
    };
    const dataWithDefaults = data?.map((ad: any) => Object.assign({}, defaultValues, ad));
    const attrs = data?.map(
      (ad: { adId: any; serviceId: any; status: any; accountId: any }) =>
        (({
          entity: 'ad',
          id: ad?.adId,
          serviceId: ad?.serviceId,
          status: ad?.status,
          accountId: ad?.accountId,
        }) as any) || [],
    );
    setAdsData(orderBy(dataWithDefaults, ['fullAdPixelRoas'], 'desc'));
    setAttributions(attrs);
    setIsLoading(false);
  }, [adStats, product?.id]);

  useEffect(() => {
    setFilteredAdsData(filterAdsData(adsData, searchTerm));
  }, [searchTerm, adsData]);

  useEffect(() => {
    const order = sortOrder === 'ascending' ? 'asc' : 'desc';
    setFilteredAdsData((filteredAdsData) => orderBy(filteredAdsData, [sortColumnIndex], [order]));
  }, [sortColumnIndex, sortOrder]);

  const onSort = useCallback((columnIndex: number, order: SortDirection) => {
    const column = columns[columnIndex];
    setSortColumnIndex(column.key);
    setSortOrder(order);
  }, []);

  const closeExport = useCallback(() => {
    setFileName('');
    setShowExport(false);
    dispatch({
      type: GOOGLE_SHEETS_BANNER_METADATA,
      payload: { ...googleSheetsBannerMetadata, showBanner: false },
    });
  }, [dispatch, googleSheetsBannerMetadata]);

  const onClose = useCallback(() => {
    action(null);
    setAdsData([]);
    setAttributions([]);
    setSortOrder('descending');
    setSortColumnIndex('fullAdPixelRoas');
    closeExport();
  }, [action, closeExport]);

  if (!adsData) return null;
  const exportCsvParams = {
    data: filteredAdsData,
    isExportCsv: exportTitle?.includes('CSV'),
    columns,
    keyMap: filteredAdsData.reduce((acc, obj) => {
      Object.keys(obj).forEach((key) => {
        if (!acc[key]) {
          acc[key] = key;
        }
      });
      return acc;
    }, {}),
    fileName,
    sheetsAccount,
  };

  return (
    <DraggableProvider
      usingDragger={true}
      initialPosition="center center"
      className="draggable-card scroll-smooth bg-[#11283E]/95
                 shadow-slate-500 rounded-lg shadow-2xl
                 max-h-[calc(80vh-50px)] max-w-[calc(80vw-50px)] overflow-auto "
    >
      <CancelMinor
        className={`absolute right-0 top-0 mt-2 mr-2 w-12 h-12 fill-[#C0D6EA] 
                    cursor-pointer p-1 rounded-md outline outline-1 outline-light-blue/10 
                    hover:outline-light-blue/20 hover:bg-[#c0d6ea]/5 
                    hover:shadow-[1px_6px_9px_0_rgba(0,0,0,0.13)]`}
        onClick={onClose}
      />
      <div className="flex items-end justify-between">
        <div className="w-3/4 pr-4">
          <Handle name={product?.name || product.id} thumbnail={product?.thumbnail} />
        </div>
        <div className="flex-1 pb-4 pr-2">
          <TextField
            clearButton
            onClearButtonClick={() => setSearchTerm('')}
            value={searchTerm}
            onChange={(searchTerm) => setSearchTerm(searchTerm)}
            label="Filter"
            labelHidden
            placeholder={`Filter by name, platform, or id`}
            autoComplete="off"
            prefix={<Icon source={FilterMinor} />}
          />
        </div>
        <div className="pr-4 pb-4">
          <SettingsButton
            items={getSettingsItems({
              setExportTitle,
              setShowExport,
              isGoogleSheetsConnected,
            })}
          />
        </div>
      </div>
      <div
        id="att-product-analytics-product-ads-popup"
        className="flex flex-col sm:rounded-[8px] text-white w-full overflow-scroll draggable-card px-4"
      >
        <Collapsible open={showExport} id="export" transition={{ timingFunction: 'ease-in-out' }}>
          {showBanner ? (
            <div className="flex flex-col justify-end text-black pb-4">
              <Banner status={status} title={bannerTitle} onDismiss={closeExport}>
                {link && (
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {text}
                  </a>
                )}
              </Banner>
            </div>
          ) : (
            <div className="flex flex-col justify-end w-2/5">
              <ExportModalForm
                fileName={fileName}
                setFileName={setFileName}
                exportType={`${exportTitle?.includes('CSV') ? 'CSV' : 'Google Sheet'}`}
                isLoading={isSavingGoogleCSV}
                onSave={() => exportSheet(exportCsvParams)}
                onClose={closeExport}
                sheetsAccount={sheetsAccount}
                setSheetsAccount={setSheetsAccount}
              />
            </div>
          )}
        </Collapsible>
        <TWTable
          id="product-ads-table"
          loading={isLoading}
          columns={columns}
          data={filteredAdsData}
          metadata={{
            isDarkMode: true,
            currency,
            attributions,
            setData: setAttributions,
            isLoading,
          }}
          stickyColumnIndex={0}
          stickyHeader
          padding="0.5rem"
          dark
          height={400}
          onSort={onSort}
          sortBy={sortColumnIndex}
          sortDirection={sortOrder}
        />
      </div>
    </DraggableProvider>
  );
};

export default ProductAdsPopup;
