import {
  Box,
  Checkbox,
  Collapse,
  Flex,
  Icon,
  Popover,
  Size,
  Text,
  TextInput,
} from '@tw/ui-components';
import { ColorPickerPopover } from '../ColorPicker';
import { WillyMetric } from '../types/willyTypes';
import { useCallback, useMemo, useState } from 'react';
import { generateColorScale } from '../utils/willyUtils';
import { EditorCollapse } from './EditorCollapse';
import { InfoPopover } from './InfoPopover';

type TableCustomizeProps = {
  queryId: string | null;
  editedWrapText?: boolean;
  setEditedWrapText: (val: boolean) => void;
  editedHasGlobalConditionalFormatting?: boolean;
  setEditedHasGlobalConditionalFormatting: (val: boolean) => void;
  editedGlobalConditionalFormattingColor: string;
  setEditedGlobalConditionalFormattingColor: (val: string) => void;
  editedMetrics: WillyMetric[];
  updateEditedMetrics: (queryId: string, metrics: Array<any>) => void;
  editedBreakdownMode: boolean;
  setEditedBreakdownMode: (val: boolean) => void;
  dataHasBreakdown: boolean;
};

export const TableCustomize: React.FC<TableCustomizeProps> = ({
  queryId,
  editedWrapText,
  setEditedWrapText,
  editedHasGlobalConditionalFormatting,
  setEditedHasGlobalConditionalFormatting,
  editedGlobalConditionalFormattingColor,
  setEditedGlobalConditionalFormattingColor,
  editedMetrics,
  updateEditedMetrics,
  editedBreakdownMode,
  setEditedBreakdownMode,
  dataHasBreakdown,
}) => {
  const [metricSearch, setMetricSearch] = useState('');

  const exampleScale = useMemo(() => {
    const exampleScale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const colors = generateColorScale(editedGlobalConditionalFormattingColor, exampleScale);
    return Object.values(colors);
  }, [editedGlobalConditionalFormattingColor]);

  const selectedMetricsNumber = useMemo(
    () => editedMetrics.filter((m) => m.applyOnGlobalConditionalFormatting).length,
    [editedMetrics],
  );

  const editedNotDimensionMetrics = useMemo(() => {
    return editedMetrics.filter((m) => !m.isDimension);
  }, [editedMetrics]);

  const handleSelectMetricChange = useCallback(
    (metric: WillyMetric) => {
      if (!queryId) {
        return;
      }
      const newMetrics = editedMetrics.map((m) => {
        if (m.key === metric.key) {
          return {
            ...m,
            applyOnGlobalConditionalFormatting: !m.applyOnGlobalConditionalFormatting,
          };
        }
        return m;
      });
      updateEditedMetrics(queryId, newMetrics);
    },
    [queryId, editedMetrics, updateEditedMetrics],
  );

  const applyToAllMetrics = useCallback(
    (checked: boolean) => {
      if (!queryId) {
        return;
      }
      const newMetrics = editedMetrics.map((m) => {
        if (m.isDimension) {
          return m;
        }
        return {
          ...m,
          applyOnGlobalConditionalFormatting: checked,
        };
      });
      updateEditedMetrics(queryId, newMetrics);
    },
    [queryId, editedMetrics, updateEditedMetrics],
  );

  return (
    <Flex direction="column" gap="md" px="md">
      <Flex align="center" gap="xs">
        <Checkbox
          label="Table with Tabs"
          checked={editedBreakdownMode}
          onChange={setEditedBreakdownMode}
          disabled={!dataHasBreakdown}
        />
        {!dataHasBreakdown && (
          <InfoPopover description="This option is only available for Pixel Data Queries that include campaign, Ad set, and Ad Breakdowns" />
        )}
      </Flex>
      <EditorCollapse title="Column Formatting">
        <Flex direction="column" gap="md" pt="sm">
          <Checkbox
            label="Wrap Text in Cells"
            onChange={() => setEditedWrapText(!editedWrapText)}
            checked={editedWrapText}
          />
          <Flex gap="xs" align="center">
            <Checkbox
              label="Enable Table Heat Map"
              onClick={() => {
                setEditedHasGlobalConditionalFormatting(!editedHasGlobalConditionalFormatting);
              }}
              checked={editedHasGlobalConditionalFormatting}
            />
            <InfoPopover description="Highlight Metrics based on their relative value to all other Metrics" />
          </Flex>
          <Collapse in={!!editedHasGlobalConditionalFormatting}>
            <Flex direction="column" gap="md">
              <ColorPickerPopover
                color={editedGlobalConditionalFormattingColor ?? ''}
                onChange={setEditedGlobalConditionalFormattingColor}
                label="Color"
                labelSize="sm"
                activatorClassName="w-[18px] h-[18px] !rounded-[4px]"
                onReset={() => {}}
                className="justify-between"
                saveOnChange
                showColorValue
              />
              <Flex justify="space-between" align="center">
                <Text size="sm">Generated Range</Text>
                <Flex gap={4 as Size} align="center">
                  {exampleScale.map((color, i) => {
                    return (
                      <div
                        key={i}
                        className="w-[9px] h-[9px] rounded-[1px]"
                        style={{
                          backgroundColor: color,
                        }}
                      ></div>
                    );
                  })}
                </Flex>
              </Flex>
              <Popover width="target">
                <Popover.Target>
                  <TextInput
                    label="Metrics Involved"
                    value={`${selectedMetricsNumber} out of ${
                      editedMetrics.filter((m) => !m.isDimension).length
                    } selected`}
                    trailingIcon={<Icon name="chevron-down" size={12} />}
                  />
                </Popover.Target>
                <Popover.Dropdown>
                  <Flex direction="column" gap="md">
                    <TextInput
                      placeholder="Search"
                      leadingIcon="search-major"
                      value={metricSearch}
                      onChange={setMetricSearch}
                    />
                    <Flex direction="column" gap="xs" mah={250} overflow="scroll">
                      <Checkbox
                        checked={editedNotDimensionMetrics.every(
                          (m) => m.applyOnGlobalConditionalFormatting,
                        )}
                        label="Apply to all metrics"
                        indeterminate={
                          editedNotDimensionMetrics.some(
                            (m) => m.applyOnGlobalConditionalFormatting,
                          ) &&
                          editedNotDimensionMetrics.some(
                            (m) => !m.applyOnGlobalConditionalFormatting,
                          )
                        }
                        onChange={applyToAllMetrics}
                      />
                      <Box mih={1} h={1} w="100%" bg="gray.2" />
                      {editedMetrics
                        .filter((m) => !m.isDimension)
                        .filter((m) => m.name.toLowerCase().includes(metricSearch.toLowerCase()))
                        .map((metric) => (
                          <Checkbox
                            key={metric.key}
                            checked={metric.applyOnGlobalConditionalFormatting}
                            label={metric.name}
                            onChange={() => handleSelectMetricChange(metric)}
                          />
                        ))}
                    </Flex>
                  </Flex>
                </Popover.Dropdown>
              </Popover>
            </Flex>
          </Collapse>
        </Flex>
      </EditorCollapse>
    </Flex>
  );
};
