import { Anchor, Icon, Loader, Tabs, Text } from '@tw/ui-components';
import { WillySearchInput } from '../WillySearchInput';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useStoreValue } from '@tw/snipestate';
import useDebounce from 'utils/useDebounce';
import { AgentLibraryCard } from './AgentLibraryCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { AgentCollection, Column, ColumnName, WillyDataSequence } from '../types/willyTypes';
import { ColumnLibraryCard } from './ColumnLibraryCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import axiosInstance from 'utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';
import { Checkout } from './Checkout';

const tabs = [
  { id: 'agents', label: 'Global Agents' },
  { id: 'ai_columns', label: 'Attribution Agents' },
];

export type AgentCheckoutItem = { id: string; type: 'agent' | 'column' };

export const AgentLibraryCollection: React.FC<{ collection: AgentCollection }> = ({
  collection,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const currentShopId = useStoreValue($currentShopId);

  const activeTab: string | null = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const tab = searchParams.get('tab');
    return tab || 'agents';
  }, [search]);

  const currentTab = useMemo(() => tabs.find((t) => t.id === activeTab) || tabs[0], [activeTab]);

  const [searchPrompt, setSearchPrompt] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchPrompt, 1000);

  const [aiColumns, setAiColumns] = useState<Column<ColumnName>[]>([]);
  const [agents, setAgents] = useState<WillyDataSequence[]>([]);
  const [totalAiColumns, setTotalAiColumns] = useState(0);
  const [totalAgents, setTotalAgents] = useState(0);

  const [aiColumnsPage, setAiColumnsPage] = useState(1);
  const [agentsPage, setAgentsPage] = useState(1);

  const [loadingMoreAiColumns, setLoadingMoreAiColumns] = useState(false);
  const [loadingMoreAgents, setLoadingMoreAgents] = useState(false);

  const [agentsAddedToCart, setAgentsAddedToCart] = useState<AgentCheckoutItem[]>([]);

  const [openCheckout, setOpenCheckout] = useState(false);

  const getPaginatedAgents = useCallback(
    async (page, searchTerm = '') => {
      if (!currentShopId) return;

      try {
        setLoadingMoreAgents(true);
        const { data } = await axiosInstance.post(
          `/v2/sequences/collections/get-paginated-agents`,
          {
            collection: collection.id,
            filter: searchTerm,
            page: page,
            shopId: currentShopId,
          },
        );

        setAgents((prev) => (page === 1 ? data.agents : [...prev, ...data.agents]));
        setTotalAgents(data.totalAgents);
        setAgentsPage(page + 1);
        setLoadingMoreAgents(false);
      } catch (error) {
        console.error('Error fetching agents:', error);
        setLoadingMoreAgents(false);
      }
    },
    [collection.id, currentShopId],
  );

  const getPaginatedAiColumns = useCallback(
    async (page, searchTerm = '') => {
      if (!currentShopId) return;

      try {
        setLoadingMoreAgents(true);
        const { data } = await axiosInstance.post(
          `/v2/sequences/collections/get-paginated-columns`,
          {
            collection: collection.id,
            filter: searchTerm,
            page: page,
            shopId: currentShopId,
          },
        );

        setAiColumns((prev) => (page === 1 ? data.agents : [...prev, ...data.agents]));
        setTotalAiColumns(data.totalAgents);
        setAiColumnsPage(page + 1);
        setLoadingMoreAgents(false);
      } catch (error) {
        console.error('Error fetching AI columns:', error);
        setLoadingMoreAgents(false);
      }
    },
    [collection.id, currentShopId],
  );

  useEffect(() => {
    getPaginatedAgents(1, '');
    getPaginatedAiColumns(1, '');
  }, [getPaginatedAgents, getPaginatedAiColumns]);

  useEffect(() => {
    getPaginatedAgents(1, debouncedSearchTerm);
    setAgentsPage(1);
  }, [debouncedSearchTerm, getPaginatedAgents]);

  useEffect(() => {
    getPaginatedAiColumns(1, searchPrompt);
    setAiColumnsPage(1);
  }, [searchPrompt, getPaginatedAiColumns]);

  const loadNextPage = useCallback(async () => {
    if (!currentShopId) return;

    if (activeTab === 'agents') {
      if (loadingMoreAgents) return; // Prevent duplicate calls
      setLoadingMoreAgents(true);

      try {
        await getPaginatedAgents(agentsPage, debouncedSearchTerm);
      } catch (error) {
        console.error('Error loading more agents:', error);
      } finally {
        setLoadingMoreAgents(false);
      }
    } else if (activeTab === 'ai-columns') {
      if (loadingMoreAiColumns) return; // Prevent duplicate calls
      setLoadingMoreAiColumns(true);

      try {
        await getPaginatedAiColumns(aiColumnsPage, debouncedSearchTerm);
      } catch (error) {
        console.error('Error loading more AI columns:', error);
      } finally {
        setLoadingMoreAiColumns(false);
      }
    }
  }, [
    currentShopId,
    activeTab,
    loadingMoreAgents,
    agentsPage,
    aiColumnsPage,
    debouncedSearchTerm,
    getPaginatedAgents,
    getPaginatedAiColumns,
    loadingMoreAiColumns,
  ]);

  const onSearchChange = (v) => {
    setSearchPrompt(v);
  };

  const toggleItemInCart = (id, type) => {
    setAgentsAddedToCart((prev) => {
      const exists = prev.some((item) => item.id === id && item.type === type);
      return exists
        ? prev.filter((item) => item.id !== id || item.type !== type)
        : [...prev, { id, type }];
    });
  };

  const bgUrl = `https://storage.googleapis.com/public-assets-shofifi/agents-library/${collection.color}_collection_header.png`;

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-[10%] mx-4 md:mx-12 mt-8 md:mt-12">
        <div
          className="back-button flex align-items-center gap-5 cursor-pointer"
          onClick={() => {
            openCheckout ? setOpenCheckout(false) : navigate(`/chat/agents/collections`);
          }}
        >
          <Icon name={'chevron-left-minor'} color="one.5" size={14} />
          <Text color="one.6" fz={14} fw={500}>
            Back
          </Text>
        </div>
      </div>
      <div className="flex w-full md:max-w-[90%]">
        <div
          className={`mx-auto p-4 md:my-12 md:px-0 md:mx-0 pt-0 h-full flex flex-col gap-[35px] w-full md:max-w-[85%]`}
        >
          {!openCheckout ? (
            <>
              <div
                className={`p-[25px]  mt-8 md:mt-0  w-full rounded-xl flex items-center gap-2 justify-between  bg-center bg-cover bg-no-repeat`}
                style={{ backgroundImage: `url(${bgUrl})` }}
              >
                <div className="flex flex-col w-full md:w-[55%] justify-between gap-7 h-full">
                  <Text color="white" fw={600} fz={24}>
                    {collection.tagline}
                  </Text>
                  <Text color="white" fz={14}>
                    {collection.subTagline}
                  </Text>
                  {/* <div>
                <Button variant="white" size="sm">
                  Get full collection
                </Button>
              </div> */}
                </div>
              </div>
              <div className="flex items-center justify-between w-full">
                <Tabs
                  value={activeTab}
                  onChange={(tab) => {
                    navigate(
                      {
                        pathname: `/chat/agents/collections/${collection.id}`,
                        search: `?tab=${tab}`,
                      },
                      { replace: true },
                    );
                    window.scrollTo(0, 0);
                  }}
                >
                  <Tabs.List>
                    {tabs.map((tab) => {
                      return (
                        <Tabs.Tab key={tab.id} value={tab.id}>
                          <Text
                            color={currentTab.id === tab.id ? 'one.6' : 'gray.5'}
                            fw={500}
                            fz={14}
                          >
                            {tab.label}
                          </Text>
                        </Tabs.Tab>
                      );
                    })}
                  </Tabs.List>
                </Tabs>
                {agentsAddedToCart.length == 0 ? (
                  <Text fw={600} fz="sm" color="gray.5">
                    Calculate Agents Costs ({agentsAddedToCart.length})
                  </Text>
                ) : (
                  <Anchor fw={600} fz="sm" onClick={() => setOpenCheckout(true)} disabled={true}>
                    Calculate Agents Costs ({agentsAddedToCart.length})
                  </Anchor>
                )}
              </div>
              <WillySearchInput
                value={searchPrompt}
                onChange={(e) => onSearchChange(e)}
                placeholder="Search"
                className="!p-0 overflow-visible w-full"
              />
              {activeTab === 'ai_columns' ? (
                <Text>
                  Attribution agents can be added to your pixel table to facilitate quick and
                  informed decision making.
                </Text>
              ) : (
                <Text>
                  Global agents can be accessed throughout Triple Whale and are specially designed
                  for complex analysis.
                </Text>
              )}
              <InfiniteScroll
                scrollableTarget="scroll-container"
                scrollThreshold={0.8}
                dataLength={activeTab === 'agents' ? agents.length : aiColumns.length}
                hasMore={
                  activeTab === 'agents'
                    ? agents.length < totalAgents
                    : aiColumns.length < totalAiColumns
                }
                next={() => {
                  loadNextPage();
                }}
                loader={
                  <div className="flex items-center justify-center h-12 py-20">
                    <Loader size="sm" />
                  </div>
                }
              >
                <div className="flex flex-col md:grid grid-cols-3 gap-6">
                  {activeTab === 'ai_columns' && (
                    <>
                      {aiColumns.map((column) => {
                        return (
                          <Fragment key={column.key}>
                            <ColumnLibraryCard
                              column={column}
                              // onAddToCart={(id) => toggleItemInCart(id, 'column')}
                              // itemInCart={agentsAddedToCart.some(
                              //   (item) => item.id === column.key && item.type === 'column',
                              // )}
                            />
                          </Fragment>
                        );
                      })}
                      {aiColumns.length === 0 && (
                        <>
                          {loadingMoreAiColumns ? (
                            <div className="flex items-center justify-center h-12 py-20">
                              <Loader size="sm" />
                            </div>
                          ) : (
                            <Text>No Agents</Text>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {activeTab === 'agents' && (
                    <>
                      {agents.map((agent) => {
                        return (
                          <Fragment key={agent.id}>
                            <AgentLibraryCard
                              agent={agent}
                              onAddToCart={(id) => toggleItemInCart(id, 'agent')}
                              itemInCart={agentsAddedToCart.some(
                                (item) => item.id === agent.id && item.type === 'agent',
                              )}
                            />
                          </Fragment>
                        );
                      })}
                      {agents.length === 0 && (
                        <>
                          {loadingMoreAgents ? (
                            <div className="flex items-center justify-center h-12 py-20">
                              <Loader size="sm" />
                            </div>
                          ) : (
                            <Text>No Agents</Text>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </InfiniteScroll>
            </>
          ) : (
            <Checkout agentIds={agentsAddedToCart} />
          )}
        </div>
      </div>
    </div>
  );
};
