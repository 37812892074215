import { useCallback, useState } from 'react';
import { Button, Text, Icon } from '@tw/ui-components';
import { DropZone } from '@shopify/polaris';
import { MobyUploadedFile, WorkflowStepBase, WorkflowStepVision } from '../types/willyTypes';
import { FlowStepWithText } from './FlowStepWithText';
import { stepActionType } from '../types/willyTypes';
import { ChatInputFile } from '../ChatInputFile';

type FlowVisionStepProps = {
  step: WorkflowStepVision & WorkflowStepBase;
  stepChange: (step: WorkflowStepVision & WorkflowStepBase) => void;
  toolConfig?: stepActionType;
  depth: number;
  setIsPristine: (isPristine: boolean) => void;
  disabled?: boolean;
};

export const FlowVisionStep: React.FC<FlowVisionStepProps> = ({
  step,
  stepChange,
  toolConfig,
  depth,
  setIsPristine,
  disabled,
}) => {
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<MobyUploadedFile[]>(step.uploadedFiles || []);

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      const newFileContents: MobyUploadedFile[] = [];
      const newFiles: File[] = [];

      acceptedFiles.forEach((file) => {
        newFiles.push(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          const content = reader.result ? (reader.result as string) : '';
          newFileContents.push({ name: file.name, content, type: file.type });
          // Update state only after all files are read
          if (newFileContents.length === acceptedFiles.length) {
            setUploadedFiles((prevContents) => [...prevContents, ...newFileContents]);

            stepChange({
              ...step,
              uploadedFiles: newFileContents,
            });
          }
        };

        reader.readAsDataURL(file);
      });
    },
    [step, stepChange],
  );

  const toggleOpenFileDialog = useCallback(
    () => setOpenFileDialog((openFileDialog) => !openFileDialog),
    [],
  );

  const removeFile = useCallback(
    (index: number) => {
      const newUploadedFiles = step.uploadedFiles?.filter((_, i) => i !== index);
      setUploadedFiles(newUploadedFiles);
      stepChange({
        ...step,
        uploadedFiles: newUploadedFiles,
      });
    },
    [step, stepChange],
  );

  return (
    <div className="flex flex-col gap-4">
      <FlowStepWithText
        readOnly={false}
        text={step.text || ''}
        textChange={(text) => stepChange({ ...step, text })}
        variables={step.variables || []}
        variableChange={(variable) => {
          const variables = step.variables || [];
          const variableIndex = variables.findIndex((v) => v.key === variable.key);
          if (variableIndex !== -1) {
            variables[variableIndex] = variable;
          } else {
            variables.push(variable);
          }
          stepChange({
            ...step,
            variables,
          });
        }}
        toolConfig={toolConfig}
        setIsPristine={setIsPristine}
        depth={depth}
        editMode={true}
        withToolbar={false}
        allowComments
      />

      <div className="flex flex-col justify-end items-start gap-2 h-full mt-auto">
        {!!uploadedFiles?.length && (
          <div className="flex gap-2 pb-4">
            {uploadedFiles?.map((file, i) => {
              return (
                <div className="w-[200px]" key={i}>
                  <ChatInputFile file={file} index={i} removeFile={removeFile} />
                </div>
              );
            })}
          </div>
        )}
        <Button
          onClick={() => toggleOpenFileDialog()}
          variant="white"
          size="xs"
          disabled={disabled}
          rightSection={<Icon name="union" size={12} />}
        >
          Upload from Computer
        </Button>

        <div className="hidden">
          <DropZone
            openFileDialog={openFileDialog}
            onDrop={(files) => {
              const MAX_FILE_SIZE = 1024 * 1024; // 1MB in bytes
              const validFiles = files.filter((file) => file.size <= MAX_FILE_SIZE);
              const rejectedFiles = files.filter((file) => file.size > MAX_FILE_SIZE);

              if (validFiles.length < files.length) {
                // Some files were rejected
                alert('Files must be smaller than 1MB');
                return;
              }

              if (validFiles.length > 1) {
                alert('You can only upload up to 1 file');
                return;
              }

              if (validFiles.length > 0) {
                handleDropZoneDrop(files, validFiles, rejectedFiles);
              }
            }}
            onFileDialogClose={toggleOpenFileDialog}
            type="file"
            accept="image/*"
            errorOverlayText="Image (.jpg, .png, and .svg) files under 1MB are supported"
          />
        </div>
      </div>
    </div>
  );
};
