import firebase from 'firebase/compat/app';
import moment from '@tw/moment-cached/module/timezone';
import { creativeType, influencer, SelectableInfluencer } from 'types/Influencers';
import axiosInstance from 'utils/axiosInstance';
import { influencersDb } from './DB';
import { toast } from 'react-toastify';

export const getInfluencers = async (shopId) => {
  const collection = await influencersDb().get();

  const influencersCol: any = collection.docs.map((doc, i) => {
    return { ...doc.data(), id: doc.id };
  });

  //await getThumbnails(influencersCol);
  return influencersCol;
};

export const getInfluencerById = async (shopId, influencerId) => {
  const collection = await influencersDb().doc(influencerId).get();

  const influencer: any = collection.data();

  return influencer;
};

const cleanUpForSave = (influencer: SelectableInfluencer) => {
  delete influencer.files;
  delete influencer.profile_file;
  delete influencer.metrics;
  delete influencer.metricsBreakdown;
  delete influencer.active;
  delete influencer.adsets;
  delete influencer.selected;
  delete influencer?.esKey;
  delete influencer?.entity;

  const links = influencer.links?.filter((link) => link.campaign || link.source);
  influencer.links = links;
  const dicountCodes = (influencer.discountCodes || [])
    .filter((c) => c.code)
    .map((code) => {
      delete code.isNew;
      return code;
    });
  influencer.discountCodes = dicountCodes;

  return influencer;
};

//update
export const influencerEdit = async (influencer: SelectableInfluencer, shopId, currency = '') => {
  cleanUpForSave(influencer);

  let influencerRef = influencersDb().doc(influencer.id?.toString());
  saveExpenses(influencerRef, influencer);
  influencer.links = createLinks(influencer, shopId);

  await influencerRef.set(influencer, { merge: true });

  try {
    await axiosInstance.post('/v2/attribution/affluencer-update', {
      influencer,
      shopDomain: shopId,
      source: 'client',
      currency,
    });
  } catch (e) {
    console.log('update: error', e);
  }

  return influencer;
};

export const createInfluencer = async (
  newInfluencer: SelectableInfluencer,
  shopId,
  currency = '',
  existingInfluencers: Array<SelectableInfluencer>,
) => {
  newInfluencer.id = cleanUpValue(newInfluencer.name);

  if (existingInfluencers.find((i) => i.id === newInfluencer.id || i.name === newInfluencer.name)) {
    toast.error('Influencer already exists');
    return false;
  }

  newInfluencer.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
  cleanUpForSave(newInfluencer);

  const newInfluencerDoc = influencersDb().doc(newInfluencer.id?.toString());
  saveExpenses(newInfluencerDoc, newInfluencer);
  newInfluencer.expenses = (newInfluencer.expenses?.map((exp) => ({
    ...exp,
    influencer_id: newInfluencer.id,
  })) || []) as any[];
  newInfluencer.links = createLinks(newInfluencer, shopId);
  await newInfluencerDoc.set(newInfluencer, { merge: true });

  try {
    await axiosInstance.post('/v2/attribution/affluencer-update', {
      influencer: newInfluencer,
      shopDomain: shopId,
      source: 'client',
      currency,
    });
  } catch (e) {
    console.log('update: error', e);
  }

  return newInfluencer;
};

const saveExpenses = async (influencerDoc, influencer: influencer) => {
  const expensePromises = influencer.expenses.map(async (expense) => {
    await influencerDoc
      .collection('expenses')
      .doc(expense.id)
      .set({ ...expense, influencer_id: influencer.id }, { merge: true });
  });
  await Promise.all(expensePromises);
};

export const updateCreatives = async (
  influencer: influencer,
  shopId,
  creative: creativeType,
  created_by = '',
) => {
  let influencerRef = influencersDb().doc(influencer.id);

  await influencerRef.update({
    creatives: firebase.firestore.FieldValue.arrayUnion({
      name: creative.name,
      type: creative.type,
      url: creative.url,
      downloadLink: creative.downloadLink || null,
      created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      created_by: created_by || shopId,
    }),
  });

  return influencer;
};

export const deleteCreative = async (
  influencer: influencer,
  creativeName: string | undefined,
  creativeSrc: string,
) => {
  const influencerRef = influencersDb().doc(influencer.id);
  const newCreatives = (await influencerRef.get()).data()?.creatives || [];

  const deletedCreative = newCreatives.find(
    (c) =>
      !c.deleted_at &&
      (creativeName
        ? c.name === creativeName
        : c.name === undefined || creativeSrc === c.url || creativeSrc === c.thumbnail),
  );
  deletedCreative.deleted_at = moment().format('YYYY-MM-DD HH:mm:ss');

  await influencerRef.update({ creatives: newCreatives });
  return newCreatives;
};

const createLinks = (influencer: influencer, shopId) => {
  const links = influencer.links?.map((link) => {
    const encodedName = encodeURIComponent(influencer.name!);
    const destUrl = link.url ? encodeURI(link.url) : shopId;
    const encodedCampaign = encodeURIComponent(link.campaign);
    const encodedSource = encodeURIComponent(link.source);
    const linkId = `${influencer.name}_${link.campaign}_${link.source}`;
    const qs = `linkId=${encodedName}_${encodedCampaign}_${encodedSource}&affluencerId=${encodedName}`;
    return {
      id: `${linkId}`,
      link: destUrl.indexOf('?') !== -1 ? `${destUrl}&${qs}` : `${destUrl}?${qs}`,
      campaign: link.campaign,
      source: link.source,
      url: link.url || '',
    };
  });
  return links;
};

export const uploadCreative = async (
  file,
  path = '',
  metaData,
  progressCB,
  params: Object | false = false,
): Promise<any> => {
  if (file.size > 100000000) {
    return new Promise((resolve, reject) => {
      reject({
        message: 'We couldn’t upload the file because it is larger than 100 Mb.',
        fileName: file.name,
      });
    });
  }
  var reqData = new FormData();
  reqData.append('file', file, file.name);
  reqData.append('path', path);
  reqData.append('shopId', metaData.shopId);
  reqData.append('metaData', JSON.stringify(metaData));

  return axiosInstance.post('/v2/acquisition/upload', reqData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params,
    onUploadProgress: (progressEvent) => {
      let progress = Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1));
      progressCB(progress, file.name);
    },
    timeout: 300000,
  });
};

export const filterCreatives = (creatives: Array<creativeType>, type: string = 'all') => {
  if (!creatives || !creatives.length) {
    return [];
  }
  let cleanCreatives = creatives
    .filter((c) => !c.deleted_at)
    .filter((c) => !c.name?.startsWith('thumb'))
    .map((creative: creativeType) => {
      if (creative.type === 'video') {
        // get video thumbnail
        let thumbnail = creatives.find((cr) => cr.name === `thumb_${creative.name}.png`);
        creative.thumbnail = thumbnail ? thumbnail.url : '';
      }
      return creative;
    });

  if (type === 'all') {
    return cleanCreatives;
  }
  return cleanCreatives.filter((creative) => creative.type === type);
};

export const emptyInfluencer = () => {
  let influencer: influencer = { name: '', expenses: [], creatives: [] };
  return influencer;
};

function cleanUpValue(val) {
  return val
    .trim()
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/\//g, '')
    .replace(/^_{2,}/, '')
    .replace(/_{2,}$/, '');
}
