import { useStoreValue } from '@tw/snipestate';
import { Button, Modal, Text } from '@tw/ui-components';
import { useNavigate } from 'react-router-dom';
import { analyticsEvents, genericEventLogger, sequencesActions } from 'utils/dataLayer';
import { $upgradeAgentsPopup, closeUpgradeAgentsPopup } from './$upgradeAgentsModal';

export const UpgradeAgentsModal = () => {
  const { opened, title, source } = useStoreValue($upgradeAgentsPopup);

  const onBookDemo = () => {
    window.open('https://share.hsforms.com/1Ef_Iz9vyTD6iuF4TwkuHMAc3zyl');
    genericEventLogger(analyticsEvents.SEQUENCES, {
      action: sequencesActions.BOOK_DEMO,
      source: source,
    });
    closeUpgradeAgentsPopup();
  };

  return (
    <Modal opened={opened} onClose={() => closeUpgradeAgentsPopup()} withCloseButton={false}>
      <div className="flex flex-col gap-6 p-2">
        <Text fz={18} fw={500}>
          {title}
        </Text>
        <Text fz={14}>Upgrade to get access to agents.</Text>
        <div className="flex justify-end gap-4 pt-4">
          <Button variant="white" onClick={() => closeUpgradeAgentsPopup()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onBookDemo();
            }}
          >
            Request Demo
          </Button>
        </div>
      </div>
    </Modal>
  );
};
