import { $store } from '@tw/snipestate';
import { SQLModel, LLMModel } from 'components/Willy/constants';

type MainChatStore = {
  sqlGeneratingModel: SQLModel;
  modelToolsName: LLMModel;
};

export const $mainChatStore = $store<MainChatStore>({
  sqlGeneratingModel: 'default',
  modelToolsName: 'default',
});

export const setSqlGeneratingModel = (sqlGeneratingModel: SQLModel) => {
  $mainChatStore.set((x) => ({ ...x, sqlGeneratingModel }));
};

export const setModelToolsName = (modelToolsName: LLMModel) => {
  $mainChatStore.set((x) => ({ ...x, modelToolsName }));
};
