import { SegmentedControl } from '@tw/ui-components';
import { stepActionType, WorkflowStepBase, WorkflowStepInsights } from '../types/willyTypes';
import { useState } from 'react';
import { FlowStepWithText } from './FlowStepWithText';

type FlowInsightsStepProps = {
  step: WorkflowStepInsights & WorkflowStepBase;
  toolConfig?: stepActionType;
  depth: number;
  stepChange: (newPrompt: WorkflowStepInsights & WorkflowStepBase) => void;
  readOnly: boolean;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowInsightsStep: React.FC<FlowInsightsStepProps> = ({
  step,
  toolConfig,
  stepChange,
  depth,
  readOnly,
  setIsPristine,
}) => {
  const [editMode, setEditMode] = useState(true);
  return (
    <div className="flex flex-col gap-4">
      {!readOnly && (
        <div>
          <SegmentedControl
            data={[
              { label: 'Edit', value: 'edit' },
              { label: 'View', value: 'view' },
            ]}
            value={editMode ? 'edit' : 'view'}
            onChange={(value) => setEditMode(value === 'edit')}
          />
        </div>
      )}
      <FlowStepWithText
        readOnly={readOnly}
        text={step.text || ''}
        textChange={(text) => stepChange({ ...step, text })}
        variableChange={(variable) => {
          const variables = step.variables || [];
          const variableIndex = variables.findIndex((v) => v.key === variable.key);
          if (variableIndex !== -1) {
            variables[variableIndex] = variable;
          } else {
            variables.push(variable);
          }
          stepChange({
            ...step,
            variables,
          });
        }}
        toolConfig={toolConfig}
        setIsPristine={setIsPristine}
        variables={step.variables || []}
        depth={depth}
        editMode={editMode}
        withToolbar={!readOnly}
        allowComments
      />
    </div>
  );
};
