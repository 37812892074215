import { $currentShopId } from '$stores/$shop';
import { useStoreValue } from '@tw/snipestate';
import { ActionIcon, Button, Icon, Popover, Textarea } from '@tw/ui-components';
import { useEffect, useState, useCallback } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

type GenerateQueryFromTextProps = {
  text: string;
  onClickUseQuery: (query: string) => void;
  activator?: React.ReactNode;
};

export const GenerateQueryFromText: React.FC<GenerateQueryFromTextProps> = ({
  activator,
  text,
  onClickUseQuery,
}) => {
  const shopId = useStoreValue($currentShopId);

  const [textAreaValue, setTextAreaValue] = useState<string>(text);
  const [opened, setOpened] = useState(false);
  const [query, setQuery] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const defaultActivator = <ActionIcon icon="stars" />;

  const generateQuery = useCallback(
    async (text: string) => {
      setLoading(true);
      try {
        const { data } = await axiosInstance.post('/v2/willy/generate-sql-from-prompt', {
          question: text,
          shopId,
          source: 'editor',
        });
        if (data.rephrasedText.errorGenerateSql) {
          setError(data.rephrasedText.errorGenerateSql);
        } else {
          setQuery(data.rephrasedText?.sqlGenerated);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [shopId],
  );

  const handleUseQuery = useCallback(() => {
    if (query) {
      onClickUseQuery(query);
    }
    setOpened(false);
    setQuery(null);
  }, [onClickUseQuery, query]);

  useEffect(() => {
    setTextAreaValue((old) => old ?? text);
  }, [text]);

  return (
    <div>
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        closeOnClickOutside={false}
        closeOnEscape={false}
        width={400}
      >
        <Popover.Target>
          <div onClick={() => setOpened(true)}>{activator || defaultActivator}</div>
        </Popover.Target>
        <Popover.Dropdown>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <Icon name="stars" />
                <span>SQL Query Generator</span>
              </div>
              <ActionIcon icon="close" onClick={() => setOpened(false)} />
            </div>
            {!!error && (
              <div className="flex flex-col gap-4 h-52 overflow-y-auto">
                <div className="text-red-500 text-md whitespace-pre-wrap">{error}</div>
                <div className="sticky bottom-0 bg-white pt-4">
                  <Button onClick={() => setError(null)}>Try again</Button>
                </div>
              </div>
            )}
            {!!query && (
              <div className="flex flex-col gap-4">
                <div className="font-medium">Generated Query</div>
                <ReactSyntaxHighlighter
                  language="sql"
                  style={vscDarkPlus}
                  customStyle={{ borderRadius: '8px' }}
                >
                  {query}
                </ReactSyntaxHighlighter>
                <div className="flex gap-4">
                  <Button onClick={handleUseQuery}>Use Query</Button>
                  <Button variant="white" onClick={() => setQuery(null)}>
                    Try again
                  </Button>
                </div>
              </div>
            )}
            {!error && !query && (
              <div className="flex flex-col gap-4">
                <Textarea
                  value={textAreaValue}
                  onChange={(e) => setTextAreaValue(e.target.value)}
                  placeholder="Enter your prompt here"
                  autosize
                  minRows={6}
                  maxRows={18}
                  disabled={loading}
                />
                <div>
                  <Button
                    disabled={!textAreaValue}
                    loading={loading}
                    onClick={() => generateQuery(textAreaValue)}
                  >
                    Generate Query
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};
