import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { Flex, Icon, Text } from '@tw/ui-components';
import DatePicker from '../SummaryDatePicker';

const PostPurchaseSurveyHeader: React.FC = () => {
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  return (
    <div className={`flex items-center gap-4 ${!isThreePointOh && 'flex-wrap'}`}>
      {isThreePointOh && (
        <Flex align="center" pl="sm" gap="sm">
          <Icon name="pen" color={'gray.5'} size={18} />
          <Text color="gray.7" weight={500} size="lg">
            Post-Purchase Survey
          </Text>
        </Flex>
      )}

      <DatePicker showGroupByButton={false} showCompareButton={false} />
    </div>
  );
};

export default PostPurchaseSurveyHeader;
