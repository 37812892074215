import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { type RootState } from 'reducers/RootType';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Icon, Text } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';

const CDPHeader = () => {
  const shopCustomersCount: number = useSelector(
    (state: RootState) => state.customers.shopCustomersCount,
  );
  const { currentSegment } = useSelector((state: RootState) => state.CDPSegments);
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  const navigate = useNavigate();

  const customersCount = useMemo(() => {
    if (currentSegment) {
      return `${currentSegment.customersCount?.toLocaleString()} customers`;
    } else if (shopCustomersCount) {
      return `${shopCustomersCount.toLocaleString()} customers`;
    } else return '';
  }, [currentSegment, shopCustomersCount]);

  const navigateToQueryBuilder = useCallback(() => {
    navigate({ pathname: '/cdp/segment-builder' });
  }, [navigate]);

  return (
    <Flex align="center" wrap="wrap" gap="md">
      {isThreePointOh && (
        <Flex align="center" pl="sm" gap="sm">
          <Icon name="segmentation" color={'gray.5'} size={18} />
          <Text color="gray.7" weight={500} size="lg">
            Segments
          </Text>
        </Flex>
      )}
      {customersCount && <Text> {customersCount}</Text>}
      <Button
        ml="auto"
        id="att-cdp-create-segment-button"
        variant="primary"
        onClick={navigateToQueryBuilder}
      >
        Create Segment
      </Button>
    </Flex>
  );
};

export default CDPHeader;
