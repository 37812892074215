import { toast } from 'react-toastify';
import axiosInstance from './axiosInstance';
import { firestoreRef, getUserId } from './DB';

export async function connectToShopify(
  shopId: string,
  newTab: boolean = false,
  asIntegration = false,
  redirectUrl = '',
) {
  try {
    const params = new URLSearchParams({
      shopId,
      asIntegration: String(asIntegration), // convert boolean to string if needed
      redirectUrl,
    });

    const res = await axiosInstance.get(`v2/shopify/login/?${params.toString()}`);

    window.open(res.data?.url, newTab ? '_blank' : '_self');
  } catch (error) {
    toast.error('Something went wrong. Please try again later.');
  }
}

async function deleteSubcollection(collectionRef, subcollectionName: string) {
  const subcollectionRef = collectionRef.collection(subcollectionName);
  const snapshot = await subcollectionRef.get();

  if (!snapshot.empty) {
    const batch = firestoreRef().batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error(`Error committing batch delete for subcollection ${subcollectionName}:`, error);
    }
  }
}

async function deleteAllSubcollections(collectionRef) {
  const subcollectionNames = ['onboarding', 'reports', 'services', 'workspaces', 'users'];
  for (const subcollectionName of subcollectionNames) {
    try {
      await deleteSubcollection(collectionRef, subcollectionName);
    } catch (error) {
      console.error(`Error deleting subcollection ${subcollectionName}:`, error);
    }
  }
}

export const copyShopDataFromTrends = async (shopId: string) => {
  const trendsShopRef = firestoreRef().collection('shops').doc(`tw-trends-${getUserId()}`);
  const trendsShop = await trendsShopRef.get();
  const newShopRef = firestoreRef().collection('shops').doc(shopId);
  const newShop = await newShopRef.get();

  if (trendsShop.exists && newShop.exists) {
    const updatedData = {
      ...trendsShop.data(),
      ...newShop.data(),
      isTrendsShop: false,
    };
    await deleteAllSubcollections(trendsShopRef);
    await trendsShopRef.delete();
    await newShopRef.update(updatedData);
    const userRef = firestoreRef().collection('users').doc(`${getUserId()}`);
    const userDoc = await userRef.get();
    await userRef.update({
      shops: {
        [shopId]: { ...userDoc.data()?.shops?.[shopId], roles: ['owner'] },
      },
    });
  } else {
    throw 'Trends shop or new shop does not exist.';
  }
};
