import { useIsSmall } from 'hooks/useDefaultWindowSizes';

import { Button, IconName, Size } from '@tw/ui-components';

export type DropdownActivatorProps = {
  title?: string;
  loading?: boolean;
  toggle: () => void;
  disabled?: boolean;
  modelIcon: any;
  customActivator?: (label: string | React.ReactNode) => React.ReactNode;
  label: React.ReactNode;
  size?: Exclude<Size, 0>;
  active?: boolean;
};

export const DropdownActivator: React.FC<DropdownActivatorProps> = ({
  title,
  loading = false,
  disabled = false,
  modelIcon,
  toggle,
  customActivator,
  label,
  size,
  active = false,
}) => {
  const isSmall = useIsSmall();

  return (
    <>
      {customActivator ? (
        <div onClick={() => !disabled && !loading && toggle()}>{customActivator(label)}</div>
      ) : (
        <Button
          variant={isSmall && active ? 'primary' : 'activator'}
          leftSection={modelIcon}
          rightSection="caret-down"
          onClick={toggle}
          disabled={disabled || loading}
          loading={loading}
          size={size}
          color={isSmall && active ? 'gray.4' : undefined}
        >
          {title ? title + ' ' : ''}
          {label}
        </Button>
      )}
    </>
  );
};
