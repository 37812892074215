import { Icon, Layout, Link, Spinner, Stack, TextStyle, Tooltip, Button } from '@shopify/polaris';
import { CartDownMajor, CheckoutMajor, EmailMajor, MobileMajor } from '@shopify/polaris-icons';
import moment from '@tw/moment-cached/module/timezone';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PixelReferrer from './PixelReferrer';
import PixelQueryString from './PixelQueryString';
import './pixel-style.scss';
import PixelCartProduct from './PixelCartProduct';
import PixelCheckout from './PixelCheckout';
import PixelSubscription from './PixelSubscription';
import { $currentShopId } from '$stores/$shop';
import {
  checkIfPageNumber,
  checkIfReferral,
  checkIfSiteSearch,
} from 'components/attribution/utils';
import EnquirelabsIconBase64 from 'components/EnquirelabsIconBase64';
import TripleWhaleIconBase64 from 'components/TripleWhaleIconBase64';
import KnoLogoSVG from 'components/Icons/KnoLogoSVG';
import { fetchExtraEvents, PostPurchaseSurveyEvent } from 'utils/extraEvents';
import {
  CheckoutEventSubType,
  PixelBaseEvent,
  PixelCustomerDetailsEvent,
  PixelEventType,
} from '@tw/types';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { isStaging } from '../../config';
import { Icon as TwIcon } from '@tw/ui-components';

type clientPixelEvent = PixelBaseEvent<any, any> | PostPurchaseSurveyEvent;

const getDateFormat = (date) => {
  date = moment(date);
  var midnight = moment().startOf('day');
  if (date.isSame(midnight, 'day')) {
    return 'Today';
  }

  var midnightYesterday = moment().subtract(1, 'days').startOf('day');
  if (date.isSame(midnightYesterday, 'day')) {
    return 'Yesterday';
  }

  return date.format('ddd, ll');
};

function isDuplicatedEvent(acc: clientPixelEvent[], curr: clientPixelEvent) {
  return (
    acc.length > 0 &&
    acc[acc.length - 1].timestamp === curr.timestamp &&
    acc[acc.length - 1].info?.url === curr.info?.url &&
    acc[acc.length - 1].type === curr.type &&
    curr.type === PixelEventType.PAGE_LOAD
  );
}

function parseURL(url: string) {
  try {
    const parsedUrl = new URL(url);

    const usp = new URLSearchParams(url.split('?')[1]);
    const query = {};
    usp.forEach((value, key) => {
      query[key] = value;
    });

    return {
      path: parsedUrl.origin + parsedUrl.pathname,
      query: query,
    };
  } catch (e) {
    return {
      path: url,
      query: {},
    };
  }
}

function extractBaseUrl(url: string) {
  try {
    const parsedUrl = new URL(url);
    return `${parsedUrl.protocol}//${parsedUrl.host}`;
  } catch (e) {
    return '';
  }
}

export default function SinglePixel({ loading = true, order, subscription = undefined, journey }) {
  const [activities, setActivities] = useState<clientPixelEvent[]>([]);
  const [activityItems, setActivityItems] = useState<any[]>([]);
  const [extraEvents, setExtraEvents] = useState<any[]>([]);
  // const [aux, setAux] = useState([]);
  const { shouldNotBeSeen: cantSeeLinks } = useFeatureFlag(FeatureFlag.PIXEL_PURCHASE_POPUP_FF);
  const shopId = order?.shopId || $currentShopId.get();

  useEffect(() => {
    let purchaseEvent, subscriptionEvent;
    if (order) {
      const { created_at } = order || {};
      purchaseEvent = {
        type: 'purchase',
        timestamp: moment(created_at).valueOf(),
      };
    }
    if (subscription) {
      const { eventDate: created_at } = subscription || {};
      subscriptionEvent = {
        type: 'new subscription',
        timestamp: moment(created_at).valueOf(),
      };
    }
    const journeyWithExtraEvents = [...(journey || []), purchaseEvent, ...extraEvents]
      .filter((x) => x)
      .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    setActivities(journeyWithExtraEvents);
  }, [journey, order, extraEvents]);

  useEffect(() => {
    (async () => {
      const extraEvents = await fetchExtraEvents(order);
      setExtraEvents(extraEvents || []);
    })();
  }, [order]);

  const guestPageType = useCallback(
    (url: string, query: { [key: string]: string }) => {
      if (url.includes('/products/')) {
        return 'Viewed Product';
      } else if (url.includes('/collections/') && !url.includes('/products')) {
        return 'Viewed Collection';
      } else if (url.includes('/thank_you')) {
        return 'Viewed Order Confirmation Page';
      } else if (url.endsWith('/cart')) {
        return 'Viewed Cart';
      } else if (query?.q) {
        return 'Searched';
      } else if (url === shopId + '/') {
        return 'Viewed Home';
      }
      return 'Viewed Page';
    },
    [shopId],
  );

  useEffect(() => {
    const allCheckouts: any[] = [];
    let alreadyFoundThanks = false;
    let tempActivityItems: any[] = [];
    const dedupedActivities = activities.reduce((acc: clientPixelEvent[], curr) => {
      if (!isDuplicatedEvent(acc, curr)) {
        acc.push(curr);
      }
      return acc;
    }, []);

    function getIconFromSubType(subType: CheckoutEventSubType) {
      switch (subType) {
        case 'checkout_started':
          return 'cart-sale';
        case 'address_info_submitted':
          return 'envelope';
        case 'shipping_info_submitted':
          return 'shipping-label';
        case 'contact_info_submitted':
          return 'person';
        case 'payment_info_submitted':
          return 'payment-capture';
      }
      return 'cart-sale';
    }

    function getTitleFromSubType(subType: CheckoutEventSubType) {
      switch (subType) {
        case 'checkout_started':
          return 'Started';
        case 'address_info_submitted':
          return 'Address Info Submitted';
        case 'shipping_info_submitted':
          return 'Shipping Options Selected';
        case 'contact_info_submitted':
          return 'Contact Info Submitted';
        case 'payment_info_submitted':
          return 'Payment Info Submitted';
      }
      return 'cart-sale';
    }

    for (const event of dedupedActivities) {
      let activity;
      const urlPath = event.info?.url?.path;
      const fullUrlPath =
        urlPath && !urlPath.startsWith('http://') && !urlPath.startsWith('https://')
          ? 'https://' + urlPath
          : urlPath;
      const shortUrlPath = fullUrlPath?.replace(/^(http:\/\/|https:\/\/)/, '') || '';
      const longFullUrl =
        fullUrlPath +
        (event.info?.url?.query && Object.keys(event.info?.url.query).length > 0
          ? '?' +
            Object.keys(event.info?.url.query)
              .sort()
              .map((k) => `${k}=${event.info?.url.query![k]}`)
              .join('&')
          : '');
      switch (event.type) {
        case PixelEventType.PAGE_LOAD:
          const unitedQuery = {
            ...event.info.ref?.query,
            ...event.info.url?.query,
          };
          const onsiteSearchQuery = checkIfSiteSearch(event.info.url?.query);
          const onsitePageNumber = checkIfPageNumber(event.info.url?.query);
          const affluencerIcon =
            [
              'influencer',
              'influencers',
              'influencer_marketing',
              'affluencer',
              'affiliate',
            ].includes(event?.info?.url?.query?.utm_medium) ||
            (event?.info?.url?.query?.influencerId ?? false);
          activity = {
            type: event.type,
            icon: (
              <PixelReferrer
                referral={event.info.ref || {}}
                url={event.info.url || {}}
                affluencerIcon={affluencerIcon}
              />
            ),
            title: guestPageType(event.info.url?.path || '', event.info?.__query),
            path: (
              <span title={decodeURI(longFullUrl?.toString()?.replace(/%/g, '%25') || '')}>
                {!cantSeeLinks ? (
                  <div className="flex items-center gap-5">
                    <a href={longFullUrl} target="_blank" className="shop-link">
                      {event.info.__title || shortUrlPath}
                    </a>
                  </div>
                ) : null}
              </span>
            ),
            subtitle: '',
            content: (
              <div>
                <PixelQueryString query={unitedQuery} />
                {checkIfReferral(event.info.url, event.info.ref) && (
                  <TextStyle variation="subdued">Referrer: {event.info.ref?.path}</TextStyle>
                )}
                {onsiteSearchQuery && (
                  <TextStyle variation="subdued">Searched for: {onsiteSearchQuery}</TextStyle>
                )}
                {onsitePageNumber && (
                  <TextStyle variation="subdued">Page #{onsitePageNumber}</TextStyle>
                )}
              </div>
            ),
            date: moment(event.timestamp).format('LTS'),
            timeStamp: event.timestamp,
          };
          break;
        case PixelEventType.CHECKOUT:
          if (event.info.sub_type == 'checkout_completed') break;
          const icon = getIconFromSubType(event.info.sub_type);
          const title = getTitleFromSubType(event.info.sub_type);
          activity = {
            type: event.type,
            icon: (
              <div style={{ margin: '1rem 0' }}>
                <TwIcon name={icon} size={20} color={'gray.6'} />
              </div>
            ),
            title: `Checkout - ${title}`,
            path: (
              <span title={decodeURI(longFullUrl?.toString()?.replace(/%/g, '%25') || '')}>
                {!cantSeeLinks ? (
                  <div className="flex items-center gap-5">
                    <a href={longFullUrl} target="_blank" className="shop-link">
                      {event.info.__title || shortUrlPath}
                    </a>
                  </div>
                ) : null}
              </span>
            ),
            subtitle: '',
            content: '',
            date: moment(event.timestamp).format('LTS'),
            timeStamp: event.timestamp,
          };
          break;
        case PixelEventType.CART_CHANGES:
          activity = {
            type: event.type,
            icon: <Icon source={CartDownMajor} />,
            title: 'Add to Cart',
            content: event.info.added.map((a, index) => (
              <div key={index}>
                <PixelCartProduct productId={'' + a.id} quantity={a.q} shopId={shopId} />
              </div>
            )),
            date: moment(event.timestamp).format('LTS'),
            timeStamp: event.timestamp,
          };
          break;
        /*case 'checkout':
          if (allCheckouts.includes(event.info.checkout)) {
            continue;
          }
          allCheckouts.push(event.info.checkout);
          activity = {
            type: event.type,
            icon: <Icon source={CheckoutMajor} />,
            title: 'Checkout',
            content: (
              <div>
                <PixelCheckout order={order} />
              </div>
            ),
            date: moment(event.timestamp).format('LTS'),
            timeStamp: event.timestamp,
          };
          break;*/
        case 'thanks':
        case 'purchase':
          if (alreadyFoundThanks) {
            continue;
          }
          alreadyFoundThanks = true;
          const landing_site_full_url =
            extractBaseUrl(order?.order_status_url) +
            (order?.landing_site ? order?.landing_site : '');
          const landing_site_path = parseURL(landing_site_full_url).path;
          activity = {
            type: event.type,
            icon: <Icon source={CheckoutMajor} />,
            title: 'Purchase',
            content: (
              <div>
                <PixelCheckout order={order} />
                {landing_site_full_url && (
                  <span
                    title={decodeURI(landing_site_full_url?.toString()?.replace(/%/g, '%25') || '')}
                  >
                    <div className="flex items-center gap-5 timeline-item-link">
                      <a href={landing_site_full_url} target="_blank" className="shop-link">
                        {landing_site_path}
                      </a>
                    </div>
                  </span>
                )}
              </div>
            ),
            date: moment(event.timestamp).format('LTS'),
            timeStamp: event.timestamp,
          };
          break;
        case 'new subscription':
          activity = {
            type: event.type,
            icon: <Icon source={CheckoutMajor} />,
            title: 'New Subscription',
            content: (
              <div>
                <PixelSubscription subscription={subscription} />
              </div>
            ),
            date: moment(event.timestamp).format('LTS'),
            timeStamp: event.timestamp,
          };
          break;
        case PixelEventType.CONTACT_DETAILS:
          if (!isStaging || !(event as PixelCustomerDetailsEvent).source) break;
          const isEmail = event.info.__email;
          const isPhone = event.info.__phone;
          if (!isEmail && !isPhone) {
            break;
          }
          activity = {
            type: event.type,
            icon: <Icon source={isEmail ? EmailMajor : MobileMajor} />,
            title: isEmail ? 'Email Signup' : 'SMS Signup',
            content: (
              <>
                <TextStyle variation="strong">
                  {`Customer fill ${isEmail ? 'Email' : 'Phone Number'} for updates`}
                </TextStyle>
                <TextStyle variation="subdued">
                  {event.info.__email || event.info.__phone}
                </TextStyle>
              </>
            ),
            date: moment(event.timestamp).format('LTS'),
            timeStamp: event.timestamp,
          };
          break;
        case 'ppSurvey':
          const platformIconMapper = {
            enquirelabs: EnquirelabsIconBase64,
            kno: KnoLogoSVG,
            triplesurvey: TripleWhaleIconBase64,
          };
          activity = {
            type: event.type,
            icon: <Icon source={platformIconMapper[event.info.platform]} />,
            title: 'Post Purchase Survey Submitted',
            content:
              event.info.platform === 'triplesurvey' ? (
                <>
                  <div>
                    <TextStyle variation="subdued">{event.info.description}</TextStyle>
                  </div>
                  <div className="flex items-center gap-2" key={event.info.answer}>
                    {event.info.icon || <></>}
                    <b>{event.info.title}</b>
                    {event.info.free_text ? (
                      <>
                        : <i>"{event.info.free_text}"</i>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {event.info.question && (
                    <>
                      <div>
                        <TextStyle variation="subdued">To question:</TextStyle>
                      </div>
                      <div className="flex items-center gap-2" key={event.info.question}>
                        <i>{event.info.question}</i>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <TextStyle variation="subdued">{event.info.description}</TextStyle>
                  <br />
                  <div className="flex items-center gap-2" key={event.info.answer}>
                    {event.info.icon || <></>}
                    {event.info.title}
                  </div>
                </>
              ),
            date: moment(event.timestamp).format('LTS'),
            timeStamp: event.timestamp,
          };
          break;
        case 'custom':
          activity = {
            type: event.type,
            icon: <TwIcon name="bolt" width={30} height={30} />,
            title: `${event.info.event_name} (Custom Event)`,
            content: Object.entries(event.info as { [key: string]: string })
              .filter(([k, v]) => !['event_name'].includes(k))
              .map(([k, v]) => (
                <div>
                  {k}: {v.toString()}
                </div>
              )),
            date: moment(event.timestamp).format('LTS'),
            timeStamp: event.timestamp,
          };
          break;
        default:
          break;
      }
      tempActivityItems.push(activity);
      //setActivityItems(acts => acts.concat(activity));
    }
    setActivityItems(tempActivityItems.filter((act) => act));
  }, [activities, order, guestPageType]);

  const timeline = useCallback(() => {
    let lastDate = moment(1e15);
    return (
      <div className="timeline">
        {activityItems
          .filter((a) => a)
          .map((item, i) => {
            lastDate = i > 0 ? moment(activityItems[i - 1]?.timeStamp) : lastDate;

            return (
              <Fragment key={`${moment(item.timeStamp)}-${i}`}>
                {!moment(item.timeStamp).isSame(lastDate, 'day') && (
                  <div className="day-divider">
                    <TextStyle variation="strong">{getDateFormat(item.timeStamp)}</TextStyle>
                  </div>
                )}
                <div className="container">
                  <div className="timeline-item-icon">{item.icon}</div>

                  <div className="content">
                    <div className="timeline-item-title">
                      <TextStyle variation="strong">{item.title}</TextStyle>
                      <TextStyle variation="subdued">{item.date}</TextStyle>
                    </div>
                    <div className="timeline-item-link">{item.path}</div>
                    <div className="timeline-item-content">{item.content}</div>
                  </div>
                </div>
              </Fragment>
            );
          })}
      </div>
    );
  }, [activityItems]);

  var el = (
    <>
      <style>{`
      .Polaris-Modal-Dialog__Modal { min-height: 95vh; }
    `}</style>
      <Layout sectioned>
        {loading && (
          <div style={{ padding: '1.875rem' }}>
            <Stack distribution="center">
              <Spinner />
            </Stack>
          </div>
        )}
        {/* {!isLoading && <div className="pixel-aux-list">
          <ul>
            {aux.map((a, i) => (<li key={i}>
              <span className="aux-icon">{a.icon}</span>
              <span className="aux-title">{a.title}</span>
              <span className="aux-description">{a.description}</span>
            </li>))}
          </ul>
        </div>} */}
        {!loading && timeline()}
        {/* {!isLoading && <p>Session Duration: {moment.utc(duration).format('HH:mm:ss')}</p>} */}
      </Layout>
    </>
  );
  return el;
}
