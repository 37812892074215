import React, { FC, useCallback, useEffect, useState } from 'react';
import './Auth2Consent.scss';
import { useSelector } from 'react-redux';
import { type RootState } from '../../../reducers/RootType';
import axiosInstance from '../../../utils/axiosInstance';
import { SignIn } from '../../../routes/auth/SignIn';
import { Button, Link, Spinner, Card, List } from '@shopify/polaris';
import SignUpGrid from '../../../routes/auth/SignUp/components/SignUpGrid';
import StoreSelect from './StoreSelect';
import twLogo from '../../../icons/tw-logo.svg';
import arrow from '../../../icons/arrow-dot-oauth.svg';
import { ConsentSubmitType, SupportedScopes, scopesCopy } from '@tw/types/module/services/users';
import { toast } from 'react-toastify';
import { getShopName } from '@tw/constants/module/utils';

interface Auth2ConsentProps {}

const scopeToCopy = (scope: SupportedScopes, clientName: string): string => {
  return scopesCopy(clientName)[scope];
};

export const Auth2Consent: FC<Auth2ConsentProps> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [shopsList, setShopsList] = useState<
    {
      value: any;
      label: string;
    }[]
  >([]);
  const [selectedShop, setSelectedShop] = useState<{
    value: any;
    label: string;
  }>({ label: '- Select Shop - ', value: -1 });

  const [selectedShopId, setSelectedShopId] = useState<any>(null);
  const [buttonClickedLoading, setButtonClickedLoading] = useState(false);
  const [consentData, setConsentData] = useState<{
    skip: boolean;
    redirect_to: string;
    client: {
      client_name: string;
      client_uri: string;
      client_id: string;
      logo_uri: string;
      policy_uri: string;
      tos_uri: string;
      scopes: SupportedScopes[];
    };
  }>(null as any);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const shopsAsArray = useSelector((state: RootState) => state.shopsAsArray);
  const loadingShops = useSelector((state: RootState) => state.loadingShops);
  const consent_challenge = urlParams.get('consent_challenge');

  const acceptClicked = useCallback(
    async (consentDataParam) => {
      setButtonClickedLoading(true);
      const consentDataInternal = consentDataParam || consentData;
      try {
        const { data } = await axiosInstance.post(
          `/v2/users/oauth2/flow/consent-request?consent_challenge=${consent_challenge}`,
          {
            shopId: selectedShopId,
            type: ConsentSubmitType.Accept,
            scopes: consentDataInternal.client.scopes,
            clientId: consentDataInternal.client.client_id,
            clientName: consentDataInternal.client.client_name,
            clientLogoUri: consentDataInternal.client.logo_uri,
          },
        );
        setButtonClickedLoading(false);
        window.location.replace(data.redirect_to);
      } catch (e) {
        toast.error(e.data);
      }
    },
    [consent_challenge, selectedShopId, consentData],
  );

  useEffect(() => {
    (async () => {
      if (loadingShops) return;
      if (shopsAsArray.length === 0) {
        const { data } = await axiosInstance.post(
          `/v2/users/oauth2/flow/consent-request?consent_challenge=${consent_challenge}`,
          {
            type: ConsentSubmitType.Deny,
            error: 'access_denied',
            error_description: `Resources owner does not have an account in Triple Whale`,
          },
        );
        window.location.replace(data.redirect_to);
      } else if (shopsAsArray.length === 1) {
        setSelectedShopId(shopsAsArray[0].shopId);
      } else {
        setShopsList([
          ...[{ label: '- Select Shop - ', value: -1 }],
          ...shopsAsArray.map((x) => ({ value: x.shopId, label: getShopName(x.shopId) })),
        ]);
      }
    })();
  }, [consent_challenge, shopsAsArray, loadingShops]);

  useEffect(() => {
    setSelectedShop(shopsList[0]);
  }, [shopsList]);

  useEffect(() => {
    if (shopsAsArray.length > 1) {
      setSelectedShopId(selectedShop?.value);
    }
  }, [selectedShop, shopsAsArray]);

  useEffect(() => {
    let skip, redirect_to, client;
    if (loadingShops) return;
    (async () => {
      if (consentData === null) {
        ({
          data: { skip, redirect_to, client },
        } = await axiosInstance.get(
          `/v2/users/oauth2/flow/consent-request?consent_challenge=${consent_challenge}`,
        ));
        setConsentData({ skip, redirect_to, client });
        // setIsLoading(false);
        await acceptClicked({ skip, redirect_to, client });
      }
      if (redirect_to) {
        window.location.replace(redirect_to);
      } else {
      }
    })();
  }, [acceptClicked, consentData, consent_challenge, loadingShops]);

  const declineClicked = async () => {
    setButtonClickedLoading(true);
    const { data } = await axiosInstance.post(
      `/v2/users/oauth2/flow/consent-request?consent_challenge=${consent_challenge}`,
      {
        type: ConsentSubmitType.Deny,
        error: 'access_denied',
        error_description: 'The resource owner denied the request',
      },
    );
    setButtonClickedLoading(false);
    window.location.replace(data.redirect_to);
  };

  return (
    <>
      {!isLoading && (
        <div className="flex items-center justify-center consent-container">
          <div
            className={
              'signup-page-container flex flex-col justify-between bg-white w-[28rem]  rounded border-2 border-solid border-slate-300 py-20 px-20 pb-10'
            }
          >
            <div className={'flex-col'}>
              <div className={'flex justify-center items-center'}>
                <img
                  className={
                    'flex justify-center items-center rounded border border-slate-300 border-solid p-5 w-40 h-40'
                  }
                  src={twLogo}
                />
                <img className={'p-5 w-40 h-40'} src={arrow} />
                <img
                  className={
                    'flex justify-center items-center rounded border border-slate-300 border-solid p-5 w-40 h-40'
                  }
                  src={consentData.client.logo_uri}
                />
              </div>
              <div className={'flex-col justify-center text-center items-center mt-10'}>
                <div className={'text-4xl'}>
                  <a
                    className={'no-underline'}
                    href={consentData.client.client_uri}
                    target={'_blank'}
                  >
                    {consentData.client.client_name}
                  </a>
                </div>
              </div>
              <div className={'text-2xl mt-3 font-medium'}>
                Wants access to your shop in Triple Whale account
              </div>
              <div className={'flex-col'}>
                {/*{shopsAsArray.length === 1 && (*/}
                {/*  <div className={'rounded border border-solid border-slate-200 px-3 p-2.5  my-5'}>*/}
                {/*    {selectedShopId}*/}
                {/*  </div>*/}
                {/*)}*/}
                {shopsAsArray.length > 1 && (
                  <div className={'mt-5'}>
                    <div className={'font-light text-slate-600 text-xl'}>Choose a Shop</div>
                    <div className={'pt-2.5'}>
                      <StoreSelect
                        value={selectedShop}
                        onChange={(x) => setSelectedShop(x!)}
                        options={shopsList}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={'mt-8 font-medium text-xl2'}>
                {consentData.client.scopes.length > 0 && (
                  <ul className={'list-outside pl-7'}>
                    The app will be able to:
                    {consentData.client.scopes.map((scope) => (
                      <li className={'text-xl font-light'}>
                        {scopeToCopy(scope, consentData.client.client_name)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className={'flex-col justify-center text-center items-center mt-3 font-light'}>
                By clicking allow, you are agreeing to Triple Whale’s{' '}
                <span>
                  {consentData.client.tos_uri ? (
                    <a
                      target={'_blank'}
                      className={'no-underline'}
                      href={consentData.client.tos_uri}
                    >
                      terms of service
                    </a>
                  ) : (
                    'terms of service'
                  )}
                </span>{' '}
                and{' '}
                <span>
                  {consentData.client.tos_uri ? (
                    <a
                      target={'_blank'}
                      className={'no-underline'}
                      href={consentData.client.policy_uri}
                    >
                      privacy policy
                    </a>
                  ) : (
                    'privacy policy'
                  )}
                </span>
                .
              </div>
            </div>

            <div className={'flex justify-between items-center align-bottom mt-10'}>
              <button
                onClick={declineClicked}
                className={
                  'flex-col justify-center text-center items-center h-15 w-32 p-3 rounded-lg bg-white font-normal transition-colors text-slate-600 border-0 enabled:hover:bg-gray-100 enabled:cursor-pointer'
                }
              >
                Deny
              </button>
              <button
                type={'submit'}
                disabled={!selectedShopId || selectedShopId === -1}
                onClick={acceptClicked}
                className={
                  'flex-col justify-center transition-colors text-center items-center h-15 w-32 p-3 rounded-lg bg-logo text-white border-0 enabled:hover:bg-blue-500 enabled:cursor-pointer disabled:bg-gray-200 disabled:text-slate-500'
                }
              >
                Allow
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <Spinner accessibilityLabel="App is loading" size="large" />
        </div>
      )}
    </>
  );
};
