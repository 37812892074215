import { SummaryMetricIdsTypes, SummaryMetrics } from '@tw/types/module/SummaryMetrics';
import { type RootState } from 'reducers/RootType';
import { createSelector } from 'reselect';
import { callInitialImport } from 'utils/callInitialImport';
import { $summaryPerformanceEnabled, $summaryPerformanceStartMs } from '../$stores/$perfomence';
import { analyticsEvents } from '../utils/dataLayer';
import moment from '@tw/moment-cached';
import { datadogRum } from '@datadog/browser-rum';

const loadingStats = (state) => state.loadingStats;
const msp = (state) => state.msp;
const isLoadingNewStats = (state: RootState) => state.isLoadingNewStats;
const loadingStatsLtv = (state) => state.loadingStatsLtv;
const loadingCustomSpends = (state) => state.loadingCustomSpends;
const loadingShippingCosts = (state) => state.loadingShippingCosts;
const loadingGoogleAnalytics = (state) => state.loadingGoogleAnalytics;
const loadingStatsComparisons = (state) => state.loadingStatsComparisons;
const calculatingInventory = (state) => state.calculatingInventory;
const loadingWillyBox = (state) => state.willy.loadingWillySummaryBox;
const willySummaryBox = (state) => state.willySummaryBox;
// const loadingForwardView = (state) => state.forecasting.loadingForwardView;

type TileLoadingProps = {
  [selector in SummaryMetricIdsTypes]?: boolean;
};

type TileLoadingObject = {
  setValue: (props: SummaryMetricIdsTypes[], value: boolean) => void;
} & TileLoadingProps;

export const selectLoadingAnyNewStats = createSelector([isLoadingNewStats], (loadingNewStats) => {
  const servicesToLoad = ['pixel'];
  return Object.entries(loadingNewStats)
    .filter(([s]) => !servicesToLoad.includes(s) && !s.includes('amazon'))
    .some(([service, loading]) => loading);
});

export const isTileLoading = createSelector<any, any>(
  [
    loadingStats,
    msp,
    selectLoadingAnyNewStats,
    loadingCustomSpends,
    loadingShippingCosts,
    loadingGoogleAnalytics,
    loadingStatsLtv,
    calculatingInventory,
    loadingStatsComparisons,
    loadingWillyBox,
    willySummaryBox,
    // loadingForwardView,
  ],
  (
    loadingStats,
    msp,
    loadingAnyNewStats,
    loadingCustomSpends,
    loadingShippingCosts,
    loadingGoogleAnalytics,
    loadingStatsLtv,
    calculatingInventory,
    loadingStatsComparisons,
    loadingWillyBox,
    willySummaryBox,
    // loadingForwardView
  ) => {
    const obj: TileLoadingObject = {
      setValue: function (props, value) {
        while (props.length) this[props.pop()!] = value;
      },
    };
    const isNotRealTimeTiles = Object.values(SummaryMetrics)
      .filter((x) => x.isNotRealTime)
      .map((x) => x.id);

    const isBlendedNotDone =
      loadingCustomSpends ||
      loadingStats ||
      loadingAnyNewStats ||
      (msp === 'shopify' ? loadingShippingCosts : false);

    if (!isBlendedNotDone) {
      const endTime = performance.now();
      const startTime = $summaryPerformanceStartMs.get();
      const summaryPerformanceEnabled = $summaryPerformanceEnabled.get();
      const durationMs = endTime - startTime;
      const searchParams = new URLSearchParams(window.location.search);
      let start = searchParams.get('start');
      let end = searchParams.get('end');
      const payload = {
        durationMs,
        startDate: start,
        endDate: end,
        days: moment(end).diff(moment(start), 'days') + 1,
      };
      datadogRum.addAction(analyticsEvents.SUMMARY_LOADING, payload);
      if (summaryPerformanceEnabled) {
        console.debug('Summary performance', payload);
      }
      $summaryPerformanceEnabled.set(false);
    }

    // Rest of metrics not noted here are watching on loadingStats
    // Add here only special cases, like:
    obj.setValue(
      [
        'totalNetProfit',
        'roas',
        'mer',
        'totalNetMargin',
        'googleRps',
        'totalReturns',
        'blendedAds',
        'shopifyCpa',
        'newCustomersCpa',
        'cashTurnover',
        'newCustomersRoas',
        'blendedAttributedRoas',
        'poas',
        'totalCustomAdSpends',
        'totalCustomNonAdSpends',
      ],
      isBlendedNotDone,
    );

    obj.setValue(['googleRps', 'googleAnalyticsUsers'], loadingStats || loadingGoogleAnalytics);

    obj.setValue(['cogs', 'handlingFees'], loadingAnyNewStats);

    obj.setValue(
      willySummaryBox.map((x) => x.id),
      loadingWillyBox,
    );

    obj.setValue(
      ['customSpends', 'roas', 'blendedAds'],
      loadingStats || loadingAnyNewStats || loadingCustomSpends,
    );

    obj.setValue(
      ['uniqueCustomers', 'uniqueCustomerSales', 'customerFrequency', 'ltvCpa'],
      loadingStatsLtv,
    );

    obj.setValue(
      ['inventoryItems', 'inventoryItemsMissing', 'totalInventoryCost', 'totalInventoryValue'],
      calculatingInventory,
    );
    // obj.setValue(
    //   [
    //     'forwardViewTotalRevenue',
    //     'forwardViewProductOrdersCount',
    //     'forwardViewNcCustomerCntAttribution',
    //   ],
    //   loadingForwardView
    // );

    return obj;
  },
);

export const REIMPORTING_TILE = 'REIMPORTING_TILE';
export const REIMPORTING_TILE_DONE = 'REIMPORTING_TILE_DONE';
export const CHECK_IMPORTING = 'CHECK_IMPORTING';
export const NOT_CHECK_IMPORTING = 'NOT_CHECK_IMPORTING';

export const checkForImporting = (state = true, action) => {
  switch (action.type) {
    case CHECK_IMPORTING:
      return true;
    case NOT_CHECK_IMPORTING:
      return false;
    case REIMPORTING_TILE:
      return true;
    case REIMPORTING_TILE_DONE:
      return false;
    default:
      return state;
  }
};

export const reimportService = (service, dataType) => {
  const { id, useNewJobManager } = service || {};
  return async (dispatch, getState) => {
    dispatch({
      type: REIMPORTING_TILE,
      serviceId: id,
    });
    await callInitialImport(id, [], undefined, dataType, useNewJobManager);
    dispatch({
      type: REIMPORTING_TILE_DONE,
      serviceId: id,
    });
  };
};

export const checkingForImporting = () => ({
  type: CHECK_IMPORTING,
});

export const finishedCheckForImporting = () => ({
  type: NOT_CHECK_IMPORTING,
});

export const reducers = {
  reimportService,
  checkForImporting,
};
