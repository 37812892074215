import InfiniteScroll from 'react-infinite-scroll-component';
import { WorkflowWithRun } from './types/willyTypes';
import firebase from 'firebase/compat/app';
import { Loader } from '@tw/ui-components';
import { SequenceListCard } from './SequenceListCard';
import { useStoreValue } from '@tw/snipestate';
import { $globalDashboardRoles } from '$stores/willy/$globalDashboardRoles';
import { analyticsEvents, genericEventLogger, sequencesActions } from 'utils/dataLayer';

type SequenceFeedListProps = {
  workflows: WorkflowWithRun[];
  loading: boolean;
  fetchWorkflows: (startAfter?: firebase.firestore.Timestamp | null) => Promise<void>;
  lastWorkflow: firebase.firestore.Timestamp | null;
  hasMoreItems: boolean;
  freeSearch?: string;
  source?: string;
  customOnClick?: (item: WorkflowWithRun) => void;
  fromDashboard?: boolean;
};

export const SequenceFeedList: React.FC<SequenceFeedListProps> = ({
  workflows,
  loading,
  fetchWorkflows,
  lastWorkflow,
  hasMoreItems,
  freeSearch,
  source,
  customOnClick,
  fromDashboard,
}) => {
  const workflowCategories = useStoreValue($globalDashboardRoles);

  return (
    <div className="overflow-auto flex-grow w-full" id="feed-scroll">
      <div className="sm:max-w-[800px] m-auto sm:p-4">
        {workflows.length === 0 && !loading && <div className="p-4">No agents found</div>}
        {workflows.length > 0 && (
          <InfiniteScroll
            dataLength={workflows.length}
            scrollableTarget="feed-scroll"
            scrollThreshold={0.95}
            next={() => fetchWorkflows(lastWorkflow)}
            hasMore={hasMoreItems}
            loader={
              <div className="flex items-center justify-center h-12">
                <Loader size="sm" />
              </div>
            }
            endMessage={
              <div className="flex flex-col items-center justify-center my-6.5">
                <p className="no-more-orders-msg pt-6.5">
                  {workflows?.length ? '🐳' : freeSearch?.length ? 'No agents found' : 'Feed Empty'}
                </p>
              </div>
            }
          >
            <div className="flex flex-col gap-6.5 p-4 sm:p-0">
              {workflows.map((item, i) => {
                return (
                  <SequenceListCard
                    item={item}
                    index={i}
                    key={item.id + i}
                    workflowCategories={workflowCategories}
                    fetchWorkflows={fetchWorkflows}
                    onItemClick={() => {
                      customOnClick?.(item);
                      genericEventLogger(analyticsEvents.SEQUENCES, {
                        action: sequencesActions.VIEW_RUN,
                        sequence_id: item.id,
                        sequence_name: item.name,
                        source: source ?? 'feed',
                      });
                    }}
                    fromDashboard={fromDashboard}
                    read={!!item?.run?.read}
                  />
                );
              })}
            </div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};
