import React from 'react';
const ShareasaleIconBase64 = ({ small }) => (
  <img
    style={{ height: small ? 15 : 20 }}
    alt="Shareasale icon"
    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjMyMCAxMCA0MCA0MCIgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiPgogIDxwYXRoIGZpbGw9IiNGOUIzMTciIGQ9Im0zMzUuMjA5IDE4LjYgMi44MDUgNS42IDYuMjEyLjljMi41MDQuNCAzLjAwNSAyLjEgMS4zMDIgMy44bC00LjUwOCA0LjQgMS4xMDIgNi4yYy40MDEgMi41LTEuMDAyIDMuNS0zLjIwNiAyLjRsLTUuNjEtMi45LTUuNjEgMi45Yy0yLjIwNCAxLjItMy43MDcgMC0zLjIwNi0yLjRsMS4xMDItNi4yLTQuNTA4LTQuNGMtMS44MDQtMS43LTEuMjAyLTMuNCAxLjMwMi0zLjhsNi4yMTEtLjkgMi44MDUtNS42YzEuMTAyLTIuMiAyLjkwNi0yLjIgNC4wMDggMGgtLjIwMVoiLz4KPC9zdmc+"
  />
);

export default ShareasaleIconBase64;
