import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WillyDataSequence } from '../types/willyTypes';
import { firestoreRef } from 'utils/DB';
import { useStoreValue } from '@tw/snipestate';
import { $globalSequences } from '$stores/willy/$sequences';
import { AgentLibraryCard } from './AgentLibraryCard';
import { copyGlobalSequenceToShop } from '../utils/willyUtils';
import { analyticsEvents, genericEventLogger, templateLibraryActions } from 'utils/dataLayer';
import { toast } from 'react-toastify';
import copyToClipboard from 'utils/copyToClipboard';
import { TemplatePage } from './TemplatePage';
import { isGlobalDashboardExistsInShop } from '../dashContext';
import axiosInstance from 'utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';

export const AgentTemplate = () => {
  const currentShopId = useStoreValue($currentShopId);
  const { templateId } = useParams();
  const navigate = useNavigate();
  const [agent, setAgent] = useState<null | WillyDataSequence>(null);
  const [loadingAgent, setLoadingAgent] = useState(false);
  const [relatedAgents, setRelatedAgents] = useState<WillyDataSequence[]>([]);
  const redirectNoTemplate = useCallback(() => {
    navigate('/chat/agents');
  }, [navigate]);

  const fetchAgent = useCallback(async () => {
    if (!templateId) {
      redirectNoTemplate();
      return;
    }
    setLoadingAgent(true);
    try {
      const { data } = await axiosInstance.post(`/v2/sequences/workflows/get-agent-template`, {
        shopId: currentShopId,
        agentId: templateId,
        type: 'agent',
      });
      setAgent(data.agent as WillyDataSequence);
      setRelatedAgents(data.relatedAgents);
      setLoadingAgent(false);
    } catch {
      redirectNoTemplate();
    }
  }, [templateId, redirectNoTemplate, currentShopId]);

  useEffect(() => {
    fetchAgent();
  }, [fetchAgent]);

  const existInShop = useMemo(() => {
    if (agent) {
      return isGlobalDashboardExistsInShop(agent);
    }
  }, [agent]);

  const relatedTemplateCards = useMemo(() => {
    return relatedAgents.map((agent, i) => {
      return <AgentLibraryCard agent={agent} />;
    });
  }, [relatedAgents]);

  const copyLink = () => {
    const origin = window.location.origin;
    const link = `${origin}/chat/agents/templates/${templateId}`;
    copyToClipboard(link, true);
    toast.success('Link copied to clipboard');
  };

  const copyToShop = useCallback(async () => {
    if (!agent) {
      return;
    }
    const newId = await copyGlobalSequenceToShop(agent.id);
    genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
      action: templateLibraryActions.ADD_TEMPLATE_TO_WORKSPACE,
      sequence_id: agent.id,
      sequence_name: agent.name,
    });
    toast.success(`Agent added to your shop`);
    navigate({
      pathname: `/workflows/create/${newId}`,
      search: 'openScheduler=true&fromTemplateLibrary=true',
    });
  }, [agent, navigate]);
  return (
    <TemplatePage
      id={templateId ?? ''}
      name={agent?.name ?? ''}
      description={agent?.description ?? ''}
      themeColor={agent?.themeColor ?? 'blue'}
      iconSrc={
        !!agent?.iconSrc
          ? `https://storage.googleapis.com/file-hosting-bucket-shofifi/global-agent-image/${agent.id}.png`
          : undefined
      }
      tagline={agent?.tagline ?? ''}
      aboutPoints={agent?.aboutPoints ?? []}
      howInfo={agent?.howInfo ?? []}
      whyInfo={agent?.whyInfo ?? []}
      loadingTemplate={loadingAgent}
      relatedTemplatesCards={relatedTemplateCards}
      onCopyLink={copyLink}
      onCopyToShop={copyToShop}
      exists={!!existInShop}
      needsUpdate={false}
      onUpdate={() => {}}
    />
  );
};
