import _db, { Timestamp, firestoreRef } from 'utils/DB';
import {
  Conversation,
  WorkflowStep,
  WillyBaseMainElement,
  WillyDataSequence,
  DialectWithBoth,
  WorkflowResponse,
  Message,
  WorkflowStepSendToGoogleSheet,
  HistoryItem,
  WillyDataSequenceTaggedMetric,
  WillyDataSequencePixelSettings,
  WorkflowStepVariable,
  ScheduleItem,
  RunSequenceRequest,
  WillyElementType,
} from '../types/willyTypes';
import axiosInstance from 'utils/axiosInstance';
import {
  detectDynamicParameters,
  extractVariablesFromWhereClause,
  getConversationDialect,
  takeSampleOfData,
  removeUndefinedFields,
  cleanToolResults,
} from './willyUtils';
import { Dialect } from '@tw/types';
import { DEFAULT_DIALECT, DEFAULT_MODEL, MAX_ITEMS_PER_PAGE } from '../constants';
import { updateDashboardForFFConfigs } from '../api/updateDashboardForFFConfigs';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { isValidUrl } from './isValidUrl';
import { v4 as uuidV4 } from 'uuid';
import { WorkflowStepBase } from '../types/willyTypes';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';
import { replaceQueryIdsWithWidgets } from './replaceQueryIdsWithWidgets';
import moment from 'moment-timezone';
import firebase from 'firebase/compat/app';
import { emptyEmoji } from '../dashboardManagment/WillyDashDescription';
import { WillyEmoji } from '../types/emojiTypes';

export type EditNewSequenceProps = {
  baseMainElement: Partial<WillyBaseMainElement>;
  messages: WorkflowStep[];
  skipSaveRunDate?: boolean;
  dialect?: DialectWithBoth;
  featureFlagConfigs?: any[];
  ffConfigsToRemoveDashId?: any[];
  featureFlagDefaultCopyConfigs?: any[];
  ffConfigsDefaultToRemoveDashId?: any[];
  additionalShopIds?: string[];
  taggedMetrics?: WillyDataSequenceTaggedMetric[];
  pixelSettings?: WillyDataSequencePixelSettings;
  schedule: ScheduleItem | null;
};

export type CreateNewSequenceProps = {
  baseMainElement: WillyBaseMainElement;
  shopId: string;
  userId: string;
  messages: WorkflowStep[];
  conversationId?: string;
  dialect?: DialectWithBoth;
  featureFlagConfigs?: any[];
  ffConfigsToRemoveDashId?: any[];
  featureFlagDefaultCopyConfigs?: any[];
  ffConfigsDefaultToRemoveDashId?: any[];
  additionalShopIds?: string[];
  taggedMetrics?: WillyDataSequenceTaggedMetric[];
  pixelSettings?: WillyDataSequencePixelSettings;
  skipSaveRunDate?: boolean;
  schedule: ScheduleItem | null;
};

export async function createNewSequence(props: CreateNewSequenceProps) {
  const {
    baseMainElement,
    shopId,
    userId,
    conversationId,
    dialect,
    messages,
    additionalShopIds,
    taggedMetrics,
    pixelSettings,
    skipSaveRunDate = true,
    schedule,
  } = props;
  const {
    id,
    name,
    description,
    isGlobal,
    emoji,
    image,
    isBeta,
    isHide,
    roles,
    category,
    providers,
    providersBlockingCombination,
    createdAt,
    updatedAt,
  } = baseMainElement;

  let conversationData: Conversation | null = null;
  let conversationDialect: Dialect | null = null;
  let steps: WorkflowStep[] | undefined = [];

  if (conversationId) {
    const conversation = await _db().collection('conversations').doc(conversationId).get();
    if (!conversation.exists) {
      return {
        error: 'Conversation not found',
      };
    }
    conversationData = conversation.data() as Conversation;

    const { data } = await axiosInstance.post<
      any,
      {
        data: {
          steps?: WorkflowStep[];
          error?: string;
        };
      }
    >('/v2/sequences/create-messages-from-conversation', {
      shopId,
      conversationId,
    });
    const { error } = data;
    ({ steps } = data);
    if (error || !steps) {
      return {
        error: 'Could not create messages from conversation',
      };
    }

    conversationDialect = getConversationDialect(conversationData);
  } else {
    steps = messages;
  }

  let newSequence: WillyDataSequence = {
    id: id,
    name: name,
    description: description ?? '',
    updatedAt,
    isGlobal: isGlobal,
    type: 'sequence',
    canEdit: false,
    steps,
    createdAt,
    emoji: emoji,
    user: userId,
    image: image ?? '',
    v: conversationData?.v ?? 6,
    dialect: conversationDialect ?? dialect ?? DEFAULT_DIALECT,
    additionalShopIds: additionalShopIds,
    providers,
    roles,
    skipSaveRunDate,
    schedule: schedule ?? null,
  };

  if (isGlobal) {
    newSequence = {
      ...newSequence,
      providersBlockingCombination,
      category,
      isBeta,
      isHide,
      taggedMetrics: taggedMetrics,
    };
    if (pixelSettings) {
      newSequence = {
        ...newSequence,
        pixelSettings: pixelSettings,
      };
    }
  }

  try {
    const { data: res } = await axiosInstance.post<WillyDataSequence>(
      '/v2/sequences/create-workflow',
      {
        shopId,
        sequence: newSequence,
        isGlobal,
      },
    );

    return {
      success: true,
      message: 'Agent created successfully',
      conversationData,
      sequence: newSequence,
    };
  } catch (e) {
    console.error(e);
    return {
      error: 'Could not save agent',
    };
  }
}

export const duplicateSequence = async (sequence: WillyDataSequence) => {
  const newSequence: WillyDataSequence = {
    ...sequence,
    id: uuidV4(),
    name: `(Copy) ${sequence.name}`,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  };

  const sanitizedSequence = cleanToolResults(removeUndefinedFields(newSequence));

  try {
    await _db()
      .collection('data_sequences')
      .doc(sanitizedSequence.id)
      .set(sanitizedSequence, { merge: true });

    return {
      id: sanitizedSequence.id,
      conversationData: sanitizedSequence,
      success: true,
      message: 'Agent duplicated successfully',
    };
  } catch (e) {
    console.error(e);
    return {
      error: 'Could not duplicate agent',
    };
  }
};

// You can't change this function's payload or logic before talking to me, the old one below became a true mess
export async function simpleEditSequence(seq: WillyDataSequence) {
  const { actionsMenuOpen, renameMode, ...rest } = seq;
  const isGlobal = seq.isGlobal;
  const ref = isGlobal
    ? firestoreRef().collection('global_data_sequences')
    : _db().collection('data_sequences');
  await ref
    .doc(seq.id)
    .set({ ...rest, actionsMenuOpen: false, renameMode: false }, { merge: true });
}

export const editSequence = async (seq: EditNewSequenceProps) => {
  const {
    baseMainElement,
    dialect,
    messages,
    featureFlagConfigs,
    ffConfigsToRemoveDashId,
    featureFlagDefaultCopyConfigs,
    ffConfigsDefaultToRemoveDashId,
    additionalShopIds,
    taggedMetrics,
    pixelSettings,
    skipSaveRunDate,
    schedule,
  } = seq;
  const {
    id,
    name,
    description,
    isGlobal,
    emoji,
    image,
    isBeta,
    isHide,
    roles,
    category,
    providers,
    providersBlockingCombination,
    createdAt,
    updatedAt,
    msps,
  } = baseMainElement;
  const ref = isGlobal
    ? firestoreRef().collection('global_data_sequences')
    : _db().collection('data_sequences');
  let newSequence: Partial<WillyDataSequence> = {
    description: description ? description : '',
    updatedAt: Timestamp.now(),
    name: name,
    emoji: emoji,
    image: image ?? '',
    steps: messages,
    dialect: dialect ?? 'clickhouse',
    reportPrompt: '',
    additionalShopIds,
    providers,
    roles,
    skipSaveRunDate: skipSaveRunDate ?? true,
    schedule: schedule ?? null,
  };

  if (isGlobal) {
    newSequence = {
      ...newSequence,
      providersBlockingCombination: providersBlockingCombination,
      category: category,
      isBeta: isBeta,
      isHide: isHide,
      dialect: dialect,
      msps: msps,
      taggedMetrics: taggedMetrics,
    };
    if (pixelSettings) {
      newSequence = {
        ...newSequence,
        pixelSettings: pixelSettings,
      };
    }
  }
  try {
    await ref.doc(id).set(newSequence, { merge: true });
    if (isGlobal) {
      await Promise.all([
        updateDashboardForFFConfigs(
          {
            configs: featureFlagConfigs,
            mergeStrategy: 'merge',
            dashboardId: id!,
          },
          FeatureFlag.TEMPLATES_FF,
        ),
        updateDashboardForFFConfigs(
          {
            configs: ffConfigsToRemoveDashId,
            mergeStrategy: 'delete',
            dashboardId: id!,
          },
          FeatureFlag.TEMPLATES_FF,
        ),
        updateDashboardForFFConfigs(
          {
            configs: seq.featureFlagDefaultCopyConfigs,
            mergeStrategy: 'merge',
            dashboardId: id!,
          },
          FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
        ),
        updateDashboardForFFConfigs(
          {
            configs: ffConfigsDefaultToRemoveDashId,
            mergeStrategy: 'delete',
            dashboardId: id!,
          },
          FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
        ),
      ]);
    }
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const validateSequenceSteps = (sequenceSteps: WorkflowStep[]) => {
  const stepValid = !sequenceSteps.some((step, i) => {
    const olderSteps = sequenceSteps.slice(0, i);
    return isStepInvalid(step, olderSteps, false);
  });
  return stepValid;
};

export function getMessagesFromHistory(history: HistoryItem[]): Message[] {
  return history?.map((h) => {
    return {
      id: h.messageId,
      question: h.originalQuestion,
      ...h,
    };
  });
}

export function getMessagesFromWorkflowResponse(
  response: WorkflowResponse,
  dialect: Dialect,
): Message[] {
  if (response.stepType === 'tool' || response.stepType === 'runQuery') {
    return (
      response.res?.messages
        .filter((message) => {
          if (message.role === 'user') {
            return message.text.length > 0;
          } else if (message.role === 'assistant') {
            return message.text.length > 0;
          } else {
            return message.toolResults || message.text.length > 0;
          }
        })
        .map((message) => {
          return {
            ...message,
            id: message.messageId,
            dialect: dialect,
            question: message.originalQuestion,
            text: message.text,
          };
        }) || []
    );
  } else if (response.stepType === 'insights') {
    const modelUsed = response.modelUsed;
    const modelRequested = response.modelRequested;
    if (modelUsed && modelRequested && modelUsed !== modelRequested) {
      response.text = response.text ? `(Model used: ${modelUsed})\n\n${response.text}` : '';
      response.richText = response.richText
        ? `(Model used: ${modelUsed})\n\n${response.richText}`
        : null;
    }
    const children = replaceQueryIdsWithWidgets(response, dialect);
    const res: Message[] = [];
    res.push({
      id: uuidV4(),
      role: 'assistant',
      text: !!children ? '' : response.text,
      richText: children,
      error: response.error ?? undefined,
    });
    return res;
  } else if (response.stepType === 'preloadData') {
    const all = response.data?.flatMap((c) => c) ?? [];

    return all.map<Message>((m) => {
      if (m.dataType === 'forecast' || m.dataType === 'mmm') {
        return {
          id: uuidV4(),
          role: 'assistant',
          toolResults: {
            name: 'Forecasting',
            message: m,
          },
        };
      }

      return {
        id: uuidV4(),
        role: 'assistant',
        question: m.question,
        toolResults: {
          name: 'TextToSQL',
          nlqResponse: m,
          isSqlOnly: false,
          error: !!m.error,
          errorForInterface: m.error,
          sqlGenerated: m.generatedQuery,
        },
      };
    });
  } else {
    return [
      {
        id: response.stepId,
        role: 'assistant',
        text: response.status === 'error' ? response.error ?? '' : response.text ?? '',
        error: response.error ?? undefined,
      },
    ];
  }
}

export function isStepInvalid(
  step: WorkflowStep,
  olderSteps: WorkflowStep[],
  validateDynamicParams = true,
): string | null {
  if (step.stepType === 'subSequence') {
    // if (!step.sequenceIds || step.sequenceIds.length === 0) {
    //   return 'Sub-agent must have at least one parent agent';
    // }
    return null;
  } else if (step.stepType === 'preloadData') {
    if (!step.dashboardId || step.dashboardId.length === 0) {
      return 'Preload data step must have a report id';
    }
  } else if (step.stepType === 'tool' || step.stepType === 'insights' || step.stepType === 'rule') {
    const dynamicParams = detectDynamicParametersInStep(step);
    const dynamicParamsWithoutValue = dynamicParams?.filter((p) => p.value.length === 0);
    if (validateDynamicParams && dynamicParamsWithoutValue?.length) {
      return `${dynamicParamsWithoutValue.map((p) => `${p.key}`).join(', ')} declared in step "${step.title || step.stepType}", assign a value to it before running the step`;
    }
    if (!step.text || step.text.length === 0) {
      return 'Step text is required';
    }
  } else if (step.stepType === 'sendToDashboard') {
    if (!step.dashboardId || step.dashboardId.length === 0) {
      return 'Send to report step must have a report id';
    }
  } else if (step.stepType === 'sendToEmail') {
    const dynamicParams = detectDynamicParametersInStep(step);
    const dynamicParamsWithoutValue = dynamicParams?.filter((p) => p.value.length === 0);
    if (validateDynamicParams && dynamicParamsWithoutValue?.length) {
      return `${dynamicParamsWithoutValue.map((p) => `${p.key}`).join(', ')} declared in step "${step.title || step.stepType}", assign a value to it before running the step`;
    }
    if (!step.email || step.email.length === 0) {
      return 'Email is required';
    }
  } else if (step.stepType === 'sendToSlack') {
    // no validation needed yet?
  } else if (step.stepType === 'condition') {
    if (!step.rules || step.rules.length === 0) {
      return 'Condition must have at least one rule';
    }
    if (!step.rules.every((rule) => rule.ruleIds?.length > 0)) {
      return 'Each rule must have at least one rule id';
    }
  } else if (step.stepType === 'sendToGoogleSheet') {
    return validateSendToGoogleSheetStep(step);
  } else if (step.stepType === 'sendToWarehouse') {
    if (!step.providerId) {
      return 'Provider id is required in warehouse step';
    }
    if (!step.integrationId) {
      return 'Integration id is required in warehouse step';
    }
    if (!step.tableId) {
      return 'Table id is required in warehouse step';
    }
    if (step.useDedicatedQuery && !step.query?.length) {
      return 'Query is required if you check "Use dedicated query"';
    }
    if (
      !step.useDedicatedQuery &&
      !olderSteps.some(
        (s) => s.stepType === 'runQuery' || s.stepType === 'preloadData' || s.stepType === 'tool',
      )
    ) {
      return 'You must have at least one query before a warehouse step';
    }
  } else if (step.stepType === 'runQuery') {
    const params = extractVariablesFromWhereClause(step.query);
    if (params?.length && !Object.keys(step.queryParams || {}).length) {
      return `${params.join(', ')} declared in query but not provided in query params`;
    }
    const dynamicParams = detectDynamicParametersInStep(step);
    const dynamicParamsWithoutValue = dynamicParams?.filter((p) => p.value.length === 0);
    if (validateDynamicParams && dynamicParamsWithoutValue?.length) {
      return `${dynamicParamsWithoutValue.map((p) => `${p.key}`).join(', ')} declared in query but not provided in query params`;
    }
    if (!step.query || step.query.length === 0) {
      return 'Query is required in run query step';
    }
  } else if (step.stepType === 'sendToWebhook') {
    if (!step.url || step.url.length === 0) {
      return 'Url is required in send to webhook step';
    }
    if (!isValidUrl(step.url)) {
      return 'Url is invalid in send to webhook step';
    }
  }

  return null;
}

export function validateSendToGoogleSheetStep(step: WorkflowStepSendToGoogleSheet): string | null {
  if (!step.sheetsAccount) {
    return 'Google Sheets account is required';
  }
  if (!step.spreadsheetId) {
    return 'Spreadsheet is required';
  }
  if (!step.worksheetId) {
    return 'Worksheet is required';
  }
  if (!step.spreadsheetName) {
    return 'Spreadsheet name is required';
  }
  if (!step.worksheetName) {
    return 'Worksheet name is required';
  }

  if (step.ssNameError) {
    return 'Spreadsheet name is invalid';
  }

  if (step.wsNameError) {
    return 'Worksheet name is invalid';
  }

  return null;
}

export function detectDynamicParametersInStep(step: WorkflowStep): WorkflowStepVariable[] | null {
  const { variables = [] } = step;

  const removeBrackets = (text: string) =>
    text.replace(/\{\{\s*(.*?)\s*\}\}/g, (_, p1) => p1.replace(/\s+/g, ''));

  if (step.stepType === 'tool') {
    let all = detectDynamicParameters(step.text);

    let code: string | undefined = undefined;
    if (step.toolToUse === 'TextToSQL' && step.toolPreload?.name === 'TextToSQL') {
      code = step.toolPreload.generatedQuery;
    } else if (step.toolToUse === 'TextToPython' && step.toolPreload?.name === 'TextToPython') {
      code = step.toolPreload.pythonCode;
    }

    const fromCode = detectDynamicParameters(code ?? '');
    if (fromCode) {
      if (all) {
        all.push(...fromCode);
      } else {
        all = fromCode;
      }
    }

    return (
      all?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  } else if (step.stepType === 'insights') {
    const res = detectDynamicParameters(step.text);
    return (
      res?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  } else if (step.stepType === 'rule') {
    const res = detectDynamicParameters(step.text);
    return (
      res?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  } else if (step.stepType === 'sendToEmail') {
    const res = detectDynamicParameters(step.email);
    return (
      res?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  } else if (step.stepType === 'runQuery') {
    const res = detectDynamicParameters(step.query);
    return (
      res?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  }
  return null;
}

export function createNewStep(step: Partial<WorkflowStep>, userEmail?: string) {
  let newStep: WorkflowStep;
  const id = uuidV4();

  const { stepType } = step;

  const baseStep: WorkflowStepBase = {
    createdAt: step.createdAt ?? new Date().toISOString(),
    id: step.id ?? id,
    title: step.title ?? '',
    variables: [],
    runInParallel: step.runInParallel ?? false,
  };

  if (stepType === 'tool') {
    newStep = {
      ...baseStep,
      text: '',
      stepType,
      preserveHistory: true,
    };
    if (!!step.toolToUse) {
      newStep = {
        ...newStep,
        toolToUse: step.toolToUse,
      };
      if (step.toolToUse === 'Searching') {
        newStep.searchSource = 'webSearch';
      }
    }
  } else if (stepType === 'preloadData') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      dashboardId: '',
      isStandardDashboard: false,
      date: DatePickerTimePeriods.TODAY,
      previousDate: 'none',
      filters: null,
      attributionOptions: null,
    };
  } else if (stepType === 'preloadRuns') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      runIds: [],
    };
  } else if (stepType === 'subSequence') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      sequenceIds: [],
    };
  } else if (stepType === 'loop') {
    newStep = {
      ...baseStep,
      stepType: stepType,
    };
  } else if (stepType === 'rule') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      text: '',
    };
  } else if (stepType === 'condition') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      rules: [],
    };
  } else if (stepType === 'insights') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      text: '',
      model: DEFAULT_MODEL,
      outputType: 'text',
      addExtraData: true,
    };
  } else if (stepType === 'sendToEmail') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      email: userEmail ?? '',
      formats: [],
    };
  } else if (stepType === 'sendToSlack') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      formats: [],
    };
  } else if (stepType === 'sendToDashboard') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      dashboardId: '',
    };
  } else if (stepType === 'sendToGoogleSheet') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      spreadsheetId: '',
      spreadsheetName: '',
      ssNameError: false,
      worksheetId: '',
      worksheetName: '',
      wsNameError: false,
      sheetsAccount: '',
    };
  } else if (stepType === 'sendToWarehouse') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      providerId: '',
      integrationId: '',
      tableId: '',
      useDedicatedQuery: false,
      query: '',
      queryParams: {},
    };
  } else if (stepType === 'sendToWebhook') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      url: '',
      headers: [],
    };
  } else if (stepType === 'runQuery') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      query: '',
      queryParams: {},
    };
  } else if (stepType === 'preloadGoogleSheet') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      spreadsheetId: '',
      spreadsheetName: '',
      worksheetId: '',
      worksheetName: '',
      sheetsAccount: '',
    };
  } else if (stepType === 'preloadCSV') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      url: '',
      fileName: '',
      destFileName: '',
    };
  } else if (stepType === 'vision') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      text: '',
      uploadedFiles: [],
    };
  } else {
    return null;
  }
  return newStep;
}

export function editStep(stepToUpdate: WorkflowStep, newStep: WorkflowStep) {
  const varsInStep = detectDynamicParametersInStep(newStep);
  const oldVariables = (stepToUpdate.variables || []).filter((v) =>
    varsInStep?.some((v2) => v2.key === v.key),
  );
  const newVariables = newStep.variables || [];
  const variables = [
    ...oldVariables.map((v) => ({
      ...v,
      value: newVariables.find((v2) => v2.key === v.key)?.value ?? v.value,
    })),
    ...newVariables.filter((v) => !oldVariables.some((v2) => v2.key === v.key)),
  ];

  let uniqueVariables = variables.filter(
    (v, i, self) => i === self.findIndex((v2) => v2.key === v.key),
  );

  if (!varsInStep) {
    uniqueVariables = [];
  }

  stepToUpdate = {
    ...stepToUpdate,
    title: newStep.title ?? stepToUpdate.title ?? '',
    variables: uniqueVariables || [],
    runInParallel: newStep.runInParallel ?? stepToUpdate.runInParallel ?? false,
  };
  if (newStep.stepType === 'tool') {
    return {
      ...stepToUpdate,
      text: newStep.text,
      preserveHistory: !!newStep.preserveHistory,
      toolPreload: newStep.toolPreload ?? null,
      searchSource: newStep.searchSource ?? null,
    };
  } else if (newStep.stepType === 'insights') {
    return {
      ...stepToUpdate,
      text: newStep.text,
      model: newStep.model || null,
      outputType: newStep.outputType || 'text',
      addExtraData: newStep.addExtraData ?? true,
    };
  } else if (newStep.stepType === 'vision') {
    return {
      ...stepToUpdate,
      text: newStep.text,
      uploadedFiles: newStep.uploadedFiles ?? [],
    };
  } else if (newStep.stepType === 'rule') {
    return {
      ...stepToUpdate,
      text: newStep.text,
    };
  } else if (newStep.stepType === 'preloadData') {
    return {
      ...stepToUpdate,
      dashboardId: newStep.dashboardId,
      isStandardDashboard: newStep.isStandardDashboard,
      date: newStep.date,
      previousDate: newStep.previousDate,
      filters: newStep.filters || null,
      attributionOptions: newStep.attributionOptions || null,
    };
  } else if (newStep.stepType === 'condition') {
    return {
      ...stepToUpdate,
      rules: newStep.rules,
    };
  } else if (newStep.stepType === 'subSequence') {
    return {
      ...stepToUpdate,
      sequenceIds: newStep.sequenceIds,
    };
  } else if (newStep.stepType === 'loop') {
    return {
      ...stepToUpdate,
    };
  } else if (newStep.stepType === 'sendToDashboard') {
    return {
      ...stepToUpdate,
      dashboardId: newStep.dashboardId,
    };
  } else if (newStep.stepType === 'sendToEmail') {
    return {
      ...stepToUpdate,
      email: newStep.email,
      formats: newStep.formats || [],
    };
  } else if (newStep.stepType === 'sendToSlack') {
    return {
      ...stepToUpdate,
      formats: newStep.formats || [],
    };
  } else if (newStep.stepType === 'sendToGoogleSheet') {
    return {
      ...stepToUpdate,
      spreadsheetId: newStep.spreadsheetId,
      spreadsheetName: newStep.spreadsheetName,
      ssNameError: newStep.ssNameError,
      worksheetId: newStep.worksheetId,
      worksheetName: newStep.worksheetName,
      wsNameError: newStep.wsNameError,
      sheetsAccount: newStep.sheetsAccount,
    };
  } else if (newStep.stepType === 'sendToWarehouse') {
    return {
      ...stepToUpdate,
      providerId: newStep.providerId,
      integrationId: newStep.integrationId,
      tableId: newStep.tableId,
      useDedicatedQuery: newStep.useDedicatedQuery,
      query: newStep.query,
      queryParams: newStep.queryParams || {},
    };
  } else if (newStep.stepType === 'sendToWebhook') {
    return {
      ...stepToUpdate,
      url: newStep.url || '',
      headers: newStep.headers || [],
    };
  } else if (newStep.stepType === 'runQuery') {
    return {
      ...stepToUpdate,
      query: newStep.query,
      queryParams: newStep.queryParams,
    };
  } else if (newStep.stepType === 'preloadRuns') {
    return {
      ...stepToUpdate,
      runIds: newStep.runIds,
    };
  } else if (newStep.stepType === 'preloadGoogleSheet') {
    return {
      ...stepToUpdate,
      spreadsheetId: newStep.spreadsheetId,
      spreadsheetName: newStep.spreadsheetName,
      worksheetId: newStep.worksheetId,
      worksheetName: newStep.worksheetName,
      sheetsAccount: newStep.sheetsAccount,
    };
  } else if (newStep.stepType === 'preloadCSV') {
    return {
      ...stepToUpdate,
      url: newStep.url,
      fileName: newStep.fileName,
      destFileName: newStep.destFileName,
    };
  }

  return stepToUpdate;
}

export function getParentRuleId(stepId: string, type: 'passed' | 'failed') {
  return `${stepId}_${type}`;
}

export function cutWorkflowResponse(result: WorkflowResponse | null): WorkflowResponse | null {
  if (!result) {
    return null;
  }
  if (result.stepType !== 'tool' && result.stepType !== 'runQuery') {
    return result;
  }
  if (!result.res?.messages?.length) {
    return result;
  }

  return {
    ...result,
    res: {
      ...result.res,
      messages: result.res.messages.map((msg) => {
        if (msg.role !== 'tool') {
          return msg;
        }
        if (!msg.toolResults) {
          return msg;
        }
        if (msg.toolResults.name === 'TextToSQL') {
          return {
            ...msg,
            toolResults: {
              ...msg.toolResults,
              nlqResponse: {
                ...msg.toolResults.nlqResponse,
                dataColumns: msg.toolResults.nlqResponse?.dataColumns || { x: [], y: [] },
                data: takeSampleOfData(msg.toolResults.nlqResponse?.data, MAX_ITEMS_PER_PAGE),
              },
            },
          };
        }
        return msg;
      }),
    },
  };
}

export async function resumeSequence(scheduleId: string, shopId: string, userId: string) {
  await axiosInstance.post(`/v2/sequences/schedules/${scheduleId}/resume`, {
    shopId,
    userId,
  });
}

export async function triggerSequence(scheduleId: string, shopId: string, userId: string) {
  await axiosInstance.post(`/v2/sequences/schedules/${scheduleId}/execute`, {
    shopId,
    userId,
  });
}

const formatLong = 'MMM D, YYYY h:mm A';
const formatShort = 'MMM D, h:mm A';
const milliSecondsInDay = 86400000;

export const formatSequenceItemDate = (
  date: firebase.firestore.Timestamp | null,
  isSmall?: boolean,
) => {
  if (date instanceof Timestamp) {
    const dateAsMoment = moment(date.toDate());
    const diff = moment().diff(dateAsMoment);
    if (diff < milliSecondsInDay) {
      return moment.duration(moment().diff(dateAsMoment)).humanize() + ' ago';
    }
    return dateAsMoment.format(isSmall ? formatShort : formatLong);
  }

  return '';
};

export async function runWorkflow(params: RunSequenceRequest) {
  try {
    const response = await axiosInstance.post<{ workflowId: string }>(
      '/v2/sequences/workflows/run',
      params,
    );
    return response.data;
  } catch (error: any) {
    const errorMessage = error.message || 'Error running sequence';
    console.error(errorMessage);
    throw errorMessage;
  }
}

export async function convertSequenceToGlobal(
  c: WillyDataSequence,
  currentShopId: string,
  dialect: Dialect,
) {
  const newSequenceTemplate: CreateNewSequenceProps = {
    shopId: currentShopId!,
    userId: c.user,
    dialect: c.dialect ?? dialect,
    conversationId: (c as any).conversationId,
    messages: c.steps || [],
    schedule: c.schedule || null,
    baseMainElement: {
      id: uuidV4(),
      type: 'sequence' as WillyElementType,
      canEdit: false,
      isGlobal: true,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      name: c.name ?? '',
      description: c.description ?? '',
      emoji: c.emoji ?? (emptyEmoji as WillyEmoji),
      roles: c.roles ?? [],
      isBeta: c.isBeta ?? false,
      isHide: c.isHide ?? false,
      image: c.image,
      category: c.category ?? '',
      dialect: c.dialect ?? dialect ?? 'clickhouse',
      providers: c.providers ?? [],
      providersBlockingCombination: c.providersBlockingCombination ?? 'NONE',
      packages: c.packages ?? [],
      defaultPackages: c.defaultPackages ?? [],
      msps: c.msps ?? [],
    },
  };

  const { error, success, message, conversationData } =
    await createNewSequence(newSequenceTemplate);

  return {
    error,
    success,
    message,
    conversationData,
  };
}
