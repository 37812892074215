import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// style
import module_styles from '../Integrations.module.scss';

// components
import LockedFeatureIndicator from 'components/library/LockedFeatures/LockedFeatureIndicator';
import { IntegrationWidgetButtons } from './IntegrationWidgetButton';

// types
import { useAppSelector, type RootState } from 'reducers/RootType';
import { ServicesIds } from '@tw/types/module/services';
import { WarningIcon } from './WarningIcon';
import { ConnectionDetailsModal } from './ConnectionDetailsModal';
import { useAppDispatch } from 'index';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { Popover, Tooltip } from '@shopify/polaris';
import { CircleIcon } from 'components/library/CircleIcon';
import { toast } from 'react-toastify';
import { CONNECT_INTEGRATION_SOURCE_LOCATION } from '../constants';
import { useLocation } from 'react-router';
import { Badge, Image, Text } from '@tw/ui-components';
import { ShopProviderStatus, ShopProviderStatusEnum } from '@tw/types/module/types/ShopProviders';
import { providers } from 'ducks/shopIntegrations';
import { providerDomainEnum } from '@tw/types/module/sensory/integration/generalTypes';

export interface IIntegrationWidget {
  id: string;
  isFromSensory?: boolean;
  name: string;
  icon: any;
  isDarkIcon?: boolean;
  connected?: boolean;
  isFromChat?: boolean;
  title?: string;
  subTitle?: string;
  showAccountConnectedDetails?: boolean;
  isLocked?: boolean;
  disconnectHook: () => any;
  connectHook: (data?: IIntegrationWidget) => any;
  serviceId: ServicesIds;
  infoWhenUnconnected?: string;
  configHook?: () => Promise<any> | void;
  enabled?: boolean;
  customConnectionDetails?: React.FC;
  isLoading?: boolean;
  providerState?: ShopProviderStatus;
  showButtons?: boolean;
  tooltipWhenUnconnected?: string;
  domain?: providerDomainEnum;
  isBeta?: boolean;
}

export const IntegrationWidget: FC<IIntegrationWidget> = (props) => {
  const {
    id,
    name,
    icon,
    isDarkIcon,
    isLocked,
    serviceId,
    showAccountConnectedDetails = true,
    disconnectHook,
    connectHook,
    infoWhenUnconnected,
    configHook,
    customConnectionDetails,
    isLoading = false,
    providerState = {},
    showButtons = true,
    tooltipWhenUnconnected = '',
    isBeta = false,
  } = props;

  const dispatch = useAppDispatch();

  // STATE
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const fetchAccountsError = useSelector(
    (state: RootState) => state.shopServices.fetchAccountsError,
  );
  const providersConfig = useAppSelector((state) => state.sensory.providers);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showConnectionDetails, setShowConnectionDetails] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const providersProps = useSelector(providers);
  const saveWindowLocation = useCallback(() => {
    localStorage.setItem(
      `${CONNECT_INTEGRATION_SOURCE_LOCATION}_${currentShopId}_${serviceId}`,
      location.pathname,
    );
  }, [currentShopId, location.pathname, serviceId]);

  const accountsErrorMsg: string = useMemo(() => {
    const srvId = serviceId || id;
    if (!srvId) return '';
    const err = fetchAccountsError[srvId]?.trim() || '';
    return err;
  }, [serviceId, id, fetchAccountsError]);

  // COMPUTED
  const providerStatus: ShopProviderStatus = useMemo(() => {
    return accountsErrorMsg
      ? { status: ShopProviderStatusEnum.connected, errorMessage: accountsErrorMsg }
      : providerState;
  }, [accountsErrorMsg, providerState]);

  // HANDLERS
  const connect = useCallback(async () => {
    setLoading(true);
    saveWindowLocation();
    try {
      await connectHook(props);
    } catch (e) {
      toast.error(`Something went wrong. Please refresh the page or try again later`);
    } finally {
      setLoading(false);
    }
  }, [connectHook, saveWindowLocation, props]);

  const disconnect = useCallback(async () => {
    setLoading(true);
    try {
      await disconnectHook();
    } catch (e) {
      toast.error(`Something went wrong. Please refresh the page or try again later`);
    } finally {
      setLoading(false);
    }
  }, [disconnectHook]);

  const isConnected: boolean = useMemo(() => {
    return !!providerState?.status && providerState.status != ShopProviderStatusEnum.disconnected;
  }, [providerState]);

  const { iconColor, displayText } = useMemo(() => {
    switch (providerStatus.status) {
      case ShopProviderStatusEnum.pending:
        const text = providersConfig?.find((x) => x.id === serviceId)?.has_approval_flow
          ? `Request pending, waiting for approval from ${name}`
          : 'No Account Selected';
        return { iconColor: 'orange', displayText: text };
      case ShopProviderStatusEnum.connected:
        return {
          iconColor: providerStatus.errorMessage ? 'red' : 'green',
          displayText: 'View Connection Details',
        };
      case ShopProviderStatusEnum.backfill:
        return { iconColor: 'blue', displayText: 'Importing your data' };
      default:
        return { iconColor: 'gray', displayText: 'unknown status' };
    }
  }, [providerStatus.status, providerStatus.errorMessage, serviceId, name, providersConfig]);

  return (
    <div
      id={`integrations-${id}`}
      aria-label={`${name} Integration Widget`}
      className={module_styles['integration-card']}
    >
      <div className={module_styles['card-main']} style={{ position: 'relative' }}>
        <div className="flex flex-col whitespace-nowrap">
          <div className={module_styles['brand-name']}>
            <div className={isDarkIcon ? 'darkmode-white-filter' : ''}>
              {/* // TODO: Double check */}
              <Image src={icon} w={20} h={20} />
            </div>
            <Text span>{name}</Text>
            {isBeta && (
              <Badge right={0} top={0} color="one.0" size="sm">
                <Text fw={500} size={'10px'} color={'one.5'}>
                  Beta
                </Text>
              </Badge>
            )}
          </div>
          {providerStatus.status == ShopProviderStatusEnum.disconnected && (
            <Tooltip content={tooltipWhenUnconnected || ''}>
              <div className="text-blue-500 text-md text-wrap">{infoWhenUnconnected || ''}</div>
            </Tooltip>
          )}
          {!!providerStatus.status &&
            providerStatus.status != ShopProviderStatusEnum.disconnected && (
              <div
                onClick={() => {
                  if (providerStatus.status == ShopProviderStatusEnum.pending) {
                    if (serviceId) {
                      const parts = serviceId.split('-').map((part) => part.toUpperCase());
                      dispatch({ type: `${parts.join('_')}_CONFIG_SCREEN_OPEN` });
                    }
                  } else setShowConnectionDetails(true);
                }}
                className={`flex gap-2 items-center ${module_styles['link']}`}
              >
                <CircleIcon color={iconColor} />
                {displayText}
              </div>
            )}

          {providerStatus.status == ShopProviderStatusEnum.connected &&
            !!providerStatus.errorMessage && (
              <Popover
                active={open}
                onClose={() => setOpen(false)}
                sectioned
                activator={
                  <div
                    onMouseOver={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                    className={module_styles['error'] + ' cursor-pointer'}
                  >
                    <WarningIcon /> Reconnection required
                  </div>
                }
              >
                <div className="italic">
                  <p> We've identified an error with this connection.</p>
                  <br />
                  {!!providerStatus.errorMessage ? (
                    <p>{providerStatus.errorMessage}</p>
                  ) : (
                    <p>
                      Please try reconnecting or reach out to Customer Support through the chat icon
                      in the lower right-hand corner for help
                    </p>
                  )}
                </div>
              </Popover>
            )}
        </div>
        {isLocked && (
          <LockedFeatureIndicator
            iconSize={22}
            iconOnly
            upgradeButton
            featureFlag={
              props.domain === providerDomainEnum.dataWarehouse
                ? FeatureFlag.WAREHOUSE_SYNC_OUT_FF
                : FeatureFlag.LIMIT_INTEGRATIONS_FF
            }
            extraData={{ targetToUnlock: id }}
          />
        )}

        {!isLocked && showButtons && (
          <IntegrationWidgetButtons
            id={id}
            name={name}
            connected={
              !!providerStatus?.status &&
              providerStatus.status != ShopProviderStatusEnum.disconnected
            }
            connect={connect}
            disconnect={disconnect}
            configHook={configHook}
            loading={loading || isLoading}
          />
        )}
      </div>

      {!isLocked && (
        <ConnectionDetailsModal
          connected={providerStatus.status != ShopProviderStatusEnum.disconnected}
          platformName={name}
          serviceId={serviceId || id}
          open={showConnectionDetails}
          onClose={() => setShowConnectionDetails(false)}
          configHook={configHook}
          customConnectionDetails={customConnectionDetails}
        />
      )}
    </div>
  );
};
