import { firestoreRef, getUserId } from 'utils/DB';
import axiosInstance from '../../utils/axiosInstance';
import { confirm } from '@tw/ui-components';
import { $currentShopId } from '../../$stores/$shop';
import { toast } from 'react-toastify';

const GET_SHOPIFY_SCOPES = 'GET_SHOPIFY_SCOPES';
const COPY_FROM_TRENDS = 'COPY_FROM_TRENDS';

export const getShopifyScopes = (shopId: string) => {
  return async (dispatch) => {
    const { data: scopes } = await axiosInstance.get(
      `/v2/shopify/get-shopify-scopes?shopId=${shopId}`,
    );
    if (scopes && scopes.access_scopes) {
      const scopesArr = scopes.access_scopes.map((o) => o.handle);
      dispatch({
        type: GET_SHOPIFY_SCOPES,
        payload: scopesArr,
      });
    }
  };
};

export const uninstallShopify = () => async (dispatch) => {
  if (
    !(await confirm({
      title: 'Uninstall Shopify',
      message: 'Are you sure you want to uninstall Shopify?',
    }))
  ) {
    return;
  }
  await axiosInstance.get(`/v2/shopify/login/uninstall?shopId=${$currentShopId.get()}`);
  toast.success('Shopify uninstalled successfully');
  setTimeout(() => {
    window.location.reload();
  }, 2000);
};

export const checkTrendsData = () => {
  return async (dispatch) => {
    const doc = firestoreRef().collection('shops').doc(`tw-trends-${getUserId()}`);
    const shopData = (await doc.get()).data();
    const google =
      (Object.values(shopData?.googleAdsAccounts || {})?.[0] as any)?.descriptive_name || '';
    const facebook = (Object.values(shopData?.facebookAccounts || {})?.[0] as any)?.name || '';
    dispatch({
      type: COPY_FROM_TRENDS,
      payload: { google, facebook },
    });
  };
};

const showCopyFromTrends = (state = { google: '', facebook: '' }, action) => {
  switch (action.type) {
    case COPY_FROM_TRENDS:
      return action.payload;
    default:
      return state;
  }
};

const shopifyScopesFromApi = (state = null, action) => {
  switch (action.type) {
    case GET_SHOPIFY_SCOPES: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export const reducers = {
  shopifyScopesFromApi,
  showCopyFromTrends,
};
