import ReactSwitch from 'react-switch';
import { AttributionData, AttributionTableMetadata } from 'types/attribution';
import { useCallback, useState } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { toast } from 'react-toastify';
import { genericEventLogger, analyticsEvents, attributionActions } from 'utils/dataLayer';
import { setConfirmModal } from './StatusChangeConfirmationModal';
import { Text } from '@tw/ui-components';
import { capitalize } from 'lodash';
import { providers } from 'ducks/shopIntegrations';
import allServices from 'constants/services';
import { UpdateEntityParamsFromClient } from '@tw/types/module/pixel';

type ToggleStatusProps = {
  attribution: AttributionData;
  metadata?: AttributionTableMetadata;
  disabled?: boolean;
  setData?: React.Dispatch<React.SetStateAction<AttributionData[]>>;
  onStatusChange?: (newStatus: 'ACTIVE' | 'PAUSED') => void;
};

const ToggleStatus: React.FC<ToggleStatusProps> = ({
  attribution,
  metadata,
  disabled = false,
  setData = () => {},
  onStatusChange = () => {},
}) => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [loading, setLoading] = useState(false);
  const { hasUpdateScope, updateAdsAllowed, setIntegrationsPopUpOpened } = metadata || {};
  const providerList = useSelector(providers);

  const toggleStatus = useCallback(
    async (checked: boolean) => {
      if (!hasUpdateScope) {
        if (setIntegrationsPopUpOpened) {
          setIntegrationsPopUpOpened(true);
        }
        return { message: 'More permissions are needed', success: false };
      }
      const newStatus = checked ? 'ACTIVE' : 'PAUSED';
      const oldStatus = checked ? 'PAUSED' : 'ACTIVE';
      setLoading(true);
      try {
        let updateURI = '';
        const [serviceId, props] =
          Object.entries(providerList).find(([id, v]) => id === attribution.serviceId) || [];
        if (serviceId && props?.isSensory) {
          updateURI = `/v2/integration/integrations/update-entity`;
        } else {
          updateURI =
            attribution.serviceId === 'facebook-ads'
              ? '/v2/facebook-ads/update-ad'
              : `/v2/${attribution.serviceId}/update-entity`;
        }
        const { data } = await axiosInstance.post(updateURI, {
          shopId: currentShopId,
          accountId: attribution.accountId,
          entity: attribution.entity,
          id: attribution.id,
          field: 'status',
          value: newStatus,
          oldValue: oldStatus,
          integrationId: attribution?.integrationId,
        } as UpdateEntityParamsFromClient);
        setLoading(false);

        if (data.error) {
          toast.error(data.error);
          genericEventLogger(analyticsEvents.ATTRIBUTION, {
            action: attributionActions.TOGGLE_STATUS_ERROR,
            message: data.error,
            shop: currentShopId,
            error: data.error,
            id: attribution.id,
            campaignId: attribution.campaignId,
            campaignName: attribution.campaignName,
            adsetId: attribution.adsetId,
            adsetName: attribution.adsetName,
          });
        } else {
          toast.success('Status successfully updated');
          setData((old) => {
            return old.map((x) => ({
              ...x,
              status: x.id === attribution.id ? newStatus : x.status,
              adsets: x.adsets?.map((set) => ({
                ...set,
                status: set.id === attribution.id ? newStatus : set.status,
                ads: set.ads?.map((ad) => ({
                  ...ad,
                  status: ad.id === attribution.id ? newStatus : ad.status,
                })),
              })),
            }));
          });

          onStatusChange(newStatus);

          genericEventLogger(analyticsEvents.ATTRIBUTION, {
            action: attributionActions.TOGGLE_STATUS_SUCCESS,
            shop: currentShopId,
            id: attribution.id,
            campaignId: attribution.campaignId,
            campaignName: attribution.campaignName,
            adsetId: attribution.adsetId,
            adsetName: attribution.adsetName,
          });
        }
      } catch (e) {
        setLoading(false);
        toast.error('Something went wrong while updating status, please try again later');
      }
    },
    [
      attribution,
      currentShopId,
      hasUpdateScope,
      setData,
      onStatusChange,
      setIntegrationsPopUpOpened,
      providerList,
    ],
  );
  return (
    <>
      {!allServices[attribution.serviceId!]?.updatableFields?.['status'] ? (
        <span
          className={`w-4 h-4 rounded-full flex flex-shrink-0 ${
            attribution.status === 'ACTIVE' ? 'bg-[#3acf00]' : 'bg-gray-400'
          }`}
        ></span>
      ) : (
        <>
          <ReactSwitch
            onColor="#1877f2"
            handleDiameter={16}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={10}
            width={30}
            disabled={loading || !updateAdsAllowed || disabled}
            className="react-switch align-middle"
            id="material-switch"
            checked={attribution.status === 'ACTIVE'}
            onChange={(checked, event) => {
              event.preventDefault();
              event.stopPropagation();

              const message = (
                <Text fz="md">
                  Are you sure you want to turn{' '}
                  <strong>
                    {capitalize(attribution.entity)}:{' '}
                    <Text span td="underline">
                      {attribution.name}
                    </Text>{' '}
                    {checked ? 'ON' : 'OFF'}
                  </strong>
                  ?
                </Text>
              );

              setConfirmModal(message, () => {
                toggleStatus(checked);
                genericEventLogger(analyticsEvents.ATTRIBUTION, {
                  action: attributionActions.CLICKED_TOGGLE_STATUS,
                  shop: currentShopId,
                  id: attribution.id,
                  campaignId: attribution.campaignId,
                  campaignName: attribution.campaignName,
                  adsetId: attribution.adsetId,
                  adsetName: attribution.adsetName,
                });
              });
            }}
          />
        </>
      )}
    </>
  );
};

export default ToggleStatus;
