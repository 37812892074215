import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  RunSequenceRequest,
  StepWsStatus,
  WillyDataSequence,
  WorkflowWsStatus,
  Message,
} from './Willy/types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $globalDashboardRoles, Role } from '$stores/willy/$globalDashboardRoles';
import { otherColorSets } from './Willy/useSamplePrompts';
import { WorkflowTemplateTile } from './Willy/WillyTemplateLibrary/WorkflowTemplateTile';
import { $currentDateRange, $prevDateRange } from '$stores/willy/$dateRange';
import { $activeAccounts, $currency, $industry, $shop } from '$stores/$shop';
import { useAppSelector } from 'reducers/RootType';
import { $socket } from '$stores/$socket';
import { v4 as uuidV4 } from 'uuid';
import { sleep } from 'utils/sleep';
import axiosInstance from 'utils/axiosInstance';
import { useSequenceFlowSocket } from './Willy/sequenceBuilder/useSequenceFlowSocket';
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Icon,
  Loader,
  Menu,
  Text,
  TextInput,
  Tooltip,
} from '@tw/ui-components';
import { SequenceFlowStep } from './Willy/sequenceBuilder/SequenceFlowStep';
import { noop } from 'lodash';
import { WillyMessageTemplate } from './Willy/WillyMessageTemplate';
import { analyticsEvents, genericEventLogger, sequencesActions } from 'utils/dataLayer';
import { Dialect } from '@tw/types';
import { $dialect } from '$stores/$user';
import { $isAgentSupported, $shopSequences } from '$stores/willy/$sequences';
import _db from 'utils/DB';
import { getMessagesFromWorkflowResponse } from './Willy/utils/sequences';
import { WillySimpleText } from './Willy/WillySimpleText';
import { WorkflowSelector } from './Willy/WorkflowSelector';
import { useInsightsStreamSocket } from './Willy/sequenceBuilder/useInsightsStreamSocket';
import { openUpgradeAgentsPopup } from './Willy/$upgradeAgentsModal';

type InsightsPopupInnerProps = {
  workflows: WillyDataSequence[];
  selectedWorkflow: WillyDataSequence | null;
  setSelectedWorkflow: (workflow: WillyDataSequence | null) => void;
  useDatePickers?: boolean;
  variables?: Record<string, string>;
  source: 'pixel' | 'summary';
  sourceDetails: string | { entity: string; id: string; channel?: string };
  showGeneralInsights?: boolean;
  generalInsights?: string;
  generalInsightsError?: string | null;
};

export const InsightsPopupInner: React.FC<InsightsPopupInnerProps> = ({
  workflows,
  selectedWorkflow,
  setSelectedWorkflow,
  useDatePickers,
  variables,
  source,
  sourceDetails,
  showGeneralInsights,
  generalInsights,
  generalInsightsError,
}) => {
  const isAgentSupported = useStoreValue($isAgentSupported);

  const workflowCategories = useStoreValue($globalDashboardRoles);
  const sourceDetailKey =
    typeof sourceDetails === 'string'
      ? sourceDetails
      : sourceDetails?.entity === 'campaign' && !!sourceDetails.channel
        ? `${sourceDetails?.entity}_${sourceDetails?.channel}`
        : `${sourceDetails?.entity}_${sourceDetails?.id}`;

  const shop = useStoreValue($shop);

  const shopMetricWorkflowIds = useMemo(() => {
    const sequencesForSourceType =
      source === 'pixel' ? shop?.pixelSequences : shop?.metricSequences;
    return sequencesForSourceType?.[sourceDetailKey] || [];
  }, [shop, sourceDetailKey, source]);

  const allShopSequences = useStoreValue($shopSequences);

  const shopMetricWorkflows = useMemo(() => {
    return allShopSequences.filter((sequence) => shopMetricWorkflowIds.includes(sequence.id));
  }, [allShopSequences, shopMetricWorkflowIds]);

  const workflowsToShow = useMemo(() => {
    return [...workflows, ...shopMetricWorkflows];
  }, [workflows, shopMetricWorkflows]);

  const workflowCatsWithColors: Role[] = [
    ...workflowCategories?.map((role, i) => {
      const colorSet = otherColorSets[i % otherColorSets.length];
      return {
        ...role,
        color: colorSet.color,
        labelColor: colorSet.labelColor,
        badgeColor: colorSet.badgeColor,
      };
    }),
    {
      name: 'Shop Agent',
      color: 'two.0',
      labelColor: 'two.0',
      badgeColor: 'two.7',
      id: 'shop',
      icon: 'user-single',
      category: 'NONE',
      order: 9,
    },
  ];

  const toggleWorkflowMetric = useCallback(
    async (worfklowId) => {
      await _db().set(
        {
          [source === 'pixel' ? 'pixelSequences' : 'metricSequences']: {
            [sourceDetailKey]: shopMetricWorkflowIds?.includes(worfklowId)
              ? shopMetricWorkflowIds.filter((id) => id !== worfklowId)
              : [...shopMetricWorkflowIds, worfklowId],
          },
        },
        { merge: true },
      );
    },
    [shopMetricWorkflowIds, sourceDetailKey, source],
  );

  const onBookDemo = () => {
    window.open('https://share.hsforms.com/1Ef_Iz9vyTD6iuF4TwkuHMAc3zyl');
    genericEventLogger(analyticsEvents.SEQUENCES, {
      action: sequencesActions.BOOK_DEMO,
      source: source,
    });
  };

  return (
    <div className="flex flex-col gap-5">
      {isAgentSupported ? (
        <>
          {!selectedWorkflow?.id ? (
            <div className="flex flex-col gap-5 px-5 py-5 pt-7">
              {showGeneralInsights && (
                <div className="pl-[5px]">
                  <Text fw={600} color="gray.9" fz={24}>
                    Agent Insights
                  </Text>
                </div>
              )}

              <div className="pl-[5px] flex items-center justify-between">
                <Text color="gray.6" fz={15}>
                  Select an agent from templates or add your own.
                </Text>
                <WorkflowSelector
                  activator={
                    <div>
                      <ActionIcon
                        size="md"
                        variant="activatorWithHover"
                        icon="plus"
                        radius="round"
                        iconSize={10}
                      />
                    </div>
                  }
                  selectedList={shopMetricWorkflowIds}
                  toggleSelection={toggleWorkflowMetric}
                />
              </div>
              {workflowsToShow?.length === 0 && <Text>No agents available</Text>}
              {workflowsToShow?.map((workflow) => {
                return (
                  <div
                    key={workflow.id}
                    onClick={() => {
                      setSelectedWorkflow(workflow);
                      genericEventLogger(analyticsEvents.SEQUENCES, {
                        action: sequencesActions.RUN_WORKFLOW_INSIGHT,
                        source: source,
                        sequence_id: workflow.id,
                        sequence_name: workflow.name,
                        source_details: sourceDetails,
                      });
                    }}
                  >
                    <WorkflowTemplateTile
                      hideButtons={true}
                      key={workflow.id}
                      workflow={{
                        ...workflow,
                        roles: workflow.isGlobal ? workflow.roles : ['shop'],
                      }}
                      workflowCategories={workflowCatsWithColors}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <ViewWorkflow
              workflow={selectedWorkflow}
              backToInsights={() => setSelectedWorkflow(null)}
              useDatePickers={useDatePickers}
              variables={variables}
              source={source}
              sourceDetails={sourceDetails}
            />
          )}
          {showGeneralInsights && (
            <div className="flex flex-col gap-5 p-6.5 pt-0">
              <Text fw={600} color="gray.9" fz={24}>
                Metric Insights
              </Text>
              {generalInsights && generalInsights.length > 0 ? (
                <WillySimpleText text={generalInsights} />
              ) : generalInsightsError ? (
                <Text color="red.4">{generalInsightsError}</Text>
              ) : (
                <Text>Loading data...</Text>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col gap-6 p-10">
          <Text fz={18} fw={500}>
            You are one step away from getting insights!
          </Text>
          <Text fz={14}>Upgrade to get access to agents.</Text>
          <div className="flex justify-end gap-4 pt-4">
            <Button
              variant="primary"
              onClick={() => {
                onBookDemo();
              }}
            >
              Request Demo
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

type ViewWorkflowProps = {
  workflow: WillyDataSequence;
  backToInsights: () => void;
  useDatePickers?: boolean;
  variables?: Record<string, string>;
  source: 'pixel' | 'summary';
  sourceDetails: string | { entity: string; id: string };
};

export const ViewWorkflow: React.FC<ViewWorkflowProps> = ({
  workflow,
  backToInsights,
  useDatePickers,
  variables,
  source,
  sourceDetails,
}) => {
  const { start, end } = useStoreValue($currentDateRange) || {};
  const { start: prevStart, end: prevEnd } = useStoreValue($prevDateRange) || {};
  const [wsWorkflows, setWsWorkflows] = useState<Record<string, WorkflowWsStatus>>({});
  const [wsSteps, setWsSteps] = useState<Record<string, StepWsStatus>>({});
  const [mainWorkflowStopped, setMainWorkflowStopped] = useState(false);
  const activeAccounts = useStoreValue($activeAccounts);
  const [loadingAnswers, setLoadingAnswers] = useState(false);
  const [currentRunId, setCurrentRunId] = useState<string>();
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const currency = useStoreValue($currency);
  const industry = useStoreValue($industry);
  const timezone = useAppSelector((state) => state.shopTimezone);
  const [errorRunSequence, setErrorRunSequence] = useState<string>();
  const realtimeSocket = useStoreValue($socket);
  const user = useAppSelector((state) => state.user);
  const [activeWorkflowId, setActiveWorkflowId] = useState<string>();
  const defaultDialect = useStoreValue($dialect);
  const { dialect } = workflow;

  // const taggedMetricSettings = workflow?.taggedMetrics?.find((tm) => tm.metric === metricId);

  const runSequence = useCallback(
    async (workflowId: string, variables?: Record<string, string>, useDatePickers?: boolean) => {
      if (!activeAccounts) {
        return;
      }
      setLoadingAnswers(true);
      let currentMessageId = uuidV4();
      const newRunId = uuidV4();
      setCurrentRunId(newRunId);
      const requestParams: RunSequenceRequest = {
        source: 'sequence' as const,
        sequenceId: workflowId,
        shopId: currentShopId,
        conversationId: newRunId,
        additionalShopIds: activeAccounts ?? [],
        messageId: currentMessageId,
        question: '<question will generate from sequence>',
        generateInsights: true,
        stream: false,
        currency,
        timezone: timezone,
        dialect: (dialect as Dialect) || defaultDialect,
        industry: industry || 'other',
        conversationLink: window.location.href,
        startDate: useDatePickers ? start?.format('YYYY-MM-DD') : undefined,
        endDate: useDatePickers ? end?.format('YYYY-MM-DD') : undefined,
        prevStartDate: useDatePickers
          ? prevStart
            ? prevStart?.format('YYYY-MM-DD')
            : 'previous period'
          : undefined,
        prevEndDate: useDatePickers
          ? prevEnd
            ? prevEnd?.format('YYYY-MM-DD')
            : 'previous period'
          : undefined,
        variables: variables,
      };
      try {
        setErrorRunSequence(undefined);

        await sleep(1000);

        // reconnect before emitting
        // socket.disconnect();
        // socket.connect();
        // socket.emit('run-sequence', requestParams);
        const authBody = { shopId: currentShopId, userId: user.uid };
        realtimeSocket.emit('subscribe', { channel: `workflow:${newRunId}`, ...authBody });
        const response = await axiosInstance.post('/v2/sequences/workflows/run', requestParams);
        setActiveWorkflowId(response.data.workflowId);
      } catch (error: any) {
        const errorMessage = error.message || 'Error running sequence';
        console.error(errorMessage);
        setErrorRunSequence(errorMessage);
      }
    },
    [
      activeAccounts,
      currentShopId,
      currency,
      dialect,
      industry,
      timezone,
      realtimeSocket,
      user.uid,
      start,
      end,
      defaultDialect,
      prevStart,
      prevEnd,
    ],
  );

  const firstStepStarted = useMemo(() => {
    const someStepStarted = Object.values(wsSteps).some((step) => !!step.status);
    return someStepStarted;
  }, [wsSteps]);

  const stopWorkflow = useCallback(async () => {
    setWsWorkflows({});
    setWsSteps({});
    setMainWorkflowStopped(true);
    setLoadingAnswers(false);
    realtimeSocket.emit('stop-sequence', {
      sequenceId: workflow.id,
      shopId: currentShopId,
      messageId: currentRunId || '',
    });
    if (activeWorkflowId) {
      await axiosInstance.post('/v2/sequences/workflows/cancel', {
        shopId: currentShopId,
        workflowId: activeWorkflowId,
      });
    }
  }, [currentShopId, realtimeSocket, workflow.id, currentRunId, activeWorkflowId]);

  useEffect(() => {
    if (workflow.id) {
      runSequence(workflow.id, variables, useDatePickers);
    }
  }, [workflow.id, runSequence, useDatePickers, variables]);

  const workflowRunningOrLoading = wsWorkflows[workflow.id]?.status === 'running' || loadingAnswers;

  const lastStepInsightId = useMemo(() => {
    return workflow?.steps?.[workflow?.steps?.length - 1]?.stepType === 'insights'
      ? workflow?.steps?.[workflow?.steps?.length - 1]?.id
      : null;
  }, [workflow?.steps]);

  const stepsToWaitForLoad = useMemo(() => {
    if (!!lastStepInsightId) {
      return workflow?.steps?.slice(0, -1);
    }
    return workflow?.steps;
  }, [lastStepInsightId, workflow?.steps]);

  const shouldShowOutput = useMemo(() => {
    return (
      Object.keys(wsSteps)?.length &&
      stepsToWaitForLoad?.every((step) => !!wsSteps[step.id]?.response)
    );
  }, [wsSteps, stepsToWaitForLoad]);

  useSequenceFlowSocket({
    sequenceId: workflow.id,
    runId: currentRunId,
    setWsWorkflows,
    setWsSteps,
    setMainWorkflowStopped,
    shopId: currentShopId,
  });

  useEffect(() => {
    if (mainWorkflowStopped) {
      setLoadingAnswers(false);
    }
  }, [mainWorkflowStopped]);

  const workflowFinishedLoading = useMemo(() => {
    return (
      !!wsWorkflows && wsWorkflows[workflow.id]?.status === 'done' && !!wsWorkflows[workflow.id]
    );
  }, [wsWorkflows, workflow.id]);

  const stepAnswers: Message[] = useMemo(() => {
    const generatedAnswers = workflow?.steps?.map((step) => {
      const generatedAnswer = wsSteps?.[step.id]?.response;
      return generatedAnswer
        ? getMessagesFromWorkflowResponse(generatedAnswer, dialect as Dialect)
        : null;
    });
    return generatedAnswers?.filter((answer) => !!answer)?.flat();
  }, [wsSteps, workflow.steps, dialect]);

  const changeWorkflow = () => {
    stopWorkflow();
    backToInsights();
  };

  useEffect(() => {
    if (!!workflowFinishedLoading) {
      genericEventLogger(analyticsEvents.SEQUENCES, {
        action: sequencesActions.WORKFLOW_FINISHED_LOADING,
        source: source,
        sequence_id: workflow.id,
        sequence_name: workflow.name,
        source_details: sourceDetails,
      });
    }
  }, [workflowFinishedLoading, source, sourceDetails, workflow.id, workflow.name]);

  return (
    <div className="py-10 flex flex-col gap-5 px-12  h-[85vh]">
      <div className="flex flex-col gap-5 flex-1 overflow-scroll no-scrollbar">
        <div className="flex items-center justify-center pb-5 ">
          <Text fw={600}>{workflow.name}</Text>
        </div>
        {!shouldShowOutput ? (
          <div className="flex flex-col gap-8">
            {!firstStepStarted && loadingAnswers && (
              <div className="flex items-center justify-center gap-2 mb-4">
                <Loader size="xs" />
                <Text size="sm" fw={400} color={'gray.4'}>
                  Starting agent...
                </Text>
              </div>
            )}
            <div className="flex flex-col gap-8 items-center pb-5  overflow-scroll">
              {stepsToWaitForLoad?.map((step, stepNumber) => {
                const generatedAnswer = wsSteps?.[step.id];
                return (
                  <Fragment key={step.id}>
                    <SequenceFlowStep
                      dialect={dialect || defaultDialect}
                      sequenceId={workflow.id}
                      step={step}
                      stepNumber={stepNumber}
                      onGenerateAnswer={noop}
                      stepChange={noop}
                      deleteStep={noop}
                      toggleCollapse={noop}
                      isCollapsed={true}
                      loadingAnswers={workflowRunningOrLoading}
                      allowRegenerateAnswers={false}
                      stepRunInfo={generatedAnswer}
                      depth={0}
                      saveSequences={noop}
                      readOnly={true}
                      noMoreStepsAllowed={true}
                      //hack
                      isLastStep={false}
                      useParentWidth={true}
                      viewOnly={true}
                      hideProgress={true}
                      siblingSteps={workflow.steps}
                      isGlobal={workflow.isGlobal}
                    />
                  </Fragment>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-8 items-center pb-5  overflow-scroll no-scrollbar">
            {Object?.values(wsSteps)?.at(-1)?.response?.status === 'error' || !!errorRunSequence ? (
              <div className="flex flex-col items-center">
                <Text size="sm" fw={500} color={'red.4'}>
                  Error
                </Text>
                <Text size="sm" fw={400} color={'gray.4'}>
                  <Tooltip
                    label={
                      <div className="max-w-[300px] whitespace-pre-wrap">
                        {errorRunSequence || 'Error running sequence'}
                      </div>
                    }
                  >
                    <div className="max-w-[300px] overflow-hidden">
                      <span className="line-clamp-3">
                        {errorRunSequence ||
                          Object.values(wsSteps)
                            .map((answer) => answer.error)
                            .filter((error) => !!error)
                            .join('\n')}
                      </span>
                    </div>
                  </Tooltip>
                </Text>
              </div>
            ) : (
              <SummaryInsightsOutputViewer
                messages={stepAnswers}
                lastStepInsightId={lastStepInsightId}
                finishedLoading={workflowFinishedLoading}
              />
            )}
          </div>
        )}
      </div>

      <div className="flex justify-end h-[40px]">
        <Button variant="white" onClick={() => changeWorkflow()}>
          Change Agent
        </Button>
      </div>
    </div>
  );
};

type SummaryInsightsOutputViewerProps = {
  messages: Message[];
  lastStepInsightId: string | null;
  finishedLoading: boolean;
};

export const SummaryInsightsOutputViewer: React.FC<SummaryInsightsOutputViewerProps> = ({
  messages,
  lastStepInsightId,
  finishedLoading,
}) => {
  const [insightsText, setInsightsText] = useState<string>('');
  const [showFullOutput, setShowFullOutput] = useState(false);
  useInsightsStreamSocket({
    messageId: lastStepInsightId ?? '',
    setInsightsText,
  });

  const toggleShowFullOutput = useCallback(() => {
    setShowFullOutput(!showFullOutput);
  }, [showFullOutput]);

  return (
    <div className="flex w-full flex-col">
      {lastStepInsightId && (
        <div>
          {insightsText.length === 0 ? (
            <div className="pl-[5px]">
              <div className="relative blinking-cursor"></div>
            </div>
          ) : (
            <WillySimpleText text={insightsText ?? ''} />
          )}
        </div>
      )}
      <div>
        {messages?.length > 0 && finishedLoading && !!lastStepInsightId && (
          <div className="flex justify-center items-center my-6.5">
            <Button onClick={() => toggleShowFullOutput()}>
              {showFullOutput ? 'Hide' : 'Show'} Data
            </Button>
          </div>
        )}
      </div>
      {(showFullOutput || !lastStepInsightId) && messages?.length > 0 && (
        <div className="flex flex-col gap-6.5">
          {messages?.map((message, i) => {
            return (
              <WillyMessageTemplate
                message={message}
                canEdit={false}
                userName={''}
                handleSubmit={() => {}}
                key={i}
                codeActions={[]}
                conversationUser={''}
                isSequenceMode
                conversationMessages={messages}
                hideDetails
                showToolResults
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
