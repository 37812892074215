import {
  Button,
  ColorPicker,
  HSBColor,
  Popover,
  TextField,
  hexToRgb,
  hsbToHex,
  rgbToHsb,
} from '@shopify/polaris';
import { CancelMajor, ClipboardMinor } from '@shopify/polaris-icons';
import { Flex, Text } from '@tw/ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import copyToClipboard from 'utils/copyToClipboard';

type ColorPickerProps = {
  label?: string;
  color: string;
  className?: string;
  activatorClassName?: string;
  labelSize?: string;
  labelWeight?: string;
  showColorValue?: boolean;
  saveOnChange: boolean;
  onChange: (color: string) => void;
  onReset?: () => void;
};

export const ColorPickerPopover: React.FC<ColorPickerProps> = (props) => {
  const {
    label,
    color,
    className,
    activatorClassName,
    labelSize,
    labelWeight,
    showColorValue,
    onChange,
    onReset,
    saveOnChange,
  } = props;

  const [inputColor, setInputColor] = useState(color);

  const hexToHsb = useCallback((color: string) => {
    if (!color) {
      return { hue: 0, saturation: 0, brightness: 0 };
    }
    return rgbToHsb(hexToRgb(color));
  }, []);

  const hexColor = useCallback((color: HSBColor) => {
    return hsbToHex(color);
  }, []);

  const [showPicker, setShowPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState<HSBColor>(hexToHsb(color));

  const handleColorChange = useCallback(
    (color: HSBColor) => {
      setCurrentColor(color);
      setInputColor(hexColor(color));
      if (saveOnChange) {
        onChange(hexColor(color));
      }
    },
    [hexColor, onChange, saveOnChange],
  );

  useEffect(() => {
    setInputColor(color);
    setCurrentColor(hexToHsb(color));
  }, [color, hexToHsb]);

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      {!!label && (
        <Text size={labelSize ?? 'md'} weight={labelWeight ?? 'normal'}>
          {label}
        </Text>
      )}
      <Popover
        active={showPicker}
        onClose={() => setShowPicker(false)}
        activator={
          <Flex gap="xs">
            {!!showColorValue && (
              <Text size="sm" color="gray.6">
                {color}
              </Text>
            )}
            <span
              className={`flex w-4 h-4 rounded-sm cursor-pointer ${activatorClassName}`}
              style={{
                backgroundColor: color,
                border: '1px solid #000',
              }}
              onClick={() => setShowPicker((x) => !x)}
            />
          </Flex>
        }
      >
        <Popover.Pane fixed sectioned>
          <span className="absolute right-0 top-0 -translate-x-1/4 translate-y-1/4">
            <Button plain onClick={() => setShowPicker(false)} icon={CancelMajor} size="slim" />
          </span>
          <ColorPicker color={currentColor} onChange={handleColorChange} />
        </Popover.Pane>
        <Popover.Pane sectioned>
          <TextField
            type="text"
            value={inputColor}
            onChange={(v) => {
              const valid = /^#[0-9A-F]{6}$/i.test(v);
              setInputColor(v);
              if (valid) {
                setCurrentColor(hexToHsb(v));
                onChange(v);
              }
            }}
            label
            labelHidden
            autoComplete="off"
            connectedRight={<Button onClick={() => copyToClipboard(color)} icon={ClipboardMinor} />}
          />
        </Popover.Pane>
        <Popover.Pane fixed sectioned>
          <div className="flex flex-row gap-2 justify-between">
            {!!onReset && (
              <Button
                plain
                onClick={() => {
                  setInputColor(color);
                  setCurrentColor(hexToHsb(color));
                  onChange(color);
                  onReset();
                }}
              >
                Reset
              </Button>
            )}
            <Button
              primary
              onClick={() => {
                onChange(hexColor(currentColor));
                setShowPicker(false);
              }}
              size="slim"
            >
              Save
            </Button>
          </div>
        </Popover.Pane>
      </Popover>
    </div>
  );
};
