import moment from 'moment';
import { Column, ColumnName, retentionPeriodItems } from '../types/willyTypes';

export const isRetentionPeriodExpired = (column: Column<ColumnName>, createdAt: string) => {
  if (column.retentionPeriod === 'forever' || !column.retentionPeriod) {
    return false;
  }

  const retentionPeriod = column.retentionPeriod;

  const now = moment();
  const createdAtDate = moment(createdAt);
  const diffInMs = now.diff(createdAtDate);

  return diffInMs > (retentionPeriodItems[retentionPeriod]?.asMs || Infinity);
};
